import React from "react";
import { Breadcrumb } from "../../atoms/breadcrumb";
import { PAGE_NAME } from "../../../constants/constants";
import { UnderConstruction } from "../../organisms/underConstruction";

const InventoryManagement= () => {
    const pageClassName = "p-inventoryManagement";

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.INVENTORY_MANAGEMENT}</p>
            <div className={`${pageClassName}__container`}>
                <UnderConstruction />
            </div>
        </div>
    );
};

export default InventoryManagement;
