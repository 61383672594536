import React from 'react'
import { createComponentBase, BaseComponentProps } from "../../../libs/components";
export interface SkeletonProps extends BaseComponentProps {
  className?: string
  height?: 'small'|'semiSmall' | 'medium' | 'large'
}

export const Skeleton = ({ className, height }: SkeletonProps) => {
  const componentClassName = 'a-skeleton'
  const modifiers = {
    [height + 'Height']: !!height,

  }
  const { Container } = createComponentBase(componentClassName, 'div')
  return (
    <Container className={className} modifiers={modifiers}>
      <div className={`${componentClassName}__content`}></div>
    </Container>
  )
}
