import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { useSingleSaasToolQuery } from "../../../../../store/saasTool/saasToolSlice";
import { Status } from "../../../../atoms/status";
import { PAGE_NAME } from "../../../../../constants/constants";

const ConfluenceUserDetails = () => {
  const pageClassName = "p-confluenceUserDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isSuccess } = useSingleSaasToolQuery({ id });

  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: true,
    },
    {
      title: "Number of Members",
      dataIndex: "totalNumberUsers",
      isSortable: false,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      isSortable: true,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      isSortable: true,
    },
  ];

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>
        {PAGE_NAME.CONFLUENCE_USER_INFO}
      </p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            First Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.firstName ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.lastName ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Email</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? <Skeleton height="small" /> : data?.result?.oktaUser?.email ?? "Anonymous User"}
          </p>
        </div>
        {data?.result?.oktaUser?.secondEmail && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Second Email
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.secondEmail}
            </p>
          </div>
        )}
        {data?.result?.oktaUser?.mobilePhone && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Mobile Phone
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.mobilePhone}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Status</p>
          {data?.result?.status ? (
            <Status status={data?.result?.status} />
          ) : (
            <Skeleton height="small" />
          )}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Activity Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.lastActivity ? (
                formattedDateTime(data?.result?.lastActivity)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        {data?.result?.groups?.length !== 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Groups</p>

              <Table
                header={appsTableHeader}
                data={data?.result?.groups ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                sortHandler={() => { }}
                startIndex={1}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfluenceUserDetails;
