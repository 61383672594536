import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isSidebarOpen: true,
};

/**
 * Represents the sidebar slice of the Redux store.
 */
const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialState,
  reducers: {
    /**
     * Sets the value of isSidebarOpen in the sidebar state.
     * @param state - The current sidebar state.
     * @param action - The action containing the new value for isSidebarOpen.
     */
    setSidebarToggle(state, action) {
      state.isSidebarOpen = action.payload;
    },
  },
});

export default sidebarSlice.reducer;
export const sidebarActions = sidebarSlice.actions;
