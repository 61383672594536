import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import {
  useSaasToolGroupUsersQuery,
  useSingleSaasToolGroupQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { DataTable } from "../../../../organisms/dataTable";
import { PAGE_NAME } from "../../../../../constants/constants";

const AWSGroupDetails = () => {
  const pageClassName = "p-awsGroupDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isSuccess } = useSingleSaasToolGroupQuery({ id });

    const tableHeader = [
      {
          title: "Serial",
          dataIndex: "id",
          isSortable: true,
      },
      {
          title: "First Name",
          dataIndex: "userFirstName",
          isSortable: true,
      },
      {
          title: "Last Name",
          dataIndex: "userLastName",
          isSortable: true,
      },
      {
          title: "Email",
          dataIndex: "userEmail",
          isSortable: true,
      }
  ];

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.AWS_GROUP_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            AWS Group Id
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.toolGroupId ? (
                data?.result?.toolGroupId
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.name : <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Number of Members
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.numberOfMembers
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Created At
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.createdAt ? (
                formattedDateTime(data?.result?.createdAt)
              ) : (
                <Skeleton height="small" />
              )
            ) : (
              "-"
            )}
          </p>
        </div>

        <DataTable
          title="Groups"
          id={id as string}
          toolName="AWS"
          tableHeader={tableHeader}
          useCallApiQuery={useSaasToolGroupUsersQuery}
        />
      </div>
    </div>
  );
};

export default AWSGroupDetails;
