import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useBroadcastUsersQuery,
  useSingleBroadcastQuery,
} from "../../../../store/broadcast/broadcastSlice";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Skeleton } from "../../../atoms/skeleton";
import { formattedDateTime } from "../../../../utils";
import { DataTable } from "../../../organisms/dataTable";
import { PAGE_NAME, ROUTE } from "../../../../constants/constants";
//useBroadcastUsersQuery

const SingleBroadcast = () => {
  const pageClassName = "p-broadcastDetails";
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const { data, isSuccess } = useSingleBroadcastQuery(parseInt(id as string));

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      isSortable: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      isSortable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      isSortable: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      isSortable: true,
    },
  ];

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.BROADCAST_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Type</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.type : <Skeleton height="small" />}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Subject</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.subject : <Skeleton height="small" />}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Saas Tool
          </p>
          <p className={`${pageClassName}__container__item__apps`}>
            {isSuccess ? (
              data?.result?.saasTools ? (
                data?.result?.saasTools?.map((app: any, index: number) => (
                  <span key={index}>
                    {app}{" "}
                    {index !== data?.result?.saasTools?.length - 1 ? ", " : ""}
                  </span>
                ))
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        {data?.result?.group && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Group Name
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.group?.name}
            </p>
          </div>
        )}

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Created By
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.createdBy?.firstName}{" "}
            {data?.result?.createdBy?.middleName}{" "}
            {data?.result?.createdBy?.lastName}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Attachments
          </p>
          <p className={`${pageClassName}__container__item__attachments`}>
            {isSuccess ? (
              data?.result?.attachments ? (
                data?.result?.attachments?.map(
                  (attachment: any, index: number) => (
                    <a
                      key={index}
                      href={`${attachment?.base_path}/${attachment.file_path}`}
                      download
                      target="_blank"
                      className={`${pageClassName}__container__item__attachments__link`} rel="noreferrer"
                    >
                      {attachment.file_name}
                    </a>
                  )
                )
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Message</p>
          {isSuccess ? data?.result?.message : <Skeleton height="small" />}
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Created At
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              formattedDateTime(data?.result?.createdAt)
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <DataTable
          title="Recipients"
          id={id as string}
          tableHeader={tableHeader}
          useCallApiQuery={useBroadcastUsersQuery}
          viewHandler={(id: string) => navigate(ROUTE.OKTA_USER_LIST + `/${id}`)}
          cursorPointer
        />
      </div>
    </div>
  );
};

export default SingleBroadcast;
