import React from "react";
import { Input } from "../../../atoms/input";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME, ROUTE, SOMETHING_WENT_WRONG } from "../../../../constants/constants";
import {  useNavigate } from "react-router-dom";
import { useAddRoleMutation, useCategoryWisePermissionListQuery } from "../../../../store/role/roleSlice";
import { MultipleCheckbox } from "../../../atoms/multipleCheckbox";
import { Divider } from "../../../atoms/divider";
import { ResponseType, payloadType } from "../../../../types";
import { toast } from "react-toastify";
import { Loader } from "../../../atoms/loader";

const AddRole = () => {
    const pageClassName = "p-addRole";
    const navigate = useNavigate();

    const { data: permissions, isLoading: permissionLoading, isSuccess: permissionSuccess } = useCategoryWisePermissionListQuery({});
    const [addAPI] = useAddRoleMutation();

    const [payload, setPayload] = React.useState<payloadType>({
        displayTitle: {
            value: "",
            error: "",
            isError: false,
        },
        permissions: {
            value: [],
            error: "",
            isError: false,
        },
    });

    const submitHandler = async (e: any) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("displayTitle", payload.displayTitle.value as string);
        (payload.permissions.value as number[]).map((permissionId) =>
            formData.append("permissions[]", permissionId.toString())
        );
        const response = await addAPI(formData) as ResponseType;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate(ROUTE.UM_TOOL_USER_ROLE_LIST);
        } else {
            if (response?.error?.errors) {
                Object.keys(response?.error?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.errors[key][0],
                            isError: true,
                        },
                    }));
                    //   }
                });
            } else {
                toast.error(response?.error?.message);
            }
            if (response?.error?.data?.message) {
                toast.error(response?.error?.data?.message);
            }
            if (response?.error?.errors) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={`${pageClassName}`}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.ROLE_CREATE}
            </p>
            <Loader isLoading={permissionLoading} >
            <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                <div className={`${pageClassName}__form__item`}>
                    <p className={`${pageClassName}__form__item__label`}>Display Title</p>
                    <Input
                        onChange={(e) =>
                            setPayload({
                                ...payload,
                                displayTitle: {
                                    value: e.target.value,
                                    error: "",
                                    isError: false,
                                },
                            })
                        }
                        placeholder="Display Title"
                        value={payload.displayTitle.value as string}
                        error={payload.displayTitle.error}
                        isError={payload.displayTitle.isError}
                    />
                </div>
                <div className={`${pageClassName}__form__item`}>
                    <p className={`${pageClassName}__form__item__label`}>Permissions</p>
                    <MultipleCheckbox
                        options={permissions?.result?.data || []}
                        selectedValue={payload.permissions.value as number[]}
                        totalPermissions={permissions?.result?.total || 0}
                        onCheckboxChange={(permissions) =>
                            setPayload({
                                ...payload,
                                permissions: {
                                    value: permissions as number[],
                                    error: "",
                                    isError: false,
                                },
                            })
                        }
                        isSuccess={permissionSuccess}
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__form__action`}>
                    <button
                        type="submit"
                        className={`${pageClassName}__form__action__button`}
                    >
                        Submit
                    </button>
                </div>
            </form>
            </Loader>
        </div>
    );
};

export default AddRole;
