import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import {
  PAGE_NAME,
  PERMISSIONS,
  REQUEST_MANAGEMENT_SAAS_TOOLS,
  REQUEST_MANAGEMENT_TYPE_OPTIONS,
  ROUTE,
} from "../../../../../constants/constants";
import { Input } from "../../../../atoms/input";
import { hasPermission, pascalToSnakeCase } from "../../../../../utils";
import { Table } from "../../../../atoms/table";
import { useNavigate } from "react-router-dom";
import {
  useApproverListQuery,
  useDeleteApproverMutation,
} from "../../../../../store/requestManagement/requestSlice";
import { Pagination } from "../../../../molecules/pagination";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { Dialog } from "../../../../atoms/dialog";
import { toast } from "react-toastify";
import { Icon } from "../../../../atoms/icon";


const OwnerList = () => {
  const pageClassName = "p-ownerList";

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState<number>();

  const filterRef = React.useRef<HTMLDivElement>(null);
  const [isShowFilter, setShowFilter] = React.useState(false);

  const [toolName, setToolName] = React.useState<{
    label: string;
    value: string;
  }>({
    label: "Select",
    value: "",
  });
  const [type, setType] = React.useState<{ label: string; value: string }>({
    label: "Select",
    value: "",
  });

  const [filtered, setFiltered] = React.useState({
    toolName: "",
    type: "",
  });

  const navigate = useNavigate();
  const {
    search,
    query,
    sortHandler,
    searchHandler,
    limitChangeHandler,
    pageChangeHandler,
  } = useTableHooks();

  const { data, isLoading, isFetching } = useApproverListQuery({
    search: query.search,
    sort_by: pascalToSnakeCase(query.sortBy),
    sort_direction: query.sortDirection,
    limit: query.limit,
    current_page: query.current,
    saasTool: filtered.toolName,
    type: filtered.type,
  });

  const [deleteApi] = useDeleteApproverMutation();

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "SaaS Name",
      dataIndex: "saasTool",
      isSortable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: true,
    },
    {
      title: "Approver",
      dataIndex: "approver",
      isSortable: true,
    },
  ];
  const tableHeaderWithPermission = [
    ...tableHeader,
    {
      title: "Action",
      dataIndex: "actionDeleteEdit",
      isSortable: false,
    },
  ];

  const tableHeaderWithEditPermission = [
    ...tableHeader,
    {
      title: "Action",
      dataIndex: "actionEdit",
      isSortable: false,
    },
  ];
  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: data?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  const toolSortHandler = (sortBy: string) => {
    if (sortBy === "createdAtTime") {
      sortBy = "createdAt";
    }

    sortHandler(sortBy);
  };

  const removeHandler = (id: string) => {
    setIsDialogOpen(!isDialogOpen);
    setDeleteId(parseInt(id));
  };

  const deleteHandler = async () => {
    setIsDialogOpen(!isDialogOpen);
    let response = (await deleteApi(deleteId as number)) as any;

    if (response?.data?.success) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.error?.data?.message);
    }
  };

  const closeDialogHandler = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const editHandler = (id: string) => {
    navigate(ROUTE.APPROVER_LIST + ROUTE.EDIT, { state: { id: id } });
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
        if (
            filterRef.current &&
            !filterRef.current.contains(event.target as Node)
        ) {
            setShowFilter(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.APPROVER_LIST}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__header`}>
          <div className={`${pageClassName}__search`}>
            <Input
              placeholder="Search"
              onChange={searchHandler}
              value={search}
            />
          </div>
          <div className={`${pageClassName}__filterBy`}>
            {(filtered.toolName || filtered.type) && (
              <p>
                Filtered By{" "} 
                <span>{filtered.toolName ? `SaaS Name "${filtered.toolName}" ` : ""}</span>
                <span>{filtered.toolName && filtered.type ? `And Type "${filtered.type}"` : filtered.type ? `Type "${filtered.type}"` : ""}</span>
              </p>
            )}
          </div>
          <div className={`${pageClassName}__group`}>
            {hasPermission(PERMISSIONS.APPROVER_CREATE.title) && (
              <button
                className={`${pageClassName}__button`}
                onClick={() => navigate(ROUTE.APPROVER_LIST + ROUTE.ADD)}
              >
                Add Approver
              </button>
            )}
            <div className={`${pageClassName}__filter`} ref={filterRef}>
              <button
                onClick={() => setShowFilter(!isShowFilter)}
                className={`${pageClassName}__filter__button`}
                title="Filter"
              >
                <Icon icon="filter" size="primary" />
              </button>
              {isShowFilter && (
                <div className={`${pageClassName}__filter__container`}>
                  <div className={`${pageClassName}__filter__item`}>
                    <label>SaaS Name</label>
                    <Dropdown
                      options={REQUEST_MANAGEMENT_SAAS_TOOLS}
                      selected={toolName as { label: string; value: string }}
                      onClickChange={(e) =>
                        setToolName(e as { label: string; value: string })
                      }
                      isBordered
                      isIcon
                      iniDirection="top"
                    />
                  </div>

                  <div className={`${pageClassName}__filter__item`}>
                    <label>Type</label>
                    <Dropdown
                      options={REQUEST_MANAGEMENT_TYPE_OPTIONS}
                      selected={type as { label: string; value: string }}
                      onClickChange={(e) =>
                        setType(e as { label: string; value: string })
                      }
                      isBordered
                      isIcon
                      iniDirection="top"
                    />
                  </div>

                  <Divider />
                  <div className={`${pageClassName}__filter__btn`}>
                    <button
                      onClick={() => {
                        setToolName({ label: "Select", value: "" });
                        setType({ label: "Select", value: "" });
                        setFiltered({
                          toolName: "",
                          type: "",
                        });
                        setShowFilter(false);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      onClick={() => {
                        setShowFilter(false);
                        setFiltered({
                          toolName: toolName.value,
                          type: type.value,
                        });
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${pageClassName}__table`}>
          <Table
            header={
              hasPermission(PERMISSIONS.APPROVER_DELETE.title) &&
                hasPermission(PERMISSIONS.APPROVER_EDIT.title)
                ? tableHeaderWithPermission
                : hasPermission(PERMISSIONS.APPROVER_EDIT.title)
                  ? tableHeaderWithEditPermission
                  : tableHeader
            }
            data={data?.result?.data ?? []}
            loading={isLoading || isFetching}
            firstLoad={isLoading}
            sortHandler={(sortBy: string) => toolSortHandler(sortBy)}
            editHandler={editHandler}
            deleteHandler={removeHandler}
            minWidth="800"
            startIndex={data?.result?.from}
            sortBy={query.sortBy}
            cursorPointer
          />
        </div>
        <Divider />
        <div className={`${pageClassName}__pagination`}>
          <div className={`${pageClassName}__pagination__limit`}>
            <p>Display</p>
            <div className={`${pageClassName}__pagination__dropdown`}>
              <Dropdown
                options={[
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                selected={{ label: query.limit, value: query.limit }}
                onClickChange={(e) => {
                  limitChangeHandler(e.value as number);
                  handleFirstPage();
                }}
                isBordered
                isIcon
                iniDirection="top"
              />
            </div>
          </div>
          <div className={`${pageClassName}__pagination__number`}>
            {data?.result?.total ? (
              <p>
                Show {data?.result?.from} -{" "}
                {data?.result?.from + query.limit - 1 < data?.result?.total
                  ? data?.result?.from + query.limit - 1
                  : data?.result?.total}{" "}
                of {data?.result?.total}
              </p>
            ) : (
              <p>Show 00 - 00 of 00</p>
            )}
          </div>

          <div className={`${pageClassName}__pagination__nav`}>
            {data?.result?.total > query.limit && (
              <Pagination
                totalData={totalData}
                page={query.current}
                handleFirstPage={handleFirstPage}
                handlePrevPage={handlePrevPage}
                handleLastPage={handleLastPage}
                handleNextPage={handleNextPage}
                renderPageNumbers={renderPageNumbers}
              />
            )}
          </div>
        </div>
      </div>

      <Dialog
        header="Delete Approver"
        body={
          <div className={`${pageClassName}__dialog`}>
            <p className={`${pageClassName}__dialog__text`}>
              Are you sure you want to delete this approver?
            </p>
            <p className={`${pageClassName}__dialog__text`}>
              This action cannot be undone.
            </p>
          </div>
        }
        isOpen={isDialogOpen}
        onClose={closeDialogHandler}
        successFn={deleteHandler}
        btn="Delete"
        icon="delete"
      />
    </div>
  );
};

export default OwnerList;
