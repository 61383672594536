import { apiSlice } from "../api/apiSlice";
import { ResponseDataType, listDataType } from "../../types";
import { API_URL } from "../../constants/constants";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["oktaUserList"],
});

export const userSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves a list of Okta users based on the provided parameters.
     *
     * @param search - The search term to filter the users.
     * @param limit - The maximum number of users to retrieve.
     * @param current_page - The current page number for pagination.
     * @param sort_by - The field to sort the users by.
     * @param sort_direction - The direction to sort the users in (ascending or descending).
     * @returns A Promise that resolves to the response data containing the list of Okta users.
     */
    oktaUserList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url: API_URL.OKTA_USERS + `?${limit ? `limit=${limit}` : ""}${
          current_page ? `&current_page=${current_page}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ""}${
          sort_direction ? `&sort_direction=${sort_direction}` : ""
        }${search ? `&search=${search}` : ""}`,

        method: "GET",
      }),
      providesTags: ["oktaUserList"],
    }),
    /**
     * Fetches a single Okta user from the server.
     * @param id - The ID of the user to fetch.
     * @returns A Promise that resolves to the response data of the user.
     */
    singleOktaUser: builder.query<ResponseDataType, listDataType>({
      query: ({ id }) => ({
        url: API_URL.OKTA_USERS + `/${id}`,
        method: "GET",
      }),
      providesTags: ["oktaUserList"],
    }),
  }),
});

export const { 
  useOktaUserListQuery,
  useSingleOktaUserQuery,
 } = userSlice;
