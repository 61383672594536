import React from 'react';
import { createComponentBase, BaseComponentProps } from "../../../libs/components";
import { Icon } from '../../atoms/icon';
import { Divider } from '../../atoms/divider';

export interface ModalProps extends BaseComponentProps {
  className?: string;
  children?: React.ReactNode;
  header?: string;
  isOpen: boolean;
  width?: 'small' | 'large';
  headerColor?: 'error'
  onClose: () => void;
}

export const Modal = ({
  children,
  header,
  isOpen,
  onClose,
  width,
  headerColor,
  className,
}: ModalProps) => {
  const componentClassName = 'm-Modal';
  const modifiers = {
    [width + 'Width']: !!width,
    [headerColor + 'Color']: !!headerColor,
  };
  const { Container }: { Container: React.ElementType } = createComponentBase(componentClassName, 'div');

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (isOpen && event.target.className === `${componentClassName}__container`) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  return (
    <Container className={className} modifiers={modifiers}>
      {isOpen && (
        <div className={`${componentClassName}__container`}>
          <div className={`${componentClassName}__content`}>
            <div className={`${componentClassName}__header`}>
              <p className={`${componentClassName}__header__title`}>
                {header}
              </p>
              <div className={`${componentClassName}__header__icon`} onClick={onClose}>
                <Icon icon='cross' mask />
              </div>
            </div>
            <Divider />
            <div className={`${componentClassName}__body`}>
              {children}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};
