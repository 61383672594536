import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
// import { UnderConstruction } from "../../../organisms/underConstruction";
import { usePendingRequestListQuery, useReviewRequestMutation } from "../../../../store/requestManagement/requestSlice";
import { formattedDateTime } from "../../../../utils";
import { Skeleton } from "../../../atoms/skeleton";
import { Input } from "../../../atoms/input";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { Pagination } from "../../../molecules/pagination";
import { Dropdown } from "../../../atoms/dropdown";
import { Dialog } from "../../../atoms/dialog";
import { toast } from "react-toastify";
import { PAGE_NAME } from "../../../../constants/constants";
import { Textarea } from "../../../atoms/textarea";
import { Loader } from "../../../atoms/loader";
import { Icon } from "../../../atoms/icon";
import { Modal } from "../../../molecules/modal";
import { BACKEND_URL } from "../../../../constants/urls";

const PendingRequestList = () => {
    const pageClassName = "p-pendingRequest";

    const [reviewAPI, { isLoading: reviewLoading }] = useReviewRequestMutation();

    const [isOpenComment, setIsOpenComment] = React.useState(false);
    const [comments, setComments] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState("");
    const [query, setQuery] = React.useState({
        current: 1,
        limit: 20,
        search: "",
        duration: 0,
        toolNames: [] as string[],
    });

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [reviewData, setReviewData] = React.useState({
        status: "",
        id: 0,
        comment: "",
    });


    const { data, isLoading } = usePendingRequestListQuery({
        search: search,
        limit: query.limit,
        current_page: query.current
    });

    const pageChangeHandler = (page: number) => {
        setQuery({ ...query, current: page });
    };

    const limitChangeHandler = (limit: number) => {
        setQuery({ ...query, limit: limit });
    };

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setQuery({ ...query, current: 1 });
    };

    const closeDialogHandler = () => {
        setIsDialogOpen(!isDialogOpen);
        setReviewData({
            status: "",
            id: 0,
            comment: "",
        });
    };

    const reviewHandler = async () => {
        setIsDialogOpen(!isDialogOpen);
        const fromData = new FormData();

        fromData.append("status", reviewData.status);
        fromData.append("id", reviewData.id.toString());
        fromData.append("comment", reviewData.comment);

        let response = (await reviewAPI(fromData)) as any;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.error?.data?.message);
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.PENDING_REQUEST_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>
                </div>
                <Loader isLoading={reviewLoading} >
                    <div className={`${pageClassName}__container__content`}>
                        {isLoading ? (
                            <div className={`${pageClassName}__skeleton`}>
                                {[1, 2, 3, 4, 5, 6].map((item: any) => (
                                    <div className={`${pageClassName}__skeleton__container`}>
                                        <div className={`${pageClassName}__skeleton__data`}>
                                            <div className={`${pageClassName}__skeleton__saas`}>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => (
                                                    <div
                                                        className={`${pageClassName}__skeleton__saas__tool`}
                                                        key={item}
                                                    >
                                                        <div
                                                            className={`${pageClassName}__skeleton__saas__logo`}
                                                        >
                                                            <Skeleton height="small" />
                                                        </div>
                                                        <div
                                                            className={`${pageClassName}__skeleton__saas__date`}
                                                        >
                                                            <Skeleton height="small" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            data?.result?.data?.length > 0 ?
                                <div className={`${pageClassName}__container__userRequest`}>
                                    {data?.result?.data?.map((request: any, index: number) => (
                                        <div
                                            className={`${pageClassName}__container__userRequest__content`}
                                            key={index}
                                        >
                                            <div
                                                className={`${pageClassName}__container__userRequest__content__container`}
                                            >
                                                <div
                                                    className={`${pageClassName}__container__userRequest__content__action`}
                                                >
                                                    <Dropdown
                                                        options={[
                                                            { label: "Approved", value: "Approved" },
                                                            { label: "Rejected", value: "Rejected" },
                                                        ]}
                                                        selected={{ label: "Review", value: "" }}
                                                        placeholder="Review"
                                                        onClickChange={(e) => {
                                                            setIsDialogOpen(!isDialogOpen);
                                                            setReviewData({
                                                                ...reviewData,
                                                                status: e.value as string,
                                                                id: request?.id,
                                                            });
                                                        }}
                                                        isBordered
                                                        isIcon
                                                        iniDirection="top"
                                                    />
                                                </div>
                                                <div
                                                    className={`${pageClassName}__container__userRequest__item`}
                                                >
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__title`}
                                                    >
                                                        Type
                                                    </p>
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__value`}
                                                    >
                                                        {request?.request.type}
                                                    </p>
                                                </div>

                                                <div
                                                    className={`${pageClassName}__container__userRequest__item`}
                                                >
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__title`}
                                                    >
                                                        Sub Type
                                                    </p>
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__value`}
                                                    >
                                                        {request?.request.sub_type}
                                                    </p>
                                                </div>

                                                {request?.firstName && request?.lastName && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            User Name
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.firstName} {request?.lastName}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.designation && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Designation
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.designation}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.department && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Department
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.department}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.address && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Address
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.address}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.manager && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Manager
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.manager?.middleName
                                                                ? `${request?.manager?.firstName} ${request?.manager?.middleName} ${request?.manager?.lastName}`
                                                                : `${request?.manager?.firstName} ${request?.manager?.lastName}`}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.email && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Email
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.email}
                                                        </p>
                                                    </div>
                                                )}

                                                <div
                                                    className={`${pageClassName}__container__userRequest__item`}
                                                >
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__title`}
                                                    >
                                                        SaaS Tool
                                                    </p>
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__value`}
                                                    >
                                                        {request?.saasTool}
                                                    </p>
                                                </div>

                                                {request?.type && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Type
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.type}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.type && request?.name && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            {request?.type} Name
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.name}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.type && request?.newName && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            {request?.type} New Name
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.newName}
                                                        </p>
                                                    </div>
                                                )}

                                                {request?.role && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Role
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.role}
                                                        </p>
                                                    </div>
                                                )}
                                                {request?.approvers?.length > 0 && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Approvers
                                                        </p>
                                                        <div
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.approvers.map(
                                                                (approver: any, index: number) => (
                                                                    <p key={index}>
                                                                        {approver?.middleName
                                                                            ? `${approver?.firstName} ${approver?.middleName} ${approver?.lastName}`
                                                                            : `${approver?.firstName} ${approver?.lastName}`}
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                <div
                                                    className={`${pageClassName}__container__userRequest__item`}
                                                >
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__title`}
                                                    >
                                                        Status
                                                    </p>
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__status`}
                                                    >
                                                        {request?.status}
                                                    </p>
                                                </div>

                                                {request?.reviewedBy && (
                                                    <div
                                                        className={`${pageClassName}__container__userRequest__item`}
                                                    >
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__title`}
                                                        >
                                                            Reviewed By
                                                        </p>
                                                        <p
                                                            className={`${pageClassName}__container__userRequest__item__value`}
                                                        >
                                                            {request?.reviewedBy?.middleName
                                                                ? `${request?.reviewedBy?.firstName} ${request?.reviewedBy?.middleName} ${request?.reviewedBy?.lastName}`
                                                                : `${request?.reviewedBy?.firstName} ${request?.reviewedBy?.lastName}`}
                                                        </p>
                                                    </div>
                                                )}

                                                <div
                                                    className={`${pageClassName}__container__userRequest__item`}
                                                >
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__title`}
                                                    >
                                                        Created Time
                                                    </p>
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__value`}
                                                    >
                                                        {formattedDateTime(request?.request?.created_at)}
                                                    </p>
                                                </div>

                                                <div
                                                    className={`${pageClassName}__container__userRequest__item`}
                                                >
                                                    <p
                                                        className={`${pageClassName}__container__userRequest__item__title`}
                                                    >
                                                        Comments
                                                    </p>
                                                    <button
                                                        className={`${pageClassName}__container__userRequest__item__comments`}
                                                        onClick={() => {
                                                            setIsOpenComment(true);
                                                            setComments(request?.comments);
                                                        }}
                                                    >
                                                        {request?.comments?.length}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div> :
                                <div className={`${pageClassName}__noData`}>
                                    <p>No data found</p>
                                    <Icon icon="sadFace" size="tertiary" />
                                </div>
                        )}
                    </div>
                </Loader>


                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Dialog
                header="Review Request"
                body={
                    <div className={`${pageClassName}__dialog`}>
                        <p className={`${pageClassName}__dialog__text`}>
                            Are you sure you want to {reviewData.status.toLowerCase()} this request?
                        </p>
                        <p className={`${pageClassName}__dialog__text`}>
                            This action cannot be undone.
                        </p>
                        <Textarea
                            className={`${pageClassName}__dialog__textarea`}
                            placeholder="Add a comment"
                            onChange={(e) => {
                                setReviewData({ ...reviewData, comment: e.target.value });
                            }}
                        />
                    </div>
                }
                isOpen={isDialogOpen}
                onClose={closeDialogHandler}
                successFn={reviewHandler}
                btn={reviewData.status}
                icon={reviewData.status === "Approved" ? "approved" : "rejected"}
                color={reviewData.status === "Approved" ? "green" : undefined}
            />

            <Modal
                isOpen={isOpenComment}
                onClose={() => setIsOpenComment(false)}
                header="Comments"
            >
                <div className={`${pageClassName}__comment`}>
                    {comments.length > 0 ? (
                        comments?.map((comment: any, index: number) => (
                            <div
                                className={`${pageClassName}__comment__container`}
                                key={index}
                            >
                                <div className={`${pageClassName}__comment__header`}>
                                    <div className={`${pageClassName}__comment__username`}>
                                        {comment?.user?.profilePhotoPath && (
                                            <img
                                                src={
                                                    BACKEND_URL + "/" + comment?.user?.profilePhotoPath
                                                }
                                                alt="avatar"
                                                className={`${pageClassName}__comment__username__avatar`}
                                            />
                                        )}
                                        <p>
                                            {comment?.user?.middleName
                                                ? `${comment?.user?.firstName} ${comment?.user?.middleName} ${comment?.user?.lastName}`
                                                : `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                        </p>
                                    </div>

                                    <p className={`${pageClassName}__comment__date`}>
                                        {formattedDateTime(comment?.createdAt)}
                                    </p>
                                </div>
                                <p className={`${pageClassName}__comment__content`}>
                                    {comment?.comment}
                                </p>
                            </div>
                        ))
                    ) : (
                        <div className={`${pageClassName}__comment__notFound`}>
                            <p>No comments found!</p>
                            <Icon icon="sadFace" size="tertiary" />
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default PendingRequestList;
