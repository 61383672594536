import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { PAGE_NAME } from "../../../../../constants/constants";
import { useParams } from "react-router";
import { useLicenseHistoryDetailQuery } from "../../../../../store/license/licenseSlice";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDate, formattedDateTime } from "../../../../../utils";

const LicenseHistoryDetails = () => {
    const pageClassName = "p-licenseHistoryDetails";
    const { id } = useParams<{ id: string }>();
    const { data, isLoading } = useLicenseHistoryDetailQuery(
        parseInt(id as string)
    );

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.LICENSE_HISTORY_DETAILS}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__container__info`}>
                    <div className={`${pageClassName}__container__content`}>
                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Month
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? <Skeleton height="small" /> : data?.result?.month}
                            </p>
                        </div>
                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>Name</p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.license?.name
                                )}
                            </p>
                        </div>
                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Subscription Type
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.subscriptionType ?? "-"
                                )}
                            </p>
                        </div>
                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Pricing
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    "$ " + data?.result?.pricing ?? "-"
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Pricing Structure
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.pricingStructure ?? "-"
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Renewal Date
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.renewalDate ? formattedDate(data?.result?.renewalDate) : "-"
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Total Number of Licenses
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.total ?? 0
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Number of Active Licenses
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.active ?? 0
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Number of Inactive Licenses
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.inactive ?? 0
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Number of Predicted Licenses
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.predicted ?? 0
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Number of Remaining Licenses
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : (
                                    data?.result?.remaining ?? 0
                                )}
                            </p>
                        </div>

                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Created Date
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : data?.result?.createdAt ? (
                                    formattedDateTime(data?.result?.createdAt)
                                ) : (
                                    "-"
                                )}
                            </p>
                        </div>
                        <div className={`${pageClassName}__container__item`}>
                            <p className={`${pageClassName}__container__item__title`}>
                                Updated Date
                            </p>
                            <p className={`${pageClassName}__container__item__value`}>
                                {isLoading ? (
                                    <Skeleton height="small" />
                                ) : data?.result?.updatedAt ? (
                                    formattedDateTime(data?.result?.updatedAt)
                                ) : (
                                    "-"
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LicenseHistoryDetails;
