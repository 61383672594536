import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";
import { Tooltip } from "../tooltip";
import { Icon } from "../icon";

export interface inputProps extends BaseComponentProps {
  value?: string | number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  label?: string;
  height?: "small" | "medium" | "large";
  borderRadius?: "small";
  error?: string;
  isError?: boolean;
  isRequired?: boolean;
}

export const Textarea = ({
  value,
  onChange,
  placeholder,
  label,
  height,
  borderRadius,
  error,
  isError,
  isRequired,
  className,
}: inputProps) => {
  const componentClassName = "a-textarea";
  const modifiers = {
    [borderRadius + "BorderRadius"]: !!borderRadius,
    [height + "Height"]: !!height,
    "error": isError,
  };
  const { Container } = createComponentBase(componentClassName, "div");

  return (
    <Container className={className} modifiers={modifiers}>
      {label && (
        <div className={`${componentClassName}__required`}>
          <p className={`${componentClassName}__label`}>{label}</p>
          {isRequired && (
            <div className={`${componentClassName}__required__icon`}>
              <Icon icon="star" size="medium" />
            </div>
          )}
        </div>
      )}
      <Tooltip message={error} isVisible={isError}>
        <div className={`${componentClassName}__textarea__container`}>
          <textarea
            className={`${componentClassName}__textarea`}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
      </Tooltip>
    </Container>
  );
};
