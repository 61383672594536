import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["organizationList", "organizationDetails", "organizationsPricing"],
});

export const organizationSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Fetches a list of organizations from the server.
     *
     * @param search - The search query to filter organizations.
     * @param limit - The maximum number of organizations to retrieve.
     * @param current_page - The current page number for pagination.
     * @returns A promise that resolves to the response data containing the list of organizations.
     */
    organizationList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page }) => ({
        url: API_URL.ORGANIZATIONS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["organizationList"],
    }),

    /**
     * Fetches organization details from the server.
     *
     * @param id - The ID of the organization to fetch.
     * @returns A promise that resolves to the organization details.
     */
    organizationDetails: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.ORGANIZATIONS + `/${id}`,
        method: "GET",
      }),
      providesTags: ["organizationDetails"],
    }),

    /**
     * Creates a new organization.
     * @param formData - The form data containing the organization details.
     * @returns A promise that resolves to the response data.
     */
    organizationCreate: builder.mutation<ResponseDataType, FormData>({
      query: (formData) => ({
        url: API_URL.ORGANIZATIONS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["organizationList", "organizationDetails", "organizationsPricing"],
    }),

    /**
     * Updates an organization.
     *
     * @param id - The ID of the organization to update.
     * @param formData - The form data containing the updated organization details.
     * @returns A promise that resolves to the updated organization data.
     */
    organizationUpdate: builder.mutation<ResponseDataType, { id: number, formData: FormData }>({
      query: ({ id, formData }) => ({
        url: API_URL.ORGANIZATIONS + `/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["organizationList", "organizationDetails", "organizationsPricing"],
    }),

    /**
     * Deletes an organization.
     *
     * @param id - The ID of the organization to delete.
     * @returns A promise that resolves to the response data.
     */
    organizationDelete: builder.mutation<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.ORGANIZATIONS + `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["organizationList", "organizationDetails", "organizationsPricing"],
    }),
  }),
});

export const {
  useOrganizationListQuery,
  useOrganizationDetailsQuery,
  useOrganizationCreateMutation,
  useOrganizationUpdateMutation,
  useOrganizationDeleteMutation,
} =
  organizationSlice;
