import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { PAGE_NAME, PERMISSIONS, ROUTE, saasToolNameForPayment } from "../../../../constants/constants";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { hasPermission, pascalToSnakeCase } from "../../../../utils";
import { Table } from "../../../atoms/table";
import { Pagination } from "../../../molecules/pagination";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { usePaymentListQuery } from "../../../../store/payment/paymentSlice";

const toolSortHandler = (sortBy: string) => {
    if (sortBy === "createdAtTime") {
        sortBy = "createdAt";
    } else if (sortBy === "updatedAtTime") {
        sortBy = "updatedAt";
    } else if (sortBy === "totalAmountPayment") {
        sortBy = "totalPrice";
    } else if (sortBy === "invoiceFile") {
        sortBy = "filePath";
    } else if (sortBy === "paymentToolName") {
        sortBy = "toolName";
    }
    return sortBy;
};

const PaymentList = () => {
    const pageClassName = "p-paymentList";
    const navigate = useNavigate();

    const [saasTools, setSaasTools] = React.useState<any[]>([]);

    const {
        search,
        query,
        sortHandler,
        searchHandler,
        pageChangeHandler,
        limitChangeHandler,
    } = useTableHooks();

    const { data, isLoading, isFetching } = usePaymentListQuery({
        search: query.search,
        sort_by: pascalToSnakeCase(toolSortHandler(query.sortBy)),
        sort_direction: query.sortDirection,
        limit: query.limit,
        current_page: query.current,
        saas_tools: JSON.stringify(saasTools.map((item: any) => item?.value)),
    });
    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Payment ID",
            dataIndex: "customId",
            isSortable: true,
        },
        {
            title: "Period",
            dataIndex: "paymentPeriod",
            isSortable: true,
        },
        {
            title: "SaaS/Infra",
            dataIndex: "paymentToolName",
            isSortable: false,
        },
        {
            title: "Payment Type",
            dataIndex: "type",
            isSortable: true,
        },
        {
            title: "Subscription Type",
            dataIndex: "subscriptionType",
            isSortable: true,
        },
        {
            title: "No of Licenses",
            dataIndex: "numberOfLicense",
            isSortable: true,
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmountPayment",
            isSortable: true,
        },
        {
            title: "Conversion Rate",
            dataIndex: "conversionRate",
            isSortable: true,
        },
        {
            title: "Invoice File",
            dataIndex: "invoiceFile",
            isSortable: true,
        },

    ];



    const viewHandler = (
        id: string,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if ((e.target as Element).className === "payment__invoiceFile") return;
        navigate(ROUTE.PAYMENT_HISTORY + `/${id}`);
    };

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.PAYMENT_HISTORY}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                    {hasPermission(PERMISSIONS.PAYMENT_MANAGEMENT_CREATE.title) && (
                        <button
                            className={`${pageClassName}__button`}
                            onClick={() =>
                                navigate(ROUTE.PAYMENT_HISTORY + ROUTE.PAYMENT_CREATE)
                            }
                        >
                            Create Payment
                        </button>
                    )}
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        minWidth="1440"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        dropdownOptions={saasToolNameForPayment}
                        selectedType={saasTools}
                        dropdownHandler={(selected: any) => setSaasTools(selected)}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PaymentList;
