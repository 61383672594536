import React from "react";
import { Accordion } from "../../atoms/accordion";
import SimpleBar from "simplebar-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation, useSamlLoginMutation } from "../../../store/auth/authSlice";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/auth/user";
import { toast } from "react-toastify";
import { authActions } from "../../../store/auth/auth";

export const PrivacyPolicy = () => {
    const pageClassName = "p-privacyPolicy";
    const user = useLocation().state?.user;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loginApi, { isLoading }] = useLoginMutation();
    const [samlLoginApi, { isLoading: samlLoading }] = useSamlLoginMutation();

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const toastId = toast.loading("Loading...");
        const formData = new FormData();

        formData.append("email", user.email);
        if (user?.remember_token)
            formData.append("remember_token", user?.remember_token);
        formData.append("password", user.password);
        formData.append("is_agree_terms", "1");
        let response = null;
        if (user?.remember_token)
            response = (await samlLoginApi(formData)) as any;
        else
            response = (await loginApi(formData)) as any;
        if (response?.data?.success) {
            dispatch(userActions.setUser(response.data.result.user));
            dispatch(authActions.setToken(response.data.result.token));
            dispatch(authActions.setPermissions(response.data.result.user.role[0].permissions));
            dispatch(authActions.setRole(response.data.result.user.role[0]));

            toast.update(toastId, {
                render: response?.data?.message,
                type: toast.TYPE.SUCCESS,
                isLoading: isLoading || samlLoading,
                autoClose: 2000,
            });

            navigate("/");
        } else {
            toast.update(toastId, {
                render: response?.error.data?.message,
                type: toast.TYPE.ERROR,
                isLoading: isLoading || samlLoading,
                autoClose: 2000,
            });
        }
    };

    return (
        <SimpleBar style={{ height: "100vh" }}>
            <div className={pageClassName}>
                <div className={`${pageClassName}__body`}>
                    <Accordion
                        heading={
                            <div className={`${pageClassName}__accordion__header`}>
                                <p className={`${pageClassName}__accordion__header__title`}>
                                    Privacy Policy
                                </p>
                                <p className={`${pageClassName}__accordion__header__text`}>
                                    Last updated: 17th Aug 2023
                                </p>
                            </div>
                        }
                    >
                        <div className={`${pageClassName}__accordion`}>
                            <p className={`${pageClassName}__accordion__paragraph`}>
                                The User Management Tool respects your privacy and is committed
                                to protecting your personal information. This Privacy Policy
                                explains how we collect, use, and safeguard your data when you
                                use the Tool.
                            </p>
                            <ul className={`${pageClassName}__accordion__list`}>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        1.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Information We Collect:
                                        </span>
                                        The Tool may collect certain information when you use its
                                        functionalities, including but not limited to user data from
                                        integrated services (Okta, Miro, Youtrack, GCP, GitLab,
                                        Confluence), device information, IP address, email
                                        addresses, log data of Saas Tools and cookies.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        2.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Use of Collected Information:
                                        </span>
                                        We use the collected information to provide and improve the
                                        Tool's functionalities, optimize user experience, and comply
                                        with legal obligations, including GDPR compliance. We use
                                        the data to represent different statistics, generate
                                        reports, generate logs, and run seamless operations of the
                                        tool.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        3.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Data Sharing:
                                        </span>
                                        We don’t share your data with third-party service providers
                                        or partners and we do not share, sell or rent your personal
                                        information to third parties. The data is only shared as
                                        part of generated reports or log reports.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        4.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Data Security:
                                        </span>
                                        We implement reasonable security measures to protect your
                                        data from unauthorized access, disclosure, alteration, or
                                        destruction. Our data are encrypted at the rest and we
                                        transfer data over encrypted TLS protocol.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        5.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Data Retention:
                                        </span>
                                        We will retain your data only for as long as necessary to
                                        fulfill the purposes outlined in this Privacy Policy unless
                                        a longer retention period is required or permitted by law.
                                        We delete/erase a user’s Personal Identifiable Information
                                        at the very same time He/she asked or terminated or we get a
                                        user deletion request. This request also executed from the
                                        UR&AM Tool system. You can put in a request to delete your
                                        Personal Identifiable Information by emailing
                                        <span className={`${pageClassName}__accordion__email`}>
                                            support@onemitutoyo.com
                                        </span>
                                        .
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        6.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Your Rights:
                                        </span>
                                        You have the right to access, rectify, and delete your
                                        personal information stored in the Tool's database. You may
                                        also object to the processing of your data or request data
                                        portability. Your rights also include:
                                        <ul className={`${pageClassName}__accordion__subitem`}>
                                            <li className={`${pageClassName}__accordion__list__item`}>
                                                <p className={`${pageClassName}__accordion__paragraph`}>
                                                    <span
                                                        className={`${pageClassName}__accordion__highlight`}
                                                    >
                                                        Right to Access.
                                                    </span>
                                                    You have the right to access your Personal Data held
                                                    by us.
                                                </p>
                                            </li>
                                            <li className={`${pageClassName}__accordion__list__item`}>
                                                <p className={`${pageClassName}__accordion__paragraph`}>
                                                    <span
                                                        className={`${pageClassName}__accordion__highlight`}
                                                    >
                                                        Right to Rectification.
                                                    </span>
                                                    You have the right to rectify inaccurate Personal Data
                                                    and, taking into account the purpose of processing, to
                                                    ensure it is complete.
                                                </p>
                                            </li>
                                            <li className={`${pageClassName}__accordion__list__item`}>
                                                <p className={`${pageClassName}__accordion__paragraph`}>
                                                    <span
                                                        className={`${pageClassName}__accordion__highlight`}
                                                    >
                                                        Right to Erasure (or “Right to be Forgotten”).
                                                    </span>
                                                    You have the right to have your Personal Data erased
                                                    or deleted.
                                                </p>
                                            </li>
                                            <li className={`${pageClassName}__accordion__list__item`}>
                                                <p className={`${pageClassName}__accordion__paragraph`}>
                                                    <span
                                                        className={`${pageClassName}__accordion__highlight`}
                                                    >
                                                        Right to Restrict Processing.
                                                    </span>
                                                    You have the right to restrict our processing of your
                                                    Personal Data.
                                                </p>
                                            </li>
                                            <li className={`${pageClassName}__accordion__list__item`}>
                                                <p className={`${pageClassName}__accordion__paragraph`}>
                                                    <span
                                                        className={`${pageClassName}__accordion__highlight`}
                                                    >
                                                        Right to Data Portability.
                                                    </span>
                                                    You have the right to transfer your Personal Data,
                                                    when possible.
                                                </p>
                                            </li>
                                            <li className={`${pageClassName}__accordion__list__item`}>
                                                <p className={`${pageClassName}__accordion__paragraph`}>
                                                    <span
                                                        className={`${pageClassName}__accordion__highlight`}
                                                    >
                                                        Right to Object.
                                                    </span>
                                                    You have the right to object to the processing of your
                                                    Personal Data that is carried out on the basis of
                                                    legitimate interests, such as direct marketing.
                                                </p>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        7.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Third-Party Links:
                                        </span>
                                        The Tool may contain links to third-party websites or
                                        services. We are not responsible for the privacy practices
                                        or content of these external sites.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        8.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Updates to the Privacy Policy:
                                        </span>
                                        We reserve the right to update this Privacy Policy as
                                        needed. Any changes will be posted on the Tool's website,
                                        and the revised date will be mentioned at the top of the
                                        page.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        9.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Contact Us:
                                        </span>
                                        If you have any questions or concerns or suggestions
                                        regarding this Privacy Policy or our data practices, please
                                        contact us at
                                        <span className={`${pageClassName}__accordion__email`}>
                                            support@onemitutoyo.com.
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Accordion>
                    <Accordion
                        heading={
                            <div className={`${pageClassName}__accordion__header`}>
                                <p className={`${pageClassName}__accordion__header__title`}>
                                    Terms and Conditions
                                </p>
                                <p className={`${pageClassName}__accordion__header__text`}>
                                    Last updated: 17th Aug 2023
                                </p>
                            </div>
                        }
                    >
                        <div className={`${pageClassName}__accordion`}>
                            <p className={`${pageClassName}__accordion__paragraph`}>
                                Welcome to Mitutoyo UR&AM Tool. By accessing or using the Tool, you
                                agree to be bound by these Terms and Conditions. If you do not
                                agree with all of these terms, please do not use the Tool. The
                                terms are as follows:
                            </p>
                            <ul className={`${pageClassName}__accordion__list`}>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        1.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Data Usage:
                                        </span>
                                        The Tool integrates with various third-party services,
                                        including Okta, Miro, Youtrack, GCP, GitLab, and Confluence,
                                        to provide user management functionalities. When using the
                                        Tool, you acknowledge and agree that it may access and
                                        collect data from these integrated services to facilitate
                                        user management processes.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        2.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Data Storage and Security:
                                        </span>
                                        The Tool stores data collected from integrated services in
                                        its own database in a secure manner. We implement
                                        industry-standard security measures to protect your data and
                                        comply with applicable data protection regulations like data
                                        encryption at rest and secure data transition over TLS.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        3.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            GDPR Compliance:
                                        </span>
                                        We are committed to complying with the General Data
                                        Protection Regulation (GDPR) and other applicable data
                                        protection laws. As part of this, we ensure that user data
                                        is processed lawfully, transparently, and for legitimate
                                        purposes only.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        4.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            User Responsibilities:
                                        </span>
                                        As a user of the Tool, you are responsible for ensuring that
                                        you have the necessary permissions and rights to access and
                                        use the data from the integrated services. You agree not to
                                        use the Tool for any unlawful or unauthorized purposes.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        5.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Disclaimer:
                                        </span>
                                        The Tool is provided "as is" without any warranties or
                                        guarantees. We represent or warranty regarding the accuracy,
                                        reliability, or availability of the Tool or its
                                        functionalities. Along with this, we want to acknowledge
                                        that despite deploying the application in fault-tolerant
                                        infrastructure, there might be interruptions might occur due
                                        to unavoidable circumstances.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        6.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Intellectual Property:
                                        </span>
                                        All intellectual property rights related to the Tool,
                                        including but not limited to trademarks, logos, and
                                        software, are the property of Mitutoyo. You may not use,
                                        reproduce, or modify any part of the Tool without prior
                                        written consent. You might email us a
                                        <span className={`${pageClassName}__accordion__email`}>
                                            support@onemitutoyo.com
                                        </span>
                                        .
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        7.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Changes to the Terms and Conditions:
                                        </span>
                                        We reserve the right to modify these Terms and Conditions at
                                        any time. Any changes will be effective immediately upon
                                        posting on the Tool's website. It is your responsibility to
                                        review the Terms and Conditions periodically for updates.
                                    </p>
                                </li>
                                <li className={`${pageClassName}__accordion__list__item`}>
                                    <p className={`${pageClassName}__accordion__list__number`}>
                                        8.
                                    </p>
                                    <p className={`${pageClassName}__accordion__paragraph`}>
                                        <span className={`${pageClassName}__accordion__intro`}>
                                            Governing Law and Jurisdiction:
                                        </span>
                                        These Terms and Conditions shall be governed by and
                                        construed in accordance with the laws of Japan. Any disputes
                                        arising from or related to the use of the Tool shall be
                                        subject to the exclusive jurisdiction of the courts in
                                        Japan.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Accordion>
                    {user && (
                        <>
                            <div className={`${pageClassName}__condition`}>
                                <p className={`${pageClassName}__condition__text`}>
                                    To continue, you must accept the <b>Terms & Conditions</b> and
                                    <b>Privacy Policy</b>.
                                </p>
                            </div>
                            <form
                                className={`${pageClassName}__action`}
                                onSubmit={submitHandler}
                            >
                                <button
                                    className={`${pageClassName}__action__accept`}
                                    type="submit"
                                >
                                    I accept
                                </button>
                                <button
                                    className={`${pageClassName}__action__decline`}
                                    type="button"
                                    onClick={() => navigate("/login")}
                                >
                                    I decline
                                </button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </SimpleBar>
    );
};
