import React from "react";

import {
    createComponentBase,
    BaseComponentProps,
} from "../../../../libs/components";
import { Dropdown } from "../../../atoms/dropdown";
import { Input } from "../../../atoms/input";
import { useUserListQuery } from "../../../../store/user/userSlice";
import { DropdownOptionsType } from "../../../../types";
import { Icon } from "../../../atoms/icon";

export interface projectAndTeamManagementProps extends BaseComponentProps {
    payload: any;
    setPayload: (payload: any) => void;
    approverList: any;
}

/**
 * 
 * @param subType "Project Creation",
                "Group Creation",
                "Space Creation",
                "Team Creation",
                "Dashboard Creation",
 * @returns 
 */

const Type = (subType: string) => {
    switch (subType) {
        case "Project Creation":
            return "project";
        case "Group Creation":
            return "group";
        case "Space Creation":
            return "space";
        case "Team Creation":
            return "team";
        case "Dashboard Creation":
            return "dashboard";
        default:
            return "";
    }
};

export const ProjectAndTeamManagementRequest = ({
    className,
    payload,
    setPayload,
    approverList,
}: projectAndTeamManagementProps) => {
    const componentClassName = "o-projectAndTeamManagementRequest";
    const { Container } = createComponentBase(componentClassName, "div");
    const modifiers = {};

    const { data: managerList } = useUserListQuery({});
    const [saasToolOptions, setSaaSToolOptions] = React.useState<any>([]);
    const [entityOptions, setEntityOptions] = React.useState<any>([]);

    const nameChangeHandler = (
        val: { value: string; label: string },
        index: number
    ) => {
        const newPayload = { ...payload };
        newPayload.saas_tools.value[index].name = val;
        newPayload.saas_tools.value[index].entity_name = "";
        newPayload.saas_tools.value[index].approver = [];

        setPayload(newPayload);
    };

    const entityNameChangeHandler =
        (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            const newPayload = { ...payload };
            newPayload.saas_tools.value[index].entity_name = val;
            setPayload(newPayload);
        };

    const entitySelectHandler = (val: any, index: number) => {
        const newPayload = { ...payload };

        newPayload.saas_tools.value[index].approver = val?.approver.map(
            (item: any) => ({
                label: item?.middleName
                    ? `${item?.firstName} ${item?.middleName} ${item?.lastName}`
                    : `${item?.firstName} ${item?.lastName}`,
                value: item.id,
            })
        );
        // delete val.approver;
        newPayload.saas_tools.value[index].entity_name = val;
        setPayload(newPayload);
    };

    const updatedEntityNameChangeHandler =
        (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            const newPayload = { ...payload };
            newPayload.saas_tools.value[index].new_name = val;
            setPayload(newPayload);
        };

    const approverChangeHandler = (val: DropdownOptionsType, index: number) => {
        const newPayload = { ...payload };
        newPayload.saas_tools.value[index].approver = val;
        setPayload(newPayload);
    };

    const removeSaaSToolHandler = (index: number) => {
        const newPayload = { ...payload };
        if (newPayload.saas_tools.value.length > 1) {
            newPayload.saas_tools.value.splice(index, 1);
            setPayload(newPayload);
        }
    };

    const addSaaSToolHandler = () => {
        const newPayload = { ...payload };
        if (
            [
                "Project Modification",
                "Group Modification",
                "Space Modification",
                "Team Modification",
                "Dashboard Modification",
            ].includes(payload.subType?.value?.value)
        ) {
            newPayload.saas_tools.value.push({
                name: "",
                entity_name: "",
                new_name: "",
                approver: [],
            });
        } else {
            newPayload.saas_tools.value.push({
                name: "",
                entity_name: "",
                approver: [],
            });
        }
        setPayload(newPayload);
    };

    const saasToolFocusHandler = () => {
        if (
            ["Project Creation", "Project Modification", "Project Deletion"].includes(
                payload?.subType?.value?.value
            )
        ) {
            setSaaSToolOptions([
                {
                    label: "Youtrack",
                    value: "Youtrack",
                },
                {
                    label: "GCP",
                    value: "GCP",
                },
                {
                    label: "SOPHOS Cloud Optix",
                    value: "SOPHOS Cloud Optix",
                },
            ]);
        } else if (
            ["Group Creation", "Group Modification", "Group Deletion"].includes(
                payload?.subType?.value?.value
            )
        ) {
            setSaaSToolOptions([
                {
                    label: "Confluence",
                    value: "Confluence",
                },
                {
                    label: "GitLab",
                    value: "GitLab",
                },
            ]);
        } else if (
            ["Space Creation", "Space Modification", "Space Deletion"].includes(
                payload?.subType?.value?.value
            )
        ) {
            setSaaSToolOptions([
                {
                    label: "Confluence",
                    value: "Confluence",
                },
            ]);
        } else if (
            ["Team Creation", "Team Modification", "Team Deletion"].includes(
                payload?.subType?.value?.value
            )
        ) {
            setSaaSToolOptions([
                {
                    label: "Miro",
                    value: "Miro",
                },
            ]);
        } else if (
            [
                "Dashboard Creation",
                "Dashboard Modification",
                "Dashboard Deletion",
            ].includes(payload?.subType?.value?.value)
        ) {
            setSaaSToolOptions([
                {
                    label: "MergeBase",
                    value: "MergeBase",
                },
            ]);
        }
    };

    const entityFocusHandler = (index: number, saas_tool: string) => {
        const options = approverList?.result?.data.filter(
            (item: any) =>
                item.saasTool === saas_tool &&
                item.type ===
                (["Project Modification", "Project Deletion"].includes(
                    payload.subType.value.value
                )
                    ? "Project"
                    : ["Group Modification", "Group Deletion"].includes(
                        payload.subType.value.value
                    )
                        ? "Group"
                        : ["Space Modification", "Space Deletion"].includes(
                            payload.subType.value.value
                        )
                            ? "Space"
                            : ["Team Modification", "Team Deletion"].includes(
                                payload.subType.value.value
                            )
                                ? "Team"
                                : ["Dashboard Modification", "Dashboard Deletion"].includes(
                                    payload.subType.value.value
                                )
                                    ? "Dashboard"
                                    : "")
        );
        setEntityOptions(
            options.map((item: any) => ({
                label: item.name,
                value: item.id,
                approver: item.approver,
            }))
        );
    };

    return (
        <Container className={className} modifiers={modifiers}>
            <div className={`${componentClassName}__form`}>
                {payload?.saas_tools?.value.map((saas_tool: any, index: number) => (
                    <div key={index} className={`${componentClassName}__form__items`}>
                        <div className={`${componentClassName}__form__item`}>
                            <Dropdown
                                className={`${componentClassName}__form__item__dropdown`}
                                options={saasToolOptions}
                                selected={
                                    saas_tool?.name?.value === "" ? null : saas_tool?.name
                                }
                                placeholder="Select saas tool"
                                onClickChange={(val) =>
                                    nameChangeHandler(
                                        val as { value: string; label: string },
                                        index
                                    )
                                }
                                focusHandler={saasToolFocusHandler}
                                isRequired
                            />

                            {[
                                "Project Modification",
                                "Group Modification",
                                "Space Modification",
                                "Team Modification",
                                "Dashboard Modification",
                                "Project Deletion",
                                "Group Deletion",
                                "Space Deletion",
                                "Team Deletion",
                                "Dashboard Deletion",
                            ].includes(payload.subType?.value?.value) ? (
                                <>
                                    <Dropdown
                                        className={`${componentClassName}__form__item__dropdown`}
                                        options={entityOptions}
                                        selected={
                                            saas_tool?.entity_name?.value === ""
                                                ? null
                                                : saas_tool?.entity_name
                                        }
                                        placeholder={`Please select ${Type(
                                            payload?.subType?.value?.value
                                        )} name`}
                                        onClickChange={(val) =>
                                            entitySelectHandler(
                                                val as { value: string; label: string },
                                                index
                                            )
                                        }
                                        focusHandler={() =>
                                            entityFocusHandler(index, saas_tool.name.value)
                                        }
                                        isRequired
                                    />
                                    {[
                                        "Project Modification",
                                        "Group Modification",
                                        "Space Modification",
                                        "Team Modification",
                                        "Dashboard Modification",
                                    ].includes(payload.subType?.value?.value) && (
                                            <Input
                                                value={saas_tool?.new_name}
                                                placeholder={`Please enter new ${Type(
                                                    payload?.subType?.value?.value
                                                )} name`}
                                                onChange={updatedEntityNameChangeHandler(index)}
                                                type="text"
                                                isRequired
                                                isStar
                                            />
                                        )}
                                </>
                            ) : (
                                <Input
                                    value={saas_tool?.entity_name}
                                    placeholder={`Please enter ${Type(
                                        payload?.subType?.value?.value
                                    )} name`}
                                    onChange={entityNameChangeHandler(index)}
                                    type="text"
                                    isRequired
                                    isStar
                                />
                            )}

                            <Dropdown
                                className={`${componentClassName}__form__item__dropdown`}
                                options={
                                    managerList?.result?.data?.map((user: any) => ({
                                        label: user?.middleName
                                            ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
                                            : `${user?.firstName} ${user?.lastName}`,
                                        value: user.id,
                                    })) ?? []
                                }
                                onClickChange={(val) =>
                                    approverChangeHandler(val as DropdownOptionsType, index)
                                }
                                placeholder={
                                    [
                                        "Project Deletion",
                                        "Group Deletion",
                                        "Space Deletion",
                                        "Team Deletion",
                                        "Dashboard Deletion",
                                    ].includes(payload.subType?.value?.value)
                                        ? ""
                                        : `Please select approvers`
                                }
                                isIcon
                                color="black"
                                selected={saas_tool?.approver}
                                isMulti
                                isDisabled={[
                                    "Project Deletion",
                                    "Group Deletion",
                                    "Space Deletion",
                                    "Team Deletion",
                                    "Dashboard Deletion",
                                ].includes(payload.subType?.value?.value)}
                            />
                        </div>
                        {payload?.saas_tools?.value.length > 1 && (
                            <div className={`${componentClassName}__form__items__remove`}>
                                <button
                                    onClick={() => removeSaaSToolHandler(index)}
                                    type="button"
                                    className={`${componentClassName}__form__items__remove__button`}
                                >
                                    <Icon icon="delete" size="primary" />
                                </button>
                            </div>
                        )}
                    </div>
                ))}
                {payload?.saas_tools?.value[payload?.saas_tools?.value.length - 1]
                    .name &&
                    payload?.saas_tools?.value[payload?.saas_tools?.value.length - 1]
                        .entity_name &&
                    payload?.saas_tools?.value[payload?.saas_tools?.value.length - 1]
                        .approver.length > 0 && (
                        <button
                            type="button"
                            onClick={() => addSaaSToolHandler()}
                            className={`${componentClassName}__form__add`}
                        >
                            Add SaaS Tool
                        </button>
                    )}
            </div>
        </Container>
    );
};
