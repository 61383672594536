import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Input } from "../../../../atoms/input";
import { hasPermission, pascalToSnakeCase } from "../../../../../utils";
import {
    PAGE_NAME,
    PERMISSIONS,
    ROUTE,
} from "../../../../../constants/constants";
import { Dropdown } from "../../../../atoms/dropdown";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { Pagination } from "../../../../molecules/pagination";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { useNavigate } from "react-router-dom";
import { useMyRequestListQuery } from "../../../../../store/requestManagement/requestSlice";
import { Icon } from "../../../../atoms/icon";
import {
    requestTypeOptions,
    subTypeOptionsPermission,
    subTypeOptionsProjectTeam,
    subTypeOptionsSaaS,
    subTypeOptionsUserLicense,
} from "../create";

const toolSortHandler = (sortBy: string) => {
    if (sortBy === "createdAtTime") {
        sortBy = "createdAt";
    } else if (sortBy === "requestStatus") {
        sortBy = "status";
    }

    return sortBy;
};

const RequestHistory = () => {
    const pageClassName = "p-requestHistory";

    const ref = React.useRef<HTMLDivElement>(null);
    const filterRef = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);
    const [filter, setFilter] = React.useState({
        type: {
            label: "Select Type",
            value: "",
        },
        subType: {
            label: "Select Sub Type",
            value: "",
        },
        status: {
            label: "Select Status",
            value: "",
        }
    });

    const [filterQuery, setFilterQuery] = React.useState({
        type: "",
        subType: "",
        status: "",
    });

    const navigate = useNavigate();
    const {
        search,
        query,
        sortHandler,
        searchHandler,
        limitChangeHandler,
        pageChangeHandler,
    } = useTableHooks();

    const { data, isLoading, isFetching } = useMyRequestListQuery({
        search: query.search,
        sort_by: pascalToSnakeCase(toolSortHandler(query.sortBy)),
        sort_direction: query.sortDirection,
        limit: query.limit,
        current_page: query.current,
        type: filterQuery.type,
        sub_type: filterQuery.subType,
        status: filterQuery.status,
    });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Requester",
            dataIndex: "requester",
            isSortable: true,
        },
        {
            title: "Type",
            dataIndex: "type",
            isSortable: true,
        },
        {
            title: "Sub Type",
            dataIndex: "subType",
            isSortable: true,
        },
        {
            title: "Status",
            dataIndex: "requestStatus",
            isSortable: true,
        },
        {
            title: "Number of Request",
            dataIndex: "numberOfRequests",
            isSortable: true,
        },
        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: true,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string) => {
        navigate(ROUTE.REQUEST_HISTORY + "/" + id);
    };

    const resetFilter = () => {
        setFilter({
            type: {
                label: "Select Type",
                value: "",
            },
            subType: {
                label: "Select Sub Type",
                value: "",
            },
            status: {
                label: "Select Status",
                value: "",
            }
        });

        handleFirstPage();
    };

    const filterHandler = () => {
        setFilterQuery({
            ...filterQuery,
            type: filter.type.value,
            subType: filter.subType.value,
            status: filter.status.value,
        });
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const subTypeOptions = (type: string) => {
        switch (type) {
            case requestTypeOptions[0].value:
                return subTypeOptionsUserLicense;
            case requestTypeOptions[1].value:
                return subTypeOptionsProjectTeam;
            case requestTypeOptions[2].value:
                return subTypeOptionsPermission;
            case requestTypeOptions[3].value:
                return subTypeOptionsSaaS;
            default:
                return [];
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.REQUEST_HISTORY}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                    <div className={`${pageClassName}__filterBy`}>
                        {(filterQuery.type || filterQuery.subType || filterQuery.status) && (
                            <div className={`${pageClassName}__filterBy__item`}>
                                <p>
                                    Type:{" "}
                                    <span>
                                        {filterQuery.type === ""
                                            ? "All"
                                            : filterQuery.type}
                                    </span>

                                    {filterQuery.subType && (
                                        <span>
                                            , Sub Type:{" "}
                                            {filterQuery.subType === ""
                                                ? "All"
                                                : filterQuery.subType}
                                        </span>
                                    )}

                                    {filterQuery.status && (
                                        <span>
                                            , Status:{" "}
                                            {filterQuery.status === ""
                                                ? "All"
                                                : filterQuery.status}
                                        </span>
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={`${pageClassName}__group`}>
                        {hasPermission(PERMISSIONS.REQUEST_MANAGEMENT_CREATE.title) && (
                            <button
                                className={`${pageClassName}__button`}
                                onClick={() =>
                                    navigate(ROUTE.REQUEST_HISTORY + ROUTE.CREATE_REQUEST)
                                }
                            >
                                Create Request
                            </button>
                        )}

                        <div className={`${pageClassName}__filter`} ref={filterRef}>
                            <button
                                onClick={() => setShowFilter(!isShowFilter)}
                                className={`${pageClassName}__filter__button`}
                                title="Filter"
                            >
                                <Icon icon="filter" size="primary" />
                            </button>

                            {isShowFilter && (
                                <div className={`${pageClassName}__filter__container`}>
                                    <div className={`${pageClassName}__filter__item`} ref={ref}>
                                        <label>Type</label>
                                        <Dropdown
                                            options={requestTypeOptions}
                                            selected={filter.type }
                                            onClickChange={(e) =>
                                                setFilter({ ...filter, type: e as { label: string; value: string }, subType: { label: "Select Sub Type", value: "" }})
                                            }
                                            isBordered
                                            isIcon
                                            iniDirection="top"
                                        />
                                    </div>
                                    <div className={`${pageClassName}__filter__item`} ref={ref}>
                                        <label>Sub Type</label>
                                        <Dropdown
                                            options={subTypeOptions(filter.type.value)}
                                            selected={filter.subType}
                                            onClickChange={(e) =>
                                                setFilter({ ...filter, subType: e as { label: string; value: string }})
                                            }
                                            isBordered
                                            isIcon
                                            iniDirection="top"
                                        />
                                    </div>
                                    <div className={`${pageClassName}__filter__item`}>
                                        <label htmlFor="filter">Status</label>
                                        <Dropdown
                                            options={[
                                                { label: "In Review", value: "In Review" },
                                                { label: "Reviewed", value: "Reviewed" },
                                                { label: "Fixed", value: "Fixed" },
                                                { label: "Closed", value: "Closed" },
                                            ]}
                                            selected={filter.status }
                                            onClickChange={(e) =>
                                                setFilter({ ...filter, status: e as { label: string; value: string }})
                                            }
                                            isBordered
                                            isIcon
                                            iniDirection="top"
                                        />
                                    </div>
                                    <Divider />
                                    <div className={`${pageClassName}__filter__btn`}>
                                        <button onClick={resetFilter}>Reset</button>
                                        <button
                                            onClick={() => {
                                                setShowFilter(false);
                                                filterHandler();
                                                handleFirstPage();
                                            }}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        minWidth="800"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestHistory;
