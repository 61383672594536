import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";

import { Tooltip } from "../tooltip";

export interface FileUploadProps extends BaseComponentProps {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  fileName?: string;
  label?: string;
  error?: string;
  isError?: boolean;
  multiple?: boolean;
}

export const FileUpload = ({
  className,
  onChange,
  placeholder,
  label,
  error,
  isError,
  multiple,
  fileName,
}: FileUploadProps) => {
  const componentClassName = "a-fileUpload";
  const modifiers = {
    [`${!fileName && "placeholder"}`]: !!placeholder,
    "error": !!isError,
  };
  const { Container } = createComponentBase(componentClassName, "div");

  return (
    <Container className={className} modifiers={modifiers}>
      {label && <p className={`${componentClassName}__label`}>{label}</p>}
      <Tooltip message={error} isVisible={isError}>
        <div className={`${componentClassName}__group`}>
          <input
            type="file"
            multiple={multiple}
            onChange={onChange}
            className={`${componentClassName}__input`}
          />

          <span className={`${componentClassName}__fileName`}>
            {fileName ? fileName : placeholder}
          </span>
          <button className={`${componentClassName}__button`} type="button">
            Browse File
          </button>
        </div>
      </Tooltip>
    </Container>
  );
};
