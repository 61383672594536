import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { Status } from "../../../../atoms/status";
import { formattedDateTime } from "../../../../../utils";
import {
  useSaasToolUserBoardsQuery,
  useSaasToolUserProjectsQuery,
  useSaasToolUserTeamsQuery,
  useSingleSaasToolQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { DataTable } from "../../../../organisms/dataTable";
import { PAGE_NAME } from "../../../../../constants/constants";

const MiroUserDetails = () => {
  const pageClassName = "p-miroUserDetails";

  const { id } = useParams<{ id: string }>();
  const { data, isSuccess } = useSingleSaasToolQuery({ id });

  const projectTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Project Id",
      dataIndex: "miroProjectId",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "projectName",
      isSortable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: false,
    },
  ];

  const teamTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Team Id",
      dataIndex: "toolTeamId",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "teamName",
      isSortable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: false,
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      isSortable: true,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      isSortable: true,
    }
  ];

  const boardTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Board Id",
      dataIndex: "toolBoardId",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "boardName",
      isSortable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: false,
    },
  ];


  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.MIRO_USER_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            First Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.firstName ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.lastName ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Email</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.oktaUser?.email
              ? data?.result?.oktaUser?.email
              : data?.result?.email ?? <Skeleton height="small" />}
          </p>
        </div>
        {data?.result?.oktaUser?.secondEmail && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Second Email
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.secondEmail}
            </p>
          </div>
        )}
        {data?.result?.oktaUser?.mobilePhone && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Mobile Phone
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.mobilePhone}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Status</p>
          {data?.result?.status ? (
            <Status status={data?.result?.status} />
          ) : (
            <Skeleton height="small" />
          )}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Role</p>
          {data?.result?.role ? (
            data?.result?.role
          ) : (
            <Skeleton height="small" />
          )}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.created ? (
                formattedDateTime(data?.result?.created)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Activity Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.lastActivity ? (
                formattedDateTime(data?.result?.lastActivity)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <DataTable
          title="Projects"
          id={id as string}
          toolName="Miro"
          tableHeader={projectTableHeader}
          useCallApiQuery={useSaasToolUserProjectsQuery}
        />
        <DataTable
          title="Teams"
          id={id as string}
          toolName="Miro"
          tableHeader={teamTableHeader}
          useCallApiQuery={useSaasToolUserTeamsQuery}
        />

        <DataTable
          title="Boards"
          id={id as string}
          toolName="Miro"
          tableHeader={boardTableHeader}
          useCallApiQuery={useSaasToolUserBoardsQuery}
        />
      </div>
    </div>
  );
};

export default MiroUserDetails;
