import React from "react";
import { PAGE_NAME, PERMISSIONS } from "../../../constants/constants";
import { Icon } from "../../atoms/icon";
import { useGetCardDataQuery, useGetGroupDataQuery } from "../../../store/dashboard/dashboardSlice";
import { hasPermission, numberFormat } from "../../../utils";
import { useSelector } from "react-redux";
import { userStateType } from "../../../types";

const Dashboard = () => {
  const pageClassName = "p-dashboard";
  const user = useSelector((state: userStateType) => state.user.user);
  const { data, isSuccess } = useGetCardDataQuery();

  const [cardData, setCardData] = React.useState([
    {
      title: "Total Users",
      value: 0,
      icon: "user",
    },
    {
      title: "Total Organization",
      value: 0,
      icon: "organization",
    },
    {
      title: "Total Tools & Infra",
      value: 0,
      icon: "saas",
    },
    {
      title: "Usage of GitLab Storage(GB)",
      value: '0 GB',
      icon: "gitlab",
    },
  ]);

  React.useEffect(() => {
    if (isSuccess) {
      setCardData([
        {
          title: "Total Users",
          value: data?.result?.totalUser,
          icon: "user",
        },
        {
          title: "Total Organization",
          value: data?.result?.totalOrganization,
          icon: "organization",
        },
        {
          title: "Total Tools & Infra",
          value: data?.result?.totalToolsAndInfra,
          icon: "saas",
        },
        {
          title: "Usage of GitLab Storage(GB)",
          value: `${data?.result?.usageOfGitLabStorage} GB`,
          icon: "gitlab",
        },
      ]);
    }
  }, [data, isSuccess]);

  const { data: groupData } = useGetGroupDataQuery();

  const [subCard, setSubCard] = React.useState([
    {
      title: "Usage of GitLab Computer Credit",
      value: `${0} minutes`,
      month: new Date().toLocaleString('default', { month: 'long' }),
      icon: "gitlab",
    },
    {
      title: "Total GCP Cost",
      value: 0,
      icon: "gcp",
    },
    {
      title: "Total AWS Cost",
      value: 0,
      icon: "aws",
    },
    {
      title: "Summation of Invited Users",
      value: 0,
      icon: "inviteUsers",
    },
    {
      title: "No of Requests Waiting for Approval",
      value: 0,
      icon: "pendingRequest",
    },
  ]);

  React.useEffect(() => {
    if (groupData) {
      setSubCard([
        {
          title: "Usage of GitLab Computer Credit",
          value: `${groupData?.result?.computeCredits?.computeCredits} minutes`,
          month: groupData?.result?.computeCredits?.month,
          icon: "gitlab",
        },
        {
          title: "Total GCP Cost",
          value: numberFormat(groupData?.result?.totalGCPCost, 'JPY'),
          icon: "gcp",
        },
        {
          title: "Total AWS Cost",
          value: numberFormat(groupData?.result?.totalAWSCost, 'USD'),
          icon: "aws",
        },
        {
          title: "Pending SaaS Licenses Invitations",
          value: groupData?.result?.totalInvitedUsers,
          icon: "inviteUsers",
        },
        {
          title: "No of Requests Waiting for Approval",
          value: 0,
          icon: "pendingRequest",
        },
      ]);
    }
  }, [groupData]);

  return (
    <div className={pageClassName}>
      <p className={`${pageClassName}__title`}>{PAGE_NAME.DASHBOARD}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__notification`}>
          <p className={`${pageClassName}__notification__title`}>
            <Icon icon="notification" size="primary" />Alerts & Notifications
          </p>
          <div className={`${pageClassName}__notification__content`}>
            <div className={`${pageClassName}__notification__notfound`}>
              <Icon icon="smileFace" size="tertiary" />
              <p className={`${pageClassName}__notification__content__text`}>
                No Alerts & Notifications Found.
              </p>
            </div>
          </div>
        </div>
        {hasPermission(PERMISSIONS.DASHBOARD_ADMIN_VIEW.title) && (
          <div className={`${pageClassName}__card`}>
            <div className={`${pageClassName}__card__grid`}>
              {cardData.map((item: any, index: number) => (
                <div className={`${pageClassName}__card__item`}>
                  <div className={`${pageClassName}__card__container`}>
                    <p className={`${pageClassName}__card__title`}>
                      {item.title}
                    </p>
                    <Icon icon={item.icon} size="tertiary" />
                  </div>
                  <p className={`${pageClassName}__card__value`}>{item.value}</p>
                </div>
              ))}
            </div>
            <div className={`${pageClassName}__card__subgrid`}>
              {subCard.map((item: any, index: number) => (
                <div className={`${pageClassName}__card__subgrid__item`}>
                  <div className={`${pageClassName}__card__subgrid__container`}>
                    <Icon icon={item.icon} size="primary" />
                    <p className={`${pageClassName}__card__subgrid__title`}>
                      {item.title}{item.month ? ` (${item.month})` : ""}
                    </p>
                  </div>
                  <p className={`${pageClassName}__card__subgrid__value`}>
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
