import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { useSaasToolBoardListQuery } from "../../../../../store/saasTool/saasToolSlice";
import { monthNames, pascalToSnakeCase } from "../../../../../utils";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Input } from "../../../../atoms/input";
import { Table } from "../../../../atoms/table";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { Pagination } from "../../../../molecules/pagination";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import { TableDataType } from "../../../../../types";
import { useMiroBoardReportQuery } from "../../../../../store/miro/miroSlice";
import { ColumnChart } from "../../../../molecules/columnChart";
import { Loader } from "../../../../atoms/loader";

const MiroBoardList = () => {
  const pageClassName = "p-miroBoardList";
  const navigate = useNavigate();

  const [index, setIndex] = React.useState(-1);

  const {
    search,
    query,
    pageChangeHandler,
    sortHandler,
    limitChangeHandler,
    searchHandler,
  } = useTableHooks();

  const { data, isLoading } = useSaasToolBoardListQuery({
    search: query.search,
    limit: query.limit,
    current_page: query.current,
    sort_by: pascalToSnakeCase(query.sortBy),
    sort_direction: query.sortDirection,
    tool_name: "Miro",
  });

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: true,
    },
    {
      title: "Number of Members",
      dataIndex: "numberOfMembers",
      isSortable: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      isSortable: true,
    },
  ];

  const toolSortHandler = (sortBy: string) => {
    if (sortBy === "userFirstName") {
      sortBy = "first_name";
    } else if (sortBy === "userLastName") {
      sortBy = "last_name";
    } else if (sortBy === "userEmail") {
      sortBy = "email";
    }

    sortHandler(sortBy);
  };

  const formattedSortBy = (sortBy: string) => {
    switch (sortBy) {
      case "first_name":
        return "userFirstName";
      case "last_name":
        return "userLastName";
      case "email":
        return "userEmail";
      default:
        return sortBy;
    }
  };

  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: data?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  const viewHandler = (id: string, e: any) => {
    if (e.target.className === "a-table__table__tag__more") return;
    const name = data?.result?.data.find(
      (item: TableDataType) => item.id === id
    ).name;
    navigate(ROUTE.MIRO_BOARD_LIST + `/${id}`, { state: { name } });
  };

  /************ */
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth() + 1;
  var currentYear = currentDate.getFullYear();

  const [reportQuery, setReportQuery] = React.useState<any>({
    type: {
      label: "Weeks",
      value: "weeks",
    },
    weeks: 6,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const {
    data: reportData,
    isLoading: reportIsLoading,
    isFetching,
    isSuccess: reportSuccess,
  } = useMiroBoardReportQuery({
    type: reportQuery.type.value,
    year: reportQuery.year,
    month: reportQuery.month,
    weeks: reportQuery.weeks,
  });

  const options = React.useMemo(
    () => ({
        title: `Miro Boards Report ${reportQuery.type.value === "weeks"
            ? `(${reportQuery.weeks} Weeks Data)`
            : reportQuery.type.value === "specific_month"
                ? `(${monthNames[reportQuery.month - 1]} ${reportQuery.year})`
                : reportQuery.type.value === "specific_year"
                    ? `(${reportQuery.year})`
                    : ""
            }`,
        vAxis: { title: "Number of Boards" },
        hAxis: {
            title: ["weeks", "specific_month"].includes(reportQuery.type.value)
                ? "Week"
                : reportQuery.type.value === "year"
                    ? "Year"
                    : "Month",
        },
        seriesType: "bars",
        backgroundColor: "#f5f5f5",
        legend: {
            position: "top",
            textStyle: {
                fontSize: 14,
            },
        },
    }),
    [reportQuery.type, reportQuery.weeks, reportQuery.month, reportQuery.year]
);

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.MIRO_BOARD_LIST}</p>

      <div className={`${pageClassName}__container`}>
        <Loader
          isLoading={
            (reportData?.result.length && isFetching) || reportIsLoading
          }
        >
          <div className={`${pageClassName}__chart`}>
            {reportSuccess && (
              <div className={`${pageClassName}__filter`}>
                <div className={`${pageClassName}__filter__type`}>
                  <Dropdown
                    placeholder="Type"
                    selected={reportQuery.type}
                    options={[
                      "Weeks",
                      "Specific Month",
                      "Specific Year",
                      "Year",
                    ].map((item: any) => {
                      const value = item.replace(/\s/g, "_").toLowerCase();
                      return { label: item, value: value };
                    })}
                    onClickChange={(e) => {
                      setReportQuery({ ...reportQuery, type: e });
                    }}
                  />
                </div>
                {reportQuery.type.value === "weeks" && (
                  <div className={`${pageClassName}__filter__week`}>
                    <Dropdown
                      placeholder="Week"
                      selected={{
                        label: `${reportQuery.weeks} Weeks`,
                        value: reportQuery.weeks,
                      }}
                      options={[4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                        (item: any) => {
                          return { label: `${item} Weeks`, value: item };
                        }
                      )}
                      onClickChange={(e) => {
                        setReportQuery({ ...reportQuery, weeks: e.value });
                      }}
                    />
                  </div>
                )}
                {reportQuery.type.value === "specific_month" && (
                  <div className={`${pageClassName}__filter__month`}>
                    <Dropdown
                      placeholder="Month"
                      selected={{
                        label: monthNames[reportQuery.month - 1],
                        value: reportQuery.month,
                      }}
                      options={(currentYear === reportQuery.year
                        ? monthNames.slice(0, currentMonth)
                        : monthNames
                      ).map((item, index) => {
                        return { label: item, value: index + 1 } as any;
                      })}
                      onClickChange={(e) => {
                        setReportQuery({ ...reportQuery, month: e.value });
                      }}
                    />
                  </div>
                )}
                {["specific_month", "specific_year"].includes(
                  reportQuery.type.value
                ) && (
                    <div className={`${pageClassName}__filter__year`}>
                      <Dropdown
                        placeholder="Year"
                        selected={{
                          label: reportQuery.year,
                          value: reportQuery.year,
                        }}
                        options={
                          reportData?.result?.optionsYear?.map((item: any) => ({
                            label: item,
                            value: item,
                          })) ?? []
                        }
                        onClickChange={(e) => {
                          if (
                            e.value === currentYear &&
                            reportQuery.month > currentMonth
                          ) {
                            setReportQuery({
                              ...reportQuery,
                              month: currentMonth,
                              year: e.value,
                            });
                          } else {
                            setReportQuery({ ...reportQuery, year: e.value });
                          }
                        }}
                      />
                    </div>
                  )}
              </div>
            )}
            <ColumnChart
              data={reportData?.result?.data}
              options={options}
              isLoading={reportIsLoading}
            />
          </div>
        </Loader>
      </div>

      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__search`}>
          <Input
            placeholder="Search"
            onChange={(e) => {
              searchHandler(e);
              handleFirstPage();
            }}
            value={search}
          />
        </div>
        <div className={`${pageClassName}__table`}>
          <Table
            header={tableHeader}
            data={data?.result?.data ?? []}
            loading={isLoading}
            firstLoad={isLoading}
            sortHandler={(sortBy: string) => toolSortHandler(sortBy)}
            viewHandler={viewHandler}
            minWidth="1440"
            startIndex={data?.result?.from}
            showMoreHandler={(num) => setIndex(num)}
            showLessHandler={() => setIndex(-1)}
            clickedIndex={index}
            sortBy={formattedSortBy(query.sortBy)}
            cursorPointer
          />
        </div>
        <Divider />
        <div className={`${pageClassName}__pagination`}>
          <div className={`${pageClassName}__pagination__limit`}>
            <p>Display</p>
            <div className={`${pageClassName}__pagination__dropdown`}>
              <Dropdown
                options={[
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                selected={{ label: query.limit, value: query.limit }}
                onClickChange={(e) => {
                  limitChangeHandler(e.value as number);
                  handleFirstPage();
                }}
                isBordered
                isIcon
                iniDirection="top"
              />
            </div>
          </div>
          <div className={`${pageClassName}__pagination__number`}>
            {data?.result?.total ? (
              <p>
                Show {data?.result?.from} -{" "}
                {data?.result?.from + query.limit - 1 < data?.result?.total
                  ? data?.result?.from + query.limit - 1
                  : data?.result?.total}{" "}
                of {data?.result?.total}
              </p>
            ) : (
              <p>Show 00 - 00 of 00</p>
            )}
          </div>

          <div className={`${pageClassName}__pagination__nav`}>
            {data?.result?.total > query.limit && (
              <Pagination
                totalData={totalData}
                page={query.current}
                handleFirstPage={handleFirstPage}
                handlePrevPage={handlePrevPage}
                handleLastPage={handleLastPage}
                handleNextPage={handleNextPage}
                renderPageNumbers={renderPageNumbers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiroBoardList;
