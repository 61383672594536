import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["oktaGroupList", "singleOktaGroup"],
});

export const groupSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves a list of Okta groups based on the provided parameters.
     *
     * @param search - The search term to filter the groups.
     * @param limit - The maximum number of groups to retrieve.
     * @param current_page - The current page number for pagination.
     * @param sort_by - The field to sort the groups by.
     * @param sort_direction - The direction to sort the groups in.
     * @returns A Promise that resolves to the response data containing the list of Okta groups.
     */
    oktaGroupList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url: API_URL.OKTA_GROUPS + `?${limit ? `limit=${limit}` : ""}${
          current_page ? `&current_page=${current_page}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ""}${
          sort_direction ? `&sort_direction=${sort_direction}` : ""
        }${search ? `&search=${search}` : ""}`,

        method: "GET",
      }),
      providesTags: ["oktaGroupList"],
    }),
    /**
     * Retrieves a single Okta group by its ID.
     *
     * @param id - The ID of the Okta group.
     * @returns A Promise that resolves to the response data of the Okta group.
     */
    singleOktaGroup: builder.query<ResponseDataType, string>({
      query: (id) => API_URL.OKTA_GROUPS + `/${id}`,
      providesTags: ["singleOktaGroup"],
    }),
  }),
});

export const { 
  useOktaGroupListQuery ,
  useSingleOktaGroupQuery,
} = groupSlice;