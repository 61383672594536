import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { PAGE_NAME } from "../../../../../constants/constants";
import { ComboChart } from "../../../../molecules/comboChart";
import {
    useGitlabGroupStorageComparisonQuery,
    useGitlabPipelineReportQuery,
    useGitlabProjectsListQuery,
    useGitlabStorageChangedPercentageQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { PieChart } from "../../../../molecules/paiChart";
import { useLicenseListQuery } from "../../../../../store/license/licenseSlice";
import { useAwsBillingReportQuery, useMiroBoardReportByProjectQuery } from "../../../../../store/reports/reportSlice";
import { ColumnChart } from "../../../../molecules/columnChart";
import { Icon } from "../../../../atoms/icon";
import { Dropdown } from "../../../../atoms/dropdown";
import { Divider } from "../../../../atoms/divider";
import { getLast12Months, getRandomColorArray, monthNames } from "../../../../../utils";
import { Loader } from "../../../../atoms/loader";
import { useOrganizationListQuery } from "../../../../../store/organization/organizationSlice";
import { BubbleChart } from "../../../../molecules/bubbleChart";

const projectColors = getRandomColorArray();

const colors = [
    "red",
    "orange",
    "green",
    "yellow",
    "blue",
    "purple",
    "pink",
    "grey",
    "black",
    "brown",
    "cyan",
];
const StaticReporting = () => {
    const pageClassName = "p-staticReporting";

    /************************************start license combo chart ************************************** */
    const [licenseData, setLicenseData] = React.useState<any>([]);
    /**
     * Get License list data
     * @param {string} sort_by
     * @param {string} sort_direction
     * @type {Object}
     */
    const { data: licenseDataResponse, isSuccess: licenseDataSuccess, isLoading: licenseLoading } =
        useLicenseListQuery({
            sort_by: "predicted",
            sort_direction: "desc",
        });

    React.useEffect(() => {
        const temp = [] as any[];
        if (licenseDataSuccess) {
            const header = [
                "Name",
                "Total Licenses",
                "Predicted Licenses",
                { role: "annotation" },
            ];
            temp.push(header);

            licenseDataResponse?.result?.licenses?.map((item: any, index: number) => {
                const temp2 = [
                    item.name,
                    item.total,
                    item.predicted,
                    `${item?.remaining} Remaining`,
                ];
                temp.push(temp2);
                return null;
            });
        }
        setLicenseData(temp);
    }, [licenseDataResponse, licenseDataSuccess]);

    /**
     * License report combo chart options
     * @type {Object}
     */

    const licenseDataChartOptions = React.useMemo(
        () => ({
            title: "License Report",
            vAxis: { title: "Total Licenses" },
            hAxis: { title: "Predicted Licenses" },
            seriesType: "bars",
            backgroundColor: "#f5f5f5",
            legend: {
                position: "top",
                textStyle: {
                    fontSize: 14,
                },
            },
            //bar colors
            series: {
                0: { color: "blue" },
                1: { color: "green" },
            },
            //annotation
            annotations: {
                textStyle: {
                    fontName: "Times-Roman",
                    fontSize: 14,
                    bold: true,
                    italic: true,
                    opacity: 0.8,
                },
                stem: {
                    length: "15",
                },
            },
        }),
        []
    );

    /***************************************start for Gitlab storage comparison chart*****************************/
    const [data, setData] = React.useState<any[]>([]);

    /**
     * Get Gitlab groups storage comparison data
     * @type {Object}
     */

    const { data: gitlabGroupData, isLoading: gitlabGroupDataLoading } =
        useGitlabGroupStorageComparisonQuery({
            weeks: 4,
        });

    React.useEffect(() => {
        const temp = [] as any[];
        const header = ["Group"] as any[];

        /**
         * add header column
         * @example
         * ['Group', 'Week 1', 'Week 2', 'Week 3', 'Week 4', ''],
         * @type {Array}
         */
        gitlabGroupData?.result?.tableHeader?.map((item: any) => {
            header.push(item?.title);
            return null;
        });

        /**
         * Add annotation column
         * @type {Array}
         */
        header.push({ role: "annotation" });
        temp.push(header);
        /**
                     * Convert object to array
                     * @type {Array}
                     * @example
                     * [
                     
                     * ['Group 1', 100, 200, 300, 400, ''],
                     * ['Group 2', 100, 200, 300, 400, ''],
                     */
        gitlabGroupData?.result?.data?.map((item: any) => {
            const temp2 = [] as any[];
            Object.keys(item).map((key: any) => {
                if (key !== "id")
                    if (key !== "name")
                        temp2.push(
                            parseFloat(item[key].replace(/,/g, "").replace(/MB/g, ""))
                        );
                    else temp2.push(item[key]);
                return null;
            });

            temp2.push("");
            temp.push(temp2);
            return null;
        });

        /**
         * Calculate percentage change
         * @type {number}
         */
        for (let i = 1; i < temp.length; i++) {
            const prevValue = temp[i][temp[i].length - 3];
            const currentValue = temp[i][temp[i].length - 2];
            const percentageChange =
                (((currentValue as number) - parseInt(prevValue as string)) /
                    parseInt(prevValue as string)) *
                100;
            temp[i][temp[i].length - 1] = `${Number.isNaN(percentageChange) ? "0.00" : percentageChange.toFixed(2)
                }%`; // Update annotation column
        }
        setData(temp);
    }, [gitlabGroupData]);

    /**
     * Gitlab storage comparison chart options
     * @type {Object}
     */
    const StorageComparisonOptions: object = {
        title: "GitLab - Weekly Storage Usage Comparison Report",
        vAxis: { title: "MB" },
        hAxis: { title: "Group" },
        seriesType: "bars",
        backgroundColor: "#f5f5f5",
        legend: {
            position: "top",
            textStyle: {
                fontSize: 14,
            },
        },
    };


    /***********************AWS cost report chart******************************** */
    const filterRef = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);

    const [selectedMonthYear, setSelectedMonthYear] = React.useState<any>();
    const [awsFilterData, setAwsFilterData] = React.useState<any>({
        month: "",
        year: "",

    });

    /**
     * Get AWS cost report data
     * @type {Object}
     */

    const { data: awsCostDataResponse } = useAwsBillingReportQuery({
        month: (monthNames.indexOf(awsFilterData.month) + 1).toString(),
        year: awsFilterData.year,
    });



    const awsCostChartOptions: object = {
        title: `AWS Cost Report (${awsFilterData.month && awsFilterData.year ? `${awsFilterData.month} ${awsFilterData.year}` : new Date().toLocaleString("default", { month: "long" }) + " " + new Date().getFullYear()})`,
        vAxis: { title: "Amount ($)" },
        hAxis: { title: "Date" },
        seriesType: "bars",
        backgroundColor: "#f5f5f5",
        legend: {
            position: "none",
            textStyle: {
                fontSize: 14,
            },
        },
    };

    const filterHandler = () => {
        setShowFilter(false);
        setAwsFilterData({
            month: selectedMonthYear.value.split("-")[0],
            year: selectedMonthYear.value.split("-")[1],
        });
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //start gitlab pipeline report chart
    const [pipelineReportData, setPipelineReportData] = React.useState<any>([]);
    const [year, setYear] = React.useState(new Date().getFullYear());
    const [optionYears, setOptionYears] = React.useState<any>([]);

    /**
     * Get Gitlab pipeline report data
     * @param {number} limit
     * @param {number} year
     * @param {string} sort_by
     * @type {Object}
     */
    const {
        data: gitlabPipelineReportData,
        isSuccess: pipelineReportSuccess,
        isFetching: pipelineReportFetching,
        isLoading: pipelineReportLoading,
    } = useGitlabPipelineReportQuery({
        limit: 12,
        year: year,
        sort_by: "month_iso",
    });

    React.useEffect(() => {
        const temp = [] as any[];
        if (pipelineReportSuccess) {
            const header = ["Month", "Minutes", { role: "style" }];
            temp.push(header);

            gitlabPipelineReportData?.result?.data?.map(
                (item: any, index: number) => {
                    const temp2 = [item.month, item.minutes, colors[index]];
                    temp.push(temp2);

                    return null;
                }
            );
            setOptionYears(gitlabPipelineReportData?.result?.years);
            setPipelineReportData(temp);
        }
    }, [gitlabPipelineReportData, pipelineReportSuccess]);

    /**
     * Gitlab pipeline report chart options
     * @type {Object}
     */

    const gitlabPipelineReportOptions = {
        title: `GitLab - Monthly Pipeline Usage Comparison Report (${year})`,
        hAxis: {
            title: "Month",
        },
        vAxis: {
            title: "Minutes",
        },
        seriesType: "bars",
        tooltip: { isHtml: true },
        backgroundColor: "#f5f5f5",
        legend: {
            position: "top",
            textStyle: {
                fontSize: 14,
            },
        },
    };
    //end gitlab pipeline report chart

    //start for Gitlab storage pie chart
    const { data: gitlabStorageChangedPercentage } = useGitlabStorageChangedPercentageQuery()
    const [totalStorageSize, setTotalStorageSize] = React.useState(0);

    /**
     * Calculate total storage size
     * @type {number}
     */

    React.useEffect(() => {
        if (pipelineReportSuccess) {
            const storageSize = parseFloat((gitlabPipelineReportData?.result?.data[gitlabPipelineReportData?.result?.data?.length - 1].totalStorage / 1073741824).toFixed(1));
            setTotalStorageSize(storageSize);
        }
    }, [gitlabPipelineReportData, pipelineReportSuccess]);

    /**
     * Gitlab storage pie chart options
     * @type {Object}
     */

    const gitlabStorageChartOptions: Object = {
        title: `GitLab - Overall Storage Usage (GB) Ration Report ${gitlabStorageChangedPercentage?.result ? `(${gitlabStorageChangedPercentage?.result}% ${Math.sign(gitlabStorageChangedPercentage?.result) === 1 ? "Increased" : "Decreased"} Monthly)` : ""}`,
        pieHole: 0.4,
        is3D: true,
        backgroundColor: "#f5f5f5",
        legend: {
            position: "top",
            textStyle: {
                fontSize: 14,
            },
        },
        slices: {
            0: { color: "#e74c3c" },
            1: { color: "#2ecc71" },
        },
    };

    /**
     * Gitlab storage pie chart data
     * @type {Array}
     */
    const gitlabStorageChartData: Array<[string, Number | string]> = [
        ["Total Storage", "Used Storage"],
        ["Used Storage", totalStorageSize],
        ["Total Storage", 50],
    ];
    //end of Gitlab storage pie chart

    //top 10 projects by storage size column chart
    const [top10ProjectData, setTop10ProjectData] = React.useState<any>([]);

    /**
     * Get Gitlab top 10 projects by storage size data
     * @param {number} limit
     * @param {string} sort_by
     * @param {string} sort_direction
     * @type {Object}
     */
    const {
        data: gitlabTop10ProjectData,
        isSuccess: Top10ProjectDataSuccess,
        isLoading: top10ProjectLoading,
    } = useGitlabProjectsListQuery({
        tool_name: "Gitlab",
        limit: 10,
        sort_by: "storage_size",
        sort_direction: "desc",
    });

    React.useEffect(() => {
        const temp = [] as any[];

        if (Top10ProjectDataSuccess) {
            const header = ["Project", "Storage Size (MB)", { role: "style" }];
            temp.push(header);

            gitlabTop10ProjectData?.result?.data?.map((item: any, index: number) => {
                const temp2 = [
                    item.name,
                    item.storageSize / 1048575,
                    projectColors[index],
                ];
                temp.push(temp2);
                return null;
            });
        }

        setTop10ProjectData(temp);
    }, [gitlabTop10ProjectData, Top10ProjectDataSuccess]);

    /**
     * Gitlab top 10 projects by storage size column chart options
     * @type {Object}
     */
    const top10ProjectOptions: object = {
        title: `Top 10 Largest Projects by Storage Size (${new Date().getFullYear()})`,
        vAxis: { title: "Storage Size (MB)" },
        hAxis: { title: "Project" },
        seriesType: "bars",
        backgroundColor: "#f5f5f5",
        legend: { position: "none" },
    };

    //end of top 10 projects by storage size column chart


    //total ci/cd minutes pai chart
    const [totalMinuteData, setTotalMinuteData] = React.useState<any>([]);
    const [optionMonths, setOptionMonths] = React.useState<any>([]);
    const [selectedMonth, setSelectedMonth] = React.useState<string>(
        `${monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    );

    /**
     * Get Gitlab total ci/cd minutes data
     * @param {number} limit
     * @param {number} year
     * @param {string} sort_by
     * @param {string} month_year
     * @type {Object}
     */
    const {
        data: gitlabTotalMinuteData,
        isSuccess: totalMinuteSuccess,
        isLoading: totalMinuteLoading,
        isFetching: totalMinuteFetching,
    } = useGitlabPipelineReportQuery({
        limit: 10,
        sort_by: "month_iso",
        month_year: selectedMonth,
    });

    /**
     * Calculate total minutes
     * @type {number}
     */

    React.useEffect(() => {
        if (totalMinuteSuccess) {
            setTotalMinuteData([
                ["Total Minutes", "Used Minutes"],
                ["Used Minutes", gitlabTotalMinuteData?.result?.data[0]?.minutes],
                ["Remaining Minutes", 10000 - gitlabTotalMinuteData?.result?.data[0]?.minutes],
            ]);
            const options = getLast12Months().map((item: any) => {
                return { label: item, value: item };
            });
            setOptionMonths(options);
        } else {
            setTotalMinuteData([
                ["Total Minutes", "Used Minutes"],
                ["Used Minutes", 0],
                ["Remaining Minutes", 10000],
            ]);
        }
    }, [gitlabTotalMinuteData, totalMinuteSuccess]);

    /**
     * Gitlab total ci/cd minutes pai chart options
     * @type {Object}
     */

    const gitlabTotalMinuteOptions = {
        title: `GitLab - Monthly Pipeline Usage Ration Report (${selectedMonth}) [Total: 10000 Minutes Per Month]`,
        pieHole: 0.4,
        is3D: true,
        backgroundColor: "#f5f5f5",
        legend: {
            position: "top",
            textStyle: {
                fontSize: 14,
            },
        },
        slices: {
            0: { color: "#e74c3c" },
            1: { color: "#2ecc71" },
        },
    };

    //end total ci/cd minutes pai chart


    //start organization bubble chart
    const [organizationData, setOrganizationData] = React.useState<any>([]);
    /**
     * Get License list data
     * @type {Object}
     */
    const {
        data: organizationDataResponse,
        isSuccess: organizationDataSuccess,
        isLoading: organizationLoading,
    } = useOrganizationListQuery({});

    /**
     * organize data for bubble chart
     */
    React.useEffect(() => {
        const temp = [] as any[];
        if (organizationDataSuccess) {
            const header = ["Name", "Users", "Okta", "Country"];
            temp.push(header);

            organizationDataResponse?.result?.data?.map(
                (item: any, index: number) => {
                    const temp2 = [
                        item.name,
                        item.numberOfOrgMember,
                        item.okta,
                        item.country,
                    ];
                    temp.push(temp2);
                    return null;
                }
            );
        }
        setOrganizationData(temp);
    }, [organizationDataResponse, organizationDataSuccess]);

    /**
     * License report combo chart options
     * @type {Object}
     */

    const organizationDataChartOptions = React.useMemo(
        () => ({
            title: "Organization Report",
            vAxis: { title: "Number of Users" },
            hAxis: { title: "Number of Okta Users" },
            backgroundColor: "#f5f5f5",
            legend: {
                position: "top",
                textStyle: {
                    fontSize: 14,
                },
            },
            bubble: {
                textStyle: {
                    fontSize: 12,
                    fontName: "Times-Roman",
                    bold: true,
                    italic: true,
                },
            },
            colors: [
                "red",
                "orange",
                "green",
                "yellow",
                "blue",
                "purple",
                "pink",
                "grey",
                "black",
                "brown",
                "cyan",
            ],
        }),
        []
    );

    //end organization bubble chart


    /************ Miro Board Report By Project ************************/
    const { data: miroBoardReportByProject, isLoading: miroBoardReportByProjectLoading } = useMiroBoardReportByProjectQuery();

    const miroBoardReportByProjectOptions: object = {
        title: `Miro Board Report By Project (${new Date().getFullYear()})`,
        vAxis: { title: "Number of Boards" },
        hAxis: { title: "Project" },
        seriesType: "bars",
        backgroundColor: "#f5f5f5",
        legend: { position: "none" },
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.STATIC_REPORTING}</p>
            <div className={`${pageClassName}__container`}>
                <ComboChart
                    data={licenseData}
                    options={licenseDataChartOptions}
                    isLoading={licenseLoading}
                />
                <ComboChart
                    data={data}
                    options={StorageComparisonOptions}
                    isLoading={gitlabGroupDataLoading}
                />

                <PieChart
                    data={gitlabStorageChartData}
                    options={gitlabStorageChartOptions}
                    isLoading={pipelineReportLoading}
                />

                <ColumnChart
                    data={top10ProjectData}
                    options={top10ProjectOptions}
                    isLoading={top10ProjectLoading}
                />

                <ColumnChart
                    data={miroBoardReportByProject?.result ?? []}
                    options={miroBoardReportByProjectOptions}
                    isLoading={miroBoardReportByProjectLoading}
                />

                <div className={`${pageClassName}__chart`}>
                    <div className={`${pageClassName}__chart__year`}>
                        <Dropdown
                            placeholder="Year"
                            options={optionYears.map((item: any) => {
                                return { label: item, value: item };
                            })}
                            onClickChange={(e) => {
                                setYear(e.value as number);
                            }}
                        />
                    </div>
                    <Loader
                        isLoading={pipelineReportData.length && pipelineReportFetching}
                    >
                        <ColumnChart
                            data={pipelineReportData}
                            options={gitlabPipelineReportOptions}
                            isLoading={pipelineReportLoading}
                        />
                    </Loader>
                </div>

                <div className={`${pageClassName}__chart`}>
                    <div className={`${pageClassName}__chart__monthYear`}>
                        <Dropdown
                            placeholder="Month"
                            options={optionMonths}
                            onClickChange={(e) => {
                                setSelectedMonth(e.value as string);
                            }}
                        />
                    </div>
                    <PieChart
                        data={totalMinuteData}
                        options={gitlabTotalMinuteOptions}
                        isLoading={totalMinuteLoading}
                        isFetching={totalMinuteFetching}
                    />
                </div>

                <div>
                    <div className={`${pageClassName}__filter`} ref={filterRef}>
                        <button
                            onClick={() => setShowFilter(!isShowFilter)}
                            className={`${pageClassName}__filter__button`}
                            title="Filter"
                        >
                            <Icon icon="filter" size="primary" />
                        </button>
                        {isShowFilter && (
                            <div className={`${pageClassName}__filter__container`}>
                                <div className={`${pageClassName}__filter__item`}>
                                    <label>Month</label>
                                    <Dropdown
                                        options={awsCostDataResponse?.result?.monthYears.map(
                                            (item: any) => ({
                                                label: item,
                                                value: item,
                                            })
                                        )}
                                        selected={selectedMonthYear as { label: string; value: string }}
                                        onClickChange={(e) =>
                                            setSelectedMonthYear(e as { label: string; value: string })
                                        }
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                                <Divider />
                                <div className={`${pageClassName}__filter__btn`}>
                                    <button
                                        onClick={() => {
                                            setSelectedMonthYear({ label: "Select", value: "" });
                                            setAwsFilterData({ label: "Select", value: "" });
                                            setShowFilter(false);
                                        }}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        onClick={filterHandler}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <ColumnChart
                        data={awsCostDataResponse?.result?.expense ?? []}
                        options={awsCostChartOptions}
                        isLoading={false}
                    />


                </div>
                <BubbleChart
                    data={organizationData}
                    options={organizationDataChartOptions}
                    isLoading={organizationLoading}
                />
            </div>
        </div>
    );
};

export default StaticReporting;
