import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";

export interface LoaderProps extends BaseComponentProps {
  children: React.ReactNode;
  isLoading: boolean;
  className?: string;
  align?: "center";
}

export const Loader = ({
  children,
  className,
  isLoading: loading,
  align,
}: LoaderProps) => {
  const componentClassName = "a-loader";
  const modifiers = { 
    loading: loading,
    [align +"Align"]: align,
  };
  const { Container } = createComponentBase(componentClassName, "div");
  return (
    <Container className={className} modifiers={modifiers}>
      <div className={`${componentClassName}__children`}>{children}</div>
      <div className={`${componentClassName}__content`}>
        <div className={`${componentClassName}__spinner`}>
          {Array.from(Array(12).keys()).map((index) => (
            <div key={index}></div>
          ))}
        </div>
      </div>
    </Container>
  );
};
