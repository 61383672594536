import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";
import { Tooltip } from "../tooltip";
export interface inputProps extends BaseComponentProps {
  value: Date;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  height?: "small" | "medium" | "large";
  borderRadius?: "small";
  error?: string;
  isError?: boolean;
  min?: Date;
  max?: Date;
}

export const DatePicker = ({
  value,
  onChange,
  label,
  height,
  borderRadius,
  error,
  min,
  max,
  isError,
  className,
}: inputProps) => {
  const componentClassName = "a-datePicker";
  const modifiers = {
    [borderRadius + "BorderRadius"]: !!borderRadius,
    [height + "Height"]: !!height,
  };
  const { Container } = createComponentBase(componentClassName, "div");

  return (
    <Container className={className} modifiers={modifiers}>
      {label && <p className={`${componentClassName}__label`}>{label}</p>}
      <Tooltip message={error} isVisible={isError}>
        <div className={`${componentClassName}__input__container`}>
          <input
            className={`${componentClassName}__input`}
            value={value ? value.toISOString().split('T')[0] : ''}
            onChange={onChange}
            type="date"
            min={min ? min.toISOString().split('T')[0] : undefined}
            max={max ? max.toISOString().split('T')[0] : undefined}
          />
        </div>
      </Tooltip>
    </Container>
  );
};
