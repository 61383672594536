import React from "react";
import {
  useSaasToolUserLogsQuery,
  useSingleSaasToolQuery,
} from "../../../../store/saasTool/saasToolSlice";
import { useParams } from "react-router-dom";
import { Status } from "../../../atoms/status";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { formattedDateTime, pascalToSnakeCase } from "../../../../utils";
import { Divider } from "../../../atoms/divider";
import { Table } from "../../../atoms/table";
import { Pagination } from "../../../molecules/pagination";
import { Dropdown } from "../../../atoms/dropdown";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { Input } from "../../../atoms/input";
import { Skeleton } from "../../../atoms/skeleton";
import { DatePicker } from "../../../atoms/datePicker";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { PAGE_NAME } from "../../../../constants/constants";

export const BoxUserLogHistory = () => {
  const pageClassName = "p-boxUserLogHistory";
  const { id } = useParams<{ id: string }>();
  const { data, isSuccess } = useSingleSaasToolQuery({ id });
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [eventType, setEventType] = React.useState([]);

  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Event ID",
      dataIndex: "eventId",
      isSortable: true,
    },
    {
      title: "Event Type",
      dataIndex: "eventType",
      isSortable: false,
    },
    {
      title: "Item ID",
      dataIndex: "itemId",
      isSortable: true,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      isSortable: true,
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      isSortable: true,
    },
    {
      title: "Created",
      dataIndex: "eventCreatedAt",
      isSortable: true,
    },
  ];
  const {
    search,
    query,
    setQuery,
    pageChangeHandler,
    limitChangeHandler,
    searchHandler,
  } = useTableHooks();

  const { data: logsData, isLoading: logsLoading } = useSaasToolUserLogsQuery({
    id,
    tool_name: "Box",
    search: query.search,
    limit: query.limit,
    current_page: query.current,
    sort_by: pascalToSnakeCase(query.sortBy),
    sort_direction: query.sortDirection,
    start_date: startDate,
    end_date: endDate,
    event_type: JSON.stringify(eventType.map((item: any) => item?.value)),
  });

  const sortHandler = (sortBy: string) => {
    if (sortBy === "eventCreatedAt") {
      sortBy = "createdAt";
    }
    if (query.sortBy === sortBy) {
      setQuery({
        ...query,
        sortDirection: query.sortDirection === "asc" ? "desc" : "asc",
        current: 1,
      });
    } else {
      setQuery({
        ...query,
        sortBy,
        sortDirection: "asc",
        current: 1,
      });
    }
  };
  const manageStartDate = () => {
    var currentDate = new Date();

    var fifteenDaysBefore = new Date();
    fifteenDaysBefore.setDate(currentDate.getDate() - 15);

    var formattedDate = fifteenDaysBefore.toISOString().split("T")[0];
    return new Date(formattedDate);
  };

  const options = [
    { label: "Login", value: "login" },
    { label: "Download", value: "download" },
    { label: "Upload", value: "upload" },
    { label: "Delete", value: "delete" },
    { label: "Preview", value: "preview" },
    { label: "Rename", value: "rename" },
    { label: "Move", value: "move" },
    { label: "Copy", value: "copy" },
    { label: "Share", value: "share" },
    { label: "Create", value: "create" },
    { label: "Edit", value: "edit" },
  ];

  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: logsData?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.BOX_USER_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            First Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.oktaUser?.firstName ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.oktaUser?.lastName ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Email</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.oktaUser?.email ?? <Skeleton height="small" />}
          </p>
        </div>
        {data?.result?.oktaUser?.secondEmail && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Second Email
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.secondEmail}
            </p>
          </div>
        )}
        {data?.result?.mobilePhone && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Mobile Phone
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.mobilePhone}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Status</p>
          {data?.result?.status ? (
            <Status status={data?.result?.status} />
          ) : (
            <Skeleton height="small" />
          )}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Space Used
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.spaceUsed ? (
                `${(data?.result?.spaceUsed / 1000000).toFixed(2)} MB`
              ) : (
                0 + " MB"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.created ? (
                formattedDateTime(data?.result?.created)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Updated</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.updated ? (
                formattedDateTime(data?.result?.updated)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        {((!logsLoading && logsData?.result?.data?.length !== 0) ||
          query.search) && (
          <>
            <Divider />
            <div className={`${pageClassName}__logs`}>
              <p className={`${pageClassName}__logs__title`}>
                Activity Logs of last 15 days{" "}
              </p>
              <div className={`${pageClassName}__logs__filter`}>
                <div className={`${pageClassName}__search`}>
                  <Input
                    placeholder="Search"
                    onChange={(e) => {
                      searchHandler(e);
                      handleFirstPage();
                    }}
                    value={search}
                  />
                </div>
                <div className={`${pageClassName}__logs__datePicker`}>
                  <DatePicker
                    label="Start Date"
                    value={startDate ? new Date(startDate) : manageStartDate()}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={endDate ? new Date(endDate) : new Date()}
                    min={new Date(manageStartDate())}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate ? new Date(endDate) : new Date()}
                    onChange={(e) => setEndDate(e.target.value)}
                    min={startDate ? new Date(startDate) : manageStartDate()}
                    max={new Date()}
                  />
                </div>
              </div>
              <Table
                header={appsTableHeader}
                data={logsData?.result?.data ?? []}
                loading={logsLoading}
                firstLoad={logsLoading}
                sortHandler={sortHandler}
                startIndex={logsData?.result?.from}
                minWidth="1440"
                minHeight="330"
                dropdownOptions={options}
                selectedType={eventType}
                dropdownHandler={(selected: any) => setEventType(selected)}
                sortBy={
                  query.sortBy === "createdAt" ? "eventCreatedAt" : query.sortBy
                }
              />
            </div>
            <Divider />

            <div className={`${pageClassName}__pagination`}>
              <div className={`${pageClassName}__pagination__limit`}>
                <p>Display</p>
                <div className={`${pageClassName}__pagination__dropdown`}>
                  <Dropdown
                    options={[
                      { label: "20", value: 20 },
                      { label: "50", value: 50 },
                      { label: "100", value: 100 },
                    ]}
                    selected={{ label: query.limit, value: query.limit }}
                    onClickChange={(e) => {
                      limitChangeHandler(e.value as number);
                      handleFirstPage();
                    }}
                    isBordered
                    isIcon
                    iniDirection="top"
                  />
                </div>
              </div>

              <div className={`${pageClassName}__pagination__number`}>
                {logsData?.result?.total ? (
                  <p>
                    Show {logsData?.result?.from} -{" "}
                    {logsData?.result?.from + query.limit - 1 <
                    logsData?.result?.total
                      ? logsData?.result?.from + query.limit - 1
                      : logsData?.result?.total}{" "}
                    of {logsData?.result?.total}
                  </p>
                ) : (
                  <p>Show 00 - 00 of 00</p>
                )}
              </div>
              <div className={`${pageClassName}__pagination__nav`}>
                {logsData?.result?.total > query.limit && (
                  <Pagination
                    totalData={totalData}
                    page={query.current}
                    handleFirstPage={handleFirstPage}
                    handlePrevPage={handlePrevPage}
                    handleLastPage={handleLastPage}
                    handleNextPage={handleNextPage}
                    renderPageNumbers={renderPageNumbers}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
