import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "miroBoardReport",
    "BoardListByProject",
    "boardListByTeam",
    "boardReportsByTeam",
  ],
});

export const miroSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves the Miro board report from the server.
     * @type - The type of the report.
     * @year - The year of the report.
     * @month - The month of the report.
     * @weeks - The number of weeks to include in the report.
     * @returns A promise that resolves to the Miro board report.
     */
    miroBoardReport: builder.query<ResponseDataType, listDataType>({
      query: ({ type, year, month, weeks }) => ({
        url:
          API_URL.MIRO +
          API_URL.BOARD_REPORTS +
          `?type=${type}${year ? `&year=${year}` : ""}${month ? `&month=${month}` : ""
          }${weeks ? `&weeks=${weeks}` : ""}`,
        method: "GET",
      }),
      providesTags: ["miroBoardReport"],
    }),

    /**
     * Retrieves the list of boards by project from the server.
     * @id - The project ID.
     * @search - The search query string.
     * @limit - The maximum number of results to return.
     * @current_page - The current page number.
     * @sort_by - The field to sort by.
     * @sort_direction - The direction to sort in.
     * @returns A promise that resolves to the list of boards.
     */
    BoardListByProject: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
      }) => ({
        url:
          API_URL.MIRO +
          API_URL.PROJECT +
          "/" +
          id +
          API_URL.BOARDS +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["BoardListByProject"],
    }),

    /**
     * Retrieves the list of boards by team from the server.
     * @id - The team ID.
     * @search - The search query string.
     * @limit - The maximum number of results to return.
     * @current_page - The current page number.
     * @sort_by - The field to sort by.
     * @sort_direction - The direction to sort in.
     * @returns A promise that resolves to the list of boards.
     */
    boardListByTeam: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
      }) => ({
        url:
          API_URL.MIRO +
          API_URL.TEAM +
          "/" +
          id +
          API_URL.BOARDS +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["boardListByTeam"],
    }),

    /**
     * Retrieves the list of board reports by team from the server.
     * @returns A promise that resolves to the list of board reports.
     */
    boardReportsByTeam: builder.query<ResponseDataType, void>({
      query: () => ({
        url: API_URL.MIRO + API_URL.BOARD_REPORTS + "-by-team",
        method: "GET",
      }),
      providesTags: ["boardReportsByTeam"],
    }),
  }),
});

export const {
  useMiroBoardReportQuery,
  useBoardListByProjectQuery,
  useBoardListByTeamQuery,
  useBoardReportsByTeamQuery,
} = miroSlice;
