import React, { useState } from "react";
import {
    createComponentBase,
    BaseComponentProps,
} from "../../../libs/components";
import { PermissionType, RoleType } from "../../../types";

interface MultipleCheckboxProps extends BaseComponentProps {
    options: RoleType[];
    onCheckboxChange: (options: number[]) => void;
    className?: string;
    selectedValue?: number[];
    isSuccess?: boolean;
    totalPermissions?: number;
}

export const MultipleCheckbox = ({
    options,
    onCheckboxChange,
    selectedValue,
    isSuccess,
    totalPermissions,
}: MultipleCheckboxProps) => {
    const componentClassName = "a-multipleCheckbox";

    const [checkedValues, setCheckedValues] = useState<number[]>(selectedValue || []);

    React.useEffect(() => {
        setCheckedValues(selectedValue || []);
    }, [selectedValue]);

    const handleCheckboxChange = (permission: PermissionType) => {
        const isChecked = checkedValues.includes(permission.id as number);
        if (isChecked) {
            const newCheckedValues = checkedValues.filter(
                (value: any) => value !== permission.id
            );
            setCheckedValues(newCheckedValues);
            onCheckboxChange(newCheckedValues);
        } else {
            const newCheckedValues = [...checkedValues, permission.id];
            setCheckedValues(newCheckedValues as number[]);
            onCheckboxChange(newCheckedValues as number[]);
        }
    };

    const handleCheckAllChange = () => {
        const allChecked = checkedValues.length === totalPermissions;
        if (allChecked) {
            setCheckedValues([]);
            onCheckboxChange([]);
        } else {
            const allPermissionIds = options.map((category: RoleType) => (
                category?.permissions.map((permission: PermissionType) => permission.id)
            )).flat();

            setCheckedValues(allPermissionIds as number[]);
            onCheckboxChange(allPermissionIds as number[]);
        }
    };

    const checkedCategory = (category: RoleType) => {
        const allPermissionIds = category?.permissions.map((permission: PermissionType) => permission.id);

        if (checkedValues.length === totalPermissions) {
            return true;
        }
        for (const element of allPermissionIds) {
            if (!checkedValues.includes(element as number)) {
                return false;
            }
        }

        return true;
    };

    const handleCategoryCheckboxChange = (category: RoleType) => {
        const allPermissionIds = category?.permissions.map((permission: PermissionType) => permission.id);
        const isChecked = checkedCategory(category);

        if (isChecked) {
            const newCheckedValues = checkedValues.filter(
                (value: number) => !allPermissionIds.includes(value)
            );
            setCheckedValues(newCheckedValues);
            onCheckboxChange(newCheckedValues);
        } else {
            const newCheckedValues = [...checkedValues, ...allPermissionIds];
            setCheckedValues(newCheckedValues as number[]);
            onCheckboxChange(newCheckedValues as number[]);
        }
    };



    const modifiers = {};
    const { Container } = createComponentBase(componentClassName, "div");
    return (
        <Container className={componentClassName} modifiers={modifiers}>
            <div className={`${componentClassName}__category`}>
                <div className={`${componentClassName}__category__title`} onClick={handleCheckAllChange}>
                    <input
                        type="checkbox"
                        checked={checkedValues.length === totalPermissions && isSuccess}
                        onChange={handleCheckAllChange}
                        className={`${componentClassName}__item__checkbox`}
                    />
                    <p className={`${componentClassName}__category__text`}>All</p>
                </div>
                <div
                    className={`${componentClassName}__item`}
                    onClick={handleCheckAllChange}
                >
                    <input
                        type="checkbox"
                        checked={checkedValues.length === totalPermissions && isSuccess}
                        onChange={handleCheckAllChange}
                        className={`${componentClassName}__item__checkbox`}
                    />
                    <label className={`${componentClassName}__item__label`}>
                        Check All
                    </label>
                </div>
            </div>
            {options.map((category: RoleType, index: number) => (
                <div key={category.id} className={`${componentClassName}__category`}>
                    <div className={`${componentClassName}__category__title`} onClick={() => handleCategoryCheckboxChange(category)}>
                        <input
                            type="checkbox"
                            checked={checkedCategory(category)}
                            onChange={() => handleCategoryCheckboxChange(category)}
                            className={`${componentClassName}__item__checkbox`}
                        />
                        <p className={`${componentClassName}__category__text`}>{category?.title}</p>
                    </div>
                    <div className={`${componentClassName}__category__items`}>
                        {category?.permissions.map((permission: PermissionType, index: number) => (
                            <div
                                key={permission?.id}
                                className={`${componentClassName}__item`}
                                onClick={() => handleCheckboxChange(permission)}
                            >
                                <input
                                    type="checkbox"
                                    checked={checkedValues.includes(permission?.id as number)}
                                    onChange={() => handleCheckboxChange(permission)}
                                    className={`${componentClassName}__item__checkbox`}
                                />
                                <label className={`${componentClassName}__item__label`}>
                                    {permission.displayTitle}
                                </label>
                            </div>
                        )
                        )}
                    </div>
                </div>
            ))}
        </Container>
    );
};
