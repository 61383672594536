import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import {
  DEFAULT_PROFILE_PHOTO,
  PAGE_NAME,
  PERMISSIONS,
  ROUTE,
} from "../../../../constants/constants";
import { hasPermission, monthShortNames } from "../../../../utils";
import {
  useUserSoftDeleteMutation,
  useSingleUserQuery,
  useUserRestoreMutation,
} from "../../../../store/user/userSlice";
import { Dialog } from "../../../atoms/dialog";
import { toast } from "react-toastify";
import { Icon } from "../../../atoms/icon";
import { Loader } from "../../../atoms/loader";
import { BACKEND_URL } from "../../../../constants/urls";

const formattedDateTime = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const localTime = `${month}, ${day < 10 ? `0${day}` : day} ${year} ${hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;

  const UTCTime = `${
    monthShortNames[date.getUTCMonth()]
  }, ${date.getUTCDate()} ${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()} UTC`

  return `${localTime} | ${UTCTime}`
};

const UserDetails = () => {
  const pageClassName = "p-userDetails";
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data, isSuccess } = useSingleUserQuery({ id });

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState<number>();

  const dialogHandler = () => {
    setIsDialogOpen(true);
    setDeleteId(Number(id));
  };

  const closeDialogHandler = () => {
    setIsDialogOpen(false);
  };

  const [deleteApi, { isLoading: deleteLoading }] = useUserSoftDeleteMutation();
  const [restoreApi, { isLoading: restoreLoading }] = useUserRestoreMutation();

  const deleteHandler = async () => {
    closeDialogHandler();
    let res;
    if (data?.result?.deletedAt) {
      res = (await restoreApi(deleteId as number)) as any;
    } else {
      res = (await deleteApi(deleteId as number)) as any;
    }
    if (res?.data?.success) {
      toast.success(res.data.message);
      navigate(ROUTE.UM_TOOL_USER_LIST);
    } else {
      toast.error(res.error.message);
    }
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.UM_TOOL_USER_DETAILS}</p>
      <Loader isLoading={deleteLoading || restoreLoading}>
        <div className={`${pageClassName}__content`}>
          <div className={`${pageClassName}__container`}>
            <div className={`${pageClassName}__container__image`}>
              <img
                className={`${pageClassName}__container__image__photo`}
                src={
                  data?.result?.profilePhotoPath
                    ? `${BACKEND_URL}/${data?.result?.profilePhotoPath}`
                    : DEFAULT_PROFILE_PHOTO
                }
                alt="profile"
              />
              <div className={`${pageClassName}__container__image__info`}>
                <span>Role: </span>
                <p>{data?.result?.role[0].displayTitle}</p>
                <span>ORG: </span>
                <p>{data?.result?.organization?.name}</p>
                <span>ORG Address: </span>
                <p>{data?.result?.organization?.country}</p>
              </div>
            </div>
            <div className={`${pageClassName}__container__container`}>
              {/* first Name and Last Name */}
              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>First Name</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.firstName}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Middle Name</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.middleName}</p>
                  </div>
                </div>
              </div>
              {/* last name Japanese Name*/}
              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Last Name</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.lastName}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Name In Japanese</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.nameInJp}</p>
                  </div>
                </div>
              </div>
              {/* email and contact no*/}
              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Email</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.email as string}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Contact No</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.contactNo ? data?.result?.contactNo : "" : ""}</p>
                  </div>
                </div>
              </div>

              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Designation</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.designation as string :""}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Department</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.department as string : ""}</p>
                  </div>
                </div>
              </div>

              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Address</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.address ? data?.result?.address : "" : ""}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Personal In Charge (PIC/Manager/Team Lead)</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.manager ? data?.result?.manager?.middleName ? `${data?.result?.manager?.firstName} ${data?.result?.manager?.middleName} ${data?.result?.manager?.lastName}` : `${data?.result?.manager?.firstName} ${data?.result?.manager?.lastName}` : "" : ""}</p>
                  </div>
                </div>
              </div>

              {/* Remarks*/}
              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Remarks</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.remarks ? data?.result?.remarks : "" : ""}</p>
                  </div>
                </div>
              </div>
              {/* Created Date and Updated Date*/}
              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Created Date</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.createdAt ? formattedDateTime(data?.result?.createdAt) : ""}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Updated Date</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{data?.result?.updatedAt ? formattedDateTime(data?.result?.updatedAt) : ""}</p>
                  </div>
                </div>
              </div>
              {/* D */}
              <div className={`${pageClassName}__container__group`}>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Delete Date</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.deletedAt ? formattedDateTime(data?.result?.deletedAt) : "-" : ""}</p>
                  </div>
                </div>
                <div className={`${pageClassName}__container__group__label`}>
                  <label>Accept Terms & Conditions</label>
                  <div className={`${pageClassName}__container__group__content`}>
                    <p>{isSuccess ? data?.result?.isAgreeTerms ? "Agree" : "Not Yet" : ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${pageClassName}__content__action`}>
            {hasPermission(PERMISSIONS.USER_EDIT.title) && (
              <button
                className={`${pageClassName}__content__action__button`}
                onClick={() =>
                  navigate(ROUTE.UM_TOOL_USER_LIST + ROUTE.EDIT, {
                    state: {
                      userId: data?.result?.id,
                    },
                  })
                }
              >
                <Icon icon="edit" /> Edit
              </button>
            )}
            {(hasPermission(PERMISSIONS.USER_DELETE.title) || hasPermission(PERMISSIONS.USER_RESTORE.title)) &&
              <button
                className={`${pageClassName}__content__action__button ${pageClassName}__content__action__button--delete`}
                onClick={dialogHandler}
              >
                {data?.result?.deletedAt ? (
                  <span>
                    <Icon icon="restore" /> Restore
                  </span>
                ) : (
                  <span>
                    <Icon icon="delete" /> Delete
                  </span>
                )}
              </button>
            }
          </div>
        </div>
      </Loader>
      <Dialog
        header={data?.result?.deletedAt ? "Restore User" : "Delete User"}
        body={
          <div className={`${pageClassName}__dialog`}>
            <p className={`${pageClassName}__dialog__text`}>
              {data?.result?.deletedAt
                ? "Are you sure you want to restore this user?"
                : "Are you sure you want to delete this user?"}
            </p>
            <p className={`${pageClassName}__dialog__text`}>
              This action cannot be undone.
            </p>
          </div>
        }
        isOpen={isDialogOpen}
        onClose={closeDialogHandler}
        successFn={deleteHandler}
        btn={data?.result?.deletedAt ? "Restore" : "Delete"}
        icon={data?.result?.deletedAt ? "restore" : "delete"}
      />
    </div>
  );
};

export default UserDetails;
