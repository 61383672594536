import React from "react";
import { Breadcrumb } from "../../atoms/breadcrumb";
import { UnderConstruction } from "../../organisms/underConstruction";


const NotificationAndAlert = () => {
    const pageClassName = "p-inventoryManagement";

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>Notification And Alerts</p>
            <div className={`${pageClassName}__container`}>
                <UnderConstruction />
            </div>
        </div>
    );
};

export default NotificationAndAlert;
