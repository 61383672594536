import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";
import { Link, useNavigate } from "react-router-dom";
import { userStateType } from "../../../types";
import { useSelector } from "react-redux";
import logo from "../../../assets/logo/logo.png";
import { ROUTE } from "../../../constants/constants";
import { BACKEND_URL } from "../../../constants/urls";

export interface HeaderProps extends BaseComponentProps {
  className?: string;
  isFullWidth?: boolean;
}

export const Header = ({ className, isFullWidth }: HeaderProps) => {
  const componentClassName = "o-Header";
  const navigate = useNavigate();
  const user = useSelector((state: userStateType) => state.user.user);

  const { Container } = createComponentBase(componentClassName, "div");

  const [isShowDropdown, setIsShowDropdown] = React.useState(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const modifiers = {
    showDropdown: isShowDropdown,
  };

  const logoutHandler = async () => {
    navigate("/logout");
  };

  return (
    <Container className={className} modifiers={modifiers}>
      <div className={`${componentClassName}__container`}>
        <div className={`${componentClassName}__logo__container`}>
          <img src={logo} alt="logo" height={"100%"} width={"100%"} />
        </div>

        <div
          className={`${componentClassName}__profile`}
          onClick={() => setIsShowDropdown(!isShowDropdown)}
          ref={dropdownRef}
        >
          <p className={`${componentClassName}__profile__name`}>
            {user?.firstName} {user?.middleName} {user?.lastName}
          </p>
          <div className={`${componentClassName}__profile__icon`}>
            {user?.profilePhotoPath ? (
              <img
                className={`${componentClassName}__profile__icon__image`}
                src={BACKEND_URL + "/" + user.profilePhotoPath}
                alt="profile"
              />
            ) : (
              <p className={`${componentClassName}__profile__icon__text`}>
                {user?.firstName?.[0]}
              </p>
            )}
          </div>
          {isShowDropdown && (
            <div className={`${componentClassName}__profile__dropdown`}>
              <Link
                to={ROUTE.USER_PROFILE}
                className={`${componentClassName}__profile__dropdown__item`}
              >
                Profile
              </Link>

              <p
                className={`${componentClassName}__profile__dropdown__item`}
                onClick={logoutHandler}
              >
                Logout
              </p>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
