import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Dropdown } from "../../../atoms/dropdown";
import { Textarea } from "../../../atoms/textarea";
import { PAGE_NAME, ROUTE, SOMETHING_WENT_WRONG, saasToolName } from "../../../../constants/constants";
import { MultipleDropdown } from "../../../atoms/multipleDropdown";
import { useOktaUserListQuery } from "../../../../store/okta/userSlice";
import { useCreateBroadcastMutation } from "../../../../store/broadcast/broadcastSlice";
import { toast } from "react-toastify";
import { payloadType } from "../../../../types";
import { Icon } from "../../../atoms/icon";
import { useOktaGroupListQuery } from "../../../../store/okta/groupSlice";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../molecules/modal";
import logo from "../../../../assets/logo/logo.png";
import { MultipleFileUpload } from "../../../atoms/multipleFileUpload";
import { Loader } from "../../../atoms/loader";

const BroadcastCreate = () => {
    const pageClassName = "p-broadcastCreate";
    const navigate = useNavigate();

    const initialPayload: payloadType = {
        type: {
            value: {} as { label: string; value: string },
            error: "",
            isError: false,
        },
        user_emails: {
            value: [] as { label: string; value: string }[],
            error: "",
            isError: false,
        },
        group_id: {
            value: {} as { label: string; value: string },
            error: "",
            isError: false,
        },
        subject: {
            value: "",
            error: "",
            isError: false,
        },
        message: {
            value: "",
            error: "",
            isError: false,
        },
        saas_tool: {
            value: [] as { label: string; value: string }[],
            error: "",
            isError: false,
        },
        attachments: {
            value: [] as any[],
            error: "",
            isError: false,
        },
    };

    const [payload, setPayload] = React.useState(initialPayload);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const closeHandler = () => {
        setIsModalOpen(false);
    };

    const { data, isLoading: oktaUserLoading } = useOktaUserListQuery({
        search: "active",
    });
    const { data: groupData, isLoading: oktaGroupLoading } = useOktaGroupListQuery({});

    const [createApi, { isLoading }] = useCreateBroadcastMutation();

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append(
            "type",
            (payload.type.value as { label: string; value: string })?.value ?? ""
        );
        // eslint-disable-next-line no-lone-blocks
        {
            Object.keys(payload.user_emails.value).length > 0 &&
                formData.append(
                    "user_emails",
                    JSON.stringify(
                        (
                            payload.user_emails.value as { label: string; value: string }[]
                        ).map((user: { label: string; value: string }) => user.value)
                    )
                );
        }
        // eslint-disable-next-line no-lone-blocks
        {
            Object.keys(payload.group_id.value).length > 0 &&
                formData.append(
                    "group_id",

                    (payload.group_id.value as { label: string; value: string })?.value
                );
        }
        formData.append("subject", payload.subject.value as string);
        formData.append("message", payload.message.value as string);
        (payload.saas_tool.value as { label: string; value: string }[]).length >
            0 &&
            formData.append(
                "saas_tool",
                JSON.stringify(
                    (payload.saas_tool.value as { label: string; value: string }[])?.map(
                        (tool: { label: string; value: string }) => tool.value
                    )
                )
            );

        (payload.attachments.value as []).length > 0 &&
            // eslint-disable-next-line array-callback-return
            (payload.attachments.value as []).map((file) => {
                formData.append("attachments[]", file);
            });


        const response = (await createApi(formData)) as any;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate(ROUTE.BROADCAST_HISTORY);
        } else {
            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                    //   }
                });
            } else {
                toast.error(response?.error.data?.message);
            }
            if (response?.error?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.BROADCAST_EMAIL}</p>
            <div className={`${pageClassName}__container`}>
                <Loader isLoading={oktaUserLoading || oktaGroupLoading || isLoading} >
                    <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                        <div className={`${pageClassName}__form__item`}>
                            <div className={`${pageClassName}__required`}>
                                <label className={`${pageClassName}__required__label`}>
                                    Type
                                </label>

                                <div className={`${pageClassName}__required__icon`}>
                                    <Icon icon="star" size="medium" />
                                </div>
                            </div>

                            <Dropdown
                                options={[
                                    { value: "users", label: "Users" },
                                    { value: "group", label: "Group" },
                                ]}
                                placeholder="Select Type"
                                onClickChange={(selected) =>
                                    setPayload({
                                        ...payload,
                                        type: {
                                            ...payload.type,
                                            value: selected as { label: string; value: string },
                                            isError: false,
                                            error: "",
                                        },
                                    })
                                }
                                isIcon
                                selected={payload.type.value as { label: string; value: string }}
                                isError={payload.type.isError}
                                error={payload.type.error}
                            />
                        </div>
                        {(payload.type.value as { label: string; value: string })?.value ===
                            "users" ? (
                            <div className={`${pageClassName}__form__item`}>
                                <div className={`${pageClassName}__required`}>
                                    <label className={`${pageClassName}__required__label`}>
                                        User
                                    </label>
                                    <div className={`${pageClassName}__required__icon`}>
                                        <Icon icon="star" size="medium" />
                                    </div>
                                </div>
                                <MultipleDropdown
                                    options={
                                        data?.result?.data.map((user: any) => ({
                                            label: user?.firstName + " " + user?.lastName,
                                            value: user.email,
                                        })) || []
                                    }
                                    placeholder="Select User"
                                    dropdownHandler={(e) =>
                                        setPayload({
                                            ...payload,
                                            user_emails: {
                                                ...payload.user_emails,
                                                value: e as { label: string; value: string }[],
                                                isError: false,
                                                error: "",
                                            },
                                        })
                                    }
                                    value={
                                        payload.user_emails.value as {
                                            label: string;
                                            value: string;
                                        }[]
                                    }
                                    isError={payload.user_emails.isError}
                                    error={payload.user_emails.error}
                                />
                            </div>
                        ) : (
                            <div className={`${pageClassName}__form__item`}>
                                <div className={`${pageClassName}__required`}>
                                    <label className={`${pageClassName}__required__label`}>
                                        Group
                                    </label>
                                    <div className={`${pageClassName}__required__icon`}>
                                        <Icon icon="star" size="medium" />
                                    </div>
                                </div>
                                <Dropdown
                                    options={
                                        groupData?.result?.data.map((group: any) => ({
                                            label: group?.name,
                                            value: group.id,
                                        })) || []
                                    }
                                    placeholder="Select Group"
                                    onClickChange={(e) =>
                                        setPayload({
                                            ...payload,
                                            group_id: {
                                                ...payload.group_id,
                                                value: e as { label: string; value: string },
                                                isError: false,
                                                error: "",
                                            },
                                        })
                                    }
                                    selected={
                                        payload.group_id.value as {
                                            label: string;
                                            value: string;
                                        }
                                    }
                                    isError={payload.group_id.isError}
                                    error={payload.group_id.error}
                                />
                            </div>
                        )}
                        <Input
                            label="Subject"
                            placeholder="Please enter subject"
                            type="text"
                            isRequired
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    subject: {
                                        ...payload.subject,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            error={payload.subject.error}
                            isError={payload.subject.isError}
                        />
                        <Textarea
                            label="Message"
                            placeholder="Please enter message"
                            isRequired
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    message: {
                                        ...payload.message,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            error={payload.message.error}
                            isError={payload.message.isError}
                        />
                        <div className={`${pageClassName}__form__item`}>
                            <div className={`${pageClassName}__required`}>
                                <label className={`${pageClassName}__required__label`}>
                                    Saas Tool
                                </label>
                            </div>
                            <MultipleDropdown
                                options={saasToolName.map((tool) => ({
                                    label: tool.label,
                                    value: tool.value,
                                }))}
                                dropdownHandler={(selected) =>
                                    setPayload({
                                        ...payload,
                                        saas_tool: {
                                            ...payload.saas_tool,
                                            value: selected,
                                            isError: false,
                                            error: "",
                                        },
                                    })
                                }
                                value={
                                    payload.saas_tool.value as { label: string; value: string }[]
                                }
                                placeholder="Select Apps"
                                isError={payload.saas_tool.isError}
                                error={payload.saas_tool.error}
                            />
                        </div>
                        <div className={`${pageClassName}__form__item`}>
                            <div className={`${pageClassName}__required`}>
                                <label className={`${pageClassName}__required__label`}>
                                    Attachments
                                </label>
                            </div>
                            <MultipleFileUpload
                                onUpload={(files) => {
                                    setPayload({
                                        ...payload,
                                        attachments: {
                                            ...payload.attachments,
                                            value: files as File[],
                                            isError: false,
                                            error: "",
                                        },
                                    });
                                }}

                                placeholder="Please select attachments"
                            />
                        </div>
                        <div className={`${pageClassName}__action`}>
                            <div>
                                {payload.message.value && (
                                    <button
                                        className={`${pageClassName}__action__button`}
                                        type="button"
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        Preview
                                    </button>
                                )}
                            </div>

                            <div className={`${pageClassName}__action__group`}>
                                <button
                                    className={`${pageClassName}__action__button ${pageClassName}__action__create`}
                                    type="submit"
                                >
                                    Broadcast
                                </button>
                                <button
                                    className={`${pageClassName}__action__button ${pageClassName}__action__cancel`}
                                    type="button"
                                    onClick={() => navigate(ROUTE.BROADCAST_HISTORY)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </Loader>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeHandler} header="Preview">
                <div className={`${pageClassName}__preview`}>
                    <div className={`${pageClassName}__preview__container`}>
                        <h1 className={`${pageClassName}__preview__tooName`}>
                            {process.env.REACT_APP_NAME}
                        </h1>

                        <img src={logo} alt="email" width="150px" />
                        <p className={`${pageClassName}__preview__name`}>Hi all,</p>
                        <p className={`${pageClassName}__preview__message`}>
                            {payload.message.value as string}
                        </p>
                        {(payload?.saas_tool?.value as { label: string; value: string }[])
                            ?.length > 0 && (
                                <div className={`${pageClassName}__preview__apps`}>
                                    <p className={`${pageClassName}__preview__apps__label`}>
                                        Saas Tool :
                                    </p>
                                    <div className={`${pageClassName}__preview__apps__list`}>
                                        {(
                                            payload?.saas_tool?.value as {
                                                label: string;
                                                value: string;
                                            }[]
                                        ).map((tool, index) => (
                                            <p
                                                key={index}
                                                className={`${pageClassName}__preview__apps__list__item`}
                                            >
                                                {tool.label}{" "}
                                                {index !== (payload?.saas_tool.value as []).length - 1 && ","}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        <button
                            className={`${pageClassName}__preview__button`}
                            type="button"
                        >
                            <a
                                href={process.env.REACT_APP_OKTA_URL}
                                target="_blank"
                                rel="noreferrer"
                                className={`${pageClassName}__preview__button__link`}
                            >
                                Okta Dashboard
                            </a>
                        </button>

                        <p className={`${pageClassName}__preview__footer`}>
                            For further queries, please feel free to send an email &nbsp;
                            <a href={`mailto:${process.env.REACT_APP_MAIL_ADDRESS}`}>
                                {process.env.REACT_APP_MAIL_ADDRESS}
                            </a>
                            <p>We will be happy to answer your questions.</p>
                        </p>
                    </div>
                </div>
            </Modal>
        </div >
    );
};

export default BroadcastCreate;
