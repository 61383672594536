import React, { useMemo } from "react";
export type BaseComponentProps = React.HTMLAttributes<HTMLElement> & {
  className?: string;
};

type MapModifiersModifier =
  | string
  | false
  | null
  | undefined
  | (string | false | null | undefined)[];

export type ContainerProps = {
  modifiers?: any;
} & BaseComponentProps;

export type ContainerHTMLProps<T extends keyof React.ReactHTML> =
  ContainerProps & JSX.IntrinsicElements[T];

function generateModifierClassNameArray(
  baseClassName: string,
  ...modifiers: MapModifiersModifier[]
): string[] {
  let classNameArray: string[] = [];

  for (const modifier of modifiers) {
    if (Array.isArray(modifier)) {
      classNameArray = classNameArray.concat(
        generateModifierClassNameArray(baseClassName, ...modifier)
      );
    } else if (typeof modifier === "string" && modifier.length > 0) {
      classNameArray.push(baseClassName + "--" + modifier);
    }
  }

  return classNameArray;
}

export function mapModifiers(
  baseClassName: string,
  ...modifiers: MapModifiersModifier[]
): string {
  return (
    baseClassName +
    " " +
    generateModifierClassNameArray(baseClassName, ...modifiers)
      .join(" ")
      .trim()
  ).trim();
}

export const createComponentBase = <T extends keyof React.ReactHTML>(
  baseClassName: string,
  tagName?: string
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMemo(() => {
    const Container = React.forwardRef(function Container(
      props: ContainerHTMLProps<T>,
      ref
    ) {
      const {
        children,
        className: additionalClassName,
        modifiers,
        ...otherProps
      } = props;
      const modifierArray = modifiers
        ? Object.keys(modifiers)
            .map((item: any) => (modifiers[item] ? `${item}` : ""))
            .filter((item) => item)
        : [];

      const className = additionalClassName
        ? addClassName(
            mapModifiers(baseClassName, ...modifierArray),
            additionalClassName
          )
        : mapModifiers(baseClassName, ...modifierArray);
      return React.createElement(
        tagName ?? "div",
        {
          className,
          ref,
          ...otherProps,
        },
        children
      );
    });
    return { Container };
  }, [baseClassName, tagName]);

export const addClassName = (baseClassName: string, ...add: string[]) =>
  add.reduce((prev, v) => (v ? `${prev} ${v}` : prev), baseClassName);
