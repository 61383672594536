import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "licenseList",
    "singleLicense",
    "pricingList",
    "singlePricing",
    "organizationsPricing",
    "userPricingList",
  ],
});

export const pricingSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Fetches the pricing list from the server.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of items to retrieve.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @returns A promise that resolves to the response data.
     */
    pricingList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url: API_URL.PRICING + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["pricingList"],
    }),

    /**
     * Updates the pricing information.
     * @param payload - The data to be sent in the request body.
     * @returns A promise that resolves to the response data.
     */
    updatePricing: builder.mutation<ResponseDataType, FormData>({
      query: (payload) => ({
        url: API_URL.PRICING,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "licenseList",
        "singleLicense",
        "pricingList",
        "singlePricing",
        "organizationsPricing",
        "userPricingList",
      ],
    }),

    /**
     * Fetches a single pricing data from the server.
     *
     * @param id - The ID of the pricing data to fetch.
     * @returns A Promise that resolves to the response data.
     */
    singlePricing: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.PRICING + `/${id}`,
        method: "GET",
      }),
      providesTags: ["singlePricing"],
    }),

    /**
     * Fetches organizations pricing data from the server.
     *
     * @param search - The search query for filtering organizations.
     * @param limit - The maximum number of organizations to fetch.
     * @param current_page - The current page number for pagination.
     * @returns A promise that resolves to the response data.
     */
    organizationsPricing: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page }) => ({
        url: API_URL.PRICING + API_URL.ORGANIZATIONS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""}${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["organizationsPricing"],
    }),

    /**
     * Fetches the user pricing list from the server.
     *
     * @param search - The search query for filtering the list.
     * @param limit - The maximum number of items to retrieve.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the list by.
     * @param sort_direction - The direction to sort the list in.
     * @returns A promise that resolves to the response data.
     */
    userPricingList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url: API_URL.PRICING + API_URL.USERS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["userPricingList"],
    }),
  }),
});

export const {
  usePricingListQuery,
  useUpdatePricingMutation,
  useSinglePricingQuery,
  useOrganizationsPricingQuery,
  useUserPricingListQuery,
} = pricingSlice;
