import React from "react";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { pascalToSnakeCase } from "../../../../../utils";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Input } from "../../../../atoms/input";
import { Table } from "../../../../atoms/table";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { Pagination } from "../../../../molecules/pagination";
import { useNavigate } from "react-router-dom";
import { useYoutrackTicketListQuery } from "../../../../../store/youtrack/youtrackSlice";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import { TableDataType } from "../../../../../types";

const YoutrackSupportTicketsList = () => {
    const pageClassName = "p-youtrackSupportTicketsList";

    const navigate = useNavigate();
    const [state, setState] = React.useState<any>([]);

    /**
     * dropdown options
     */
    const options = [
        { label: "Open", value: "open" },
        { label: "Fixed", value: "fixed" },
        { label: "Closed", value: "closed" },
        { label: "In Progress", value: "in Progress" },
        { label: "Won't fix", value: "won't fix" },
        { label: "Can't Reproduce", value: "can't reproduce" },
        { label: "Future Implementation", value: "Future Implementation" },
        { label: "Hold", value: "Hold" },
    ];
    /**
     * Table Hooks
     * @returns
     * search: string;
     * query: QueryType;
     * pageChangeHandler: (page: number) => void;
     * sortHandler: (sortBy: string) => void;
     * limitChangeHandler: (limit: number) => void;
     * searchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
     */
    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    /**
     *
     * @param {string} sortBy
     * @returns
     */

    const formatSortHandler = (sortBy: string) => {
        if (sortBy === "ticketProject") {
            return "project";
        } else if (sortBy === "createdAtTime") {
            return "createdAt";
        }
        return sortBy;
    };

    /**
     * fetch data from api
     * @returns
     * data: ResponseDataType | undefined;
     * isLoading: boolean;
     *
     */

    const { data, isLoading } = useYoutrackTicketListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(formatSortHandler(query.sortBy)),
        sort_direction: query.sortDirection,
        state: JSON.stringify(
            state?.map((item: any) => {
                return item.value;
            })
        ),
    });

    /**
     * Table Header
     * @returns
     * title: string;
     * dataIndex: string;
     * isSortable: boolean;
     */

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Ticket ID",
            dataIndex: "issueId",
            isSortable: true,
        },
        {
            title: "Parent ID",
            dataIndex: "parentIssueId",
            isSortable: true,
        },
        {
            title: "Name",
            dataIndex: "ticketName",
            isSortable: true,
        },
        {
            title: "Assignee",
            dataIndex: "assignee",
            isSortable: true,
        },
        {
            title: "Board Name",
            dataIndex: "board",
            isSortable: true,
        },
        {
            title: "Spent Time",
            dataIndex: "spentTime",
            isSortable: true,
        },
        {
            title: "State",
            dataIndex: "state",
            isSortable: false,
        },
        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: true,
        },
    ];

    /**
     * Pagination Hooks
     * @returns
     * totalData: number;
     * handleFirstPage: () => void;
     * handlePrevPage: () => void;
     * handleLastPage: () => void;
     * handleNextPage: () => void;
     * renderPageNumbers: number[];
     */
    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    /**
     *
     * @param id
     *
     */
    const viewHandler = (id: string, e: any) => {
        if (e.target.className === "a-table__table__hyperlink") return;
        const name = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        ).issueId;
        navigate(ROUTE.SUPPORT_TICKETS_LIST + `/${id}`, { state: { name } });
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.SUPPORT_TICKETS_LIST}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>
                    {state.length > 0 && (
                        <p className={`${pageClassName}__header__title`}>
                            Filtered By State:{" "}
                            {state.length > 0
                                ? state.map((item: any) => item.value).join(", ")
                                : ""}
                        </p>
                    )}
                    <div></div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={(sortBy: string) => sortHandler(sortBy)}
                        viewHandler={viewHandler}
                        minWidth="1600"
                        minHeight="330"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                        dropdownOptions={options}
                        selectedType={state}
                        dropdownHandler={(selected: any) => setState(selected)}
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YoutrackSupportTicketsList;
