import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { TableDataType } from "../../../../types";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME, PERMISSIONS, ROUTE } from "../../../../constants/constants";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { useRoleListQuery } from "../../../../store/role/roleSlice";
import { hasPermission, pascalToSnakeCase } from "../../../../utils";

const RoleList = () => {
    const pageClassName = "p-roleList";
    const navigate = useNavigate();

    const { search, query, sortHandler, searchHandler } = useTableHooks();

    const { data, isLoading, isFetching } = useRoleListQuery({
        search: query.search,
        sort_by: pascalToSnakeCase(query.sortBy),
        sort_direction: query.sortDirection,
    });
    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Display Title",
            dataIndex: "displayTitle",
            isSortable: true,
        },
        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: true,
        },
        {
            title: "Updated At",
            dataIndex: "updatedAtTime",
            isSortable: true,
        },
    ];

    const toolSortHandler = (sortBy: string) => {
        if (sortBy === "createdAtTime") {
            sortBy = "createdAt";
        } else if (sortBy === "updatedAtTime") {
            sortBy = "updatedAt";
        }

        sortHandler(sortBy);
    };

    const viewHandler = (id: string) => {
        const role = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        );
        navigate(ROUTE.UM_TOOL_USER_ROLE_LIST + `/${id}`, {
            state: {
                name: role?.displayTitle,
            },
        });
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.UM_TOOL_USER_ROLE_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                    {hasPermission(PERMISSIONS.ROLE_CREATE.title) && (
                        <button
                            className={`${pageClassName}__button`}
                            onClick={() => navigate(ROUTE.UM_TOOL_USER_ROLE_LIST + ROUTE.ROLE_CREATE)}
                        >
                            Add Role
                        </button>
                    )}
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={(sortBy: string) => toolSortHandler(sortBy)}
                        viewHandler={viewHandler}
                        minWidth="800"
                        startIndex={1}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
            </div>
        </div>
    );
};

export default RoleList;
