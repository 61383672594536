import React from "react";
import pageNotFound from "../../../assets/images/404.svg";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const pageClassName = "p-pageNotFound";
  const navigate = useNavigate();

  return (
    <div className={pageClassName}>
      <div className={`${pageClassName}__container`}>
        <img src={pageNotFound} alt="Page Not Found" />
        <div className={`${pageClassName}__container__content`}>
          <h1 className={`${pageClassName}__container__content__title`}>
            Page not found
          </h1>
          <button
            onClick={() => navigate(-1)}
            className={`${pageClassName}__container__content__button`}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
