import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { hasPermission, numberFormat, numberFormatWithDynamicDecimal, pascalToSnakeCase } from "../../../../utils";
import { Table } from "../../../atoms/table";
import { Input } from "../../../atoms/input";
import { Modal } from "../../../molecules/modal";
import { Dropdown } from "../../../atoms/dropdown";
import { toast } from "react-toastify";
import { DropdownOptionsType, payloadType } from "../../../../types";
import { Loader } from "../../../atoms/loader";
import {
    NOTE_FOR_LICENSE_MANAGEMENT,
    PAGE_NAME,
    PERMISSIONS,
    PricingStructure,
    SOMETHING_WENT_WRONG,
    SubscriptionsName,
} from "../../../../constants/constants";
import {
    usePricingListQuery,
    useSinglePricingQuery,
    useUpdatePricingMutation,
} from "../../../../store/pricing/pricingSlice";
import { Icon } from "../../../atoms/icon";

const formatColumnName = (name: string) => {
    switch (name) {
        case "totalLicenses":
            return "total";
        case "updatedAtTime":
            return "updatedAt";
        default:
            return name;
    }
};
const PricingList = () => {
    const pageClassName = "p-pricingList";

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Saas Name",
            dataIndex: "name",
            isSortable: true,
        },
        {
            title: "Subscriptions Type",
            dataIndex: "subscriptionType",
            isSortable: true,
        },
        {
            title: "Renewal Date",
            dataIndex: "renewalDate",
            isSortable: true,
        },
        {
            title: "Total Licenses",
            dataIndex: "totalLicenses",
            isSortable: true,
            permission: hasPermission(PERMISSIONS.EDIT_BUDGET.title)
        },
        {
            title: "Pricing Structure",
            dataIndex: "pricingStructure",
            isSortable: true,
        },
        {
            title: "Unit Price",
            dataIndex: "price",
            isSortable: true,
        },
        {
            title: "Total Price",
            dataIndex: "totalPrice",
            isSortable: true,
        },
    ];

    const initialState: payloadType = {
        name: {
            value: "",
            error: "",
            isError: false,
        },
        subscription_type: {
            value: "",
            error: "",
            isError: false,
        },
        total: {
            value: "",
            error: "",
            isError: false,
        },
        renewal_date: {
            value: "",
            error: "",
            isError: false,
        },
        price: {
            value: "",
            error: "",
            isError: false,
        },
        pricing_structure: {
            value: "",
            error: "",
            isError: false,
        },
        id: {
            value: "",
            error: "",
            isError: false,
        },
    };

    const { search, query, sortHandler, searchHandler } = useTableHooks();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [payload, setPayload] = React.useState(initialState);

    const { data, isLoading, isSuccess, isFetching } = usePricingListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(formatColumnName(query.sortBy)),
        sort_direction: query.sortDirection,
    });

    const [updateApi, { isLoading: updateLoading }] = useUpdatePricingMutation();

    const {
        data: singleLicenseData,
        isSuccess: isSingleSuccess,
        isFetching: isSingleFetching,
    } = useSinglePricingQuery(payload.id.value as number, {
        skip: payload.id.value ? false : true,
    });

    React.useEffect(() => {
        if (isSingleSuccess) {
            setPayload({
                ...payload,
                name: {
                    ...payload.name,
                    value: singleLicenseData?.result?.name,
                },
                subscription_type: {
                    ...payload.subscription_type,
                    value: {
                        label: singleLicenseData?.result?.subscriptionType,
                        value: singleLicenseData?.result?.subscriptionType,
                    },
                },
                total: {
                    ...payload.total,
                    value: singleLicenseData?.result?.total,
                },

                renewal_date: {
                    ...payload.renewal_date,
                    value: singleLicenseData?.result?.renewalDate,
                },
                price: {
                    ...payload.price,
                    value: singleLicenseData?.result?.pricingStructure === "Yearly" ? singleLicenseData?.result?.price * 12 : singleLicenseData?.result?.price,
                },
                pricing_structure: {
                    ...payload.pricing_structure,
                    value: {
                        label: singleLicenseData?.result?.pricingStructure,
                        value: singleLicenseData?.result?.pricingStructure,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSingleSuccess, singleLicenseData]);

    const closeHandler = () => {
        setIsModalOpen(false);
        setPayload(initialState);
    };

    const editHandler = (id: string | number) => {
        setPayload({
            ...payload,
            id: {
                ...payload.id,
                value: id,
            },
        });
        setIsModalOpen(true);
    };

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("name", payload.name.value as string);
        payload.total.value &&
            formData.append("total", payload.total.value.toString());
        formData.append("renewal_date", payload.renewal_date.value as string);
        formData.append("price", payload.price.value as string);
        payload.pricing_structure.value &&
            formData.append(
                "pricing_structure",
                (payload.pricing_structure.value as DropdownOptionsType).value as string
            );
        payload.subscription_type.value &&
            formData.append(
                "subscription_type",
                (payload.subscription_type.value as DropdownOptionsType).value as string
            );

        formData.append("id", payload?.id?.value?.toString());

        const response = (await updateApi(formData)) as any;

        if (response?.data?.success) {
            closeHandler();
            toast.success(response?.data?.message);
        } else {
            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                });
            } else {
                toast.error(response?.error.data?.message);
            }
            if (response?.error?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    const calculateTotalPrice = (response: any, type: "Monthly" | "Yearly") => {
        const total = response?.reduce((acc: number, item: any) => {
            return acc + item.price * item.total * (type === "Monthly" ? 1 : 12);
        }, 0);

        return Number.isInteger(total) ? total : total.toFixed(2);
    };

    const calculateTotalLicense = (response: any) => {
        const total = response?.reduce((acc: number, item: any) => {
            return acc + item.total;
        }, 0);

        return total;
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />

            <div className={`${pageClassName}__card`}>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Total Monthly Cost
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess
                                ? numberFormat(
                                    calculateTotalPrice(data?.result?.licenses, "Monthly"),"JPY"
                                )
                                : 0}
                        </p>
                    </div>
                    <Icon icon="money" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>Total Yearly Cost</p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess
                                ? numberFormat(
                                    calculateTotalPrice(data?.result?.licenses, "Yearly"),"JPY"
                                )
                                : 0}
                        </p>
                    </div>
                    <Icon icon="moneyBundle" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Number of Saas Tools
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess ? data?.result?.total : 0}
                        </p>
                    </div>
                    <Icon icon="saas" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Number of Licenses
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess
                                ? numberFormatWithDynamicDecimal(calculateTotalLicense(data?.result?.licenses),0)
                                : 0}
                        </p>
                    </div>
                    <Icon icon="license" size="tertiary" />
                </div>
            </div>
            <p className={`${pageClassName}__title`}>{PAGE_NAME.SAAS_TOOl_WISE_BUDGET}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.licenses ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        sortBy={query.sortBy}
                        minWidth="1440"
                        startIndex={data?.result?.from}
                        editHandler={editHandler}
                    />
                    <p className={`${pageClassName}__note`}>
                        <span>*</span>
                        {NOTE_FOR_LICENSE_MANAGEMENT}
                    </p>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={closeHandler}
                header="Edit Saas Tool Price"
                width="small"
            >
                <Loader isLoading={isSingleFetching || updateLoading}>
                    <form className={`${pageClassName}__modal`} onSubmit={submitHandler}>
                        <div className={`${pageClassName}__modal__dropdown`}>
                            <label className={`${pageClassName}__modal__dropdown__label`}>
                                Saas Name
                            </label>
                            <div className={`${pageClassName}__modal__dropdown__value`}>
                                <span>{payload?.name?.value as string}</span>
                            </div>
                        </div>

                        <div className={`${pageClassName}__modal__dropdown`}>
                            <label className={`${pageClassName}__modal__dropdown__label`}>
                                Subscription Type
                            </label>
                            <Dropdown
                                options={SubscriptionsName}
                                onClickChange={(selected) =>
                                    setPayload({
                                        ...payload,
                                        subscription_type: {
                                            ...payload.subscription_type,
                                            value: selected as { label: string; value: string },
                                            isError: false,
                                            error: "",
                                        },
                                    })
                                }
                                placeholder="Please Select a Subscription Type"
                                isIcon
                                color="black"
                                isBordered
                                selected={
                                    payload.subscription_type.value as DropdownOptionsType
                                }
                                isError={payload.subscription_type.isError}
                                error={payload.subscription_type.error}
                            />
                        </div>

                        <Input
                            placeholder="Enter Number of Total Licenses"
                            label="Total Licenses"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    total: {
                                        ...payload.total,
                                        value: parseInt(e.target.value),
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            value={(payload.total.value as number) || ""}
                            isError={payload.total.isError}
                            error={payload.total.error}
                            type="number"
                        />

                        <div className={`${pageClassName}__modal__dropdown`}>
                            <label className={`${pageClassName}__modal__dropdown__label`}>
                                Pricing Structure
                            </label>
                            <Dropdown
                                options={PricingStructure}
                                onClickChange={(selected) =>
                                    setPayload({
                                        ...payload,
                                        pricing_structure: {
                                            ...payload.pricing_structure,
                                            value: selected as { label: string; value: string },
                                            isError: false,
                                            error: "",
                                        },
                                    })
                                }
                                placeholder="Please Select a Pricing Structure"
                                isIcon
                                color="black"
                                isBordered
                                selected={
                                    payload.pricing_structure.value as DropdownOptionsType
                                }
                                isError={payload.pricing_structure.isError}
                                error={payload.pricing_structure.error}
                            />
                        </div>

                        <Input
                            placeholder="Enter Price"
                            label="Price"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    price: {
                                        ...payload.price,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            value={payload.price.value as string}
                            isError={payload.price.isError}
                            error={payload.price.error}
                            type="number"
                        />
                        <Input
                            placeholder="Enter Renewal Date"
                            label="Renewal Date"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    renewal_date: {
                                        ...payload.renewal_date,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            value={
                                payload.renewal_date.value
                                    ? (new Date(payload.renewal_date.value as string)
                                        .toISOString()
                                        .substring(0, 10) as string)
                                    : ""
                            }
                            isError={payload.renewal_date.isError}
                            error={payload.renewal_date.error}
                            type="date"
                        />

                        <div className={`${pageClassName}__modal__action`}>
                            <button
                                className={`${pageClassName}__modal__cancel`}
                                onClick={closeHandler}
                                type="button"
                            >
                                Cancel
                            </button>
                            <button className={`${pageClassName}__modal__add`} type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </Loader>
            </Modal>
        </div>
    );
};

export default PricingList;
