import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";

export const iconList = [
  "hamburger",
  "home",
  "user",
  "up",
  "down",
  "app",
  "group",
  "view",
  "viewHide",
  "box",
  "license",
  "cross",
  "edit",
  "addUser",
  "gitlab",
  "youtrack",
  "confluence",
  "filter",
  "downFill",
  "sort",
  "key",
  "check",
  "arrowRight",
  "arrowLeft",
  "atlassian",
  "miro",
  "announcement",
  "star",
  "activityReport",
  "organization",
  "delete",
  "supportTicket",
  "saas",
  "money",
  "moneyBundle",
  "gcp",
  "okta",
  "aws",
  "cloud",
  "floatingLicense",
  "inventory",
  "reports",
  "sadFace",
  "smileFace",
  "list",
  "grid",
  "restore",
  "cancel",
  "camera",
  "calendar",
  "requestManagement",
  "information",
  "security",
  "approval",
  "inviteUsers",
  "pendingRequest",
  "notification",
  "copy",
  "approved",
  "rejected",
];
export interface IconProps extends BaseComponentProps {
  icon: (typeof iconList)[number];
  size?: "small" | "medium" | "primary" | "secondary" | "tertiary" | "quaternary";
  mask?: boolean;
  className?: string;
}

export const Icon = ({ icon, size, mask, className }: IconProps) => {
  const componentClassName = "a-icon";
  const modifiers = {
    [icon]: !!icon,
    [size + "Size"]: !!size,
    [icon + "-mask"]: mask,
  };
  const { Container } = createComponentBase(componentClassName, "div");
  return (
    <Container className={className} modifiers={modifiers}>
      <i className={`${componentClassName}__icon`} />
    </Container>
  );
};
