/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";

export interface MultipleSelectProps extends BaseComponentProps {
  className?: string;
  children: React.ReactNode;
  defaultOptions: { label: string; value: string }[];
  onChangeHandler: (selected: { label: string; value: string }[]) => void;
  defaultSelected: { label: string; value: string }[];
}

export const MultipleSelect = ({
  className,
  children,
  defaultOptions,
  onChangeHandler,
  defaultSelected,
}: MultipleSelectProps) => {
  const componentClassName = "a-multipleSelect";
  const modifiers = {};
  const { Container } = createComponentBase(componentClassName, "div");
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [options, setOptions] = React.useState(defaultOptions);
  const [selected, setSelected] = React.useState(defaultSelected);

  React.useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const handleCheckboxChange = (option: { label: string; value: string }) => {
    const isOptionSelected = selected.some(
      (selOption) => selOption.value === option.value
    );

    if (isOptionSelected) {
      const updatedSelected = selected.filter(
        (selOption) => selOption.value !== option.value
      );
      setSelected(updatedSelected);
    } else {
      setSelected([...selected, option]);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref.current]);

  const resetHandler = () => {
    onChangeHandler([]);
    setSelected([]);
    setIsOpen(false);
  };

  return (
    <Container className={className} modifiers={modifiers}>
      <div className={`${componentClassName}__children`} ref={ref}>
        <div onClick={() => setIsOpen(!isOpen)}>{children}</div>

        {isOpen && (
          <div className={`${componentClassName}__container`}>
            <div className={`${componentClassName}__options`}>
              {options.map((option) => (
                <div
                  className={`${componentClassName}__option`}
                  key={option.value}
                  onClick={() => handleCheckboxChange(option)}
                >
                  <input
                    type="checkbox"
                    checked={selected.some(
                      (selOption) => selOption.value === option.value
                    )}
                    onChange={() => handleCheckboxChange(option)}
                    className={`${componentClassName}__checkbox`}
                  />
                  <span className={`${componentClassName}__option__label`}>
                    {option.label}
                  </span>
                </div>
              ))}
            </div>
            <div className={`${componentClassName}__button`}>
              <button onClick={resetHandler}>Reset</button>
              <button
                onClick={() => {
                  onChangeHandler(selected);
                  setIsOpen(false);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
