import React from "react";
import { useNavigate } from "react-router";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { TableDataType } from "../../../../../types";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Input } from "../../../../atoms/input";
import { Table } from "../../../../atoms/table";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { Pagination } from "../../../../molecules/pagination";
import { monthNames, pascalToSnakeCase } from "../../../../../utils";
import { useLicenseHistoryListQuery } from "../../../../../store/license/licenseSlice";
import { Icon } from "../../../../atoms/icon";
import { MultipleSelect } from "../../../../atoms/multipleSelect";

const sortFormattedData = (dataIndex: string) => {
    switch (dataIndex) {
        case "licenseHistoryName":
            return "licenseName";
        case "licenseHistorySubscriptionType":
            return "subscriptionType";
        case "licenseHistoryPricing":
            return "pricing";
        case "licenseHistoryTotal":
            return "total";
        case "licenseHistoryActive":
            return "active";
        case "licenseHistoryInvited":
            return "invited";
        case "licenseHistoryPredicted":
            return "predicted";
        case "licenseHistoryRemaining":
            return "remaining";
        case "createdAtTime":
            return "createdAt";
        default:
            return dataIndex;
    }
};

const dropdownOptions = (data: string[], type?: "month") => {
    if (type === "month") {
        return data.map((item, index) => {
            return { value: index + 1, label: item };
        });
    }
    return data.map((item) => {
        return { value: item, label: item };
    });
};

const LicenseHistoryList = () => {
    const pageClassName = "p-licenseHistoryList";
    const navigate = useNavigate();

    const ref = React.useRef<HTMLDivElement>(null);
    const filterRef = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);
    const [selectedMonth, setSelectedMonth] = React.useState<{
        label: string;
        value: string;
    }>({ label: "All", value: "All" });

    const [selectedYear, setSelectedYear] = React.useState<{
        label: string;
        value: string;
    }>({ label: "All", value: "All" });

    const [isShowDropdown, setShowDropdown] = React.useState(false);

    const [saas, setSaaS] = React.useState<
        {
            label: string;
            value: string;
        }[]
    >([]);

    const [filter, setFilter] = React.useState<{
        month: string;
        year: string;
        saas: string[];
    }>({ month: "All", year: "All", saas: [] });

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useLicenseHistoryListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(sortFormattedData(query.sortBy)),
        sort_direction: query.sortDirection,
        year: filter.year === "All" ? undefined : filter.year,
        month: filter.month === "All" ? undefined : filter.month,
        tool_name: filter.saas.length > 0 ? JSON.stringify(filter.saas) : undefined,
    });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Date",
            dataIndex: "createdAtTime",
            isSortable: true,
        },
        {
            title: "SaaS/Infra",
            dataIndex: "licenseHistoryName",
            isSortable: true,
        },
        {
            title: "Subscription Type",
            dataIndex: "licenseHistorySubscriptionType",
            isSortable: true,
        },
        // {
        //     title: "Pricing",
        //     dataIndex: "licenseHistoryPricing",
        //     isSortable: true,
        // },
        {
            title: "Total",
            dataIndex: "licenseHistoryTotal",
            isSortable: true,
        },
        {
            title: "Active",
            dataIndex: "licenseHistoryActive",
            isSortable: true,
        },
        {
            title: "Invited",
            dataIndex: "licenseHistoryInvited",
            isSortable: true,
        },
        {
            title: "Predicted",
            dataIndex: "licenseHistoryPredicted",
            isSortable: true,
        },
        {
            title: "Remaining",
            dataIndex: "licenseHistoryRemaining",
            isSortable: true,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string) => {
        const name = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        ).license.name;
        navigate(`${ROUTE.LICENSE_HISTORY_LIST}/${id}`, { state: { name } });
    };

    const filterHandler = () => {
        setFilter({
            month: selectedMonth.value,
            year: selectedYear.value,
            saas: saas.map((item) => item.value),
        });
    };

    const dropdownHandler = (
        value: {
            label: string;
            value: string;
        }[]
    ) => {
        setSaaS(value);
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const resetFilter = () => {
        setSelectedMonth({ label: "All", value: "All" });
        setSelectedYear({ label: "All", value: "All" });
        setSaaS([]);
        setFilter({
            month: "All",
            year: "All",
            saas: [],
        });
        handleFirstPage();
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.LICENSE_HISTORY_LIST}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>
                    {(filter?.month !== "All" ||
                        filter?.year !== "All" ||
                        filter.saas.length > 0) && (
                            <p className={`${pageClassName}__header__title`}>
                                Filtered By{" "}
                                {filter?.month !== "All"
                                    ?"Month: " + monthNames[parseInt(filter?.month) - 1]
                                    : ""}{" "}
                                {filter?.year !== "All" ? "Year: " + filter?.year : ""}{" "}
                                {filter.saas.length > 0
                                    ? "SaaS Tool: " + filter?.saas.map((value) => value).join(", ")
                                    : ""}{" "}
                            </p>
                        )}

                    <div className={`${pageClassName}__filter`} ref={filterRef}>
                        <button
                            onClick={() => setShowFilter(!isShowFilter)}
                            className={`${pageClassName}__filter__button`}
                            title="Filter"
                        >
                            <Icon icon="filter" size="primary" />
                        </button>
                        {isShowFilter && (
                            <div className={`${pageClassName}__filter__container`}>
                                <div className={`${pageClassName}__filter__item`} ref={ref}>
                                    <label>SaaS/Infra</label>
                                    <MultipleSelect
                                        defaultSelected={saas as { value: string; label: string }[]}
                                        defaultOptions={
                                            dropdownOptions(data?.result?.saas ?? []) as {
                                                value: string;
                                                label: string;
                                            }[]
                                        }
                                        onChangeHandler={dropdownHandler}
                                        className={`${pageClassName}__filter__dropdown`}
                                    >
                                        <p
                                            className={`${pageClassName}__filter__text`}
                                            onClick={() => setShowDropdown(!isShowDropdown)}
                                        >
                                            {data?.result?.saas > 0
                                                ? `${data?.result?.saas} out of ${data?.result?.saas.length} SaaS/Infra`
                                                : `All SaaS/Infra (${data?.result?.saas.length})`}
                                        </p>
                                    </MultipleSelect>
                                </div>
                                <div className={`${pageClassName}__filter__item`} ref={ref}>
                                    <label>Month</label>
                                    <Dropdown
                                        options={dropdownOptions(monthNames, "month")}
                                        selected={selectedMonth}
                                        onClickChange={(e) =>
                                            setSelectedMonth(e as { label: string; value: string })
                                        }
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                                <div className={`${pageClassName}__filter__item`}>
                                    <label htmlFor="filter">Year</label>
                                    <Dropdown
                                        options={dropdownOptions(data?.result?.years ?? [])}
                                        selected={selectedYear}
                                        onClickChange={(e) =>
                                            setSelectedYear(e as { label: string; value: string })
                                        }
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                                <Divider />
                                <div className={`${pageClassName}__filter__btn`}>
                                    <button onClick={resetFilter}>Reset</button>
                                    <button
                                        onClick={() => {
                                            setShowFilter(false);
                                            filterHandler();
                                            handleFirstPage();
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        minWidth="1440"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>
                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LicenseHistoryList;
