import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Template } from "./component/templates";
import Login from "./component/pages/auth/login";
import { ToastContainer } from "react-toastify";
import { PrivetRoute, PublicRoute } from "./utils";
import { PrivacyPolicy } from "./component/pages/privacyPolicy";
import SamlWithOkta from "./component/pages/auth/samlLogin";
import Logout from "./component/pages/auth/logout";
import { ROUTE } from "./constants/constants";
import OTPLogin from "./component/pages/auth/otpLogin";

function App() {
  return (
    <Router>
      <Routes>
        
        <Route path={ROUTE.LOGIN} element={<PublicRoute element={<Login />} />} />
        <Route path={ROUTE.SAML + "/:data"} element={<SamlWithOkta />} />
        <Route path = {ROUTE.OTP_VERIFICATION + '/:data'} element={<OTPLogin />} />
        <Route path={ROUTE.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTE.LOGOUT} element={<Logout />} />
        <Route path="/*" element={
          <PrivetRoute element={<Template />} />
        } />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        theme="colored"
        closeButton={true}
      />
    </Router>
  );
}

export default App;
