import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime, minutesToDayFormat } from "../../../../../utils";
import { useYoutrackTicketDetailQuery } from "../../../../../store/youtrack/youtrackSlice";
import { PAGE_NAME, ROUTE, YOU_TRACK_URL } from "../../../../../constants/constants";

const YoutrackTicketDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const pageClassName = "p-youTrackTicketDetails";

  const { data, isSuccess } = useYoutrackTicketDetailQuery(
    id as unknown as number
  );

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.SUPPORT_TICKET_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Ticket Id
          </p>
          <a className={`${pageClassName}__container__item__link`} href={`${YOU_TRACK_URL}${data?.result?.issueId}`} target="_blank" rel="noreferrer">
            {isSuccess ? data?.result?.issueId : <Skeleton height="small" />}
          </a>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.name : <Skeleton height="small" />}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Priority</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.priority : <Skeleton height="small" />}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Type</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.type : <Skeleton height="small" />}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>State</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? data?.result?.state : <Skeleton height="small" />}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Estimation
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.estimation ? (
                minutesToDayFormat(data?.result?.estimation)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Spent Time
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.spentTime ? (
                minutesToDayFormat(data?.result?.spentTime)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Project Name
          </p>
          <p
            className={`${pageClassName}__container__item__link ${data?.result?.project?.name ? "" : `${pageClassName}--disabled`
              }`}
            onClick={() =>
              data?.result?.project?.name &&
              navigate(ROUTE.YOUTRACK_PROJECT_LIST + `/${data?.result?.project?.id}`)
            }
          >
            {isSuccess ? (
              data?.result?.project?.name ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Reporter Name
          </p>
          <p
            className={`${pageClassName}__container__item__link`}
            onClick={() =>
              navigate(ROUTE.YOUTRACK_USER_LIST + `/${data?.result?.reporter?.id}`)
            }
          >
            {isSuccess ? (
              `${data?.result?.reporter?.firstName} ${data?.result?.reporter?.lastName}` ??
              "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Assignee Name
          </p>
          <p
            className={`${pageClassName}__container__item__link ${data?.result?.assignee?.lastName &&
              data?.result?.assignee?.firstName
              ? ""
              : `${pageClassName}--unassigned`
              }`}
            onClick={() =>
              data?.result?.assignee?.lastName &&
              data?.result?.assignee?.firstName &&
              navigate(ROUTE.YOUTRACK_USER_LIST + `/${data?.result?.assignee?.id}`)
            }
          >
            {isSuccess ? (
              data?.result?.assignee?.lastName &&
                data?.result?.assignee?.firstName ? (
                `${data?.result?.assignee?.firstName} ${data?.result?.assignee?.lastName}`
              ) : (
                "Unassigned"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        {isSuccess && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>Tags</p>
            <div className={`${pageClassName}__container__item__group`}>
              {data?.result?.tags.map((item: any) => (
                <p
                  key={item.id}
                  className={`${pageClassName}__container__item__tag`}
                  style={{ backgroundColor: item?.color?.background }}
                >
                  {item.name}
                </p>
              ))}
            </div>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Resolved Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.resolvedAt ? (
                formattedDateTime(data?.result?.resolvedAt)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.createdAt ? (
              formattedDateTime(data?.result?.createdAt)
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Updated</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.updatedAt ? (
              formattedDateTime(data?.result?.updatedAt)
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default YoutrackTicketDetails;
