import { PERMISSIONS, ROUTE } from "./constants";

/**
 * Represents the sidebar menu configuration.
 */
export const sidebarMenu = [
  {
    name: "Dashboard",
    icon: "home",
    path: "/",
    permission: PERMISSIONS.DASHBOARD_VIEW.title,
  },
  {
    name: "Infrastructure Licensing",
    icon: "license",
    permission: PERMISSIONS.LICENSE_MANAGEMENT_VIEW.title,
    subMenu: [
      {
        name: "Licensing Summary",
        path: ROUTE.LICENSE_MANAGEMENT,
        permission: PERMISSIONS.LICENSE_MANAGEMENT_VIEW.title,
      },
      {
        name: "Licensing History",
        path: ROUTE.LICENSE_HISTORY_LIST,
        permission: PERMISSIONS.LICENSE_HISTORY_VIEW.title,
      },
    ],
  },

  {
    name: "Support Service",
    icon: "supportTicket",
    permission: PERMISSIONS.SUPPORT_TICKETS_VIEW.title,
    subMenu: [
      {
        name: "Support Tickets Status",
        path: ROUTE.SUPPORT_TICKET_STATUS,
        permission: PERMISSIONS.SUPPORT_TICKETS_VIEW.title,
      },
      {
        name: "Support Tickets List",
        path: ROUTE.SUPPORT_TICKETS_LIST,
        permission: PERMISSIONS.SUPPORT_TICKETS_VIEW.title,
      },
    ],
  },

  {
    name: "Reporting",
    icon: "reports",
    permission: PERMISSIONS.REPORTING_VIEW.title,
    subMenu: [
      {
        name: "User Activity Report",
        path: ROUTE.ACTIVITY_REPORT,
        permission: PERMISSIONS.ACTIVITY_REPORT_VIEW.title,
      },
      {
        name: "Static Report",
        path: ROUTE.STATIC_REPORTING,
        permission: PERMISSIONS.STATIC_REPORT_VIEW.title,
      },
      {
        name: "Dynamic Report",
        path: ROUTE.DYNAMIC_REPORTING,
        permission: PERMISSIONS.DYNAMIC_REPORT_VIEW.title,
      },
    ],
  },
{
  name: "Alerts & Notification",
  icon: "notification",
  path: ROUTE.NOTIFICATION_ALERTS,
  permission: PERMISSIONS.NOTIFICATION_ALERTS_VIEW.title,
},
  {
    name: "Information Broadcast",
    icon: "announcement",
    permission: PERMISSIONS.BROADCAST_VIEW.title,
    subMenu: [
      {
        name: "Create Broadcast Email",
        path: ROUTE.BROADCAST_EMAIL,
        permission: PERMISSIONS.BROADCAST_EMAIL_CREATE.title,
      },
      {
        name: "Email Broadcast History",
        path: ROUTE.BROADCAST_HISTORY,
        permission: PERMISSIONS.BROADCAST_VIEW.title,
      },
    ],
  },

  {
    name: "Budget Management",
    icon: "money",
    permission: PERMISSIONS.BUDGET_CALCULATION_VIEW.title,
    subMenu: [
      {
        name: "SaaS Tool Wise Budget",
        path: ROUTE.SAAS_TOOl_WISE_BUDGET,
        permission: PERMISSIONS.SAAS_TOOL_WISE_BUDGET_VIEW.title,
      },
      {
        name: "Organization Wise Budget",
        path: ROUTE.ORGANIZATIONS_WISE_BUDGET,
        permission: PERMISSIONS.ORGANIZATION_WISE_BUDGET_VIEW.title,
      },
      {
        name: "User Wise Budget",
        path: ROUTE.USER_WISE_BUDGET,
        permission: PERMISSIONS.USER_WISE_BUDGET_VIEW.title,
      },
    ],
  },

  {
    name: "Payment Management",
    icon: "moneyBundle",
    permission: PERMISSIONS.PAYMENT_MANAGEMENT_VIEW.title,
    subMenu: [
      {
        name: "Payment Report",
        path: ROUTE.PAYMENT_REPORT,
        permission: PERMISSIONS.PAYMENT_MANAGEMENT_VIEW.title,
      },
      {
        name: "Payment History",
        path: ROUTE.PAYMENT_HISTORY,
        permission: PERMISSIONS.PAYMENT_MANAGEMENT_VIEW.title,
      },
      {
        name: "Create Payment",
        path: ROUTE.PAYMENT_HISTORY + ROUTE.PAYMENT_CREATE,
        permission: PERMISSIONS.PAYMENT_MANAGEMENT_CREATE.title,
      },
    ],
  },

  {
    name: "Tool & Infra Management",
    icon: "saas",
    permission: PERMISSIONS.SAAS_TOOL_VIEW.title,
    subMenu: [
      {
        name: "SaaS Tool Management",
        permission: PERMISSIONS.SAAS_TOOL_VIEW.title,
        subMenu: [
          {
            name: "Okta",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.OKTA_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.OKTA_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.OKTA_GROUP_LIST,
                permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
              },
              {
                name: "App List",
                path: ROUTE.OKTA_APP_LIST,
                permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
              },
            ],
          },
          {
            name: "YouTrack",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.YOUTRACK_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.YOUTRACK_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.YOUTRACK_GROUP_LIST,
                permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
              },
              {
                name: "Project List",
                path: ROUTE.YOUTRACK_PROJECT_LIST,
                permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
              },
            ],
          },

          {
            name: "GitLab",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.GITLAB_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.GITLAB_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.GITLAB_GROUP_LIST,
                permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
              },
              {
                name: "Project List",
                path: ROUTE.GITLAB_PROJECT_LIST,
                permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
              },
              {
                name: "Storage Comparison",
                path: ROUTE.GITLAB_GROUP_STORAGE_COMPARISON,
                permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
              },
            ],
          },

          {
            name: "Confluence",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.CONFLUENCE_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.CONFLUENCE_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
              {
                name: "Space List",
                path: ROUTE.CONFLUENCE_SPACE_LIST,
                permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.CONFLUENCE_GROUP_LIST,
                permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
              },
            ],
          },

          {
            name: "Atlassian",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.ATLASSIAN_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.ATLASSIAN_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
            ],
          },

          {
            name: "Miro",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.MIRO_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.MIRO_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
              {
                name: "Team List",
                path: ROUTE.MIRO_TEAM_LIST,
                permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
              },
              {
                name: "Project List",
                path: ROUTE.MIRO_PROJECT_LIST,
                permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
              },
              {
                name: "Board List",
                path: ROUTE.MIRO_BOARD_LIST,
                permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
              },
            ],
          },

          {
            name: "Box",
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.BOX_USER_LIST,
                permission: PERMISSIONS.SAAS_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.BOX_INVITED_USER_LIST,
                permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
              },
            ],
          },

          {
            name: "MergeBase",
            path: ROUTE.MERGEBASE,
            permission: PERMISSIONS.SAAS_USER_VIEW.title,
          }
        ],
      },

      {
        name: "Cloud Infra Management",
        permission: PERMISSIONS.CLOUD_INFRA_VIEW.title,
        subMenu: [
          {
            name: "GCP",
            permission: PERMISSIONS.CLOUD_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.GCP_USER_LIST,
                permission: PERMISSIONS.CLOUD_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.GCP_INVITED_USER_LIST,
                permission: PERMISSIONS.CLOUD_INVITED_USER_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.GCP_GROUP_LIST,
                permission: PERMISSIONS.CLOUD_GROUP_VIEW.title,
              },
              {
                name: "Project Billing Info",
                path: ROUTE.GCP_PROJECT_BILLING_INFO,
                permission: PERMISSIONS.CLOUD_PROJECT_VIEW.title,
              },
            ],
          },
          {
            name: "AWS",
            permission: PERMISSIONS.CLOUD_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.AWS_USER_LIST,
                permission: PERMISSIONS.CLOUD_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.AWS_INVITED_USER_LIST,
                permission: PERMISSIONS.CLOUD_INVITED_USER_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.AWS_GROUP_LIST,
                permission: PERMISSIONS.CLOUD_GROUP_VIEW.title,
              },
              {
                name: "Billing Info",
                path: ROUTE.AWS_BILLING,
                permission: PERMISSIONS.STATIC_REPORT_VIEW.title,
              },
            ],
          },
        ],
      },

      {
        name: "Floating License",
        permission: PERMISSIONS.FLOATING_LICENSE_VIEW.title,
        subMenu: [
          {
            name: "Klocwork",
            path: ROUTE.KLOCWORK,
            permission: PERMISSIONS.FLOATING_LICENSE_VIEW.title,
          },
          {
            name: "Ranorex",
            path: ROUTE.RENOREX,
            permission: PERMISSIONS.FLOATING_LICENSE_VIEW.title,
          },
        ],
      },

      {
        name: "Security Tool Management",
        permission: PERMISSIONS.SECURITY_TOOL_USER_VIEW.title,
        subMenu: [
          {
            name: "Sophos",
            permission: PERMISSIONS.SECURITY_TOOL_USER_VIEW.title,
            subMenu: [
              {
                name: "User List",
                path: ROUTE.SOPHOS_USER_LIST,
                permission: PERMISSIONS.SECURITY_TOOL_USER_VIEW.title,
              },
              {
                name: "Invited User List",
                path: ROUTE.SOPHOS_INVITED_USER_LIST,
                permission: PERMISSIONS.SECURITY_TOOL_INVITED_USER_VIEW.title,
              },
              {
                name: "Group List",
                path: ROUTE.SOPHOS_GROUP_LIST,
                permission: PERMISSIONS.SECURITY_TOOL_GROUP_VIEW.title,
              },
            ],
          },
        ]
      },
    ]
  },

  {
    name: "Inventory Management",
    icon: "inventory",
    path: ROUTE.INVENTORY_MANAGEMENT,
    permission: PERMISSIONS.INVENTORY_MANAGEMENT_VIEW.title,
  },

  {
    name: "Organization Management",
    icon: "organization",
    permission: PERMISSIONS.ORGANIZATION_VIEW.title,
    subMenu: [
      {
        name: "Organization List",
        path: ROUTE.ORGANIZATION_LIST,
        permission: PERMISSIONS.ORGANIZATION_VIEW.title,
      },
      {
        name: "Create Organization",
        path: ROUTE.ORGANIZATION_CREATE,
        permission: PERMISSIONS.ORGANIZATION_CREATE.title,
      },
    ],
  },

  {
    name: "Request Management",
    icon: "requestManagement",
    permission: PERMISSIONS.REQUEST_MANAGEMENT_VIEW.title,
    subMenu: [
      {
        name: "My Request",
        permission: PERMISSIONS.REQUEST_MANAGEMENT_VIEW.title,
        subMenu: [
          {
            name: "Create Request",
            path: ROUTE.REQUEST_HISTORY + ROUTE.CREATE_REQUEST,
            permission: PERMISSIONS.REQUEST_MANAGEMENT_CREATE.title,
          },
          {
            name: "Request History",
            path: ROUTE.REQUEST_HISTORY,
            permission: PERMISSIONS.REQUEST_MANAGEMENT_VIEW.title,
          },

        ],
      },
      {
        name: "Approver",
        permission: PERMISSIONS.APPROVER_VIEW.title,
        subMenu: [
          {
            name: "Approver List",
            path: ROUTE.APPROVER_LIST,
            permission: PERMISSIONS.APPROVER_VIEW.title,
          },
          {
            name: "Create Approver",
            path: ROUTE.APPROVER_LIST + ROUTE.ADD,
            permission: PERMISSIONS.APPROVER_CREATE.title,
          },
        ],
      },
    ],
  },

  {
    name: "Approval Management",
    icon: "approval",
    permission: PERMISSIONS.APPROVAL_MANAGEMENT_PENDING_VIEW.title,
    subMenu: [
      {
        name: "Pending Request List",
        path: ROUTE.PENDING_REQUEST_LIST,
        permission: PERMISSIONS.APPROVAL_MANAGEMENT_PENDING_VIEW.title,
      },
      {
        name: "All Request List",
        path: ROUTE.ALL_REQUEST_LIST,
        permission: PERMISSIONS.APPROVAL_MANAGEMENT_VIEW.title,
      },
      {
        name: "Approved Request List",
        path: ROUTE.APPROVED_REQUEST_LIST,
        permission: PERMISSIONS.APPROVAL_MANAGEMENT_APPROVED_VIEW.title,
      }
    ],
  },

  {
    name: "User Management",
    icon: "user",
    permission: PERMISSIONS.USER_VIEW.title,
    subMenu: [
      {
        name: "UR&AM Tool User List",
        path: ROUTE.UM_TOOL_USER_LIST,
        permission: PERMISSIONS.USER_VIEW.title,
      },
      {
        name: "UR&AM Tool User Role",
        permission: PERMISSIONS.ROLE_VIEW.title,
        subMenu: [
          {
            name: "List",
            path: ROUTE.UM_TOOL_USER_ROLE_LIST,
            permission: PERMISSIONS.ROLE_VIEW.title,
          },
          {
            name: "Create Role",
            path: ROUTE.UM_TOOL_USER_ROLE_LIST + ROUTE.ROLE_CREATE,
            permission: PERMISSIONS.ROLE_CREATE.title,
          },
        ],
      },
      {
        name: "UR&AM Tool User Registration",
        path: ROUTE.USER_REGISTRATION,
        permission: PERMISSIONS.USER_REGISTRATION.title,
      },
    ],
  },
  {
    name: "API Key Management",
    icon: "key",
    path: ROUTE.API_KEY,
    permission: PERMISSIONS.API_KEY_VIEW.title,
  },
];
