import React from "react";

import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { PAGE_NAME } from "../../../../../constants/constants";
import { useChangeRequestStatusMutation, useSingleRequestQuery } from "../../../../../store/requestManagement/requestSlice";
import { useParams } from "react-router-dom";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import { Dropdown } from "../../../../atoms/dropdown";
import { Textarea } from "../../../../atoms/textarea";
import { Dialog } from "../../../../atoms/dialog";
import { toast } from "react-toastify";
import { Icon } from "../../../../atoms/icon";
import { Modal } from "../../../../molecules/modal";
import { BACKEND_URL } from "../../../../../constants/urls";
import { Loader } from "../../../../atoms/loader";

const ApprovedRequestDetails = () => {
    const pageClassName = "p-approvedRequestDetails";
    const id = useParams<{ id: string }>().id;

    const [changeStatusAPI, { isLoading: changeStatusLoading }] = useChangeRequestStatusMutation();

    const [isOpenComment, setIsOpenComment] = React.useState(false);
    const [comments, setComments] = React.useState<any[]>([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [reviewData, setReviewData] = React.useState({
        status: "",
        id: 0,
        comment: "",
    });

    const { data, isLoading } = useSingleRequestQuery(id as string);

    const closeDialogHandler = () => {
        setIsDialogOpen(!isDialogOpen);
        setReviewData({
            status: "",
            id: 0,
            comment: "",
        });
    };

    const changeStatusHandler = async () => {
        setIsDialogOpen(!isDialogOpen);
        const fromData = new FormData();

        fromData.append("status", reviewData.status);
        fromData.append("id", reviewData.id.toString());
        fromData.append("comment", reviewData.comment);

        let response = (await changeStatusAPI(fromData)) as any;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.error?.data?.message);
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.APPROVED_REQUEST_DETAILS}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Type</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.type ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Sub Type</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.subType ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Status</p>
                    <p className={`${pageClassName}__container__item__status`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.status ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Requester
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.requester ? (
                            data?.result?.requester?.middleName ? (
                                `${data?.result?.requester?.firstName} ${data?.result?.requester?.middleName} ${data?.result?.requester?.lastName}`
                            ) : (
                                `${data?.result?.requester?.firstName} ${data?.result?.requester?.lastName}`
                            )
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Created At
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.createdAt ? (
                            formattedDateTime(data?.result?.createdAt)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>
                <p className={`${pageClassName}__container__userRequest__header`}>
                    Requests
                </p>
                <Loader isLoading={changeStatusLoading} >
                    <div className={`${pageClassName}__container__userRequest`}>
                        {data?.result?.requests?.map((request: any, index: number) => (
                            <div
                                className={`${pageClassName}__container__userRequest__content`}
                                key={index}
                            >
                                <div
                                    className={`${pageClassName}__container__userRequest__content__container`}
                                >
                                    {request?.status === "Approved" && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__content__action`}
                                        >
                                            <Dropdown
                                                options={[{ label: "Fixed", value: "Fixed" }]}
                                                selected={{ label: "Action", value: "" }}
                                                placeholder="Review"
                                                onClickChange={(e) => {
                                                    setIsDialogOpen(!isDialogOpen);
                                                    setReviewData({
                                                        ...reviewData,
                                                        status: e.value as string,
                                                        id: request?.id,
                                                    });
                                                }}
                                                isBordered
                                                isIcon
                                                iniDirection="top"
                                            />
                                        </div>
                                    )}

                                    {request?.firstName && request?.lastName && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                User Name
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.firstName} {request?.lastName}
                                            </p>
                                        </div>
                                    )}

                                    {request?.designation && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Designation
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.designation}
                                            </p>
                                        </div>
                                    )}

                                    {request?.department && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Department
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.department}
                                            </p>
                                        </div>
                                    )}

                                    {request?.address && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Address
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.address}
                                            </p>
                                        </div>
                                    )}

                                    {request?.manager && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Manager
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.manager?.middleName
                                                    ? `${request?.manager?.firstName} ${request?.manager?.middleName} ${request?.manager?.lastName}`
                                                    : `${request?.manager?.firstName} ${request?.manager?.lastName}`}
                                            </p>
                                        </div>
                                    )}

                                    {request?.email && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Email
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.email}
                                            </p>
                                        </div>
                                    )}

                                    <div
                                        className={`${pageClassName}__container__userRequest__item`}
                                    >
                                        <p
                                            className={`${pageClassName}__container__userRequest__item__title`}
                                        >
                                            SaaS Tool
                                        </p>
                                        <p
                                            className={`${pageClassName}__container__userRequest__item__value`}
                                        >
                                            {request?.saasTool}
                                        </p>
                                    </div>

                                    {request?.type && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Type
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.type}
                                            </p>
                                        </div>
                                    )}

                                    {request?.type && request?.name && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                {request?.type} Name
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.name}
                                            </p>
                                        </div>
                                    )}

                                    {request?.type && request?.newName && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                {request?.type} New Name
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.newName}
                                            </p>
                                        </div>
                                    )}

                                    {request?.role && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Role
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.role}
                                            </p>
                                        </div>
                                    )}
                                    {request?.approvers?.length > 0 && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Approvers
                                            </p>
                                            <div
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.approvers.map(
                                                    (approver: any, index: number) => (
                                                        <p key={index}>
                                                            {approver?.middleName
                                                                ? `${approver?.firstName} ${approver?.middleName} ${approver?.lastName}`
                                                                : `${approver?.firstName} ${approver?.lastName}`}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className={`${pageClassName}__container__userRequest__item`}
                                    >
                                        <p
                                            className={`${pageClassName}__container__userRequest__item__title`}
                                        >
                                            Status
                                        </p>
                                        <p
                                            className={`${pageClassName}__container__userRequest__item__status`}
                                        >
                                            {request?.status}
                                        </p>
                                    </div>

                                    {request?.reviewedBy && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Reviewed By
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.reviewedBy?.middleName
                                                    ? `${request?.reviewedBy?.firstName} ${request?.reviewedBy?.middleName} ${request?.reviewedBy?.lastName}`
                                                    : `${request?.reviewedBy?.firstName} ${request?.reviewedBy?.lastName}`}
                                            </p>
                                        </div>
                                    )}

                                    {request?.instruction && (
                                        <div
                                            className={`${pageClassName}__container__userRequest__item`}
                                        >
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__title`}
                                            >
                                                Instruction
                                            </p>
                                            <p
                                                className={`${pageClassName}__container__userRequest__item__value`}
                                            >
                                                {request?.instruction}
                                            </p>
                                        </div>
                                    )}

                                    <div
                                        className={`${pageClassName}__container__userRequest__item`}
                                    >
                                        <p
                                            className={`${pageClassName}__container__userRequest__item__title`}
                                        >
                                            Comments
                                        </p>
                                        <button
                                            className={`${pageClassName}__container__userRequest__item__comments`}
                                            onClick={() => {
                                                setIsOpenComment(true);
                                                setComments(request?.comments);
                                            }}
                                        >
                                            {request?.comments?.length}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Loader>
            </div>

            <Dialog
                header="Review Request"
                body={
                    <div className={`${pageClassName}__dialog`}>
                        <p className={`${pageClassName}__dialog__text`}>
                            Are you sure you want to {reviewData.status.toLowerCase()} this
                            request?
                        </p>
                        <p className={`${pageClassName}__dialog__text`}>
                            This action cannot be undone.
                        </p>
                        <Textarea
                            className={`${pageClassName}__dialog__textarea`}
                            placeholder="Add a comment"
                            onChange={(e) => {
                                setReviewData({ ...reviewData, comment: e.target.value });
                            }}
                        />
                    </div>
                }
                isOpen={isDialogOpen}
                onClose={closeDialogHandler}
                successFn={changeStatusHandler}
                btn={reviewData.status}
                icon={reviewData.status === "Fixed" ? "approved" : "rejected"}
                color={reviewData.status === "Fixed" ? "green" : undefined}
            />


            <Modal
                isOpen={isOpenComment}
                onClose={() => setIsOpenComment(false)}
                header="Comments"
            >
                <div className={`${pageClassName}__comment`}>
                    {comments.length > 0 ? (
                        comments?.map((comment: any, index: number) => (
                            <div
                                className={`${pageClassName}__comment__container`}
                                key={index}
                            >
                                <div className={`${pageClassName}__comment__header`}>
                                    <div className={`${pageClassName}__comment__username`}>
                                        {comment?.user?.profilePhotoPath && (
                                            <img
                                                src={
                                                    BACKEND_URL + "/" + comment?.user?.profilePhotoPath
                                                }
                                                alt="avatar"
                                                className={`${pageClassName}__comment__username__avatar`}
                                            />
                                        )}
                                        <p>
                                            {comment?.user?.middleName
                                                ? `${comment?.user?.firstName} ${comment?.user?.middleName} ${comment?.user?.lastName}`
                                                : `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                        </p>
                                    </div>

                                    <p className={`${pageClassName}__comment__date`}>
                                        {formattedDateTime(comment?.createdAt)}
                                    </p>
                                </div>
                                <p className={`${pageClassName}__comment__content`}>
                                    {comment?.comment}
                                </p>
                            </div>
                        ))
                    ) : (
                        <div className={`${pageClassName}__comment__notFound`}>
                            <p>No comments found!</p>
                            <Icon icon="sadFace" size="tertiary" />
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ApprovedRequestDetails;
