import React, { useState, useEffect } from 'react'

export interface PaginationProps {
  totalNumberOfData: number
  perPage: number
  setCurrentPage: (currentPage: number) => void
  currentPage: number
  className?: string
}

export const usePaginationHook = ({
  totalNumberOfData,
  perPage,
  setCurrentPage,
  currentPage,
  className
}: PaginationProps) => {
  const [page, setPage] = useState<number>(1)
  const [totalData, setTotalData] = useState<number>(0)

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    setTotalData(Math.ceil(totalNumberOfData / perPage))
    setCurrentPage(page)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNumberOfData, perPage, page])

  const handleFirstPage = () => {
    setPage(1)
  }

  const handlePrevPage = (disabled: boolean) => {
    if (disabled) return
    setPage(page - 1)
  }

  const handleNextPage = (disabled: boolean) => {
    if (disabled) return
    setPage(page + 1)
  }

  const handleLastPage = () => {
    setPage(totalData)
  }

  const handlePageClick = (pageNum: number) => {
    setPage(pageNum)
  }

  const getPageNumbers = (): number[] => {
    if (totalData <= 5) {
      return Array.from({ length: totalData }, (_, i) => i + 1)
    }

    if (page === 1) {
      return [1, 2, 3, -1, totalData]
    }

    if (page === 2) {
      return [1, 2, 3, 4, -1, totalData]
    }

    if (page === 3) {
      return [1, 2, 3, 4, -1, totalData]
    }

    if (page === totalData - 2) {
      return [1, -1, totalData - 3, totalData - 2, totalData - 1, totalData]
    }

    if (page === totalData - 1) {
      return [1, -1, totalData - 3, totalData - 2, totalData - 1, totalData]
    }

    if (page === totalData) {
      return [1, -1, totalData - 3, totalData - 2, totalData - 1, totalData]
    }

    return [1, -1, page - 1, page, page + 1, -1, totalData]
  }
  const renderPageNumbers = (): JSX.Element[] => {
    const pageNumbers = getPageNumbers()
    return pageNumbers.map((pageNum: number, index: number) => {
      if (pageNum === -1) {
        return (
          <span className={`${className}__ellips`} key={index}>
            ...
          </span>
        )
      }

      return (
        <button
          key={index}
          onClick={() =>
            pageNum === page ? () => {} : handlePageClick(pageNum)
          }
          className={`${className}__btn ${
            pageNum === page ? `${className}__btn--active` : ''
          }`}
        >
          {pageNum}
        </button>
      )
    })
  }
  return {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleNextPage,
    handleLastPage,
    handlePageClick,
    renderPageNumbers
  }
}
