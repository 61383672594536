import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { sidebarMenu } from "../../../constants/sidebarMenu";
import { Icon } from "../../atoms/icon";
import { Link, useLocation } from "react-router-dom";
import { AuthStateType, SidebarMenuType } from "../../../types";
import { useSelector } from "react-redux";

interface MenuItemStyles {
  [key: string]: any;
}

const menuItemStyles: MenuItemStyles = {
  root: {
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: "white",
  },
  icon: {
    color: "black",
  },
  SubMenuExpandIcon: {
    color: "#b6b7b9",
  },
  button: {
    "&:hover": {
      backgroundColor: "#f3f2f7",
      color: "black",
    },
  },
  label: ({ open }: any) => ({
    fontWeight: open ? 600 : undefined,
  }),
  active: {
    backgroundColor: "#ca4c3c",
    color: "white",
  },
  prefix: {
    width: "20px",
  },
};

export const SidebarComponent: React.FC = () => {
  const pathname = useLocation().pathname;
  const permissions = useSelector((state: AuthStateType) => state.auth.permissions);

  const filteredSidebarMenu = React.useMemo(() => {
    const filterMenu = (menuItems: SidebarMenuType[]): SidebarMenuType[] => {
      const items =   menuItems.filter((item) =>
        permissions?.find((permission) => permission.title === item.permission)
      );

      return items.filter((item) => {
        if (item.subMenu) {
          item.subMenu = filterMenu(item.subMenu);
        }
        return item;
      });
    };

    const filterAndMapMenu = (menuItems: SidebarMenuType[]): React.ReactNode[] => {
      return menuItems.map((item, index) => {
        if (item.subMenu) {
          return (
            <SubMenu
              key={index}
              label={item.name}
              icon={item.icon ? <Icon icon={item.icon as string} /> : null}
            >
              {filterAndMapMenu(item.subMenu)}
            </SubMenu>

          );
        } else {
          return (
            <MenuItem
              key={index}
              icon={item.icon ? <Icon icon={item.icon as string} /> : null}
              component={<Link to={item.path as string} />}
              style={item.path === pathname ? menuItemStyles.active : {}}
            >
              {item.name}
            </MenuItem>
          );
        }
      });
    };

    return filterAndMapMenu(filterMenu(sidebarMenu));
  }, [permissions, pathname]);

  return (
    <Sidebar style={{ width: "274px", backgroundColor: "white", minHeight: "calc(100vh - 72px)" }}>
      <Menu menuItemStyles={menuItemStyles}>
        {filteredSidebarMenu}
      </Menu>
    </Sidebar>
  );
};



// import React from "react";
// import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
// import { sidebarMenu } from "../../../constants/sidebarMenu";
// import { Icon } from "../../atoms/icon";
// import { Link, useLocation } from "react-router-dom";
// import { AuthStateType, SidebarMenuType } from "../../../types";
// import { useSelector } from "react-redux";

// export const SidebarComponent = () => {
//   const pathname = useLocation().pathname;
//   const permissions = useSelector(
//     (state: AuthStateType) => state.auth.permissions
//   );
//   const style = {
//     width: "274px",
//     backgroundColor: "white",
//     minHeight: "calc(100vh - 72px)",
//   };

//   const menuItemStyles = {
//     root: {
//       fontSize: "14px",
//       fontWeight: 500,
//       backgroundColor: "white",
//     },
//     icon: {
//       color: "black",
//     },
//     SubMenuExpandIcon: {
//       color: "#b6b7b9",
//     },
//     button: {
//       "&:hover": {
//         backgroundColor: "#f3f2f7",
//         color: "black",
//       },
//     },
//     label: ({ open }: any) => ({
//       fontWeight: open ? 600 : undefined,
//     }),
//     active: {
//       backgroundColor: "#ca4c3c",
//       color: "white",
//     },
//     prefix: {
//       width: "20px",
//     },
//   };

//   return (
//     <Sidebar style={style}>
//       <Menu menuItemStyles={menuItemStyles}>
//         {sidebarMenu
//           .filter((menu: SidebarMenuType) =>
//             permissions?.find((item) => item.title === menu.permission)
//           )
//           .map((item: SidebarMenuType, index: number) =>
//             item.subMenu ? (
//               <SubMenu
//                 key={index}
//                 label={item.name}
//                 icon={<Icon icon={item.icon as string} />}
//               >
//                 {item.subMenu
//                   .filter((subItem: SidebarMenuType) =>
//                     permissions?.find(
//                       (item) => item.title === subItem.permission
//                     )
//                   )
//                   .map((subItem: SidebarMenuType, subIndex: number) =>
//                     subItem.subMenu && subItem.subMenu ? (
//                       <SubMenu
//                         key={subIndex}
//                         label={subItem.name}
//                         prefix={<p style={menuItemStyles.prefix}></p>}
//                       >
//                         {subItem.subMenu
//                           .filter((subSubItem: SidebarMenuType) =>
//                             permissions?.find(
//                               (item) => item.title === subSubItem.permission
//                             )
//                           )
//                           .map(
//                             (
//                               subSubItem: SidebarMenuType,
//                               subSubIndex: number
//                             ) => (
//                               subSubItem.subMenu && subSubItem.subMenu ? (
//                                 <SubMenu
//                                   key={subSubIndex}
//                                   label={subSubItem.name}
//                                   prefix={<p style={menuItemStyles.prefix}></p>}
//                                 >
//                                   {subSubItem.subMenu
//                                     .filter((subSubSubItem: SidebarMenuType) =>
//                                       permissions?.find(
//                                         (item) => item.title === subSubSubItem.permission
//                                       )
//                                     )
//                                     .map(
//                                       (
//                                         subSubSubItem: SidebarMenuType,
//                                         subSubSubIndex: number
//                                       ) => (
//                                         <MenuItem
//                                           key={subSubSubIndex}
//                                           component={
//                                             <Link
//                                               to={subSubSubItem.path as unknown as string}
//                                             />
//                                           }
//                                           prefix={<p style={menuItemStyles.prefix}></p>}
//                                           style={
//                                             subSubSubItem.path === pathname
//                                               ? menuItemStyles.active
//                                               : {}
//                                           }
//                                         >
//                                           {subSubSubItem.name}
//                                         </MenuItem>
//                                       )
//                                     )}
//                                 </SubMenu>
//                               )
//                                 : (
//                                   <MenuItem
//                                     key={subSubIndex}
//                                     component={
//                                       <Link
//                                         to={subSubItem.path as unknown as string}
//                                       />
//                                     }
//                                     prefix={<p style={menuItemStyles.prefix}></p>}
//                                     style={
//                                       subSubItem.path === pathname
//                                         ? menuItemStyles.active
//                                         : {}
//                                     }
//                                   >
//                                     {subSubItem.name}
//                                   </MenuItem>
//                                 )
//                             )
//                           )}
//                       </SubMenu>
//                     ) : (
//                       <MenuItem
//                         key={subIndex}
//                         component={
//                           <Link to={subItem.path as unknown as string} />
//                         }
//                         style={
//                           subItem.path === pathname ? menuItemStyles.active : {}
//                         }
//                         prefix={<p style={menuItemStyles.prefix}></p>}
//                       >
//                         {subItem.name}
//                       </MenuItem>
//                     )
//                   )}
//               </SubMenu>
//             ) : (
//               <MenuItem
//                 key={index}
//                 icon={<Icon icon={item.icon as string} />}
//                 component={<Link to={item.path as unknown as string} />}
//                 style={item.path === pathname ? menuItemStyles.active : {}}
//               >
//                 {item.name}
//               </MenuItem>
//             )
//           )}
//       </Menu>
//     </Sidebar>
//   );
// };
