import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Loader } from "../../../../atoms/loader";
import { ComboChart } from "../../../../molecules/comboChart";
import { TableChart } from "../../../../molecules/tableChart";
import {
    useSyncTicketsMutation,
    useYoutrackSupportTicketsReportQuery,
    useYoutrackSupportTicketsSpentTimeReportQuery,
} from "../../../../../store/youtrack/youtrackSlice";
import { minutesToHours, monthNames } from "../../../../../utils";
import { Dropdown } from "../../../../atoms/dropdown";
import { toast } from "react-toastify";
import { AuthStateType, ResponseType } from "../../../../../types";
import { PAGE_NAME, PERMISSIONS } from "../../../../../constants/constants";
import { useSelector } from "react-redux";

export const SupportTicketsReport = () => {
    const pageClassName = "p-youtrackSupportTicketsReport";
    const permissions = useSelector((state: AuthStateType) => state.auth.permissions);
    /**
     * Sync Tickets
     */
    const [syncTickets, { isLoading }] = useSyncTicketsMutation();
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth() + 1;
    var currentYear = currentDate.getFullYear();

    const [query, setQuery] = React.useState<any>({
        type: {
            label: "Weeks",
            value: "weeks",
        },
        weeks: 6,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
    });
    const [ticketsOptionsYears, setTicketsOptionsYears] = React.useState<any>([]);
    const [tableData, setTableData] = React.useState([]);

    const [
        youtrackSupportTicketsReportData,
        setYoutrackSupportTicketsReportData,
    ] = React.useState<any>([]);

    /**
     * Youtrack Support Tickets Report
     * @returns
     * @type {Object}
     * data: ResponseDataType | undefined;
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     */
    const {
        data: supportTicketsReportResponse,
        isSuccess: supportTicketsReportSuccess,
        isLoading: youtrackSupportTicketsReportLoading,
        isFetching: supportTicketsReportRefetch,
    } = useYoutrackSupportTicketsReportQuery({
        type: query.type.value,
        weeks: query.weeks,
        year: query.year,
        month: query.month,
    });

    React.useEffect(() => {
        const temp = [] as any[];

        /**
         * set data for table and chart
         */
        if (supportTicketsReportSuccess) {
            setTableData(supportTicketsReportResponse?.result?.tableData);
            setTicketsOptionsYears(
                supportTicketsReportResponse?.result?.optionYears?.map((item: any) => {
                    return { label: item, value: item };
                })
            );

            if (query.type.value === "weeks") {
                const header = [
                    "Week",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            `${item?.week} Week`,
                            item?.openTickets,
                            item?.inProgressTickets,
                            item?.fixedTickets,
                            item?.holdTickets,
                            item?.wontFixTickets,
                            item?.futureImplementationTickets,
                            item?.cantReproduceTickets,
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setYoutrackSupportTicketsReportData(temp);
            } else if (query.type.value === "year") {
                const header = [
                    "Year",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            `${item?.year}`,
                            item?.openTickets,
                            item?.inProgressTickets,
                            item?.fixedTickets,
                            item?.holdTickets,
                            item?.wontFixTickets,
                            item?.futureImplementationTickets,
                            item?.cantReproduceTickets,
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setYoutrackSupportTicketsReportData(temp);
            } else if (query.type.value === "specific_month") {
                const header = [
                    "Week",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            `${item?.week} Week`,
                            item?.openTickets,
                            item?.inProgressTickets,
                            item?.fixedTickets,
                            item?.holdTickets,
                            item?.wontFixTickets,
                            item?.futureImplementationTickets,
                            item?.cantReproduceTickets,
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setYoutrackSupportTicketsReportData(temp);
            } else if (query.type.value === "specific_year") {
                const header = [
                    "Month",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            item?.month,
                            item?.openTickets,
                            item?.inProgressTickets,
                            item?.fixedTickets,
                            item?.holdTickets,
                            item?.wontFixTickets,
                            item?.futureImplementationTickets,
                            item?.cantReproduceTickets,
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setYoutrackSupportTicketsReportData(temp);
            }
        }
    }, [supportTicketsReportResponse, supportTicketsReportSuccess, query]);

    /**
     * License report column chart options
     * @type {Object}
     */

    const youtrackSupportTicketsReportOptions = React.useMemo(
        () => ({
            title: `YouTrack Support Tickets Report ${query.type.value === "weeks"
                ? `(${query.weeks} Weeks Data)`
                : query.type.value === "specific_month"
                    ? `(${monthNames[query.month - 1]} ${query.year})`
                    : query.type.value === "specific_year"
                        ? `(${query.year})`
                        : ""
                }`,
            vAxis: { title: "Number of Tickets" },
            hAxis: {
                title: ["weeks", "specific_month"].includes(query.type.value)
                    ? "Week"
                    : query.type.value === "year"
                        ? "Year"
                        : "Month",
            },
            seriesType: "bars",
            backgroundColor: "#f5f5f5",
            legend: {
                position: "top",
                textStyle: {
                    fontSize: 14,
                },
            },

            series: {
                0: { color: "red" },
                1: { color: "orange" },
                2: { color: "green" },
                3: { color: "#1c5217" },
                4: { color: "#522117" },
                5: { color: "#90EE90" },
            },
        }),
        [query.type, query.weeks, query.month, query.year]
    );

    /**
     * syncTicketHandler
     */

    const syncTicketHandler = async () => {
        const response = (await syncTickets(query)) as ResponseType;
        if (response?.data?.success) toast.success(response?.data?.message);
        else toast.error(response?.error?.message);
    };

    // Youtrack Support Tickets Spent Time Report Start

    /**
     * Youtrack Support Tickets Spent Time Report
     * @returns
     * @type {Object}
     * data: ResponseDataType | undefined;
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     */

    const {
        data: supportTicketsSpentTimeReportResponse,
        isSuccess: supportTicketsSpentTimeReportSuccess,
        isLoading: youtrackSupportTicketsSpentTimeReportLoading,
        isFetching: supportTicketsSpentTimeReportRefetch,
    } = useYoutrackSupportTicketsSpentTimeReportQuery({
        type: query.type.value,
        weeks: query.weeks,
        year: query.year,
        month: query.month,
    });

    const [SpentTimeReportData, setSpentTimeReportData] = React.useState<any>([]);
    const [spentTimeTableData, setSpentTimeTableData] = React.useState([]);

    React.useEffect(() => {
        const temp = [] as any[];

        /**
         * set data for table and chart
         */
        if (supportTicketsSpentTimeReportSuccess) {
            setSpentTimeTableData(
                supportTicketsSpentTimeReportResponse?.result?.tableData
            );

            if (query.type.value === "weeks") {
                const header = [
                    "Week",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsSpentTimeReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            `${item?.week} Week`,
                            minutesToHours(item?.openTickets),
                            minutesToHours(item?.inProgressTickets),
                            minutesToHours(item?.fixedTickets),
                            minutesToHours(item?.holdTickets),
                            minutesToHours(item?.wontFixTickets),
                            minutesToHours(item?.futureImplementationTickets),
                            minutesToHours(item?.cantReproduceTickets),
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setSpentTimeReportData(temp);
            } else if (query.type.value === "year") {
                const header = [
                    "Year",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsSpentTimeReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            `${item?.year}`,
                            minutesToHours(item?.openTickets),
                            minutesToHours(item?.inProgressTickets),
                            minutesToHours(item?.fixedTickets),
                            minutesToHours(item?.holdTickets),
                            minutesToHours(item?.wontFixTickets),
                            minutesToHours(item?.futureImplementationTickets),
                            minutesToHours(item?.cantReproduceTickets),
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setSpentTimeReportData(temp);
            } else if (query.type.value === "specific_month") {
                const header = [
                    "Week",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsSpentTimeReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            `${item?.week} Week`,
                            minutesToHours(item?.openTickets),
                            minutesToHours(item?.inProgressTickets),
                            minutesToHours(item?.fixedTickets),
                            minutesToHours(item?.holdTickets),
                            minutesToHours(item?.wontFixTickets),
                            minutesToHours(item?.futureImplementationTickets),
                            minutesToHours(item?.cantReproduceTickets),
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setSpentTimeReportData(temp);
            } else if (query.type.value === "specific_year") {
                const header = [
                    "Month",
                    "Open",
                    "In Progress",
                    "Fixed",
                    "Hold",
                    "Won't Fix",
                    "Future Implementation",
                    "Can't Reproduce",
                ];
                temp.push(header);

                supportTicketsSpentTimeReportResponse?.result?.data?.map(
                    (item: any, index: number) => {
                        const temp2 = [
                            item?.month,
                            minutesToHours(item?.openTickets),
                            minutesToHours(item?.inProgressTickets),
                            minutesToHours(item?.fixedTickets),
                            minutesToHours(item?.holdTickets),
                            minutesToHours(item?.wontFixTickets),
                            minutesToHours(item?.futureImplementationTickets),
                            minutesToHours(item?.cantReproduceTickets),
                        ];
                        temp.push(temp2);
                        return null;
                    }
                );
                setSpentTimeReportData(temp);
            }
        }
    }, [
        supportTicketsSpentTimeReportResponse,
        supportTicketsSpentTimeReportSuccess,
        query,
    ]);

    /**
     * License report column chart options
     * @type {Object}
     */

    const SpentTimeReportOptions = React.useMemo(
        () => ({
            title: `YouTrack Support Tickets Spent time (Hour) Report ${query.type.value === "weeks"
                ? `(${query.weeks} Weeks Data)`
                : query.type.value === "specific_month"
                    ? `(${monthNames[query.month - 1]} ${query.year})`
                    : query.type.value === "specific_year"
                        ? `(${query.year})`
                        : ""
                }`,
            vAxis: { title: "Hours" },
            hAxis: {
                title: ["weeks", "specific_month"].includes(query.type.value)
                    ? "Week"
                    : query.type.value === "year"
                        ? "Year"
                        : "Month",
            },
            seriesType: "bars",
            backgroundColor: "#f5f5f5",
            legend: {
                position: "top",
                textStyle: {
                    fontSize: 14,
                },
            },

            series: {
                0: { color: "red" },
                1: { color: "orange" },
                2: { color: "green" },
                3: { color: "#1c5217" },
                4: { color: "#522117" },
                5: { color: "#90EE90" },
            },
        }),
        [query.type, query.weeks, query.month, query.year]
    );

    const hasSyncPermission = () => {
        const hasPermission = permissions?.find((item) => item.title === PERMISSIONS.SYNC_SUPPORT_TICKETS.title);
        return hasPermission ? true : false;
    }

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.SUPPORT_TICKET_STATUS}
            </p>
            <div className={`${pageClassName}__container`}>
                <Loader
                    isLoading={
                        (youtrackSupportTicketsReportData.length &&
                            supportTicketsReportRefetch) ||
                        isLoading
                    }
                >
                    <div className={`${pageClassName}__chart`}>
                        {supportTicketsReportSuccess && (
                            <div className={`${pageClassName}__filter`}>
                                {hasSyncPermission() && (
                                    <button
                                        className={`${pageClassName}__filter__button`}
                                        disabled={
                                            (youtrackSupportTicketsReportData.length &&
                                                supportTicketsReportRefetch) ||
                                            isLoading
                                        }
                                        onClick={syncTicketHandler}
                                    >
                                        Update Tickets
                                    </button>
                                )}
                                <div className={`${pageClassName}__filter__type`}>
                                    <Dropdown
                                        placeholder="Type"
                                        selected={query.type}
                                        options={[
                                            "Weeks",
                                            "Specific Month",
                                            "Specific Year",
                                            "Year",
                                        ].map((item: any) => {
                                            const value = item.replace(/\s/g, "_").toLowerCase();
                                            return { label: item, value: value };
                                        })}
                                        onClickChange={(e) => {
                                            setQuery({ ...query, type: e });
                                        }}
                                    />
                                </div>
                                {query.type.value === "weeks" && (
                                    <div className={`${pageClassName}__filter__week`}>
                                        <Dropdown
                                            placeholder="Week"
                                            selected={{
                                                label: `${query.weeks} Weeks`,
                                                value: query.weeks,
                                            }}
                                            options={[4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                                (item: any) => {
                                                    return { label: `${item} Weeks`, value: item };
                                                }
                                            )}
                                            onClickChange={(e) => {
                                                setQuery({ ...query, weeks: e.value });
                                            }}
                                        />
                                    </div>
                                )}
                                {query.type.value === "specific_month" && (
                                    <div className={`${pageClassName}__filter__month`}>
                                        <Dropdown
                                            placeholder="Month"
                                            selected={{
                                                label: monthNames[query.month - 1],
                                                value: query.month,
                                            }}
                                            options={(currentYear === query.year
                                                ? monthNames.slice(0, currentMonth)
                                                : monthNames
                                            ).map((item, index) => {
                                                return { label: item, value: index + 1 } as any;
                                            })}
                                            onClickChange={(e) => {
                                                setQuery({ ...query, month: e.value });
                                            }}
                                        />
                                    </div>
                                )}
                                {["specific_month", "specific_year"].includes(
                                    query.type.value
                                ) && (
                                        <div className={`${pageClassName}__filter__year`}>
                                            <Dropdown
                                                placeholder="Year"
                                                selected={{ label: query.year, value: query.year }}
                                                options={ticketsOptionsYears}
                                                onClickChange={(e) => {
                                                    if (
                                                        e.value === currentYear &&
                                                        query.month > currentMonth
                                                    ) {
                                                        setQuery({
                                                            ...query,
                                                            month: currentMonth,
                                                            year: e.value,
                                                        });
                                                    } else {
                                                        setQuery({ ...query, year: e.value });
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                            </div>
                        )}
                        <ComboChart
                            data={
                                youtrackSupportTicketsReportData.length > 1
                                    ? youtrackSupportTicketsReportData
                                    : []
                            }
                            options={youtrackSupportTicketsReportOptions}
                            isLoading={youtrackSupportTicketsReportLoading}
                            style={{ padding: "8px 0" }}
                        />
                        {supportTicketsReportSuccess && (
                            <div className={`${pageClassName}__chart__ticket__table`}>
                                <TableChart data={tableData} />
                                <p className={`${pageClassName}__chart__ticket__total`}>
                                    Total Tickets:{" "}
                                    {supportTicketsReportResponse?.result?.totalTicket}
                                </p>
                            </div>
                        )}
                    </div>
                </Loader>
            </div>
            <div className={`${pageClassName}__container`}>
                <Loader
                    isLoading={
                        isLoading ||
                        (SpentTimeReportData.length && supportTicketsSpentTimeReportRefetch)
                    }
                >
                    <div className={`${pageClassName}__chart`}>
                        <ComboChart
                            data={SpentTimeReportData.length > 1 ? SpentTimeReportData : []}
                            options={SpentTimeReportOptions}
                            isLoading={youtrackSupportTicketsSpentTimeReportLoading}
                            style={{ padding: "8px 0" }}
                        />
                        {supportTicketsReportSuccess && (
                            <div className={`${pageClassName}__chart__ticket__table`}>
                                <TableChart data={spentTimeTableData} />
                                <p className={`${pageClassName}__chart__ticket__total`}>
                                    Total Spent Time:{" "}
                                    {supportTicketsSpentTimeReportResponse?.result?.totalSpentTime}
                                </p>
                            </div>
                        )}
                    </div>
                </Loader>
            </div>
        </div>
    );
};
