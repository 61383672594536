import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { useSingleSaasToolProjectQuery } from "../../../../../store/saasTool/saasToolSlice";
import { PAGE_NAME } from "../../../../../constants/constants";

const YouTrackProjectDetails = () => {
  const pageClassName = "p-youTrackProjectDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useSingleSaasToolProjectQuery({ id });


  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      isSortable: false,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      isSortable: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      isSortable: false,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: false,
    }
  ];


  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.YOUTRACK_PROJECT_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Project Id
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.toolProjectId ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Project Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.name ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Tool Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.toolName ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Template</p>
          {isLoading ? <Skeleton height="small" /> : data?.result?.template ? "Yes" : "No"}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Archived</p>
          {isLoading ? <Skeleton height="small" /> : data?.result?.archived ? "Yes" : "No"}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.createdAt ? formattedDateTime(data?.result?.createdAt) : <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Updated
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.updatedAt ? formattedDateTime(data?.result?.updatedAt) : <Skeleton height="small" />}
          </p>
        </div>

        {data?.result?.users?.length !== 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Projects</p>

              <Table
                header={appsTableHeader}
                data={data?.result?.users ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                sortHandler={() => { }}
                startIndex={1}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default YouTrackProjectDetails;