import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["apiKeyList"],
});

export const apiKeySlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * Fetches a list of API keys from the server.
         *
         * @param search - The search query to filter the API keys.
         * @param limit - The maximum number of API keys to fetch.
         * @param current_page - The current page of API keys to fetch.
         * @param sort_by - The field to sort the API keys by.
         * @param sort_direction - The direction to sort the API keys in.
         * @returns A promise that resolves to the response data containing the list of API keys.
         */
        apiKeyList: builder.query<ResponseDataType, listDataType>({
            query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
                url: API_URL.API_KEY + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
                    }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
                    }${search ? `&search=${search}` : ""}`,
                method: "GET",
            }),
            providesTags: ["apiKeyList"],
        }),

        /**
         * Creates an API key.
         * @param payload - The data to be sent in the request body.
         * @returns A Promise that resolves to the response data.
         */
        createApiKey: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.API_KEY,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["apiKeyList"],
        }),
    }),
});

export const { useApiKeyListQuery, useCreateApiKeyMutation } = apiKeySlice;
