import React from "react";
import { useActivityReportListQuery } from "../../../../store/reports/reportSlice";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME } from "../../../../constants/constants";
import { Input } from "../../../atoms/input";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { Icon } from "../../../atoms/icon";
import { ActivityGridView } from "./grid";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { MultipleDropdown } from "../../../atoms/multipleDropdown";
import { DropdownOptionsType } from "../../../../types";
import { capitalizeFirstCharacter } from "../../../../utils";

const Activity = () => {
    const pageClassName = "p-activity";
    const [viewType, setViewType] = React.useState("grid");
    const [isShow, setIsShow] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);
    const [filter, setFilter] = React.useState({
        duration: {
            label: "Please select",
            value: "0",
        },
        toolNames: [] as { label: string; value: string }[],
    });

    const [query, setQuery] = React.useState({
        current: 1,
        limit: 20,
        search: "",
        duration: 0,
        toolNames: [] as string[],
    });
    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setQuery({ ...query, current: 1, search: search });
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const pageChangeHandler = (page: number) => {
        setQuery({ ...query, current: page });
    };

    const limitChangeHandler = (limit: number) => {
        setQuery({ ...query, limit: limit });
    };

    const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setQuery({ ...query, current: 1 });
    };

    const { data, isLoading, isFetching } = useActivityReportListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        duration: query.duration,
        tool_names: JSON.stringify(query.toolNames),
    });

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });
    const viewTypeHandler = (type: string) => {
        setIsShow(false);
        setViewType(type);
    };

    /*******************************************/
    const showMoreTableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            isSortable: false,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            isSortable: false,
        },
        {
            title: "Email",
            dataIndex: "email",
            isSortable: false,
        },
        {
            title: "SaaS Tools",
            dataIndex: "saasToolsActivity",
            isSortable: true,
        },
        {
            title: "Okta",
            dataIndex: "oktaActivity",
            isSortable: false,
        },
        {
            title: "YouTrack",
            dataIndex: "youtrackActivity",
            isSortable: false,
        },
        {
            title: "Box",
            dataIndex: "boxActivity",
        },
        {
            title: "Confluence",
            dataIndex: "confluenceActivity",
            isSortable: false,
        },
        {
            title: "Gitlab",
            dataIndex: "gitlabActivity",
            isSortable: false,
        },
        {
            title: "Miro",
            dataIndex: "miroActivity",
            isSortable: false,
        },
        {
            title: "GCP",
            dataIndex: "gcpActivity",
            isSortable: false,
        },
        {
            title: "AWS",
            dataIndex: "awsActivity",
            isSortable: false,
        },
    ];

    const showLessTableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            isSortable: false,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            isSortable: false,
        },
        {
            title: "Email",
            dataIndex: "email",
            isSortable: false,
        },
        {
            title: "SaaS Tools",
            dataIndex: "saasToolsActivity",
            isSortable: true,
        },
    ];

    const toolSortHandler = () => {
        setIsShow(!isShow);
    };

    const options = [
        {
            label: "1 month",
            value: 1,
        },
        {
            label: "3 months",
            value: 3,
        },
        {
            label: "6 months",
            value: 6,
        },
        {
            label: "12 months",
            value: 12,
        },
    ];

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //{query.duration !== 0 && <p className={`${pageClassName}__header__title`}>Filtered By {query.duration} month{query.duration > 1 && "s"}</p>}
    const filteredFunction = (query: any) => {
        if (query.duration !== 0 && query.toolNames.length > 0) {
            return (
                <p className={`${pageClassName}__header__title`}>
                    Filtered By {query.duration} month{query.duration > 1 && "s"} and{" "}
                    {query.toolNames
                        .map((item: string) => capitalizeFirstCharacter(item))
                        .join(", ")}
                </p>
            );
        } else if (query.duration !== 0) {
            return (
                <p className={`${pageClassName}__header__title`}>
                    Filtered By {query.duration} month{query.duration > 1 && "s"}
                </p>
            );
        } else if (query.toolNames.length > 0) {
            return (
                <p className={`${pageClassName}__header__title`}>
                    Filtered By {query.toolNames
                        .map((item: string) => capitalizeFirstCharacter(item))
                        .join(", ")}
                </p>
            );
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.ACTIVITY_REPORT}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>

                    {filteredFunction(query)}
                    <div className={`${pageClassName}__header__combo`}>
                        <button
                            onClick={() => viewTypeHandler("grid")}
                            className={`${pageClassName}__header__combo__button ${viewType === "grid" && pageClassName
                                }__header__combo__button--active`}
                            title="Grid"
                        >
                            <Icon icon="grid" size="primary" />
                        </button>
                        <button
                            onClick={() => viewTypeHandler("list")}
                            className={`${pageClassName}__header__combo__button ${viewType === "list" && pageClassName
                                }__header__combo__button--active`}
                            title="List"
                        >
                            <Icon icon="list" size="primary" />
                        </button>
                        <div
                            className={`${pageClassName}__header__combo__filter`}
                            ref={ref}
                        >
                            <button
                                onClick={() => setShowFilter(!isShowFilter)}
                                className={`${pageClassName}__header__combo__button ${viewType === "" && pageClassName
                                    }__header__combo__button--active`}
                                title="Filter"
                            >
                                <Icon icon="filter" size="primary" />
                            </button>
                            <div
                                className={`${pageClassName}__dropdown ${isShowFilter && pageClassName
                                    }__dropdown--show`}
                            >
                                {/* <div className={`${pageClassName}__dropdown__options`}>
                                    {options.map((item, index) => (
                                        <p
                                            key={index}
                                            className={`${pageClassName}__dropdown__options__item ${filter.duration === item.value && pageClassName
                                                }__dropdown__options__item--active`}
                                            onClick={() => {
                                                setFilter({ ...filter, duration: item.value })
                                            }}
                                        >
                                            {item.label}
                                        </p>
                                    ))}
                                </div> */}

                                <Dropdown
                                    placeholder="Select Duration"
                                    options={options}
                                    selected={filter.duration}
                                    onClickChange={(value) =>
                                        setFilter({
                                            ...filter,
                                            duration: value as { label: string; value: string },
                                        })
                                    }
                                    isBordered
                                    isIcon
                                    iniDirection="top"
                                />

                                <MultipleDropdown
                                    placeholder="Select Tools"
                                    options={[
                                        { label: "Okta", value: "okta" },
                                        { label: "YouTrack", value: "youtrack" },
                                        { label: "Box", value: "box" },
                                        { label: "Confluence", value: "confluence" },
                                        { label: "Gitlab", value: "gitlab" },
                                        { label: "Miro", value: "miro" },
                                        { label: "GCP", value: "gcp" },
                                        { label: "AWS", value: "aws" },
                                    ]}
                                    value={filter.toolNames as { label: string; value: string }[]}
                                    dropdownHandler={(value: DropdownOptionsType[]) =>
                                        setFilter({
                                            ...filter,
                                            toolNames: value as { label: string; value: string }[],
                                        })
                                    }
                                />
                                <div className={`${pageClassName}__dropdown__submit`}>
                                    <button
                                        onClick={() => {
                                            setQuery({
                                                ...query,
                                                current: 1,
                                                duration: parseInt(filter.duration.value),
                                                toolNames: filter.toolNames.map((item) => item.value),
                                            });
                                            setShowFilter(false);
                                            handleFirstPage();
                                        }}
                                    >
                                        Apply
                                    </button>
                                    <button
                                        onClick={() => {
                                            setFilter({
                                                ...filter,
                                                duration: { label: "Please select", value: "0" },
                                                toolNames: [],
                                            });
                                            setQuery({
                                                ...query,
                                                current: 1,
                                                duration: 0,
                                                toolNames: [],
                                            });
                                            setShowFilter(false);
                                            handleFirstPage();
                                        }}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {viewType === "grid" && data?.result?.total > query.limit && (
                    <div className={`${pageClassName}__pagination`}>
                        <div className={`${pageClassName}__pagination__limit`}>
                            <p>Display</p>
                            <div className={`${pageClassName}__pagination__dropdown`}>
                                <Dropdown
                                    options={[
                                        { label: "20", value: 20 },
                                        { label: "50", value: 50 },
                                        { label: "100", value: 100 },
                                    ]}
                                    selected={{ label: query.limit, value: query.limit }}
                                    onClickChange={(e) => {
                                        limitChangeHandler(e.value as number);
                                        handleFirstPage();
                                    }}
                                    isBordered
                                    isIcon
                                    iniDirection="top"
                                />
                            </div>
                        </div>
                        <div className={`${pageClassName}__pagination__number`}>
                            {data?.result?.total ? (
                                <p>
                                    Show {data?.result?.from} -{" "}
                                    {data?.result?.from + query.limit - 1 < data?.result?.total
                                        ? data?.result?.from + query.limit - 1
                                        : data?.result?.total}{" "}
                                    of {data?.result?.total}
                                </p>
                            ) : (
                                <p>Show 00 - 00 of 00</p>
                            )}
                        </div>

                        <div className={`${pageClassName}__pagination__nav`}>
                            {data?.result?.total > query.limit && (
                                <Pagination
                                    totalData={totalData}
                                    page={query.current}
                                    handleFirstPage={handleFirstPage}
                                    handlePrevPage={handlePrevPage}
                                    handleLastPage={handleLastPage}
                                    handleNextPage={handleNextPage}
                                    renderPageNumbers={renderPageNumbers}
                                />
                            )}
                        </div>
                    </div>
                )}
                {viewType === "grid" && (
                    <>
                        <Divider />
                        <ActivityGridView
                            data={data}
                            isLoading={isLoading || isFetching}
                            pageClassName={pageClassName}
                            differenceMonth={query.duration}
                        />
                    </>
                )}

                {viewType === "grid" && data?.result?.total === 0 && (
                    <div className={`${pageClassName}__noData`}>
                        <div className={`${pageClassName}__noData__content`}>
                            <Icon icon="sadFace" size="tertiary" />
                            <p>No Data Found</p>
                        </div>
                    </div>
                )}

                {viewType === "list" && (
                    <div className={`${pageClassName}__table`}>
                        <Table
                            header={isShow ? showMoreTableHeader : showLessTableHeader}
                            data={data?.result?.data ?? []}
                            loading={isLoading || isFetching}
                            firstLoad={isLoading}
                            sortHandler={toolSortHandler}
                            minWidth={isShow ? "3000" : "800"}
                            startIndex={data?.result?.from}
                            sortBy="saasToolsActivity"
                            icon={isShow ? "arrowLeft" : "arrowRight"}
                            customField={query.duration}
                        />
                    </div>
                )}

                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Activity;
