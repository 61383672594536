import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import {
  useFileUploadMutation,
  useSaasToolListQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { hasPermission, pascalToSnakeCase } from "../../../../../utils";
import { Pagination } from "../../../../molecules/pagination";
import { Dropdown } from "../../../../atoms/dropdown";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { Input } from "../../../../atoms/input";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { Modal } from "../../../../molecules/modal";
import { Loader } from "../../../../atoms/loader";
import { TableDataType, payloadType } from "../../../../../types";
import { FileUpload } from "../../../../atoms/fileUpload";
import { Icon } from "../../../../atoms/icon";
import { toast } from "react-toastify";
import { PAGE_NAME, PERMISSIONS, ROUTE, SOMETHING_WENT_WRONG } from "../../../../../constants/constants";

const ConfluenceUserList = () => {
  const pageClassName = "p-confluenceUserList";
  const navigate = useNavigate();

  const initialState: payloadType = {
    tool_name: {
      value: "Confluence",
      error: "",
      isError: false,
    },
    csv_file: {
      value: "",
      error: "",
      isError: false,
    },
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [payload, setPayload] = React.useState(initialState);

  const [fileUploadApi, { isLoading: fileUploadLoading }] =
    useFileUploadMutation();

  const closeHandler = () => {
    setIsModalOpen(false);
    setPayload(initialState);
    setFileName("");
  };

  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return;
    setPayload({
      ...payload,
      csv_file: {
        ...payload.csv_file,
        value: e.target.files[0] as File,
      },
    });
    setFileName(e.target.files[0].name);
  };

  const {
    search,
    query,
    pageChangeHandler,
    sortHandler,
    limitChangeHandler,
    searchHandler,
  } = useTableHooks();

  const { data, isLoading } = useSaasToolListQuery({
    search: query.search,
    limit: query.limit,
    current_page: query.current,
    sort_by: pascalToSnakeCase(query.sortBy),
    sort_direction: query.sortDirection,
    tool_name: "Confluence",
  });

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "First Name",
      dataIndex: "userFirstName",
      isSortable: true,
    },
    {
      title: "Last Name",
      dataIndex: "userLastName",
      isSortable: true,
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      isSortable: true,
    },
    {
      title: "Status",
      dataIndex: "userStatus",
      isSortable: false,
    },
    {
      title: "Last Activity Date",
      dataIndex: "lastActivity",
      isSortable: true,
    },
  ];

  const toolSortHandler = (sortBy: string) => {
    if (sortBy === "userFirstName") {
      sortBy = "first_name";
    } else if (sortBy === "userLastName") {
      sortBy = "last_name";
    } else if (sortBy === "userEmail") {
      sortBy = "email";
    } else if (sortBy === "userStatus") {
      sortBy = "status";
    }

    sortHandler(sortBy);
  };

  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: data?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  const formattedSortBy = (sortBy: string) => {
    switch (sortBy) {
      case "first_name":
        return "userFirstName";
      case "last_name":
        return "userLastName";
      case "email":
        return "userEmail";
      default:
        return sortBy;
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    const formData = new FormData();
    formData.append("tool_name", payload.tool_name.value as string);
    formData.append("csv_file", payload.csv_file.value as File);


    const response = await fileUploadApi(formData) as any;

    if (response?.data?.success) {
      closeHandler();
      toast.success(response?.data?.message);
    } else {
      if (response?.error?.data?.errors) {
        Object.keys(response?.error?.data?.errors).forEach((key) => {
          setPayload((prevState) => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              error: response?.error?.data?.errors[key][0],
              isError: true,
            },
          }));
          //   }
        });
      } else {
        toast.error(response?.error.data?.message);
      }
      if (response?.error?.error) {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const viewHandler = (id: string) => {
    const user = data?.result?.data.find((item: TableDataType) => item.id === id);
    navigate(ROUTE.CONFLUENCE_USER_LIST + `/${id}`,
      {
        state: {
          name: user?.oktaUser?.firstName ? user?.oktaUser?.firstName + " " + user?.oktaUser?.lastName : undefined,
        },
      }
    );
  }

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.CONFLUENCE_USER_LIST}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__header`}>
          <div className={`${pageClassName}__search`}>
            <Input
              placeholder="Search"
              onChange={searchHandler}
              value={search}
            />
          </div>
          {hasPermission(PERMISSIONS.SYNC_SAAS_USER.title) && (
            <button
              className={`${pageClassName}__button`}
              onClick={() => setIsModalOpen(true)}
            >
              File Upload
            </button>
          )}
        </div>
        <div className={`${pageClassName}__table`}>
          <Table
            header={tableHeader}
            data={data?.result?.data ?? []}
            loading={isLoading}
            firstLoad={isLoading}
            sortHandler={(sortBy: string) => toolSortHandler(sortBy)}
            viewHandler={viewHandler}
            minWidth="1440"
            startIndex={data?.result?.from}
            sortBy={formattedSortBy(query.sortBy)}
            cursorPointer
          />
        </div>
        <Divider />

        <div className={`${pageClassName}__pagination`}>
          <div className={`${pageClassName}__pagination__limit`}>
            <p>Display</p>
            <div className={`${pageClassName}__pagination__dropdown`}>
              <Dropdown
                options={[
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                selected={{ label: query.limit, value: query.limit }}
                onClickChange={(e) => {
                  limitChangeHandler(e.value as number);
                  handleFirstPage();
                }}
                isBordered
                isIcon
                iniDirection="top"
              />
            </div>
          </div>
          <div className={`${pageClassName}__pagination__number`}>
            {data?.result?.total ? (
              <p>
                Show {data?.result?.from} -{" "}
                {data?.result?.from + query.limit - 1 < data?.result?.total
                  ? data?.result?.from + query.limit - 1
                  : data?.result?.total}{" "}
                of {data?.result?.total}
              </p>
            ) : (
              <p>Show 00 - 00 of 00</p>
            )}
          </div>
          <div className={`${pageClassName}__pagination__nav`}>
            {data?.result?.total > query.limit && (
              <Pagination
                totalData={totalData}
                page={query.current}
                handleFirstPage={handleFirstPage}
                handlePrevPage={handlePrevPage}
                handleLastPage={handleLastPage}
                handleNextPage={handleNextPage}
                renderPageNumbers={renderPageNumbers}
              />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeHandler} header={"File Upload"}>
        <Loader isLoading={fileUploadLoading}>
          <form className={`${pageClassName}__modal`} onSubmit={submitHandler}>
            <Input
              readonly
              value={payload.tool_name.value as string}
              label="Tool Name"
              onChange={() => { }}
              placeholder="Tool Name"
            />

            <FileUpload
              placeholder="Please upload CSV file"
              label="File"
              fileName={fileName}
              onChange={fileChangeHandler}
              isError={payload.csv_file.isError}
              error={payload.csv_file.error}
            />

            <div className={`${pageClassName}__modal__instruction`}>
              <div className={`${pageClassName}__modal__instruction__content`}>
                <Icon icon="check" size="secondary" />
                <p className={`${pageClassName}__modal__instruction__text`}>
                  You can download from confluence admin dashboard{" "}
                  <a
                    href="https://admin.atlassian.com/o/cd16ab8d-k0kd-1j93-6540-dj947j104k84/external-users"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </a>
                </p>
              </div>
              <div className={`${pageClassName}__modal__instruction__content`}>
                <Icon icon="check" size="secondary" />
                <p className={`${pageClassName}__modal__instruction__text`}>
                  Please make sure the file is in CSV format
                </p>
              </div>

              <div className={`${pageClassName}__modal__instruction__content`}>
                <Icon icon="check" size="secondary" />
                <p className={`${pageClassName}__modal__instruction__text`}>
                  Please make sure the file has the correct headers and order <br />
                  (Email, Atlassian ID, Last active date [UTC] ,External user
                  settings apply to)
                </p>
              </div>
            </div>

            <div className={`${pageClassName}__modal__action`}>
              <button
                className={`${pageClassName}__modal__cancel`}
                onClick={closeHandler}
                type="button"
              >
                Cancel
              </button>
              <button className={`${pageClassName}__modal__add`} type="submit">
                Save
              </button>
            </div>
          </form>
        </Loader>
      </Modal>
    </div>
  );
};

export default ConfluenceUserList;
