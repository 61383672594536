import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;
export const userActions = userSlice.actions;
