import { API_URL } from "../../constants/constants";
import { ResponseDataType, UpdateType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['UserList', 'singleUser', 'Role', 'Me'],
});

/**
 * Defines the userSlice module, which contains the API endpoints and state for user management.
 */
export const userSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * Retrieves a list of users based on the provided query parameters.
         * @param search - The search term to filter users by.
         * @param limit - The maximum number of users to retrieve.
         * @param current_page - The current page of users to retrieve.
         * @param sort_by - The field to sort users by.
         * @param sort_direction - The direction to sort users in.
         * @returns A Promise that resolves to the response data.
         */
        userList: builder.query<ResponseDataType, listDataType>({
            query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
                url: API_URL.USERS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
                    }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
                    }${search ? `&search=${search}` : ""}`,
                method: "GET",
            }),
            providesTags: ['UserList'],
        }),

        /**
         * Retrieves a single user by their ID.
         * @param id - The ID of the user to retrieve.
         * @returns A Promise that resolves to the response data.
         */
        singleUser: builder.query<ResponseDataType, listDataType>({
            query: ({ id }) => ({
                url: API_URL.USERS + `/${id}`,
                method: "GET",
            }),
            providesTags: ['singleUser'],
        }),

        /**
         * Updates a user's information.
         * @param formData - The updated user data.
         * @param id - The ID of the user to update.
         * @returns A Promise that resolves to the response data.
         */
        updateUser: builder.mutation<ResponseDataType, UpdateType>({
            query: ({ formData, id }) => ({
                url: API_URL.USERS + `/${id}`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ['UserList', 'singleUser', 'Me'],
        }),

        /**
         * Soft deletes a user by their ID.
         * @param id - The ID of the user to soft delete.
         * @returns A Promise that resolves to the response data.
         */
        userSoftDelete: builder.mutation<ResponseDataType, number>({
            query: (id) => ({
                url: API_URL.USERS + `/soft-delete/${id}`,
                method: "delete",
            }),
            invalidatesTags: ['UserList', 'singleUser', 'Me'],
        }),

        /**
         * Restores a soft deleted user by their ID.
         * @param id - The ID of the user to restore.
         * @returns A Promise that resolves to the response data.
         */
        userRestore: builder.mutation<ResponseDataType, number>({
            query: (id) => ({
                url: API_URL.USERS + `/restore/${id}`,
                method: "post",
            }),
            invalidatesTags: ['UserList', 'singleUser', 'Me'],
        }),

        /**
         * Updates the user's profile information.
         * @param formData - The updated profile data.
         * @returns A Promise that resolves to the response data.
         */
        updateProfile: builder.mutation<ResponseDataType, FormData>({
            query: (formData) => ({
                url: API_URL.UPDATE_PROFILE,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ['UserList', 'singleUser', 'Me'],
        }),

        /**
         * Retrieves a list of roles.
         * @returns A Promise that resolves to the response data.
         */
        role: builder.query<ResponseDataType, void>({
            query: () => ({
                url: API_URL.ROLES,
                method: "GET",
            }),
            providesTags: ['Role'],
        }),
        me: builder.query<ResponseDataType, void>({
            query: () => ({
                url: API_URL.USERS + API_URL.ME,
                method: "GET",
            }),
            providesTags: ['Me'],
        }),
    }),
});

export const {
    useUserListQuery,
    useSingleUserQuery,
    useUpdateUserMutation,
    useUserSoftDeleteMutation,
    useUserRestoreMutation,
    useUpdateProfileMutation,
    useRoleQuery,
    useMeQuery,
} = userSlice;
