import React from 'react'
import { createComponentBase, BaseComponentProps } from "../../../libs/components";

export interface TooltipProps extends BaseComponentProps {
  className?: string
  children?: React.ReactNode
  message?: string
  isVisible?: boolean
}

export const Tooltip = ({ children, message,isVisible, className }: TooltipProps) => {
  const componentClassName = 'a-tooltip'
  const modifiers = {}
  const { Container } = createComponentBase(componentClassName, 'div')
  return (
    <Container className={className} modifiers={modifiers}>
      {isVisible && (
        <div className={`${componentClassName}__message`}>
          <p className={`${componentClassName}__message__text`}>
            {message}
          </p>
        </div>
      )}
      {children}
    </Container>
  )
}
