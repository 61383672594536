import React from "react";
import { Divider } from "../../atoms/divider";
import { Input } from "../../atoms/input";
import { useTableHooks } from "../../atoms/table/useTableHooks";
import { usePaginationHook } from "../../molecules/pagination/usePaginationHook";
import { Table } from "../../atoms/table";
import { Dropdown } from "../../atoms/dropdown";
import { Pagination } from "../../molecules/pagination";
import { pascalToSnakeCase } from "../../../utils";
import { TableHeaderType } from "../../../types";

interface dataTableProps {
    title?: string;
    toolName?: string;
    id?: string;
    useCallApiQuery: any;
    tableHeader: TableHeaderType[];
    viewHandler?: (id: string, response: any) => void;
    cursorPointer?: boolean;
}

export const DataTable = ({
    title,
    toolName,
    id,
    useCallApiQuery,
    tableHeader,
    viewHandler = () => { },
    cursorPointer = false,
}: dataTableProps) => {
    const componentClassName = "o-dataTable";

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const sortFormatHandler = (sortBy: string) => {
        switch (sortBy) {
            case "projectName":
                sortBy = "name";
                break;
            case "memberEmail":
                sortBy = "email";
                break;
            case "groupName":
                sortBy = "name";
                break;
            case "groupRole":
                sortBy = "role";
                break;
            case "userFirstName":
                sortBy = "firstName";
                break;
            case "userLastName":
                sortBy = "lastName";
                break;
            case "userEmail":
                sortBy = "email";
                break;
            default:
                break;
        }

        return sortBy;
    };

    const { data: response, isLoading } = useCallApiQuery({
        id: id,
        search: search,
        current_page: query.current,
        limit: query.limit,
        sort_by: pascalToSnakeCase(sortFormatHandler(query.sortBy)),
        sort_direction: query.sortDirection,
        tool_name: toolName,
    });

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: response?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    return (
        <div className={componentClassName}>
            <Divider />
            <div className={`${componentClassName}__container`}>
                {title && (
                    <p className={`${componentClassName}__container__title`}>{title}</p>
                )}
                <div className={`${componentClassName}__container__content`}>
                    <div className={`${componentClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>
                    <Table
                        header={tableHeader}
                        data={response?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        sortBy={query.sortBy}
                        startIndex={response?.result?.from}
                        viewHandler={(id) => viewHandler(id, response)}
                        cursorPointer={cursorPointer}
                    />
                    {response?.result?.total > 15 && (
                        <div className={`${componentClassName}__pagination`}>
                            <div className={`${componentClassName}__pagination__limit`}>
                                <p>Display</p>
                                <div className={`${componentClassName}__pagination__dropdown`}>
                                    <Dropdown
                                        options={[
                                            { label: "20", value: 20 },
                                            { label: "50", value: 50 },
                                            { label: "100", value: 100 },
                                        ]}
                                        selected={{ label: query.limit, value: query.limit }}
                                        onClickChange={(e) => {
                                            limitChangeHandler(e.value as number);
                                            handleFirstPage();
                                        }}
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                            </div>
                            <div className={`${componentClassName}__pagination__number`}>
                                {response?.result?.total ? (
                                    <p>
                                        Show {response?.result?.from} -{" "}
                                        {response?.result?.from + query.limit - 1 <
                                            response?.result?.total
                                            ? response?.result?.from + query.limit - 1
                                            : response?.result?.total}{" "}
                                        of {response?.result?.total}
                                    </p>
                                ) : (
                                    <p>Show 00 - 00 of 00</p>
                                )}
                            </div>

                            <div className={`${componentClassName}__pagination__nav`}>
                                {response?.result?.total > query.limit && (
                                    <Pagination
                                        totalData={totalData}
                                        page={query.current}
                                        handleFirstPage={handleFirstPage}
                                        handlePrevPage={handlePrevPage}
                                        handleLastPage={handleLastPage}
                                        handleNextPage={handleNextPage}
                                        renderPageNumbers={renderPageNumbers}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
