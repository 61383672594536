import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME, PERMISSIONS, ROUTE } from "../../../../constants/constants";
import { formattedDateTime, hasPermission } from "../../../../utils";
import { Dialog } from "../../../atoms/dialog";
import { toast } from "react-toastify";
import { Skeleton } from "../../../atoms/skeleton";
import { useDeleteRoleMutation, useSingleRoleQuery } from "../../../../store/role/roleSlice";
import { Icon } from "../../../atoms/icon";
import { Divider } from "../../../atoms/divider";
import { ResponseType } from "../../../../types";

const RoleDetails = () => {
  const pageClassName = "p-roleDetails";

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data, isSuccess, isError } = useSingleRoleQuery(id as string);

  React.useEffect(() => {
    if (isError) {
      navigate(ROUTE.UM_TOOL_USER_ROLE_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState<number>();

  const dialogHandler = () => {
    setIsDialogOpen(true);
    setDeleteId(Number(id));
  };

  const closeDialogHandler = () => {
    setIsDialogOpen(false);
  };

  const [deleteApi] = useDeleteRoleMutation();

  const deleteHandler = async () => {
    closeDialogHandler();

    const response = (await deleteApi(deleteId as number)) as ResponseType;

    if (response?.data?.success) {
      toast.success(response.data.message);
      navigate(ROUTE.UM_TOOL_USER_ROLE_LIST);
    } else {
      toast.error(response?.error?.data?.message);
    }
  };

  const hasRolePermission = (permission: string, id: number) => {
    if (permission === PERMISSIONS.ROLE_EDIT.title) {
      return hasPermission(permission) && id !== 1
    } else {
      return hasPermission(permission) && ![1, 2, 3, 4, 5].includes(id)
    }
  }

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.UM_TOOL_USER_ROLE_DETAILS}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Display Title
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.displayTitle ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Title</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.title ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Created Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.createdAt ? (
                formattedDateTime(data?.result?.createdAt)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Updated Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.updatedAt ? (
                formattedDateTime(data?.result?.updatedAt)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Permission
          </p>
          <div className={`${pageClassName}__container__item__permission`}>
            {isSuccess ? (
              data?.result?.permissions?.map((item: any) => (
                <p
                  key={item.id}
                  className={`${pageClassName}__container__item__permission__title`}
                >
                  {item.displayTitle}
                </p>
              ))
            ) : (
              <Skeleton height="small" />
            )}
          </div>
        </div>
        <Divider />
        <div className={`${pageClassName}__container__action`}>
          {hasRolePermission(PERMISSIONS.ROLE_EDIT.title, data?.result?.id) && isSuccess && (
            <button
              className={`${pageClassName}__container__action__button`}
              onClick={() =>
                navigate(ROUTE.UM_TOOL_USER_ROLE_LIST + ROUTE.ROLE_EDIT, {
                  state: {
                    roleId: data?.result?.id,
                  },
                })
              }
            >
              <Icon icon="edit" /> Edit
            </button>
          )}
          {hasRolePermission(PERMISSIONS.ROLE_DELETE.title, data?.result?.id) && isSuccess && (
            <button
              className={`${pageClassName}__container__action__button ${pageClassName}__container__action__button--delete`}
              onClick={dialogHandler}
            >
              <Icon icon="delete" /> Delete
            </button>
          )}
        </div>
      </div>
      <Dialog
        header="Delete Role"
        body={
          <div className={`${pageClassName}__dialog`}>
            <p className={`${pageClassName}__dialog__text`}>
              Are you sure you want to delete this role?
            </p>
            <p className={`${pageClassName}__dialog__text`}>
              This action cannot be undone.
            </p>
          </div>
        }
        isOpen={isDialogOpen}
        onClose={closeDialogHandler}
        successFn={deleteHandler}
        btn="Delete"
        icon="delete"
      />
    </div>
  );
};

export default RoleDetails;
