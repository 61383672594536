import React from "react";
import { Breadcrumb } from "../../atoms/breadcrumb";
import { UnderConstruction } from "../../organisms/underConstruction";

const MergeBaseUserList= () => {
    const pageClassName = "p-inventoryManagement";

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>MergeBase</p>
            <div className={`${pageClassName}__container`}>
                <UnderConstruction />
            </div>
        </div>
    );
};

export default MergeBaseUserList;
