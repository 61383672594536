import React, { useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import Resizer from 'react-image-file-resizer'
import getCroppedImg from './cropImage'
import { createComponentBase } from "../../../libs/components";

const resizeFile = (file: File, height: number, width: number, type: string) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      type,
      100,
      0,
      (uri: string | Blob | File | ProgressEvent<FileReader>) => {
        resolve(uri)
      },
      'blob'
    )
  })

type ImageCropProps = {
  imageUrl: string
  ratio?: number
  onCancel: () => void
  croppedImageSetter: (image: any) => void
  cropShape?: 'rect' | 'round'
  height: number
  width: number
  type: string
}

const ImageCropper = ({
  imageUrl,
  ratio = 1,
  onCancel,
  croppedImageSetter,
  cropShape = 'rect',
  height,
  width,
  type
}: ImageCropProps) => {
  const [zoom, setZoom] = useState(1)
  const [maxZoom, setMaxZoom] = useState(3)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCropAreaPixels] = useState({
    height: height,
    width: width
  })

  const onCropChange = (crop: { x: number; y: number }) => {
    setCrop(crop)
  }

  const onZoomChange = (zoom: number) => {
    if (
      croppedAreaPixels.height === height ||
      croppedAreaPixels.width === width
    ) {
      setMaxZoom(zoom)
    }
    setZoom(zoom)
  }

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCropAreaPixels(croppedAreaPixels)
  }

  const onCrop = async () => {
    const croppedImage = await getCroppedImg({
      imageSrc: imageUrl,
      pixelCrop: croppedAreaPixels,
      type
    })
    const resizedImage = await resizeFile(
      croppedImage as File,
      height,
      width,
      type
    )
    croppedImageSetter(resizedImage)
  }

  const componentClassName = 'm-imageCrop'
  const modifiers = {}
  const { Container } = createComponentBase(componentClassName, 'div')

  return (
    <Container className='container' modifiers={modifiers}>
      <div className='backdrop'></div>
      <div className={`${componentClassName}__container`}>
        <Cropper
          image={imageUrl}
          zoom={zoom}
          crop={crop}
          aspect={width / height}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
          cropShape={cropShape}
          maxZoom={maxZoom}
          zoomSpeed={0.05}
        />
      </div>

      <div className={`${componentClassName}__controls`}>
        <div className={`${componentClassName}__controls__upperArea`}>
          <input
            type='range'
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              onZoomChange(Number(e.target.value))
            }
            className={`${componentClassName}__controls__upperArea__slider`}
          />
        </div>
        <div className={`${componentClassName}__controls__btn`}>
          <button className='cancel' onClick={onCancel}>
            Cancel
          </button>
          <button className='crop' onClick={onCrop}>
            Crop
          </button>
        </div>
      </div>
    </Container>
  )
}

export default ImageCropper
