import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { hasPermission, pascalToSnakeCase } from "../../../../../utils";
import {
  useCreateLicenseMutation,
  useLicenseListQuery,
  useSingleLicenseQuery,
  useUpdateLicenseMutation,
} from "../../../../../store/license/licenseSlice";
import { Table } from "../../../../atoms/table";
import { Input } from "../../../../atoms/input";
import { Modal } from "../../../../molecules/modal";
import { Dropdown } from "../../../../atoms/dropdown";
import { toast } from "react-toastify";
import {
  DropdownOptionsType,
  TableDataType,
  payloadType,
} from "../../../../../types";
import { Loader } from "../../../../atoms/loader";
import { useSyncUserMutation } from "../../../../../store/saasTool/saasToolSlice";
import {
  PAGE_NAME,
  PERMISSIONS,
  ROUTE,
  SOMETHING_WENT_WRONG,
  SubscriptionsName,
  saasToolName,
} from "../../../../../constants/constants";
import { useOktaGroupListQuery } from "../../../../../store/okta/groupSlice";
import { useNavigate } from "react-router-dom";

const formatColumnName = (name: string) => {
  switch (name) {
    case "totalLicenses":
      return "total";
    case "updatedAtTime":
      return "updatedAt";
    default:
      return name;
  }
};
const LicenseList = () => {
  const pageClassName = "p-licenseList";
  const navigate = useNavigate();

  const [syncUserApi, { isLoading: syncLoading }] = useSyncUserMutation();

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Saas Name",
      dataIndex: "name",
      isSortable: true,
    },
    {
      title: "Subscriptions Type",
      dataIndex: "subscriptionType",
      isSortable: true,
    },
    {
      title: "Renewal Date",
      dataIndex: "renewalDate",
      isSortable: true,
    },
    {
      title: "Total Licenses",
      dataIndex: "totalLicenses",
      isSortable: true,
      permission: hasPermission(PERMISSIONS.LICENSE_EDIT.title),
    },
    {
      title: "Active",
      dataIndex: "active",
      isSortable: true,
    },
    {
      title: "Invited",
      dataIndex: "invited",
      isSortable: true,
    },
    {
      title: "Predicted",
      dataIndex: "predicted",
      isSortable: true,
    },
    {
      title: "Remaining",
      dataIndex: "remaining",
      isSortable: true,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAtTime",
      isSortable: true,
    },
  ];

  const initialState: payloadType = {
    name: {
      value: "",
      error: "",
      isError: false,
    },
    subscription_type: {
      value: "",
      error: "",
      isError: false,
    },
    total: {
      value: "",
      error: "",
      isError: false,
    },
    renewal_date: {
      value: "",
      error: "",
      isError: false,
    },
    group_id: {
      value: "",
      error: "",
      isError: false,
    },
    id: {
      value: "",
      error: "",
      isError: false,
    },
  };

  const { search, query, sortHandler, searchHandler } = useTableHooks();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [payload, setPayload] = React.useState(initialState);

  const { data, isLoading } = useLicenseListQuery({
    search: query.search,
    limit: query.limit,
    current_page: query.current,
    sort_by: pascalToSnakeCase(formatColumnName(query.sortBy)),
    sort_direction: query.sortDirection,
  });

  /**
   * fetch okta group list
   */
  const { data: groupData, isLoading: groupLoading } = useOktaGroupListQuery(
    {}
  );

  const [createApi, { isLoading: createLoading }] = useCreateLicenseMutation();
  const [updateApi, { isLoading: updateLoading }] = useUpdateLicenseMutation();

  const {
    data: singleLicenseData,
    isSuccess: isSingleSuccess,
    isFetching: isSingleFetching,
  } = useSingleLicenseQuery(payload.id.value as number, {
    skip: payload.id.value ? false : true,
  });

  React.useEffect(() => {
    if (isSingleSuccess) {
      setPayload({
        ...payload,
        name: {
          ...payload.name,
          value: singleLicenseData?.result?.name,
        },
        subscription_type: {
          ...payload.subscription_type,
          value: {
            label: singleLicenseData?.result?.subscriptionType,
            value: singleLicenseData?.result?.subscriptionType,
          },
        },
        total: {
          ...payload.total,
          value: singleLicenseData?.result?.total,
        },

        renewal_date: {
          ...payload.renewal_date,
          value: singleLicenseData?.result?.renewalDate,
        },
        group_id: {
          ...payload.group_id,
          value: {
            label: singleLicenseData?.result?.group?.name,
            value: singleLicenseData?.result?.group?.id,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleSuccess, singleLicenseData]);

  const closeHandler = () => {
    setIsModalOpen(false);
    setPayload(initialState);
  };

  const editHandler = (id: string | number) => {
    setPayload({
      ...payload,
      id: {
        ...payload.id,
        value: id,
      },
    });
    setIsModalOpen(true);
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", payload.name.value as string);
    payload.total.value &&
      formData.append("total", payload.total.value.toString());
    formData.append("renewal_date", payload.renewal_date.value as string);
    formData.append(
      "group_id",
      (payload.group_id.value as DropdownOptionsType).value as string
    );
    payload.subscription_type.value &&
      formData.append(
        "subscription_type",
        (payload.subscription_type.value as DropdownOptionsType).value as string
      );

    if (payload.id.value) formData.append("id", payload?.id?.value?.toString());

    let response: any;
    if (payload.id.value) {
      response = (await updateApi(formData)) as any;
    } else {
      response = (await createApi(formData)) as any;
    }

    if (response?.data?.success) {
      closeHandler();
      toast.success(response?.data?.message);
    } else {
      if (response?.error?.data?.errors) {
        Object.keys(response?.error?.data?.errors).forEach((key) => {
          setPayload((prevState) => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              error: response?.error?.data?.errors[key][0],
              isError: true,
            },
          }));
        });
      } else {
        toast.error(response?.error.data?.message);
      }
      if (response?.error?.error) {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  const schedulerHandler = async () => {
    const response = (await syncUserApi()) as any;
    if (response?.data?.success) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const viewHandler = (id: string, e: any) => {
    if (["a-icon a-icon--edit","a-table__table__link"].includes(e.target.className)) return;
    const name = data?.result?.licenses.find(
      (item: TableDataType) => item.id === id
    ).name;
    navigate(`${ROUTE.LICENSE_MANAGEMENT}/${id}`, { state: { name } });
  };

  const redirectHandler = (name: string) => {
    switch (name) {
      case "Okta":
        navigate(ROUTE.OKTA_INVITED_USER_LIST);
        break;
      case "GitLab":
        navigate(ROUTE.GITLAB_INVITED_USER_LIST);
        break;
      case "Confluence":
        navigate(ROUTE.CONFLUENCE_INVITED_USER_LIST);
        break;
      case "Atlassian":
        navigate(ROUTE.ATLASSIAN_INVITED_USER_LIST);
        break;
      case "Miro":
        navigate(ROUTE.MIRO_INVITED_USER_LIST);
        break;
      case "Youtrack":
        navigate(ROUTE.YOUTRACK_INVITED_USER_LIST);
        break;
      case "Box":
        navigate(ROUTE.BOX_INVITED_USER_LIST);
        break;
      case "GCP":
        navigate(ROUTE.GCP_INVITED_USER_LIST);
        break;
      case "SOPHOS MDR" :
        navigate(ROUTE.SOPHOS_INVITED_USER_LIST);
        break;
      default:
        toast.warn(SOMETHING_WENT_WRONG);
        break;
    }
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>
        {PAGE_NAME.LICENSE_MANAGEMENT}
      </p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__header`}>
          <div className={`${pageClassName}__search`}>
            <Input
              placeholder="Search"
              onChange={searchHandler}
              value={search}
            />
          </div>
          <div className={`${pageClassName}__action`}>
            {hasPermission(PERMISSIONS.SYNC_SAAS_USER.title) && (
              <button
                className={`${pageClassName}__button ${pageClassName}__button--scheduler`}
                onClick={schedulerHandler}
                disabled={syncLoading}
              >
                Update License Info
              </button>
            )}
            {hasPermission(PERMISSIONS.LICENSE_CREATE.title) && (
              <button
                className={`${pageClassName}__button`}
                onClick={() => setIsModalOpen(true)}
                disabled={syncLoading}
              >
                Add Saas Tool
              </button>
            )}
          </div>
        </div>
        <div className={`${pageClassName}__table`}>
          <Table
            header={tableHeader}
            data={data?.result?.licenses ?? []}
            loading={syncLoading || isLoading}
            firstLoad={isLoading}
            sortHandler={sortHandler}
            viewHandler={viewHandler}
            sortBy={query.sortBy}
            minWidth="1440"
            startIndex={data?.result?.from}
            editHandler={editHandler}
            deleteHandler={redirectHandler}
            cursorPointer
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeHandler}
        header={payload.id.value ? "Edit Saas Tool" : "Add Saas Tool"}
        width="small"
      >
        <Loader
          isLoading={
            isSingleFetching || createLoading || updateLoading || groupLoading
          }
        >
          <form className={`${pageClassName}__modal`} onSubmit={submitHandler}>
            <div className={`${pageClassName}__modal__dropdown`}>
              <label className={`${pageClassName}__modal__dropdown__label`}>
                Saas Name
              </label>
              {payload?.id?.value ? (
                <div className={`${pageClassName}__modal__dropdown__value`}>
                  <span>{payload?.name?.value as string}</span>
                </div>
              ) : (
                <Dropdown
                  options={saasToolName.map((tool) => ({
                    label: tool.label,
                    value: tool.value,
                  }))}
                  onClickChange={(selected) =>
                    setPayload({
                      ...payload,
                      name: {
                        ...payload.name,
                        value: selected?.value as string,
                      },
                    })
                  }
                  placeholder="Please Select a Tool"
                  isIcon
                  color="black"
                  isBordered
                  // selected={{
                  //   label: payload.name.value as string,
                  //   value: payload.name.value as string,
                  // }}
                  isError={payload.name.isError}
                  error={payload.name.error}
                />
              )}
            </div>

            <div className={`${pageClassName}__modal__dropdown`}>
              <label className={`${pageClassName}__modal__dropdown__label`}>
                Subscription Type
              </label>
              <Dropdown
                options={SubscriptionsName}
                onClickChange={(selected) =>
                  setPayload({
                    ...payload,
                    subscription_type: {
                      ...payload.subscription_type,
                      value: selected as { label: string; value: string },
                      isError: false,
                      error: "",
                    },
                  })
                }
                placeholder="Please Select a Subscription Type"
                isIcon
                color="black"
                isBordered
                selected={
                  payload.subscription_type.value as DropdownOptionsType
                }
                isError={payload.subscription_type.isError}
                error={payload.subscription_type.error}
              />
            </div>

            <div className={`${pageClassName}__modal__dropdown`}>
              <label className={`${pageClassName}__modal__dropdown__label`}>
                Okta Group
              </label>
              <Dropdown
                options={groupData?.result?.data?.map((group: any) => ({
                  label: group?.name,
                  value: group?.id,
                }))}
                onClickChange={(selected) =>
                  setPayload({
                    ...payload,
                    group_id: {
                      ...payload.group_id,
                      value: selected as { label: string; value: string },
                      isError: false,
                      error: "",
                    },
                  })
                }
                placeholder="Please Select a Group"
                isIcon
                color="black"
                isBordered
                selected={payload.group_id.value as DropdownOptionsType}
                isError={payload.group_id.isError}
                error={payload.group_id.error}
              />
            </div>

            <Input
              placeholder="Enter Number of Total Licenses"
              label="Total Licenses"
              onChange={(e) =>
                setPayload({
                  ...payload,
                  total: {
                    ...payload.total,
                    value: parseInt(e.target.value),
                    isError: false,
                    error: "",
                  },
                })
              }
              value={(payload.total.value as number) || ""}
              isError={payload.total.isError}
              error={payload.total.error}
              type="number"
            />
            <Input
              placeholder="Enter Renewal Date"
              label="Renewal Date"
              onChange={(e) =>
                setPayload({
                  ...payload,
                  renewal_date: {
                    ...payload.renewal_date,
                    value: e.target.value,
                    isError: false,
                    error: "",
                  },
                })
              }
              value={
                payload.renewal_date.value
                  ? (new Date(payload.renewal_date.value as string)
                    .toISOString()
                    .substring(0, 10) as string)
                  : ""
              }
              isError={payload.renewal_date.isError}
              error={payload.renewal_date.error}
              type="date"
            />

            <div className={`${pageClassName}__modal__action`}>
              <button
                className={`${pageClassName}__modal__cancel`}
                onClick={closeHandler}
                type="button"
              >
                Cancel
              </button>
              <button className={`${pageClassName}__modal__add`} type="submit">
                Save
              </button>
            </div>
          </form>
        </Loader>
      </Modal>
    </div>
  );
};

export default LicenseList;
