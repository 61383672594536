import React from "react";

import {
    createComponentBase,
    BaseComponentProps,
} from "../../../../../libs/components";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { Icon } from "../../../../atoms/icon";
import { DropdownOptionsType } from "../../../../../types";
import { useGetSaaSToolByUserQuery } from "../../../../../store/requestManagement/requestSlice";
import { useOktaUserListQuery } from "../../../../../store/okta/userSlice";

export interface RemoveUsersProps extends BaseComponentProps {
    payload: any;
    setPayload: (payload: any) => void;
    approverList: any;
}

export const RemoveUsersRequest = ({
    className,
    payload,
    setPayload,
    approverList,
}: RemoveUsersProps) => {
    const componentClassName = "o-removeUsersRequest";
    const { Container } = createComponentBase(componentClassName, "div");
    const modifiers = {};

    const [email, setEmail] = React.useState("");

    const { data: users } = useOktaUserListQuery({});
    const { data: saasToolByUserData } = useGetSaaSToolByUserQuery(email, {
        skip: email === "",
    });

    React.useEffect(() => {
        if (saasToolByUserData) {
            const newUsers = [...payload.users.value];
            const data = saasToolByUserData.result;
            const index = newUsers.findIndex(
                (user: any) => user.email.value === data?.email
            );

            newUsers[index].manager = data?.manager;
            newUsers[index].assign = data?.tools;

            setPayload({
                ...payload,
                users: {
                    value: newUsers,
                    isError: false,
                    error: "",
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saasToolByUserData]);

    const changeHandler = (value: DropdownOptionsType, index: number) => {
        const newUsers = [...payload.users.value];
        newUsers[index]["email"] = value;
        setEmail(value.value as string);

        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const addSaaSToolHandler = (index: number) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools.push({
            name: {
                value: "",
                label: "",
            },
            approver: {
                value: "",
                label: "",
            },
        });
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const addUserHandler = () => {
        const newUsers = [...payload.users.value];
        newUsers.push({
            email: "",
            saas_tools: [],
        });
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const removeSaaSToolHandler = (index: number, saas_tool_index: number) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools.splice(saas_tool_index, 1);
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const removeUserHandler = (index: number) => {
        const newUsers = [...payload.users.value];
        newUsers.splice(index, 1);
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const toolChangeHandler = (
        val: any,
        index: number,
        saas_tool_index: number
    ) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools[saas_tool_index].name = val;
        const approver = [
            {
                value: newUsers[index].manager.id,
                label: newUsers[index].manager.middleName
                    ? `${newUsers[index].manager.firstName} ${newUsers[index].manager.middleName} ${newUsers[index].manager.lastName}`
                    : `${newUsers[index].manager.firstName} ${newUsers[index].manager.lastName}`,
            },
        ];
        newUsers[index].saas_tools[saas_tool_index].approver = approver;

        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const toolFocusHandler = (index: number) => () => {
        const newUsers = [...payload.users.value];
        const index = newUsers.findIndex((user: any) => user.email.value === email);
        const assignTools = newUsers[index].assign;
        const saasTools = assignTools.map((tool: any) => ({
            value: tool.toolName,
            label: tool.toolName,
        }));

        newUsers[index].options = saasTools;
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    return (
        <Container className={className} modifiers={modifiers}>
            <div className={`${componentClassName}__form__users`}>
                {payload?.users?.value.map((user: any, index: number) => (
                    <div className={`${componentClassName}__form`} key={index}>
                        <Divider />
                        <div className={`${componentClassName}__form__items`}>
                            <Dropdown
                                className={`${componentClassName}__form__item__dropdown`}
                                options={users?.result?.data.map((user: any) => ({
                                    value: user.email,
                                    label: user?.middleName
                                        ? `${user.firstName} ${user.middleName} ${user.lastName}`
                                        : `${user.firstName} ${user.lastName}`,
                                }))}
                                selected={payload?.users?.value[index]?.email}
                                onClickChange={(val) => changeHandler(val, index)}
                                isRequired
                            />
                        </div>
                        {user?.assign?.length > 0 && (
                            <div className={`${componentClassName}__form__assign`}>
                                <p className={`${componentClassName}__form__assign__label`}>
                                    Assign SaaS Tools
                                </p>
                                <div className={`${componentClassName}__form__assign__tools`}>
                                    {user?.assign?.map((item: any, index: number) => (
                                        <div
                                            className={`${componentClassName}__form__assign__items`}
                                            key={index}
                                        >
                                            <div
                                                className={`${componentClassName}__form__assign__item`}
                                            >
                                                <p
                                                    className={`${componentClassName}__form__assign__item__label`}
                                                >
                                                    SaaS Tool:
                                                </p>
                                                <p
                                                    className={`${componentClassName}__form__assign__item__value`}
                                                >
                                                    {item?.toolName}
                                                </p>
                                            </div>
                                            <div
                                                className={`${componentClassName}__form__assign__item`}
                                            >
                                                <p
                                                    className={`${componentClassName}__form__assign__item__label`}
                                                >
                                                    Role:
                                                </p>
                                                <p
                                                    className={`${componentClassName}__form__assign__item__value`}
                                                >
                                                    {item?.role}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className={`${componentClassName}__form__items__saasTools`}>
                            {user?.saas_tools?.map(
                                (saas_tool: any, saas_tool_index: number) => (
                                    <div
                                        className={`${componentClassName}__form__items__remove`}
                                        key={saas_tool_index}
                                    >
                                        <div
                                            className={`${componentClassName}__form__items`}
                                            key={saas_tool_index}
                                        >
                                            <Dropdown
                                                className={`${componentClassName}__form__item__dropdown`}
                                                options={payload.users.value[index].options}
                                                selected={
                                                    saas_tool?.name?.value === "" ? null : saas_tool?.name
                                                }
                                                placeholder="Select SaaS Tool"
                                                onClickChange={(val) =>
                                                    toolChangeHandler(val, index, saas_tool_index)
                                                }
                                                focusHandler={toolFocusHandler(index)}
                                                isRequired
                                            />

                                            {saas_tool?.approver && (
                                                <Dropdown
                                                    className={`${componentClassName}__form__item__dropdown`}
                                                    options={[]}
                                                    selected={
                                                        saas_tool?.approver.length > 0
                                                            ? saas_tool?.approver
                                                            : null
                                                    }
                                                    isDisabled
                                                    isMulti
                                                    placeholder=""
                                                    onClickChange={(val) => {
                                                        const newUsers = [...payload.users.value];
                                                        newUsers[index].saas_tools[
                                                            saas_tool_index
                                                        ].approver = val;
                                                        setPayload({
                                                            ...payload,
                                                            users: {
                                                                value: newUsers,
                                                                isError: false,
                                                                error: "",
                                                            },
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>

                                        <button
                                            onClick={() =>
                                                removeSaaSToolHandler(index, saas_tool_index)
                                            }
                                            type="button"
                                            className={`${componentClassName}__form__items__remove__button`}
                                        >
                                            <Icon icon="delete" size="primary" />
                                        </button>
                                    </div>
                                )
                            )}
                            {user?.email && (
                                <button
                                    type="button"
                                    onClick={() => addSaaSToolHandler(index)}
                                    className={`${componentClassName}__form__items__saasTools__add`}
                                >
                                    Add SaaS Tool
                                </button>
                            )}
                        </div>
                        <div
                            className={`${componentClassName}__form__items__remove__action`}
                        >
                            {payload?.users?.value?.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => removeUserHandler(index)}
                                    className={`${componentClassName}__form__items__remove__button`}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    </div>
                ))}

                {payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools
                    ?.length > 0 && (
                        <button
                            type="button"
                            onClick={() => addUserHandler()}
                            className={`${componentClassName}__form__items__add`}
                        >
                            Add Another User
                        </button>
                    )}
            </div>
        </Container>
    );
};
