import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import {
    CURRENCY,
    PAGE_NAME,
    PAYMENT_TYPE_ARRAY,
    PAYMENT_TYPE_OPTIONS,
    ROUTE,
    SOMETHING_WENT_WRONG,
    saasToolName,
    saasToolNameForPayment,
} from "../../../../constants/constants";
import { Input } from "../../../atoms/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddPaymentMutation } from "../../../../store/payment/paymentSlice";
import { Dropdown } from "../../../atoms/dropdown";
import {
    DropdownOptionsType,
    ResponseType,
    payloadType,
} from "../../../../types";
import { FileUpload } from "../../../atoms/fileUpload";
import { toast } from "react-toastify";
import { Textarea } from "../../../atoms/textarea";
import { Switch } from "../../../atoms/switch";


const SubscriptionsName = [
    {
        label: "Free",
        value: "Free",
    },
    {
        label: "Premium",
        value: "Premium",
    },
    {
        label: "Business",
        value: "Business",
    },
    {
        label: "Cloud",
        value: "Cloud",
    },
    {
        label: "Cloud Optix",
        value: "Cloud Optix",
    },
    {
        label: "Enterprise",
        value: "Enterprise",
    },
    {
        label: "Enterprise Plus",
        value: "Enterprise Plus",
    },
    {
        label: "Enterprise Cloud",
        value: "Enterprise Cloud",
    },
    {
        label: "Enterprise Trial",
        value: "Enterprise Trial",
    },
    {
        label: "ZTNA",
        value: "ZTNA",
    },
    {
        label: "MDR",
        value: "MDR",
    },
    {
        label: "MDR Server",
        value: "MDR Server",
    },
    {
        label: "As Pay As You Go",
        value: "As Pay As You Go",
    },
    {
        label: "SSO, Adaptive MFA, Universal Directory, Lifecycle Management",
        value: "SSO, Adaptive MFA, Universal Directory, Lifecycle Management",
    },
    {
        label: "N/A",
        value: "N/A",
    }
];

const PaymentCreate = () => {
    const pageClassName = "p-paymentCreate";
    const navigate = useNavigate();
    const url = useLocation();


    const [addApi] = useAddPaymentMutation();

    const initialPayload: payloadType = {
        tool_name: {
            value: "",
            error: "",
            isError: false,
        },
        type: {
            value: "",
            error: "",
            isError: false,
        },
        subscription_type: {
            value: "",
            error: "",
            isError: false,
        },
        start_date: {
            value: "",
            error: "",
            isError: false,
        },
        end_date: {
            value: "",
            error: "",
            isError: false,
        },
        payment_date: {
            value: "",
            error: "",
            isError: false,
        },
        payment_method: {
            value: "",
            error: "",
            isError: false,
        },
        number_of_license: {
            value: "",
            error: "",
            isError: false,
        },
        total_price: {
            value: "",
            error: "",
            isError: false,
        },
        exclude_tax: {
            value: false,
            error: "",
            isError: false,
        },
        currency: {
            value: "",
            error: "",
            isError: false,
        },
        conversion_rate: {
            value: "",
            error: "",
            isError: false,
        },
        file: {
            value: "",
            error: "",
            isError: false,
        },
        remarks: {
            value: "",
            error: "",
            isError: false,
        },
    };

    const [fileName, setFileName] = React.useState("");

    const [payload, setPayload] = React.useState(initialPayload);


    React.useEffect(() => {
        if (url.state?.payment) {
            const payment = url.state?.payment;
            const toolName = saasToolName.find((item) => item.value === payment.toolName)?.value ?? payment.toolName;
            setPayload({
                tool_name: {
                    value: {
                        label: toolName,
                        value: toolName,
                    },
                    error: "",
                    isError: false,
                },
                type: {
                    value: {
                        label:
                            PAYMENT_TYPE_OPTIONS.find(
                                (item) => item.value === payment.type
                            )?.label ?? payment.type,
                        value: payment.type,
                    },
                    error: "",
                    isError: false,
                },
                subscription_type: {
                    value: {
                        label: payment.subscriptionType,
                        value: payment.subscriptionType,
                    },
                    error: "",
                    isError: false,
                },
                start_date: {
                    value: payment.startDate,
                    error: "",
                    isError: false,
                },
                end_date: {
                    value: payment.endDate,
                    error: "",
                    isError: false,
                },
                payment_date: {
                    value: payment.paymentDate,
                    error: "",
                    isError: false,
                },
                payment_method: {
                    value: payment.paymentMethod,
                    error: "",
                    isError: false,
                },
                number_of_license: {
                    value: payment.numberOfLicense,
                    error: "",
                    isError: false,
                },
                total_price: {
                    value: payment.totalAmount,
                    error: "",
                    isError: false,
                },
                exclude_tax: {
                    value: payment.excludeTax,
                    error: "",
                    isError: false,
                },
                currency: {
                    value: {
                        label: payment.currency,
                        value: payment.currency,
                    },
                    error: "",
                    isError: false,
                },
                conversion_rate: {
                    value: payment.conversionRate,
                    error: "",
                    isError: false,
                },
                file: {
                    value: "",
                    error: "",
                    isError: false,
                },
                remarks: {
                    value: payment.remarks,
                    error: "",
                    isError: false,
                },
            });
            // setFileName(payment.file?.fileName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files === null) return;
        setPayload({
            ...payload,
            file: {
                ...payload.file,
                value: e.target.files[0] as File,
            },
        });
        setFileName(e.target.files[0].name);
    };

    const nameChangeHandler = (selected: DropdownOptionsType) => {
        if (["GCP", "AWS"].includes(selected.value as string)) {
            setPayload({
                ...payload,
                tool_name: {
                    ...payload.tool_name,
                    value: selected as { label: string; value: string },
                    isError: false,
                    error: "",
                },
                type: {
                    ...payload.type,
                    value: {
                        label: "Monthly",
                        value: "Monthly",
                    },
                    isError: false,
                    error: "",
                },
                subscription_type: {
                    ...payload.subscription_type,
                    value: {
                        label: "As Pay As You Go",
                        value: "As Pay As You Go",
                    },
                    isError: false,
                    error: "",
                },
            })
        } else {
            setPayload({
                ...payload,
                tool_name: {
                    ...payload.tool_name,
                    value: selected as { label: string; value: string },
                    isError: false,
                    error: "",
                },
                type: {
                    ...payload.type,
                    value: "",
                    isError: false,
                    error: "",
                },
                subscription_type: {
                    ...payload.subscription_type,
                    value: "",
                    isError: false,
                    error: "",
                },
            })
        }
    }

    const PaymentTypeHandler = (selected: DropdownOptionsType) => {
        if (["Monthly"].includes(selected.value as string)) {
            setPayload({
                ...payload,
                type: {
                    ...payload.type,
                    value: selected as { label: string; value: string },
                    isError: false,
                    error: "",
                },
                subscription_type: {
                    ...payload.subscription_type,
                    value: {
                        label: "As Pay As You Go",
                        value: "As Pay As You Go",
                    },
                    isError: false,
                    error: "",
                },
            })
        } else {
            if (["On Demand"].includes(selected.value as string)) {
                setPayload({
                    ...payload,
                    type: {
                        ...payload.type,
                        value: selected as { label: string; value: string },
                        isError: false,
                        error: "",
                    },
                    subscription_type: {
                        ...payload.subscription_type,
                        value: {
                            label: "N/A",
                            value: "N/A",
                        },
                        isError: false,
                        error: "",
                    },
                })

            } else {
                setPayload({
                    ...payload,
                    type: {
                        ...payload.type,
                        value: selected as { label: string; value: string },
                        isError: false,
                        error: "",
                    },
                    subscription_type: {
                        ...payload.subscription_type,
                        value: "",
                        isError: false,
                        error: "",
                    },
                })
            }
        }
    }

    const currencyChangeHandler = (selected: DropdownOptionsType) => {
        setPayload({
            ...payload,
            currency: {
                ...payload.currency,
                value: selected as { label: string; value: string },
                isError: false,
                error: "",
            },
        });
    }

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData();
        payload.tool_name.value &&
            formData.append(
                "tool_name",
                (payload.tool_name.value as DropdownOptionsType).value as string
            );

        payload.type.value &&
            formData.append(
                "type",
                (payload.type.value as DropdownOptionsType).value as string
            );

        [PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[3], PAYMENT_TYPE_ARRAY[4]].includes(
            (payload.type.value as DropdownOptionsType).value as string
        ) &&
            payload.subscription_type.value &&
            formData.append(
                "subscription_type",
                (payload.subscription_type.value as DropdownOptionsType).value as string
            );

        if ([PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[2], PAYMENT_TYPE_ARRAY[3]].includes((payload.type.value as DropdownOptionsType).value as string)) {
            formData.append("start_date", payload.start_date.value as string);
            formData.append("end_date", payload.end_date.value as string);
        }

        formData.append("payment_date", payload.payment_date.value as string);
        formData.append("payment_method", payload.payment_method.value as string);

        [PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[2]].includes(
            (payload.type.value as DropdownOptionsType).value as string
        ) &&
            formData.append(
                "number_of_license",
                payload.number_of_license.value as string
            );
        formData.append("exclude_tax", payload.exclude_tax.value as boolean ? "1" : "0");
        formData.append("total_price", payload.total_price.value as string);
        formData.append("currency", (payload.currency.value as DropdownOptionsType).value as string);
        payload.conversion_rate.value && formData.append("conversion_rate", payload.conversion_rate.value as string)
        formData.append("file", payload.file.value as File);
        formData.append("remarks", payload.remarks.value as string);

        const response = (await addApi(formData)) as ResponseType;
        if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate(ROUTE.PAYMENT_HISTORY);
        } else {
            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                });
            } else {
                toast.error(response?.error?.data?.message);
            }
            if (response?.error?.data?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.PAYMENT_CREATE}</p>
            <div className={`${pageClassName}__container`}>
                <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                    <div className={`${pageClassName}__form__dropdown`}>
                        <label className={`${pageClassName}__form__dropdown__label`}>
                            SaaS/infra Name
                        </label>
                        <Dropdown
                            options={saasToolNameForPayment}
                            onClickChange={nameChangeHandler}
                            placeholder="Please Select a SaaS/Infra Name"
                            isIcon
                            color="black"
                            isBordered
                            selected={payload.tool_name.value as DropdownOptionsType}
                            isError={payload.tool_name.isError}
                            error={payload.tool_name.error}
                        />
                    </div>

                    <div className={`${pageClassName}__form__dropdown`}>
                        <label className={`${pageClassName}__form__dropdown__label`}>
                            Payment Type
                        </label>
                        <Dropdown
                            options={PAYMENT_TYPE_OPTIONS}
                            onClickChange={PaymentTypeHandler}
                            placeholder="Please Select a Payment Type"
                            isIcon
                            color="black"
                            isBordered
                            selected={payload.type.value as DropdownOptionsType}
                            isError={payload.type.isError}
                            error={payload.type.error}
                            isDisabled={["GCP", "AWS"].includes(
                                (payload.tool_name.value as DropdownOptionsType).value as string
                            )}
                        />
                    </div>
                    {[PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[3], PAYMENT_TYPE_ARRAY[4]].includes(
                        (payload.type.value as DropdownOptionsType).value as string
                    ) && (
                            <div className={`${pageClassName}__form__dropdown`}>
                                <label className={`${pageClassName}__form__dropdown__label`}>
                                    Subscription Type
                                </label>
                                <Dropdown
                                    options={SubscriptionsName}
                                    onClickChange={(selected) =>
                                        setPayload({
                                            ...payload,
                                            subscription_type: {
                                                ...payload.subscription_type,
                                                value: selected as { label: string; value: string },
                                                isError: false,
                                                error: "",
                                            },
                                        })
                                    }
                                    placeholder="Please Select a Subscription Type"
                                    isIcon
                                    color="black"
                                    isBordered
                                    selected={
                                        payload.subscription_type.value as DropdownOptionsType
                                    }
                                    isError={payload.subscription_type.isError}
                                    error={payload.subscription_type.error}
                                    isDisabled={["Monthly", "On Demand"].includes(
                                        (payload.type.value as DropdownOptionsType).value as string
                                    )}
                                />
                            </div>
                        )}

                    {[PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[2], PAYMENT_TYPE_ARRAY[3]].includes(
                        (payload.type.value as DropdownOptionsType).value as string
                    ) && (
                            <Input
                                placeholder="Enter Start Date"
                                label="Start Date"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        start_date: {
                                            ...payload.start_date,
                                            value: e.target.value,
                                            isError: false,
                                            error: "",
                                        },
                                    })
                                }
                                value={
                                    payload.start_date.value
                                        ? (new Date(payload.start_date.value as string)
                                            .toISOString()
                                            .substring(0, 10) as string)
                                        : ""
                                }
                                isError={payload.start_date.isError}
                                error={payload.start_date.error}
                                type="date"
                            />
                        )}

                    {[PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[2], PAYMENT_TYPE_ARRAY[3]].includes(
                        (payload.type.value as DropdownOptionsType).value as string
                    ) && (
                            <Input
                                placeholder="Enter End Date"
                                label="End Date"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        end_date: {
                                            ...payload.end_date,
                                            value: e.target.value,
                                            isError: false,
                                            error: "",
                                        },
                                    })
                                }
                                value={
                                    payload.end_date.value
                                        ? (new Date(payload.end_date.value as string)
                                            .toISOString()
                                            .substring(0, 10) as string)
                                        : ""
                                }
                                isError={payload.end_date.isError}
                                error={payload.end_date.error}
                                type="date"
                            />
                        )}

                    <Input
                        placeholder="Enter Payment Date"
                        label="Payment Date"
                        onChange={(e) =>
                            setPayload({
                                ...payload,
                                payment_date: {
                                    ...payload.payment_date,
                                    value: e.target.value,
                                    isError: false,
                                    error: "",
                                },
                            })
                        }
                        value={
                            payload.payment_date.value
                                ? (new Date(payload.payment_date.value as string)
                                    .toISOString()
                                    .substring(0, 10) as string)
                                : ""
                        }
                        isError={payload.payment_date.isError}
                        error={payload.payment_date.error}
                        type="date"
                    />

                    <Input
                        label="Payment Method"
                        type="text"
                        name="payment_method"
                        placeholder="Enter Payment Method"
                        value={payload.payment_method.value as string}
                        error={payload.payment_method.error}
                        isError={payload.payment_method.isError}
                        onChange={(e) =>
                            setPayload({
                                ...payload,
                                payment_method: {
                                    ...payload.payment_method,
                                    value: e.target.value,
                                    isError: false,
                                    error: "",
                                },
                            })
                        }
                    />
                    {[PAYMENT_TYPE_ARRAY[0], PAYMENT_TYPE_ARRAY[1], PAYMENT_TYPE_ARRAY[2]].includes(
                        (payload.type.value as DropdownOptionsType).value as string
                    ) && (
                            <Input
                                label="Number of License"
                                type="number"
                                name="number_of_license"
                                placeholder="Enter Number of License"
                                value={payload.number_of_license.value as number}
                                error={payload.number_of_license.error}
                                isError={payload.number_of_license.isError}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        number_of_license: {
                                            ...payload.number_of_license,
                                            value: e.target.value,
                                            isError: false,
                                            error: "",
                                        },
                                        unit_price: {
                                            ...payload.unit_price,
                                            value: payload.total_price.value
                                                ? (payload.total_price.value as number) /
                                                parseInt(e.target.value)
                                                : "",
                                        },
                                    })
                                }
                            />
                        )}

                    <Input
                        label="Total Price"
                        type="number"
                        name="total_price"
                        placeholder="Enter Total Price"
                        value={payload.total_price.value as number}
                        error={payload.total_price.error}
                        isError={payload.total_price.isError}
                        onChange={(e) =>
                            setPayload({
                                ...payload,
                                total_price: {
                                    ...payload.total_price,
                                    value: e.target.value,
                                    isError: false,
                                    error: "",
                                },
                                unit_price: {
                                    ...payload.unit_price,
                                    value:
                                        parseInt(e.target.value) /
                                        (payload.number_of_license.value as number),
                                },
                            })
                        }
                    />

                    <div className={`${pageClassName}__form__group`}>
                        <div className={`${pageClassName}__form__group__tax`}>
                            <label>Excluded Tax</label>
                            <div className={`${pageClassName}__form__group__content`}>
                                <Switch
                                    color="blue"
                                    onInputChange={(checked) => {
                                        setPayload({
                                            ...payload,
                                            exclude_tax: {
                                                value: checked,
                                                error: "",
                                                isError: false,
                                            },
                                        });
                                    }}
                                    defaultChecked={payload.exclude_tax.value as boolean}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`${pageClassName}__form__dropdown`}>
                        <label className={`${pageClassName}__form__dropdown__label`}>
                            Currency
                        </label>
                        <Dropdown
                            options={CURRENCY}
                            onClickChange={currencyChangeHandler}
                            placeholder="Please Select a Currency"
                            isIcon
                            color="black"
                            isBordered
                            selected={payload.currency.value as DropdownOptionsType}
                            isError={payload.currency.isError}
                            error={payload.currency.error}
                        />
                    </div>
                    {(payload.currency.value as DropdownOptionsType).value === "USD" && (
                        <Input
                            label="Conversion Rate"
                            type="text"
                            name="Conversion Rate"
                            placeholder="Enter Conversion Rate"
                            value={payload.conversion_rate.value as number}
                            error={payload.conversion_rate.error}
                            isError={payload.conversion_rate.isError}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    conversion_rate: {
                                        ...payload.conversion_rate,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    }
                                })
                            }
                        />
                    )}

                    <FileUpload
                        placeholder="Upload Invoice File"
                        label="Invoice File"
                        fileName={fileName}
                        onChange={fileChangeHandler}
                        isError={payload.file.isError}
                        error={payload.file.error}
                    />

                    <Textarea
                        label="Remarks"
                        placeholder="Enter Remarks"
                        value={payload.remarks.value as string}
                        error={payload.remarks.error}
                        isError={payload.remarks.isError}
                        onChange={(e) =>
                            setPayload({
                                ...payload,
                                remarks: {
                                    ...payload.remarks,
                                    value: e.target.value,
                                    isError: false,
                                    error: "",
                                },
                            })
                        }
                    />

                    <div className={`${pageClassName}__action`}>
                        <button
                            className={`${pageClassName}__action__button`}
                            type="submit"
                        >
                            Save
                        </button>
                        <button
                            className={`${pageClassName}__action__button ${pageClassName}__action__cancel`}
                            type="button"
                            onClick={() => navigate(ROUTE.PAYMENT_HISTORY)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PaymentCreate;
