import React from "react";
import { Chart } from "react-google-charts";


interface BubbleChartProps {
    data: any[];
    options: any;
    isLoading?: boolean;
}

export const BubbleChart = ({ data, options, isLoading }: BubbleChartProps) => {
    const [chartSize, setChartSize] = React.useState({ width: 0, height: 0 });
    const [chartKey, setChartKey] = React.useState(0);

    React.useEffect(() => {
        const handleResize = () => {
            const chartContainer = document.querySelector(
                ".m-chart"
            ) as HTMLElement;
            if (chartContainer) {
                setChartSize({
                    width: chartContainer.offsetWidth,
                    height: chartContainer.offsetHeight,
                });
                setChartKey((prevKey) => prevKey + 1);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="m-chart">
            {isLoading ? (
                <div className="m-chart__skelton"></div>
            ) : (
                data.length ? (
                    <Chart
                        key={chartKey}
                        chartType="BubbleChart"
                        width={chartSize.width}
                        height={chartSize.height}
                        data={data}
                        options={options}
                    />
                ) : (
                    <p className="m-chart__nodata">No Data Found</p>
                )
            )}
        </div>
    );
}
