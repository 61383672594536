import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "activityReportList",
    "gcpProjectBillingList",
    "awsBillingList",
    "awsBillingReport",
    "miroBoardReportByProject"
  ],
});

export const reportSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves the activity report list from the server.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param duration - The duration of the activity report.
     * @returns A promise that resolves to the activity report list.
     */
    activityReportList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, duration,tool_names }) => ({
        url:
          API_URL.REPORTS_ACTIVITY +
          "?" +
          `${limit ? "limit=" + limit : ""}${current_page ? "&current_page=" + current_page : ""
          }${search ? "&search=" + search : ""}${duration ? "&duration=" + duration : ""
          }${tool_names ? "&tool_names=" + tool_names : ""}`,
        method: "GET",
      }),
      providesTags: ["activityReportList"],
    }),

    /**
     * Fetches the activity report days list from the server.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of items to retrieve.
     * @param current_page - The current page number.
     * @returns A promise that resolves to the response data.
     */
    activityReportDaysList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page }) => ({
        url:
          API_URL.REPORTS_ACTIVITY_DAYS +
          "?" +
          `${limit ? "limit=" + limit : ""}${current_page ? "&current_page=" + current_page : ""
          }${search ? "&search=" + search : ""}`,
        method: "GET",
      }),
      providesTags: ["activityReportList"],
    }),

    /**
     * Query function to fetch GCP project billing information.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param invoice_month - The month of the invoice.
     * @param projects - The projects to filter the results by.
     * @returns A promise that resolves to the response data.
     */
    gcpProjectBillingList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        invoice_month,
        projects,
      }) => ({
        url:
          API_URL.GCP_PROJECT_BILLING_INFO +
          "?" +
          `${limit ? "limit=" + limit : ""}${current_page ? "&current_page=" + current_page : ""
          }${search ? "&search=" + search : ""}${sort_by ? "&sort_by=" + sort_by : ""
          }${sort_direction ? "&sort_direction=" + sort_direction : ""}${invoice_month ? "&invoice_month=" + invoice_month : ""
          }${projects ? "&projects=" + projects : ""}`,
        method: "GET",
      }),
      providesTags: ["gcpProjectBillingList"],
    }),

    /**
     * Query function to fetch AWS billing information.
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param month - The month of the invoice.
     * @param year - The year of the invoice.
     */

    awsBillingList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        month,
        year,
      }) => ({
        url:
          API_URL.AWS_BILLING_INFO +
          "?" +
          `${limit ? "limit=" + limit : ""}${current_page ? "&current_page=" + current_page : ""
          }${search ? "&search=" + search : ""}${sort_by ? "&sort_by=" + sort_by : ""
          }${sort_direction ? "&sort_direction=" + sort_direction : ""}${month ? "&month=" + month : ""
          }${year ? "&year=" + year : ""}`,
        method: "GET",
      }),
      providesTags: ["awsBillingList"],
    }),

    /**
     * Query function to fetch AWS billing report.
     * @param month - The month of the invoice.
     * @param year - The year of the invoice.
     */

    awsBillingReport: builder.query<ResponseDataType, listDataType>({
      query: ({ month, year }) => ({
        url:
          API_URL.AWS_BILLING_REPORT +
          "?" +
          `${month ? "&month=" + month : ""}${year ? "&year=" + year : ""}`,
        method: "GET",
      }),
      providesTags: ["awsBillingReport"],
    }),

    miroBoardReportByProject: builder.query<ResponseDataType, void>({
      query: () => ({
        url: API_URL.MIRO_BOARD_REPORT_BY_PROJECT,
        method: "GET",
      }),
      providesTags: ["miroBoardReportByProject"],
    }),
  }),
});

export const {
  useActivityReportListQuery,
  useActivityReportDaysListQuery,
  useGcpProjectBillingListQuery,
  useAwsBillingListQuery,
  useAwsBillingReportQuery,
  useMiroBoardReportByProjectQuery,
} = reportSlice;
