import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganizationDeleteMutation, useOrganizationDetailsQuery } from "../../../../store/organization/organizationSlice";
import { Skeleton } from "../../../atoms/skeleton";
import { formattedDateTime, hasPermission } from "../../../../utils";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Icon } from "../../../atoms/icon";
import { Dialog } from "../../../atoms/dialog";
import { toast } from "react-toastify";
import { PAGE_NAME, PERMISSIONS, ROUTE } from "../../../../constants/constants";

const OrganizationDetails = () => {
    const pageClassName = "p-organizationDetails";
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState<number>();

    const { data, isSuccess, isLoading } = useOrganizationDetailsQuery(id as unknown as number);
    const [deleteApi] = useOrganizationDeleteMutation();

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "first Name",
            dataIndex: "firstName",
            isSortable: false,
        },
        {
            title: "last Name",
            dataIndex: "lastName",
            isSortable: false,
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Status",
            dataIndex: "status",
            isSortable: false,
        },
        {
            title: "Saas Tools",
            dataIndex: "toolNames",
            isSortable: false,
        },
    ];

    const removeHandler = (id: string) => {
        setIsDialogOpen(!isDialogOpen);
        setDeleteId(parseInt(id));
    };

    const deleteHandler = async () => {
        setIsDialogOpen(!isDialogOpen);
        let response = await deleteApi(deleteId as number) as any;

        if (response?.data?.success) {
            navigate(ROUTE.ORGANIZATION_LIST);
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.error?.data?.message);
        }
    };

    const editHandler = (id: string) => {
        navigate(ROUTE.ORGANIZATION_EDIT + `/${id}`);
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.ORGANIZATION_INFO}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Name</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? data?.result?.name : <Skeleton height="small" />}
                    </p>
                </div>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>State</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? (
                            data?.result?.state ?? "-"
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Country</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? data?.result?.country : <Skeleton height="small" />}
                    </p>
                </div>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Email Extension</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? data?.result?.emailExt : <Skeleton height="small" />}
                    </p>
                </div>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Number of Members
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? (
                            data?.result?.numberOfOrgMember ?? 0
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Okta Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? data?.result?.okta ?? 0 : <Skeleton height="small" />}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Youtrack Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? (
                            data?.result?.youtrack ?? 0
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Box Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? data?.result?.box ?? 0 : <Skeleton height="small" />}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Confluence Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? (
                            data?.result?.confluence ?? 0
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Atlassian Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? (
                            data?.result?.atlassian ?? 0
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Gitlab Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? (
                            data?.result?.gitlab ?? 0
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Miro Users
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isSuccess ? data?.result?.miro ?? 0 : <Skeleton height="small" />}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Created At
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {data?.result?.createdAt ? (
                            formattedDateTime(data?.result?.createdAt)
                        ) : (
                            <Skeleton height="small" />
                        )}
                    </p>
                </div>
                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Action
                    </p>
                    <div className={`${pageClassName}__container__item__action`}>
                        {hasPermission(PERMISSIONS.ORGANIZATION_EDIT.title) && (
                            <button
                                title='Edit'

                                className={`${pageClassName}__container__item__action__button ${pageClassName}__container__item__action__edit`}
                                onClick={() => editHandler(data?.result?.id)}
                            >
                                <Icon icon="edit" size="secondary" />
                            </button>
                        )}
                        {hasPermission(PERMISSIONS.ORGANIZATION_DELETE.title) && (
                        <button
                            title='Delete'
                            className={`${pageClassName}__container__item__action__button`}
                            onClick={() => removeHandler(data?.result?.id)}
                        >
                            <Icon icon="cross" size="secondary" />
                        </button>
                        )}
                    </div>
                </div>


                {data?.result?.organizationMember?.length > 0 && (
                    <>
                        <Divider />
                        <div className={`${pageClassName}__table`}>
                            <p className={`${pageClassName}__table__title`}>{data?.result?.name}'s Member</p>
                            <Table
                                header={tableHeader}
                                data={data?.result?.organizationMember ?? []}
                                loading={isLoading}
                                firstLoad={isLoading}
                                startIndex={1}
                            />
                        </div>
                    </>
                )}
            </div>
            <Dialog
                header="Delete Organization"
                body={
                    <div className={`${pageClassName}__dialog`}>
                        <p className={`${pageClassName}__dialog__text`}>
                            Are you sure you want to delete this organization?
                        </p>
                        <p className={`${pageClassName}__dialog__text`}>
                            This action cannot be undone.
                        </p>
                    </div>
                }
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(!isDialogOpen)}
                successFn={deleteHandler}
                btn="Delete"
                icon="delete"
            />
        </div>
    );
};

export default OrganizationDetails;
