import React from "react";
import { useGitlabGroupStorageComparisonQuery } from "../../../../store/saasTool/saasToolSlice";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Table } from "../../../atoms/table";
import { Dropdown } from "../../../atoms/dropdown";
import { DropdownOptionsType } from "../../../../types";
import { PAGE_NAME } from "../../../../constants/constants";

const GroupStorageComparison = () => {
    const pageClassName = "p-group-storage-comparison";
    const [weeks, setWeeks] = React.useState({
        label: "4 Weeks",
        value: 4,
    });

    const [tableHeader, setTableHeader] = React.useState<any[]>([
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "Group Name",
            dataIndex: "name",
            isSortable: false,
        },
        {
            title: "Week",
            dataIndex: "week1",
            isSortable: false,
        },
        {
            title: "Week",
            dataIndex: "week2",
            isSortable: false,
        },
        {
            title: "Week",
            dataIndex: "week3",
            isSortable: false,
        },
        {
            title: "Week",
            dataIndex: "week4",
            isSortable: false,
        },
    ]);

    // const { search, query, sortHandler, searchHandler } = useTableHooks();

    const { data, isLoading } = useGitlabGroupStorageComparisonQuery({
        weeks: weeks.value,
    });

    React.useEffect(() => {
        if (data) {
            const header = data?.result?.tableHeader?.map((item: any) => {
                return {
                    title: item?.title,
                    dataIndex: item?.dataIndex,
                    isSortable: false,
                };
            });

            setTableHeader([
                {
                    title: "Serial",
                    dataIndex: "id",
                    isSortable: false,
                },
                {
                    title: "Group Name",
                    dataIndex: "name",
                    isSortable: false,
                },
                ...header,
            ]);
        }
    }, [data]);

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.GITLAB_GROUP_STORAGE_COMPARISON}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__dropdown`}>
                    <div className={`${pageClassName}__dropdown__container`}>
                        <label>Filter by</label>
                        <Dropdown
                            options={[
                                { label: "4 Weeks", value: 4 },
                                { label: "6 Weeks", value: 6 },
                                { label: "8 Weeks", value: 8 },
                            ]}
                            selected={weeks}
                            onClickChange={(value: DropdownOptionsType) =>
                                setWeeks({
                                    label: value?.label as string,
                                    value: value?.value as number,
                                })
                            }
                            placeholder="Select weeks"
                            isBordered
                            isIcon
                        />
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={() => {}}
                        // viewHandler={(id) => navigate(`/user-data-list/${id}`)}
                        minWidth="1000"
                        startIndex={1}
                    />
                </div>
            </div>
        </div>
    );
};

export default GroupStorageComparison;
