import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { useGcpProjectBillingListQuery } from "../../../../store/reports/reportSlice";
import {
    convertToMonthYear,
    numberFormat,
    pascalToSnakeCase,
} from "../../../../utils";
import { TableDataType } from "../../../../types";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { PAGE_NAME, ROUTE } from "../../../../constants/constants";
import { Icon } from "../../../atoms/icon";
import { MultipleSelect } from "../../../atoms/multipleSelect";

const GCPProjectBilling = () => {
    const pageClassName = "p-gcpProjectBilling";
    const navigate = useNavigate();

    const ref = React.useRef<HTMLDivElement>(null);
    const filterRef = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);
    const [isShowDropdown, setShowDropdown] = React.useState(false);
    const [projects, setProjects] = React.useState<
        { label: string; value: string }[]
    >([]);
    const [dropdownOptions, setDropdownOptions] = React.useState<
        { label: string; value: string }[]
    >([]);
    const [monthsOptions, setMonthsOptions] = React.useState<
        { label: string; value: string }[]
    >([]);
    const [selectedMonth, setSelectedMonth] = React.useState<{
        label: string;
        value: string;
    }>({ label: "All", value: "All" });
    const [filter, setFilter] = React.useState<{
        projects: string;
        billingMonth: string;
    }>({
        projects: "",
        billingMonth: "All",
    });

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading, isSuccess, isFetching } =
        useGcpProjectBillingListQuery({
            search: query.search,
            limit: query.limit,
            current_page: query.current,
            sort_by: pascalToSnakeCase(query.sortBy),
            sort_direction: query.sortDirection,
            invoice_month:
                filter.billingMonth === "All" ? undefined : filter.billingMonth,
            projects: filter.projects ? filter.projects : [],
        });

    React.useEffect(() => {
        if (data?.result?.projects) {
            const options = data?.result?.projects.map((item: string) => {
                return {
                    label: item,
                    value: item,
                };
            });
            setDropdownOptions(options);
        }

        if (data?.result?.months) {
            const options = data?.result?.months.map((item: number) => {
                return {
                    label: convertToMonthYear(item),
                    value: item,
                };
            });
            setMonthsOptions([{ label: "All", value: "All" }, ...options]);
        }
    }, [data?.result?.projects, data?.result?.months]);

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Billing Date",
            dataIndex: "billingDate",
            isSortable: true,
        },
        {
            title: "Cost",
            dataIndex: "cost",
            isSortable: true,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string, e: any) => {
        const name = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        ).name;
        navigate(ROUTE.GCP_PROJECT_BILLING_INFO + `/${id}`, { state: { name } });
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }

            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const dropdownHandler = (
        value: {
            label: string;
            value: string;
        }[]
    ) => {
        setProjects(value);
    };

    const filterHandler = () => {
        setFilter({
            projects: JSON.stringify(projects.map((item) => item.value)),
            billingMonth: selectedMonth.value,
        });
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <div className={`${pageClassName}__card`}>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>Total Cost</p>
                        <p className={`${pageClassName}__card__value`}>
                            {numberFormat(data?.result?.totalCost ?? 0, "JPY")}
                        </p>
                    </div>
                    <Icon icon="moneyBundle" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Total Monthly Cost
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {numberFormat(data?.result?.currentMonthCost ?? 0, "JPY")}
                        </p>
                    </div>
                    <Icon icon="money" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Number of Projects
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess ? data?.result?.projects.length : 0}
                        </p>
                    </div>
                    <Icon icon="organization" size="tertiary" />
                </div>
            </div>
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.GCP_PROJECT_BILLING_INFO}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>

                    {filter.projects && (JSON.parse(filter.projects).length > 0 ||
                        filter.billingMonth !== "All") && (
                            <p className={`${pageClassName}__header__title`}>
                                Filtered By{" "}
                                {filter.projects
                                    ? "Projects: " + JSON.parse(filter.projects)
                                        .map((item: any) => item)
                                        .join(", ")
                                    : ""}
                                {filter.billingMonth !== "All" &&
                                    ` Billing Month: ${convertToMonthYear(
                                        parseInt(filter.billingMonth)
                                    )}`}
                            </p>
                        )}

                    <div className={`${pageClassName}__filter`} ref={filterRef}>
                        <button
                            onClick={() => setShowFilter(!isShowFilter)}
                            className={`${pageClassName}__filter__button`}
                            title="Filter"
                        >
                            <Icon icon="filter" size="primary" />
                        </button>
                        {isShowFilter && (
                            <div className={`${pageClassName}__filter__container`}>
                                <div className={`${pageClassName}__filter__item`} ref={ref}>
                                    <label>Projects</label>
                                    <MultipleSelect
                                        defaultSelected={projects}
                                        defaultOptions={dropdownOptions}
                                        onChangeHandler={dropdownHandler}
                                        className={`${pageClassName}__filter__dropdown`}
                                    >
                                        <p
                                            className={`${pageClassName}__filter__text`}
                                            onClick={() => setShowDropdown(!isShowDropdown)}
                                        >
                                            {projects.length > 0
                                                ? `${projects.length} out of ${dropdownOptions.length} projects`
                                                : `All projects (${dropdownOptions.length})`}
                                        </p>
                                    </MultipleSelect>
                                </div>
                                <div className={`${pageClassName}__filter__item`}>
                                    <label htmlFor="filter">Billing Month</label>
                                    <Dropdown
                                        options={monthsOptions}
                                        selected={selectedMonth}
                                        onClickChange={(e) =>
                                            setSelectedMonth(e as { label: string; value: string })
                                        }
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                                <Divider />
                                <div className={`${pageClassName}__filter__btn`}>
                                    <button
                                        onClick={() => {
                                            setSelectedMonth({ label: "All", value: "All" });
                                            setProjects([]);
                                            handleFirstPage();
                                        }}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowFilter(false);
                                            filterHandler();
                                            handleFirstPage();
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
                <div className={`${pageClassName}__cost`}>
                    <p className={`${pageClassName}__cost__title`}>Total :</p>
                    <p className={`${pageClassName}__cost__value`}>
                        ¥ {isSuccess ? numberFormat(data?.result?.totalQueryCost) : 0}
                    </p>
                </div>
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GCPProjectBilling;
