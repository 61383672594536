import React from 'react'
import { createComponentBase, BaseComponentProps } from '../../../libs/components';
import { Link, useLocation } from 'react-router-dom'



const removeHyphen = (str = '') => {
  return str.replace(/-/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const PageTitle = () => {
  const pathnames = window.location.pathname.split('/').filter((x) => x)
  const title = removeHyphen(pathnames[0])
  return (
    <span>{title}</span>
  )
}

export interface BreadcrumbProps extends BaseComponentProps {
  className?: string
}

export const Breadcrumb = ({
  className
}: BreadcrumbProps) => {
  const componentClassName = 'a-dashboardBreadcrumb'
  const modifiers = {}
  const { Container } = createComponentBase(componentClassName, 'ol')

  const [breadcrumbs, setBreadcrumbs] = React.useState<
    {
      path: string
      name: string
    }[]
  >([])

  const { state } = useLocation();
  const name = state ? (state as { name: string }).name : undefined;


  React.useEffect(() => {
    const pathnames = window.location.pathname.split('/').filter((x) => x)
    if (pathnames.length !== 0) {
      pathnames.unshift('dashboard')
    }
    const paths = pathnames.map((path, i) => ({
      name: removeHyphen(path),
      path: path === 'dashboard' ? '/' : `/${pathnames.slice(0, i + 1).join('/')}`
    }))
    setBreadcrumbs(paths)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

const nameFormate = (url: string) => {
   const splitName = url.split(' ');
   return splitName.map((item, index) => {
      if ([ 'Ur&am', 'Api', 'Gcp', 'Aws'].includes(item)) {
        return item.toUpperCase();
      }
      return item.charAt(0).toUpperCase() + item.slice(1);
   }).join(' ');
}

  return (
    <Container className={className} modifiers={modifiers}>
      <ol className={`${componentClassName}__container`}>
        {breadcrumbs.map((item, index) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <li
                key={item.path}
                className={`${componentClassName}__container__item ${componentClassName}__container__item--active`}
              >
                {name ? name : nameFormate(item.name)}
              </li>
            )
          } else {
            return (
              <React.Fragment key={item.path}>
                <li className={`${componentClassName}__container__item`}>
                  <Link to={item.path.replace('/dashboard', '')}>{nameFormate(item.name)}</Link>
                </li>
                <p className={`${componentClassName}__container__separator`}>&#x226B;</p>
              </React.Fragment>
            )
          }
        })}
      </ol>
    </Container>
  )
}
