import React, { useState, useEffect } from "react";
import { Chart, ChartWrapperOptions } from "react-google-charts";

interface ComboChartProps {
  data: any;
  options: ChartWrapperOptions["options"];
  isLoading?: boolean;
  style?: React.CSSProperties;
}

export const ComboChart = ({
  data,
  options,
  isLoading,
  style,
}: ComboChartProps) => {
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const [chartKey, setChartKey] = React.useState(0);

  useEffect(() => {
    const handleResize = () => {
      const chartContainer = document.querySelector(
        ".m-chart__container"
      ) as HTMLElement;
      if (chartContainer) {
        setChartSize({
          width: chartContainer.offsetWidth,
          height: chartContainer.offsetHeight,
        });
        setChartKey((prevKey) => prevKey + 1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="m-chart__container">
      {isLoading ? (
        <div className="m-chart__skelton"></div>
      ) : (
        data.length ? (
          <Chart
            key={chartKey}
            chartType="ComboChart"
            width={chartSize.width}
            height={chartSize.height}
            style={style}
            data={data}
            options={options}
          />
        ) : (
          <p className="m-chart__nodata">No Data Found</p>
        )
      )}
    </div>
  );
};
