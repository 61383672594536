import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { Status } from "../../../../atoms/status";
import { formattedDateTime } from "../../../../../utils";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { useSingleSaasToolQuery } from "../../../../../store/saasTool/saasToolSlice";
import { PAGE_NAME } from "../../../../../constants/constants";

const YouTrackUserDetails = () => {
  const pageClassName = "p-youTrackUserDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useSingleSaasToolQuery({ id });

  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "Project Id",
      dataIndex: "toolProjectId",
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: false,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: false,
    }
  ];


  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.YOUTRACK_USER_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            First Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? <Skeleton height="small" /> : data?.result?.oktaUser?.firstNam}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? <Skeleton height="small" /> : data?.result?.oktaUser?.lastName}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Email</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? <Skeleton height="small" /> : data?.result?.oktaUser?.email}
          </p>
        </div>
        {data?.result?.oktaUser?.secondEmail && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Second Email
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.secondEmail}
            </p>
          </div>
        )}
        {data?.result?.oktaUser?.mobilePhone && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Mobile Phone
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.mobilePhone}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Status</p>
          {isLoading ? <Skeleton height="small" /> : <Status status={data?.result?.status} /> }
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.oktaUser.created ? formattedDateTime(data?.result?.created) : <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Login Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.lastLogin ? formattedDateTime(data?.result?.lastLogin) : '-'}
          </p>
        </div>

        {data?.result?.projects?.length !== 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Projects</p>

              <Table
                header={appsTableHeader}
                data={data?.result?.projects ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                sortHandler={() => { }}
                startIndex={1}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default YouTrackUserDetails;