import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { Status } from "../../../../atoms/status";
import { formattedDateTime } from "../../../../../utils";
import {
  useSaasToolUserGroupListQuery,
  useSingleSaasToolQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { DataTable } from "../../../../organisms/dataTable";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";

const AWSUserDetails = () => {
  const pageClassName = "p-awsUserDetails";
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const { data, isSuccess } = useSingleSaasToolQuery({ id });

  const projectTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Group Id",
      dataIndex: "groupId",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "groupName",
      isSortable: true,
    }
  ];

  const viewHandler = (id: string, response: any) => {
    const viewId = response?.result?.data?.find((item: any) => item.id === id).group.id;
    navigate(ROUTE.GCP_GROUP_LIST + `${viewId}`);
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.AWS_USER_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            First Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.firstName ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.lastName ?? "-"
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Email</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.oktaUser?.email
              ? data?.result?.oktaUser?.email
              : data?.result?.email ?? <Skeleton height="small" />}
          </p>
        </div>
        {data?.result?.oktaUser?.secondEmail && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Second Email
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.secondEmail}
            </p>
          </div>
        )}
        {data?.result?.oktaUser?.mobilePhone && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Mobile Phone
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.oktaUser?.mobilePhone}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Status</p>
          {data?.result?.status ? (
            <Status status={data?.result?.status} />
          ) : (
            <Skeleton height="small" />
          )}
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.oktaUser?.created ? (
                formattedDateTime(data?.result?.created)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Login Date
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.lastLogin ? (
                formattedDateTime(data?.result?.lastLogin)
              ) : (
                "-"
              )
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <DataTable
          title="Groups"
          id={id as string}
          toolName="AWS"
          tableHeader={projectTableHeader}
          useCallApiQuery={useSaasToolUserGroupListQuery}
          viewHandler={viewHandler}
          cursorPointer
        />
      </div>
    </div>
  );
};

export default AWSUserDetails;
