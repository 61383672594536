import React from "react";
import logo from "../../../../assets/logo/logo.png";
// import { Input } from "../../../atoms/input";
// import { useLoginMutation } from "../../../../store/auth/authSlice";
// import { useDispatch } from "react-redux";
// import { userActions } from "../../../../store/auth/user";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { Icon } from "../../../atoms/icon";

const Login = () => {
  const pageClassName = "p-login";
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const [showPassword, setShowPassword] = React.useState(false);
  // const [payload, setPayload] = React.useState({
  //   email: {
  //     value: "",
  //     error: "",
  //     isError: false,
  //   },
  //   password: {
  //     value: "",
  //     error: "",
  //     isError: false,
  //   },
  // });

  // const [loginApi, { isLoading }] = useLoginMutation();

  // const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   const toastId = toast.loading("Loading...");
  //   const formData = new FormData();

  //   formData.append("email", payload.email.value);
  //   formData.append("password", payload.password.value);

  //   const response = (await loginApi(formData)) as any;
  //   if (response?.data?.success) {
  //     if (response?.data?.result?.user?.isAgreeTerms) {
  //       dispatch(userActions.setUser(response.data.result.user));
  //       dispatch(userActions.setToken(response.data.result.token));

  //       toast.update(toastId, {
  //         render: response?.data?.message,
  //         type: toast.TYPE.SUCCESS,
  //         isLoading: isLoading,
  //         autoClose: 2000,
  //         closeButton:true ,
  //       });

  //       navigate("/");
  //     } else {
  //       toast.update(toastId, {
  //         render: response?.data?.message,
  //         type: toast.TYPE.SUCCESS,
  //         isLoading: isLoading,
  //         autoClose: 2000,
  //       });
  //       navigate("/privacy-policy", {
  //         state: {
  //           user: {
  //             email: payload.email.value,
  //             password: payload.password.value,
  //             provider: response?.data?.result?.user?.provider,
  //           }
  //         }
  //       });
  //     }
  //   } else {
  //     if (response?.error?.data?.errors) {
  //       if (response?.error?.data?.errors.email) {
  //         setPayload({
  //           ...payload,
  //           email: {
  //             ...payload.email,
  //             error: response?.error?.data?.errors.email[0],
  //             isError: true,
  //           },
  //         });
  //       }
  //       if (response?.error?.data?.errors.password) {
  //         setPayload({
  //           ...payload,
  //           password: {
  //             ...payload.password,
  //             error: response?.error?.data?.errors.password[0],
  //             isError: true,
  //           },
  //         });
  //       }
  //       toast.update(toastId, {
  //         render: response?.error.data.message,
  //         type: toast.TYPE.ERROR,
  //         isLoading: isLoading,
  //         autoClose: 2000,
  //       });
  //     } else {
  //       toast.update(toastId, {
  //         render: response?.error.data?.message,
  //         type: toast.TYPE.ERROR,
  //         isLoading: isLoading,
  //         autoClose: 2000,
  //       });
  //     }
  //     if (response?.error?.error) {
  //       toast.update(toastId, {
  //         render: SOMETHING_WENT_WRONG,
  //         type: toast.TYPE.ERROR,
  //         isLoading: isLoading,
  //         autoClose: 2000,
  //       });
  //     }
  //   }
  // };

  return (
    <div className={pageClassName}>
      <div className={`${pageClassName}__body`}>
        <div className={`${pageClassName}__body__container`}>
          <div className={`${pageClassName}__body__logo`}>
            <img src={logo} alt="logo" />
          </div>

          <div className={`${pageClassName}__body__form`}>
            <h1 className={`${pageClassName}__body__form__title`}>Sign In to Your Account</h1>
            <a
              href={process.env.REACT_APP_OKTA_LOGIN_URL as string}
              className={`${pageClassName}__body__form__btn`}
            >
              <Icon icon="okta" size="primary" className={`${pageClassName}__body__form__btn__icon`} />

              Sign In with Okta
            </a>
          </div>

          {/* <form
            className={`${pageClassName}__body__form`}
            onSubmit={submitHandler}
          >
            <h1 className={`${pageClassName}__body__form__title`}>Login</h1>
            <Input
              label="Email"
              type="email"
              placeholder="Email"
              onChange={(e) =>
                setPayload({
                  ...payload,
                  email: { value: e.target.value, error: "", isError: false },
                })
              }
              error={payload.email.error}
              isError={payload.email.isError}
            />
            <Input
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              showPassword={showPassword}
              showPasswordHandler={() => setShowPassword(!showPassword)}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  password: {
                    value: e.target.value,
                    error: "",
                    isError: false,
                  },
                })
              }
              error={payload.password.error}
              isError={payload.password.isError}
            />
            <button
              type="submit"
              className={`${pageClassName}__body__form__button`}
            >
              Login
            </button>
            <button
              type="button"
              className={`${pageClassName}__body__form__forgot`}
            >
              Forgot Password ?
            </button>
          </form> */}
        </div>
      </div>
    </div>
  );
};
export default Login;
