import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";
import { Icon } from "../icon";
import { Tooltip } from "../tooltip";
export interface inputProps extends BaseComponentProps {
  value?: string | number;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: "password" | "email" | "number" | "text" | "date"
  showPassword?: boolean;
  showPasswordHandler?: () => void;
  label?: string;
  height?: "small" | "medium" | "large";
  borderRadius?: "small";
  error?: string;
  isError?: boolean;
  readonly?: boolean;
  isRequired?: boolean;
  isStar?: boolean;
}

export const Input = ({
  value,
  name,
  onChange,
  placeholder,
  type = "text",
  showPassword,
  showPasswordHandler = () => {},
  label,
  height,
  borderRadius,
  error,
  isError,
  readonly,
  isRequired = false,
  isStar,
  className,
}: inputProps) => {
  const componentClassName = "a-input";
  const modifiers = {
    [borderRadius + "BorderRadius"]: !!borderRadius,
    [height + "Height"]: !!height,
    [isError ? "error" : ""]: true,
    'readonly': readonly,
  };
  const { Container } = createComponentBase(componentClassName, "div");

  return (
    <Container className={className} modifiers={modifiers}>
      {label && (
        <div className={`${componentClassName}__required`}>
          <p className={`${componentClassName}__label`}>{label}</p>
          {isRequired && !isStar && (
            <div className={`${componentClassName}__required__icon`}>
              <Icon icon="star" size="medium"/>
            </div>
          )}
        </div>
      )}
      <Tooltip message={error} isVisible={isError}>
        <div className={`${componentClassName}__input__container`}>
          <input
            className={`${componentClassName}__input`}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            type={type}
            readOnly={readonly}
            onWheel={(e) => e.currentTarget.blur()}
            required={isRequired}
          />
          <div
            className={`${componentClassName}__icon`}
            onClick={showPasswordHandler}
          >
            {(type === "password" || showPassword) &&
              (showPassword ? <Icon icon="view" /> : <Icon icon="viewHide" />)}
          </div>
        </div>
      </Tooltip>
    </Container>
  );
};
