import { API_URL } from "../../constants/constants";
import { ResponseDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["cardData", "groupData"],
});

/**
 * Creates and configures the dashboard slice using the `apiWithTags.injectEndpoints` function.
 * The `dashboardSlice` function defines the endpoints for fetching card data and group data.
 *
 * @returns The configured dashboard slice.
 */
export const dashboardSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * Fetches the card data from the API.
         * The `getCardData` query is used to fetch the card data from the API.
         * The `providesTags` option is used to specify that the `cardData` tag should be invalidated when the data is refetched.
         * The `query` function returns the URL and method for the API request.
         * The `url` is constructed using the `API_URL.DASHBOARD` and `API_URL.CARD_DATA` constants.
         * 
         */
        getCardData: builder.query<ResponseDataType, void>({
            query: () => ({
                url: API_URL.DASHBOARD + API_URL.CARD_DATA,
                method: "GET",
            }),
            providesTags: ["cardData"],
        }),
        /**
         * Fetches the group data from the API.
         * The `getGroupData` query is used to fetch the group data from the API.
         * The `providesTags` option is used to specify that the `groupData` tag should be invalidated when the data is refetched.
         * The `query` function returns the URL and method for the API request.
         * The `url` is constructed using the `API_URL.DASHBOARD` and `API_URL.GROUP_DATA` constants.
         */
        getGroupData: builder.query<ResponseDataType, void>({
            query: () => ({
                url: API_URL.DASHBOARD + API_URL.GROUP_DATA,
                method: "GET",
            }),
            providesTags: ["groupData"],
        }),
    }),
});

export const {
    useGetCardDataQuery,
    useGetGroupDataQuery,
} = dashboardSlice;
