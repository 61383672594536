import React from "react";
import {
    useApiKeyListQuery,
    useCreateApiKeyMutation,
} from "../../../../store/apiKey/apiKeySlice";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { pascalToSnakeCase } from "../../../../utils";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { Modal } from "../../../molecules/modal";
import { Loader } from "../../../atoms/loader";
import { payloadType } from "../../../../types";
import { toast } from "react-toastify";
import { DatePicker } from "../../../atoms/datePicker";
import { SOMETHING_WENT_WRONG } from "../../../../constants/constants";

const ApiKeyList = () => {
    const pageClassName = "p-apiKeyList";

    const initialState: payloadType = {
        tool_name: {
            value: "",
            error: "",
            isError: false,
        },
        api_key: {
            value: "",
            error: "",
            isError: false,
        },
        organization_id: {
            value: "",
            error: "",
            isError: false,
        },
        expires_at: {
            value: "",
            error: "",
            isError: false,
        },
    };

    const toolName = [
        // {
        //     label: "Okta",
        //     value: "Okta",
        // },
        // {
        //     label: "Jira",
        //     value: "Jira",
        // },
        // {
        //     label: "GitLab",
        //     value: "GitLab",
        // },
        {
            label: "Confluence",
            value: "Confluence",
        },
        // {
        //     label: "YouTrack",
        //     value: "Youtrack",
        // },
        // {
        //     label: "Slack",
        //     value: "Slack",
        // },
        // {
        //     label: "AnyDesk",
        //     value: "AnyDesk",
        // },
        // {
        //     label: "Miro",
        //     value: "Miro",
        // },
        // {
        //     label: "GCP",
        //     value: "GCP",
        // },
    ];

    const [createApi,{isLoading:createLoading}] = useCreateApiKeyMutation();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [payload, setPayload] = React.useState(initialState);

    const closeHandler = () => {
        setIsModalOpen(false);
        setPayload(initialState);
    };

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useApiKeyListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(query.sortBy),
        sort_direction: query.sortDirection,
    });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "Tool Name",
            dataIndex: "toolName",
            isSortable: true,
        },
        {
            title: "Key",
            dataIndex: "apiKey",
            isSortable: true,
        },
        {
            title: "Organization Id",
            dataIndex: "organizationId",
            isSortable: true,
        },
        {
            title: "Revoked",
            dataIndex: "isRevoked",
            isSortable: false,
        },
        {
            title: "Expired At",
            dataIndex: "expiresAt",
            isSortable: true,
        },
        {
            title: "Created",
            dataIndex: "createdAt",
            isSortable: true,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("tool_name", payload.tool_name.value as string);
        formData.append("api_key", payload.api_key.value as string);
        formData.append("organization_id", payload.organization_id.value as string);
        formData.append("expires_at", payload.expires_at.value as string);

        const response = (await createApi(formData)) as any;

        if (response?.data?.success) {
            closeHandler();
            toast.success(response?.data?.message);
        } else {
            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                    //   }
                });
            } else {
                toast.error(response?.error.data?.message);
            }
            if (response?.error?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>Api Key Management</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                    <button
                        className={`${pageClassName}__button`}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Add Api Key
                    </button>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        minWidth="1000"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>
                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={closeHandler}
                header={"Add Api Key"}
            >
                <Loader isLoading={createLoading}>
                    <form className={`${pageClassName}__modal`} onSubmit={submitHandler}>
                        <div className={`${pageClassName}__modal__dropdown`}>
                            <label className={`${pageClassName}__modal__dropdown__label`}>
                                Tool Name
                            </label>
                            <Dropdown
                                options={toolName.map((tool) => ({
                                    label: tool.label,
                                    value: tool.value,
                                }))}
                                onClickChange={(selected) =>
                                    setPayload({
                                        ...payload,
                                        tool_name: {
                                            ...payload.tool_name,
                                            value: selected?.value as string,
                                        },
                                    })
                                }
                                placeholder="Please Select a Tool"
                                isIcon
                                color="black"
                                isBordered
                                selected={{
                                    label: payload.tool_name.value as string,
                                    value: payload.tool_name.value as string,
                                }}
                                isError={payload.tool_name.isError}
                                error={payload.tool_name.error}
                            />
                        </div>

                        <Input
                            placeholder="Enter Api Key"
                            label="Api Key"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    api_key: {
                                        ...payload.api_key,
                                        value: e.target.value,
                                    },
                                })
                            }
                            value={(payload.api_key.value as string) || ""}
                            isError={payload.api_key.isError}
                            error={payload.api_key.error}
                        />

                        <Input
                            placeholder="Enter Organization Id"
                            label="Organization Id"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    organization_id: {
                                        ...payload.organization_id,
                                        value: e.target.value,
                                    },
                                })
                            }
                            value={(payload.organization_id.value as string) || ""}
                            isError={payload.organization_id.isError}
                            error={payload.organization_id.error}
                        />

                        <DatePicker
                            label="Expires At"
                            value={payload.expires_at.value ? new Date(payload.expires_at.value as string) : new Date()}
                            onChange={(e) => {
                                setPayload({
                                    ...payload,
                                    expires_at: {
                                        ...payload.expires_at,
                                        value: e.target.value,
                                    },
                                })
                            }}
                            min={new Date(new Date().getTime() + (8 * 24 * 60 * 60 * 1000))}
                            max={new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000))}
                        />

                        <div className={`${pageClassName}__modal__action`}>
                            <button
                                className={`${pageClassName}__modal__cancel`}
                                onClick={closeHandler}
                                type="button"
                            >
                                Cancel
                            </button>
                            <button className={`${pageClassName}__modal__add`} type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </Loader>
            </Modal>
        </div>
    );
};

export default ApiKeyList;
