import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME, ROUTE } from "../../../../constants/constants";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { TableDataType } from "../../../../types";
import { useUserListQuery } from "../../../../store/user/userSlice";
import { pascalToSnakeCase } from "../../../../utils";

const UserList = () => {
    const pageClassName = "p-userList";
    const navigate = useNavigate();  

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useUserListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(query.sortBy),
        sort_direction: query.sortDirection,
    });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Photo",
            dataIndex: "profilePhoto",
            isSortable: false,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            isSortable: true,
        },
        {
            title: "Middle Name",
            dataIndex: "middleName",
            isSortable: true,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            isSortable: true,
        },
        {
            title: "Email",
            dataIndex: "email",
            isSortable: true,
        },
        {
            title: "Contact No",
            dataIndex: "contactNo",
            isSortable: true,
        },
        {
            title: "Role",
            dataIndex: "umUserRole",
            isSortable: true,
        },
        {
            title: "Status",
            dataIndex: "isDeleted",
            isSortable: false,
        },
        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: true,
        },
        {
            title: "Updated At",
            dataIndex: "updatedAtTime",
            isSortable: true,
        },
    ];

    const toolSortHandler = (sortBy: string) => {
        if (sortBy === "createdAtTime") {
            sortBy = "createdAt";
        } else if (sortBy === "updatedAtTime") {
            sortBy = "updatedAt";
        }else if (sortBy === "umUserRole") {
            sortBy = "role";
        }

        sortHandler(sortBy);
    };

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string) => {
        const user = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        );
        navigate(ROUTE.UM_TOOL_USER_LIST + `/${id}`, {
            state: {
                name: user?.firstName + " " + user?.lastName,
            },
        });
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.UM_TOOL_USER_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__search`}>
                    <Input
                        placeholder="Search"
                        onChange={(e) => {
                            searchHandler(e);
                            handleFirstPage();
                        }}
                        value={search}
                    />
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={(sortBy: string) => toolSortHandler(sortBy)}
                        viewHandler={viewHandler}
                        minWidth="2000"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;
