import React from "react";
import { useNavigate } from "react-router-dom";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { useAwsBillingListQuery } from "../../../../store/reports/reportSlice";
import {
    monthNames,
    numberFormat,
    pascalToSnakeCase,
} from "../../../../utils";
import { TableDataType } from "../../../../types";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { PAGE_NAME, ROUTE } from "../../../../constants/constants";
import { Icon } from "../../../atoms/icon";

const AWSBilling = () => {
    const pageClassName = "p-awsBilling";
    const navigate = useNavigate();

    const filterRef = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);
    const [selectedMonth, setSelectedMonth] = React.useState({
        label: "All",
        value: "All",
    });

    const [filter, setFilter] = React.useState<{
        month: string;
        year: string;
    }>({
        month: "",
        year: "",
    });

    const [monthsOptions, setMonthsOptions] = React.useState<any[]>([]);

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading, isSuccess, isFetching } = useAwsBillingListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(query.sortBy),
        sort_direction: query.sortDirection,
        month: filter.month,
        year: filter.year,
    });

    React.useEffect(() => {
        if (data?.result?.monthYears) {
            const options = data?.result?.monthYears.map((item: number) => {
                return {
                    label: item,
                    value: item,
                };
            });
            setMonthsOptions([{ label: "All", value: "All" }, ...options]);
        }
    }, [data?.result?.monthYears]);

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Billing Date",
            dataIndex: "startDate",
            isSortable: true,
        },
        {
            title: "Cost",
            dataIndex: "amount",
            isSortable: true,
        },
        {
            title: "Currency",
            dataIndex: "currency",
            isSortable: true,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string, e: any) => {
        const name = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        ).name;
        navigate(ROUTE.GCP_PROJECT_BILLING_INFO + `/${id}`, { state: { name } });
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filterHandler = () => {
        if (selectedMonth.value === "All") {
            setFilter({
                year: "",
                month: "",
            });
            return;
        }

        const monthYear = selectedMonth.label.split("-");
        setFilter({
            year: monthYear[1],
            month: (monthNames.indexOf(monthYear[0]) + 1).toString(),
        });
    };


    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <div className={`${pageClassName}__card`}>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>Total Cost</p>
                        <p className={`${pageClassName}__card__value`}>
                            {numberFormat(data?.result?.totalAmount ?? 0, "USD")}
                        </p>
                    </div>
                    <Icon icon="moneyBundle" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Current Month
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {new Date().toLocaleString("default", { month: "long" })} {new Date().getFullYear()}
                        </p>
                    </div>
                    <Icon icon="calendar" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Current Month Cost
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {numberFormat(data?.result?.currentMonthAmount ?? 0, "USD")}
                        </p>
                    </div>
                    <Icon icon="money" size="tertiary" />
                </div>
            </div>
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.AWS_BILLING}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                searchHandler(e);
                                handleFirstPage();
                            }}
                            value={search}
                        />
                    </div>

                    {(filter.month || filter.year) && (
                        <p className={`${pageClassName}__header__title`}>
                            Filtered By{" "}
                            {monthNames[parseInt(filter.month) - 1]}-{filter.year}
                        </p>
                    )}

                    <div className={`${pageClassName}__filter`} ref={filterRef}>
                        <button
                            onClick={() => setShowFilter(!isShowFilter)}
                            className={`${pageClassName}__filter__button`}
                            title="Filter"
                        >
                            <Icon icon="filter" size="primary" />
                        </button>
                        {isShowFilter && (
                            <div className={`${pageClassName}__filter__container`}>
                                <div className={`${pageClassName}__filter__item`}>
                                    <label htmlFor="filter">Month</label>
                                    <Dropdown
                                        options={monthsOptions}
                                        selected={selectedMonth}
                                        onClickChange={(e) =>
                                            setSelectedMonth(e as { label: string; value: string })
                                        }
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                                <Divider />
                                <div className={`${pageClassName}__filter__btn`}>
                                    <button
                                        onClick={() => {
                                            setSelectedMonth({ label: "All", value: "All" });
                                            handleFirstPage();
                                        }}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowFilter(false);
                                            filterHandler();
                                            handleFirstPage();
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
                <div className={`${pageClassName}__cost`}>
                    <p className={`${pageClassName}__cost__title`}>Total :</p>
                    <p className={`${pageClassName}__cost__value`}>{isSuccess ? numberFormat(data?.result?.totalQueryAmount) : 0}</p>
                </div>
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AWSBilling;
