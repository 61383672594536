import React from "react";

import { Breadcrumb } from "../../../../atoms/breadcrumb";
import {
    PAGE_NAME,
    REQUEST_MANAGEMENT_SAAS_TOOLS,
    ROUTE,
    SOMETHING_WENT_WRONG,
} from "../../../../../constants/constants";
import { Dropdown } from "../../../../atoms/dropdown";
import {
    DropdownOptionsType,
    ResponseType,
    payloadType,
} from "../../../../../types";
import { useUserListQuery } from "../../../../../store/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../../../atoms/loader";
import {
    useApproverDetailsQuery,
    useNamesBySaasToolQuery,
    useUpdateApproverMutation,
} from "../../../../../store/requestManagement/requestSlice";
import { toast } from "react-toastify";
import { CreatableDropdown } from "../../../../atoms/creatableDropdown";

const typeOption = [
    { label: "Group", value: "Group" },
    { label: "Project", value: "Project" },
    { label: "Space", value: "Space" },
    { label: "Team", value: "Team" },
    { label: "Dashboard", value: "Dashboard" },
];

const OwnerUpdate = () => {
    const pageClassName = "p-ownerUpdate";
    const navigate = useNavigate();

    const url = useLocation();

    React.useEffect(() => {
        if (url?.state?.id === undefined) {
            navigate(ROUTE.APPROVER_LIST);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const { data: userListData, isLoading: isUserListLoading } = useUserListQuery(
        {}
    );
    const [updateApi] = useUpdateApproverMutation();
    const { data } = useApproverDetailsQuery(parseInt(url?.state?.id as string));
    const initialPayload: payloadType = {
        name: {
            value: "",
            isError: false,
            error: "",
        },
        type: {
            value: "",
            isError: false,
            error: "",
        },
        saas_tool: {
            value: "",
            isError: false,
            error: "",
        },
        approver_id: {
            value: "",
            isError: false,
            error: "",
        },
    };

    const [payload, setPayload] = React.useState(initialPayload);
    const [typeOptions, setTypeOptions] =
        React.useState<DropdownOptionsType[]>(typeOption);

    const { data: namesOptions, isLoading: isNamesOptionsLoading } =
        useNamesBySaasToolQuery({
            saasTool: (payload.saas_tool?.value as DropdownOptionsType)
                ?.value as string,
            type: (payload.type.value as DropdownOptionsType)?.value as string,
        });

    React.useEffect(() => {
        if (data) {
            const saasTool = REQUEST_MANAGEMENT_SAAS_TOOLS.find(
                (item) => item.value === data?.result?.saasTool
            );
            setPayload({
                name: {
                    value: {
                        label: data?.result?.name,
                        value: data?.result?.name,
                    },
                    isError: false,
                    error: "",
                },
                type: {
                    value: {
                        label: data?.result?.type,
                        value: data?.result?.type,
                    },
                    isError: false,
                    error: "",
                },
                saas_tool: {
                    value: saasTool as { label: string; value: string },
                    isError: false,
                    error: "",
                },
                approver_id: {
                    value: data?.result?.approver?.map((item: any) => ({
                        label: item?.middleName
                            ? `${item?.firstName} ${item?.middleName} ${item?.lastName}`
                            : `${item?.firstName} ${item?.lastName}`,
                        value: item.id,
                    })),
                    isError: false,
                    error: "",
                },
            });

            if (saasTool?.value === "Confluence") {
                setTypeOptions([
                    { label: "Space", value: "Space" },
                    { label: "Group", value: "Group" },
                ]);
            } else if (saasTool?.value === "GitLab") {
                setTypeOptions([
                    { label: "Group", value: "Group" },
                    { label: "Project", value: "Project" },
                ]);
            } else if (saasTool?.value === "MergeBase") {
                setTypeOptions([{ label: "Dashboard", value: "Dashboard" }]);
            } else if (saasTool?.value === "Miro") {
                setTypeOptions([{ label: "Team", value: "Team" }]);
            } else if (saasTool?.value === "Youtrack") {
                setTypeOptions([
                    { label: "Group", value: "Group" },
                    { label: "Project", value: "Project" },
                ]);
            }
        }
    }, [data]);

    const saasChangeHandler = (value: DropdownOptionsType) => {
        setPayload({
            ...payload,
            saas_tool: {
                value: value as { label: string; value: string },
                isError: false,
                error: "",
            },
        });

        if (value.value === "Confluence") {
            setTypeOptions([
                { label: "Space", value: "Space" },
                { label: "Group", value: "Group" },
            ]);
        } else if (["GitLab", "Youtrack"].includes(value.value as string)) {
            setTypeOptions([
                { label: "Group", value: "Group" },
                { label: "Project", value: "Project" },
            ]);
        } else if (value.value === "Miro") {
            setTypeOptions([{ label: "Team", value: "Team" }]);
        } else if (value.value === "Box") {
            setTypeOptions([
                {
                    label: "License",
                    value: "License",
                },
            ]);
        } else if (["SOPHOS Cloud Optix", "GCP"].includes(value.value as string)) {
            setTypeOptions([
                {
                    label: "Project",
                    value: "Project",
                },
            ]);
        } else if (
            [
                "SOPHOS MDR Agent Installation",
                "SOPHOS MDR Server Agent Installation",
            ].includes(value.value as string)
        ) {
            setTypeOptions([
                {
                    label: "OS",
                    value: "OS",
                },
            ]);
        }
    };

    const typeChangeHandler = (value: DropdownOptionsType) => {
        setPayload({
            ...payload,
            type: {
                value: value as { label: string; value: string },
                isError: false,
                error: "",
            },
        });
    };

    const nameChangeHandler = (value: DropdownOptionsType) => {
        setPayload({
            ...payload,
            name: {
                value: value as { label: string; value: string },
                isError: false,
                error: "",
            },
        });
    };

    const ownerChangeHandler = (value: DropdownOptionsType) => {
        setPayload({
            ...payload,
            approver_id: {
                value: value as { label: string; value: string },
                isError: false,
                error: "",
            },
        });
    };

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData();

        (payload.name.value as DropdownOptionsType).value &&
            formData.append(
                "name",
                (payload.name.value as DropdownOptionsType).value as string
            );
        payload.type.value &&
            formData.append(
                "type",
                (payload.type.value as DropdownOptionsType).value as string
            );
        payload.saas_tool.value &&
            formData.append(
                "saas_tool",
                (payload.saas_tool.value as DropdownOptionsType).value as string
            );
        payload.approver_id.value &&
            (payload.approver_id.value as DropdownOptionsType[]).length > 0 &&
            (payload.approver_id.value as DropdownOptionsType[]).forEach((item) => {
                formData.append("approver_id[]", item.value as string);
            });

        formData.append("_method", "PUT");

        const response = (await updateApi({
            id: url?.state?.id as string,
            formData,
        })) as ResponseType;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate(ROUTE.APPROVER_LIST);
        } else {
            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                });
            } else {
                toast.error(response?.error?.data?.message);
            }
            if (response?.error?.data?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.APPROVER_EDIT}</p>
            <div className={`${pageClassName}__container`}>
                <Loader isLoading={isUserListLoading || isNamesOptionsLoading}>
                    <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                        <div className={`${pageClassName}__form__dropdown`}>
                            <label className={`${pageClassName}__form__dropdown__label`}>
                                SaaS/infra Name
                            </label>
                            <Dropdown
                                options={REQUEST_MANAGEMENT_SAAS_TOOLS}
                                onClickChange={saasChangeHandler}
                                placeholder="Please Select a SaaS/Infra Name"
                                isIcon
                                color="black"
                                isBordered
                                selected={payload.saas_tool.value as DropdownOptionsType}
                                isError={payload.saas_tool.isError}
                                error={payload.saas_tool.error}
                                isDisabled
                            />
                        </div>

                        <div className={`${pageClassName}__form__dropdown`}>
                            <label className={`${pageClassName}__form__dropdown__label`}>
                                Type
                            </label>
                            <Dropdown
                                options={typeOptions}
                                onClickChange={typeChangeHandler}
                                placeholder="Please Select a Type"
                                isIcon
                                color="black"
                                isBordered
                                selected={payload.type.value as DropdownOptionsType}
                                isError={payload.type.isError}
                                error={payload.type.error}
                            />
                        </div>
                        {(payload.type.value as DropdownOptionsType).value !==
                            "License" && (
                                <div className={`${pageClassName}__form__dropdown`}>
                                    <label className={`${pageClassName}__form__dropdown__label`}>
                                        {(payload.type.value as DropdownOptionsType).value} Name
                                    </label>
                                    <CreatableDropdown
                                        options={namesOptions?.result ?? []}
                                        onClickChange={nameChangeHandler}
                                        placeholder={`Please Select a ${(payload.type.value as DropdownOptionsType).value ?? ""
                                            } Name`}
                                        isIcon
                                        color="black"
                                        isBordered
                                        selected={payload.name.value as DropdownOptionsType}
                                        isError={payload.name.isError}
                                        error={payload.name.error}
                                    />
                                </div>
                            )}
                        <div className={`${pageClassName}__form__dropdown`}>
                            <label className={`${pageClassName}__form__dropdown__label`}>
                                Approver
                            </label>
                            <Dropdown
                                options={
                                    userListData?.result?.data?.map((user: any) => ({
                                        label: user?.middleName
                                            ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
                                            : `${user?.firstName} ${user?.lastName}`,
                                        value: user?.id,
                                    })) ?? []
                                }
                                onClickChange={ownerChangeHandler}
                                placeholder="Please select approvers"
                                isIcon
                                color="black"
                                isBordered
                                selected={payload.approver_id.value as DropdownOptionsType[]}
                                isError={payload.approver_id.isError}
                                error={payload.approver_id.error}
                                isMulti
                            />
                        </div>

                        <div className={`${pageClassName}__action`}>
                            <button
                                className={`${pageClassName}__action__button`}
                                type="submit"
                            >
                                Save
                            </button>
                            <button
                                className={`${pageClassName}__action__button ${pageClassName}__action__cancel`}
                                type="button"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </Loader>
            </div>
        </div>
    );
};

export default OwnerUpdate;
