import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "paymentList",
    "paymentDetails",
    "paymentReport",
    "paymentReportFeedback",
  ],
});

export const paymentSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Fetches a list of payments from the server.
     *
     * @param search - The search query to filter the payments.
     * @param limit - The maximum number of payments to retrieve.
     * @param current_page - The current page of the payment list.
     * @param sort_by - The field to sort the payments by.
     * @param sort_direction - The direction to sort the payments in (ascending or descending).
     * @returns A promise that resolves to the response data containing the list of payments.
     */
    paymentList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction, saas_tools }) => ({
        url:
          API_URL.PAYMENT +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${saas_tools ? `&saas_tools=${saas_tools}` : ""}`,
        method: "GET",
      }),
      providesTags: ["paymentList"],
    }),

    /**
     * Fetches a single payment from the server.
     *
     * @param id - The ID of the payment to retrieve.
     * @returns A promise that resolves to the response data containing the payment.
     */

    paymentDetails: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.PAYMENT + `/${id}`,
        method: "GET",
      }),
      providesTags: ["paymentDetails"],
    }),

    addPayment: builder.mutation<ResponseDataType, FormData>({
      query: (body) => ({
        url: API_URL.PAYMENT,
        method: "POST",
        body,
      }),
      invalidatesTags: ["paymentList", "paymentDetails","paymentReport"],
    }),

    updatePayment: builder.mutation<ResponseDataType, { id: number, formData: FormData }>({
      query: ({ id, formData }) => ({
        url: API_URL.PAYMENT + "/" + id,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["paymentList", "paymentDetails","paymentReport"],
    }),

    deletePayment: builder.mutation<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.PAYMENT + `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["paymentList", "paymentDetails","paymentReport"],
    }),

    paymentReport: builder.query<ResponseDataType, listDataType>({
      query: ({tool_name}) => ({
        url: API_URL.PAYMENT + API_URL.REPORT + (tool_name ? `?tool_name=${tool_name}` : ""),
        method: "GET",
      }),
      providesTags: ["paymentReport"],
    }),
  }),
});

export const {
  usePaymentListQuery,
  usePaymentDetailsQuery,
  useAddPaymentMutation,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,
  usePaymentReportQuery,
} = paymentSlice;
