import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  width: "100%",
  cssClassNames: {
    tableCell: "m-chart__cell-text-align",
    headerCell: "m-chart__cell-text-align",
  },
};

interface ITableChartProps {
  data: any;
}
export const TableChart = ({ data }: ITableChartProps) => {
  const [chartSize, setChartSize] = React.useState({ width: 0, height: 0 });
  const [chartKey, setChartKey] = React.useState(0);

  React.useEffect(() => {
    const handleResize = () => {
      const chartContainer = document.querySelector(".m-tableChart") as HTMLElement;
      if (chartContainer) {
        setChartSize({
          width: chartContainer.offsetWidth,
          height: chartContainer.offsetHeight,
        });
        setChartKey((prevKey) => prevKey + 1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="m-tableChart">
      <Chart
        key={chartKey}
        chartType="Table"
        height="100%"
        width={chartSize.width}
        data={data}
        options={options as any}
      />
    </div>
  );
};
