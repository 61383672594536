import React from "react";
import { Chart, ChartWrapperOptions } from "react-google-charts";
import { Loader } from "../../atoms/loader";

interface PieChartProps {
    data: any[];
    options: ChartWrapperOptions["options"];
    isLoading?: boolean;
    isFetching?: boolean;
}
export const PieChart = ({ data, options, isLoading, isFetching = false }: PieChartProps) => {
    const [chartSize, setChartSize] = React.useState({ width: 0, height: 0 });
    const [chartKey, setChartKey] = React.useState(0);

    React.useEffect(() => {
        const handleResize = () => {
            const chartContainer = document.querySelector(".m-chart") as HTMLElement;
            if (chartContainer) {
                setChartSize({
                    width: chartContainer.offsetWidth,
                    height: chartContainer.offsetHeight,
                });
                setChartKey((prevKey) => prevKey + 1);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="m-chart">
            {isLoading ? (
                <div className="m-chart__skelton"></div>
            ) : (
                <Loader isLoading={isFetching}>
                    <Chart
                        key={chartKey}
                        chartType="PieChart"
                        data={data}
                        options={options}
                        width={chartSize.width}
                        height={chartSize.height}
                    />
                </Loader>
            )}
        </div>
    );
};
