import React from "react";
// import { createComponentBase, BaseComponentProps } from "../../../../libs/components";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../../../atoms/skeleton";
import { formattedDateTime, isExpiredDate, isValidDate } from "../../../../utils";
import { ROUTE } from "../../../../constants/constants";
interface ActivityGridViewProps {
    pageClassName: string;
    isLoading: boolean;
    data: any;
    differenceMonth: number;
}
export const ActivityGridView = ({ pageClassName, isLoading, data, differenceMonth }: ActivityGridViewProps) => {
    const navigate = useNavigate();

    
    return (
        <div className={`${pageClassName}__content`}>
            {isLoading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item: any) => (
                    <div className={`${pageClassName}__skeleton__container`}>
                        <div className={`${pageClassName}__skeleton__data`}>
                            <div className={`${pageClassName}__skeleton__header`}>
                                <div className={`${pageClassName}__skeleton__info`}>
                                    <p className={`${pageClassName}__skeleton__info__name`}>
                                        <Skeleton height="small" />
                                    </p>
                                    <p className={`${pageClassName}__skeleton__info__email`}>
                                        <Skeleton height="small" />
                                    </p>
                                </div>
                                <p className={`${pageClassName}__skeleton__serial`}>
                                    <Skeleton height="semiSmall" />
                                </p>
                            </div>
                            <div className={`${pageClassName}__skeleton__saas`}>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => (
                                    <div
                                        className={`${pageClassName}__skeleton__saas__tool`}
                                        key={item}
                                    >
                                        <div className={`${pageClassName}__skeleton__saas__logo`}>
                                            <Skeleton height="small" />
                                        </div>
                                        <div className={`${pageClassName}__skeleton__saas__date`}>
                                            <Skeleton height="small" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
                : data?.result?.total > 0 &&
                data?.result?.data.map((item: any, index: number) => (
                    <div className={`${pageClassName}__content__container`}>
                        <div className={`${pageClassName}__content__data`}>
                            <div className={`${pageClassName}__content__header`}>
                                <div className={`${pageClassName}__content__info`}>
                                    <p className={`${pageClassName}__content__info__name`}>
                                        {item.firstName} {item.lastName}
                                    </p>
                                    <p className={`${pageClassName}__content__info__email`}>
                                        {item.email}
                                    </p>
                                </div>
                                <p className={`${pageClassName}__content__serial`}>
                                    {data?.result?.from + index}
                                </p>
                            </div>
                            <div className={`${pageClassName}__content__saas`}>
                                {item?.okta && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(ROUTE.OKTA_USER_LIST + `/${item.oktaUserId}`)
                                            }
                                            title="Okta"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/oktaLicense.png")}
                                                alt="okta"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.okta?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`}>
                                            {isValidDate(item?.okta?.date)
                                                ? formattedDateTime(item?.okta?.date)
                                                : item?.okta?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.box && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(ROUTE.BOX_USER_LIST + `/${item?.box?.id}`)
                                            }
                                            title="Box"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/boxLicense.png")}
                                                alt="box"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.box?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.box?.date)
                                                ? formattedDateTime(item?.box?.date)
                                                : item?.box?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.gitlab && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(
                                                    ROUTE.GITLAB_USER_LIST + `/${item.gitlab?.id}`
                                                )
                                            }
                                            title="GitLab"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/gitlabLicense.png")}
                                                alt="gitlab"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.gitlab?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.gitlab?.date)
                                                ? formattedDateTime(item?.gitlab?.date)
                                                : item?.gitlab?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.youtrack && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(
                                                    ROUTE.YOUTRACK_USER_LIST + `/${item.youtrack?.id}`
                                                )
                                            }
                                            title="YouTrack"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/youtrackLicense.png")}
                                                alt="youtrack"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.youtrack?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.youtrack?.date)
                                                ? formattedDateTime(item?.youtrack?.date)
                                                : item?.youtrack?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.confluence && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(
                                                    ROUTE.ATLASSIAN_USER_LIST +
                                                    `/${item.confluence?.id}`
                                                )
                                            }
                                            title="Atlassian"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/atlassianLicense.png")}
                                                alt="atlassian"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.confluence?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.confluence?.date)
                                                ? formattedDateTime(item?.confluence?.date)
                                                : item?.confluence?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.confluence && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(
                                                    ROUTE.CONFLUENCE_USER_LIST +
                                                    `/${item?.confluence?.id}`
                                                )
                                            }
                                            title="Confluence"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/confluenceLicense.png")}
                                                alt="confluence"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.confluence?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.confluence?.date)
                                                ? formattedDateTime(item?.confluence?.date)
                                                : item?.confluence?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.gcp && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(ROUTE.GCP_USER_LIST + `/${item?.gcp?.id}`)
                                            }
                                            title="GCP"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/gcpLicense.png")}
                                                alt="gcp"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.gcp?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.gcp?.date)
                                                ? formattedDateTime(item?.gcp?.date)
                                                : item?.gcp?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.miro && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(ROUTE.MIRO_USER_LIST + `/${item?.miro?.id}`)
                                            }
                                            title={item?.miro?.license ? "Miro" : "Miro Guest"}
                                        >
                                            <img
                                                src={require(`../../../../assets/saasToolLogo/${item?.miro?.license ? "miroLicense" : "miroGuest"}.png`)}
                                                alt="miro"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.miro?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.miro?.date)
                                                ? formattedDateTime(item?.miro?.date)
                                                : item?.miro?.date}
                                        </p>
                                    </div>
                                )}
                                {item?.aws && (
                                    <div className={`${pageClassName}__content__saas__tool`}>
                                        <div
                                            className={`${pageClassName}__content__saas__logo`}
                                            onClick={() =>
                                                navigate(ROUTE.AWS_USER_LIST + `/${item?.aws?.id}`)
                                            }
                                            title="AWS"
                                        >
                                            <img
                                                src={require("../../../../assets/saasToolLogo/awsLicense.png")}
                                                alt="aws"
                                            />
                                        </div>
                                        <p className={`${pageClassName}__content__saas__date ${isExpiredDate(item?.aws?.date, differenceMonth) ? pageClassName + '__content__saas__date--red' : ""}`} >
                                            {isValidDate(item?.aws?.date)
                                                ? formattedDateTime(item?.aws?.date)
                                                : item?.aws?.date}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
