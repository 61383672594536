/* eslint-disable no-mixed-operators */
import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import {
  DEFAULT_PROFILE_PHOTO,
  PAGE_NAME,
  SOMETHING_WENT_WRONG,
} from "../../../../constants/constants";
import {
  useMeQuery,
  useUpdateProfileMutation,
  useUserListQuery,
} from "../../../../store/user/userSlice";
import { Loader } from "../../../atoms/loader";
import { ResponseType, payloadType } from "../../../../types";
import { Input } from "../../../atoms/input";
import { Icon } from "../../../atoms/icon";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userActions } from "../../../../store/auth/user";
import ImageCropper from "../../../atoms/imageCropper";
import { ImageInput } from "../../../atoms/imageInput";
import { BACKEND_URL } from "../../../../constants/urls";
import { Switch } from "../../../atoms/switch";
import { Dropdown } from "../../../atoms/dropdown";

const UserProfile = () => {
  const pageClassName = "p-userProfile";

  const dispatch = useDispatch();

  const { data, isLoading, isSuccess,isFetching } = useMeQuery();
  const [updateProfileApi, { isLoading: updateProfileLoading }] =
    useUpdateProfileMutation();
  const [image, setImage] = React.useState<any>(undefined);
  const { data: managerList, isLoading: managerListLoading } = useUserListQuery(
    {}
  );

  const initialState: payloadType = {
    firstName: {
      value: "",
      error: "",
      isError: false,
    },
    middleName: {
      value: "",
      error: "",
      isError: false,
    },
    lastName: {
      value: "",
      error: "",
      isError: false,
    },
    nameInJp: {
      value: "",
      error: "",
      isError: false,
    },
    email: {
      value: "",
      error: "",
      isError: false,
    },
    designation: {
      value: "",
      error: "",
      isError: false,
    },
    department: {
      value: "",
      error: "",
      isError: false,
    },
    address: {
      value: "",
      error: "",
      isError: false,
    },
    managerId: {
      value: "",
      error: "",
      isError: false,
    },
    is2FAEnabled: {
      value: false,
      error: "",
      isError: false,
    },
    image: {
      value: "",
      error: "",
      isError: false,
    },
    contactNo: {
      value: "",
      error: "",
      isError: false,
    },
    remarks: {
      value: "",
      error: "",
      isError: false,
    },
  };
  const [payload, setPayload] = React.useState(initialState);
  const [isEditing, setIsEditing] = React.useState({
    firstName: false,
    middleName: false,
    lastName: false,
    nameInJp: false,
    email: false,
    designation: false,
    department: false,
    address: false,
    managerId: false,
    is2FAEnabled: false,
    image: false,
    password: false,
    passwordConfirmation: false,
    contactNo: false,
    remarks: false,
  });

  React.useEffect(() => {
    if (isSuccess) {
      setPayload((prevState) => ({
        ...prevState,
        firstName: {
          value: data?.result?.firstName,
          error: "",
          isError: false,
        },
        middleName: {
          value: data?.result?.middleName,
          error: "",
          isError: false,
        },
        lastName: {
          value: data?.result?.lastName,
          error: "",
          isError: false,
        },
        nameInJp: {
          value: data?.result?.nameInJp,
          error: "",
          isError: false,
        },
        email: {
          value: data?.result?.email,
          error: "",
          isError: false,
        },
        designation: {
          value: data?.result?.designation,
          error: "",
          isError: false,
        },
        department: {
          value: data?.result?.department,
          error: "",
          isError: false,
        },
        address: {
          value: data?.result?.address,
          error: "",
          isError: false,
        },
        managerId: {
          value: {
            label: data?.result?.manager?.middleName
              ? `${data?.result?.manager?.firstName} ${data?.result?.manager?.middleName} ${data?.result?.manager?.lastName}`
              : `${data?.result?.manager?.firstName} ${data?.result?.manager?.lastName}`,
            value: data?.result?.manager?.id,
          },
          error: "",
          isError: false,
        },
        is2FAEnabled: {
          value: data?.result?.is2FAEnabled,
          error: "",
          isError: false,
        },
        image: {
          value: data?.result?.profilePhotoPath,
          error: "",
          isError: false,
        },
        contactNo: {
          value: data?.result?.contactNo,
          error: "",
          isError: false,
        },
        remarks: {
          value: data?.result?.remarks,
          error: "",
          isError: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);


  const isSubmitButton = () => {
    if (
      (!isLoading &&
        data?.result?.email &&
        (
          data?.result?.firstName !== payload.firstName.value ||
          data?.result?.middleName !== payload.middleName.value ||
          data?.result?.lastName !== payload.lastName.value ||
          data?.result?.nameInJp !== payload.nameInJp.value ||
          data?.result?.designation !== payload.designation.value ||
          data?.result?.department !== payload.department.value ||
          data?.result?.address !== payload.address.value ||
          data?.result?.manager?.id !==
            (payload.managerId.value as { value: string; label: string }).value ||
          data?.result?.profilePhotoPath !== payload.image.value ||
          data?.result?.contactNo !== payload.contactNo.value ||
          data?.result?.remarks !== payload.remarks.value)
        ) ||
      data?.result?.is2FAEnabled !== payload.is2FAEnabled.value
    ) {
      return true;
    }
    return false;
  };

  const resetHandler = () => {
    setPayload((prevState) => ({
      ...prevState,
      firstName: {
        value: data?.result?.firstName,
        error: "",
        isError: false,
      },
      middleName: {
        value: data?.result?.middleName,
        error: "",
        isError: false,
      },
      lastName: {
        value: data?.result?.lastName,
        error: "",
        isError: false,
      },
      nameInJp: {
        value: data?.result?.nameInJp,
        error: "",
        isError: false,
      },
      email: {
        value: data?.result?.email,
        error: "",
        isError: false,
      },
      designation: {
        value: data?.result?.designation,
        error: "",
        isError: false,
      },
      department: {
        value: data?.result?.department,
        error: "",
        isError: false,
      },
      address: {
        value: data?.result?.address,
        error: "",
        isError: false,
      },
      managerId: {
        value: {
          label: data?.result?.manager?.middleName
            ? `${data?.result?.manager?.firstName} ${data?.result?.manager?.middleName} ${data?.result?.manager?.lastName}`
            : `${data?.result?.manager?.firstName} ${data?.result?.manager?.lastName}`,
          value: data?.result?.manager?.id,
        },
        error: "",
        isError: false,
      },
      is2FAEnabled: {
        value: data?.result?.is2FAEnabled,
        error: "",
        isError: false,
      },
      image: {
        value: data?.result?.profilePhotoPath,
        error: "",
        isError: false,
      },
      contactNo: {
        value: data?.result?.contactNo,
        error: "",
        isError: false,
      },
      remarks: {
        value: data?.result?.remarks,
        error: "",
        isError: false,
      },
    }));
    setIsEditing({
      firstName: false,
      middleName: false,
      lastName: false,
      nameInJp: false,
      email: false,
      designation: false,
      department: false,
      address: false,
      managerId: false,
      is2FAEnabled: false,
      image: false,
      password: false,
      passwordConfirmation: false,
      contactNo: false,
      remarks: false,
    });
  };

  const onCancelHandler = () => {
    setImage(undefined);
  };

  const croppedImageHandler = (img: File) => {
    setPayload({
      ...payload,
      image: { value: img, isError: false, error: "" },
    });

    onCancelHandler();
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const toastId = toast.loading("Loading...");
    const formData = new FormData();
    formData.append("firstName", payload.firstName.value as string);
    formData.append("middleName", (payload.middleName.value as string) ?? "");
    formData.append("lastName", payload.lastName.value as string);
    formData.append("nameInJp", (payload.nameInJp.value as string) ?? "");

    payload.designation.value &&
      formData.append("designation", payload.designation.value as string);
    payload.department.value &&
      formData.append("department", payload.department.value as string);
    payload.address.value &&
      formData.append("address", payload.address.value as string);
    (payload.managerId.value as { value: string; label: string }).value &&
      formData.append(
        "managerId",
        (payload.managerId.value as { value: string; label: string })
          .value as string
      );

    formData.append("isTwoFa", payload.is2FAEnabled.value === true ? "1" : "0");
    if (payload.image.value) {
      if (typeof payload.image.value === "string") {
        formData.append("profilePhotoPath", payload.image.value as string);
      } else {
        formData.append("photo", payload.image.value as File);
      }
    }
    formData.append("contactNo", (payload.contactNo.value as string) ?? "");
    formData.append("remarks", (payload.remarks.value as string) ?? "");

    const response = (await updateProfileApi(formData)) as ResponseType;

    if (response?.data?.success) {
      dispatch(userActions.setUser(response?.data?.result));
      setIsEditing({
        firstName: false,
        middleName: false,
        lastName: false,
        nameInJp: false,
        email: false,
        designation: false,
        department: false,
        address: false,
        managerId: false,
        is2FAEnabled: false,
        image: false,
        password: false,
        passwordConfirmation: false,
        contactNo: false,
        remarks: false,
      });
      toast.update(toastId, {
        render: response?.data?.message,
        type: toast.TYPE.SUCCESS,
        isLoading: isLoading,
        autoClose: 2000,
      });
    } else {
      toast.update(toastId, {
        render: response?.error?.message,
        type: toast.TYPE.ERROR,
        isLoading: isLoading,
        autoClose: 2000,
      });

      if (response?.error?.errors) {
        Object.keys(response?.error?.errors).forEach((key) => {
          setPayload((prevState) => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              error: response?.error?.errors[key][0],
              isError: true,
            },
          }));
          //   }
        });
      } else {
        toast.error(response?.error?.message);
      }
      if (response?.error?.errors) {
        toast.error(SOMETHING_WENT_WRONG);
      }
    }
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.USER_PROFILE}</p>
      {image && (
        <ImageCropper
          imageUrl={URL.createObjectURL(image)}
          type={image.type}
          onCancel={onCancelHandler}
          croppedImageSetter={croppedImageHandler}
          cropShape="rect"
          width={200}
          height={200}
        />
      )}
      <Loader isLoading={isLoading || updateProfileLoading || managerListLoading || isFetching}>
        <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
          <div className={`${pageClassName}__form__image`}>
            <ImageInput
              fileChange={(file) => {
                setImage(file);
                setIsEditing({
                  ...isEditing,
                  image: true,
                });
              }}
              isError={payload.image.isError}
              error={payload.image.error}
              url={
                payload?.image.value
                  ? typeof payload?.image.value === "string"
                    ? `${BACKEND_URL}/${payload?.image.value}`
                    : URL.createObjectURL(payload?.image.value as File)
                  : DEFAULT_PROFILE_PHOTO
              }
            />
            <div className={`${pageClassName}__form__image__info`}>
              <span>Role: </span>
              <p>{data?.result?.role[0].displayTitle}</p>
              <span>ORG: </span>
              <p>{data?.result?.organization?.name}</p>
              <span>ORG Address: </span>
              <p>{data?.result?.organization?.country}</p>
            </div>
          </div>
          <div className={`${pageClassName}__form__container`}>
            {/* first Name and Last Name */}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__label`}>
                <label>First Name</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.firstName ? (
                    <Input
                      placeholder="First Name"
                      value={payload.firstName.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          firstName: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.firstName.isError}
                      error={payload.firstName.error}
                    />
                  ) : (
                    <p>{payload.firstName.value as string}</p>
                  )}

                  {isEditing.firstName ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          firstName: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          firstName: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Middle Name</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.middleName ? (
                    <Input
                      placeholder="Middle Name"
                      value={payload.middleName.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          middleName: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.middleName.isError}
                      error={payload.middleName.error}
                    />
                  ) : (
                    <p>{payload.middleName.value as string}</p>
                  )}
                  {isEditing.middleName ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          middleName: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          middleName: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* last name Japanese Name*/}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Last Name</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.lastName ? (
                    <Input
                      placeholder="Last Name"
                      value={payload.lastName.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          lastName: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.lastName.isError}
                      error={payload.lastName.error}
                    />
                  ) : (
                    <p>{payload.lastName.value as string}</p>
                  )}

                  {isEditing.lastName ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          lastName: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          lastName: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Name In Japanese</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.nameInJp ? (
                    <Input
                      placeholder="Name In Japanese"
                      value={payload.nameInJp.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          nameInJp: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.nameInJp.isError}
                      error={payload.nameInJp.error}
                    />
                  ) : (
                    <p>{payload.nameInJp.value as string}</p>
                  )}
                  {isEditing.nameInJp ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          nameInJp: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          nameInJp: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* email and contact no*/}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Email</label>
                <div className={`${pageClassName}__form__group__content`}>
                  <p className={`${pageClassName}__form__group__email`}>
                    {payload.email.value as string}
                  </p>
                </div>
              </div>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Contact No</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.contactNo ? (
                    <Input
                      placeholder="Contact Number"
                      value={payload.contactNo.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          contactNo: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.contactNo.isError}
                      error={payload.contactNo.error}
                    />
                  ) : (
                    <p>{payload.contactNo.value as string}</p>
                  )}
                  {isEditing.contactNo ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          contactNo: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          contactNo: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/*designation and department */}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Designation</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.designation ? (
                    <Input
                      placeholder="Enter Designation"
                      value={payload.designation.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          designation: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.designation.isError}
                      error={payload.designation.error}
                    />
                  ) : (
                    <p>{payload.designation.value as string}</p>
                  )}
                  {isEditing.designation ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          designation: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          designation: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Department</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.department ? (
                    <Input
                      placeholder="Enter Department"
                      value={payload.department.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          department: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.department.isError}
                      error={payload.department.error}
                    />
                  ) : (
                    <p>{payload.department.value as string}</p>
                  )}
                  {isEditing.department ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          department: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          department: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Address and Manager */}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Address</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.address ? (
                    <Input
                      placeholder="Enter Address"
                      value={payload.address.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          address: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.address.isError}
                      error={payload.address.error}
                    />
                  ) : (
                    <p>{payload.address.value as string}</p>
                  )}
                  {isEditing.address ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          address: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          address: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Personal In Charge (PIC/Manager/Team Lead)</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.managerId ? (
                    <Dropdown
                      options={managerList?.result?.data.map((item: any) => ({
                        value: item.id,
                        label: item.middleName
                          ? `${item.firstName} ${item.middleName} ${item.lastName}`
                          : `${item.firstName} ${item.lastName}`,
                      }))}
                      isBordered
                      isIcon
                      selected={
                        payload.managerId.value as { value: string; label: string }
                      }
                      placeholder="Select PIC"
                      onClickChange={(selected) =>
                        setPayload({
                          ...payload,
                          managerId: {
                            value: selected as { value: string; label: string },
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      className={`${pageClassName}__form__group__content__dropdown`}
                      isError={payload.managerId.isError}
                      error={payload.managerId.error}
                    />
                  ) : (
                    <p>{(payload.managerId.value as { value: string; label: string }).label}</p>
                  )}
                  {isEditing.managerId ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          managerId: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          managerId: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* 2FA */}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__2fa`}>
                <label>Enable Two Factor Authentication</label>
                <div className={`${pageClassName}__form__group__content`}>
                  <Switch
                    color="blue"
                    onInputChange={(checked) => {
                      setPayload({
                        ...payload,
                        is2FAEnabled: {
                          value: checked,
                          error: "",
                          isError: false,
                        },
                      });
                    }}
                    defaultChecked={payload.is2FAEnabled.value as boolean}
                  />
                </div>
              </div>
            </div>

            {/* Remarks*/}
            <div className={`${pageClassName}__form__group`}>
              <div className={`${pageClassName}__form__group__label`}>
                <label>Remarks</label>
                <div className={`${pageClassName}__form__group__content`}>
                  {isEditing.remarks ? (
                    <Input
                      placeholder="Remarks"
                      value={payload.remarks.value as string}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          remarks: {
                            value: e.target.value,
                            error: "",
                            isError: false,
                          },
                        })
                      }
                      isError={payload.remarks.isError}
                      error={payload.remarks.error}
                    />
                  ) : (
                    <p>{payload.remarks.value as string}</p>
                  )}

                  {isEditing.remarks ? (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          remarks: false,
                        })
                      }
                    >
                      <Icon icon="cancel" mask />
                    </button>
                  ) : (
                    <button
                      className={`${pageClassName}__form__group__content__button`}
                      type="button"
                      onClick={(e) =>
                        setIsEditing({
                          ...isEditing,
                          remarks: true,
                        })
                      }
                    >
                      <Icon icon="edit" mask />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* submit button */}
            {isSubmitButton() && (
              <div className={`${pageClassName}__form__button`}>
                <button
                  className={`${pageClassName}__form__button__submit`}
                  type="submit"
                >
                  Save
                </button>
                <button
                  className={`${pageClassName}__form__button__cancel`}
                  type="button"
                  onClick={resetHandler}
                >
                  Reset
                </button>
              </div>
            )}
          </div>
        </form>
      </Loader>
    </div>
  );
};

export default UserProfile;
