import React from "react";
import {
    useRegisterMutation
} from "../../../../store/auth/authSlice";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Dropdown } from "../../../atoms/dropdown";
import { RoleType, payloadType } from "../../../../types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PAGE_NAME, ROUTE, SOMETHING_WENT_WRONG } from "../../../../constants/constants";
import { useRoleQuery, useUserListQuery } from "../../../../store/user/userSlice";
import { Loader } from "../../../atoms/loader";

const Registration = () => {
    const pageClassName = "p-registration";
    const navigate = useNavigate();

    const [registerApi, { isLoading }] = useRegisterMutation();
    const { data: roleData } = useRoleQuery();
    const { data: managerList, isLoading: managerListLoading } = useUserListQuery(
        {}
    );

    const initialState: payloadType = {
        firstName: {
            value: "",
            error: "",
            isError: false,
        },
        middleName: {
            value: "",
            error: "",
            isError: false,
        },
        lastName: {
            value: "",
            error: "",
            isError: false,
        },
        nameInJp: {
            value: "",
            error: "",
            isError: false,
        },
        email: {
            value: "",
            error: "",
            isError: false,
        },
        designation: {
            value: "",
            error: "",
            isError: false,
        },
        department: {
            value: "",
            error: "",
            isError: false,
        },
        address: {
            value: "",
            error: "",
            isError: false,
        },
        managerId: {
            value: "",
            error: "",
            isError: false,
        },
        password: {
            value: "",
            error: "",
            isError: false,
        },
        passwordConfirmation: {
            value: "",
            error: "",
            isError: false,
        },
        role: {
            value: "",
            error: "",
            isError: false,
        },
        contactNo: {
            value: "",
            error: "",
            isError: false,
        },
        remarks: {
            value: "",
            error: "",
            isError: false,
        },
    };
    const [payload, setPayload] = React.useState<payloadType>(initialState);
    const [showPass, setShowPass] = React.useState(false);
    const [showPassConf, setShowPassConf] = React.useState(false);

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const toastId = toast.loading("Loading...");

        const formData = new FormData();
        formData.append("firstName", payload.firstName.value as string);
        formData.append("middleName", payload.middleName.value as string);
        formData.append("lastName", payload.lastName.value as string);
        formData.append("japaneseName", payload.nameInJp.value as string);
        formData.append("email", payload.email.value as string);

        payload.designation.value &&
            formData.append("designation", payload.designation.value as string);
        payload.department.value &&
            formData.append("department", payload.department.value as string);
        payload.address.value &&
            formData.append("address", payload.address.value as string);
        (payload.managerId.value as { value: string; label: string }).value &&
            formData.append(
                "managerId",
                (payload.managerId.value as { value: string; label: string })
                    .value as string
            );

        formData.append("password", payload.password.value as string);
        formData.append(
            "passwordConfirmation",
            payload.passwordConfirmation.value as string
        );
        formData.append("role", payload.role.value as string);
        formData.append("contactNo", payload.contactNo.value as string);
        formData.append("remarks", payload.remarks.value as string);

        const response = (await registerApi(formData)) as any;
        if (response?.data?.success) {
            setPayload(initialState);

            toast.update(toastId, {
                render: response?.data?.message,
                type: toast.TYPE.SUCCESS,
                isLoading: isLoading,
                autoClose: 2000,
            });
            navigate(ROUTE.UM_TOOL_USER_LIST);
        } else {
            toast.update(toastId, {
                render: response?.error?.data?.message,
                type: toast.TYPE.ERROR,
                isLoading: isLoading,
                autoClose: 2000,
            });

            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                    //   }
                });
            } else {
                toast.error(response?.error.data?.message);
            }
            if (response?.error?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };
    //remove Super Admin from roleData
    // React.useEffect(() => {
    //     if (roleData?.result?.data) {
    //         const filteredData = roleData?.result?.data.filter(
    //             (item: RoleType) => item.id !== "1"
    //         );
    //         roleData.result.data = filteredData;
    //     }
    // }, [roleData]);
    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.USER_REGISTRATION}</p>
            <Loader isLoading={isLoading || managerListLoading} >
                <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                    <div className={`${pageClassName}__form__container`}>
                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="First Name"
                                placeholder="First name"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        firstName: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.firstName.isError}
                                error={payload.firstName.error}
                            />
                            <Input
                                label="Middle Name"
                                placeholder="Middle name"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        middleName: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.middleName.isError}
                                error={payload.middleName.error}
                            />
                        </div>
                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Last Name"
                                placeholder="Last name"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        lastName: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.lastName.isError}
                                error={payload.lastName.error}
                            />
                            <Input
                                label="Japanese Name"
                                placeholder="Enter your japanese name"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        nameInJp: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.nameInJp.isError}
                                error={payload.nameInJp.error}
                            />
                        </div>
                        <Input
                            label="Email"
                            placeholder="Enter your email"
                            type="email"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    email: { value: e.target.value, error: "", isError: false },
                                })
                            }
                            isError={payload.email.isError}
                            error={payload.email.error}
                        />
                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Password"
                                placeholder="Enter your password"
                                type={showPass ? "text" : "password"}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        password: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                showPassword={showPass}
                                showPasswordHandler={() => setShowPass(!showPass)}
                                isError={payload.password.isError}
                                error={payload.password.error}
                            />
                            <Input
                                label="Password Confirmation"
                                placeholder="Enter your password again"
                                type={showPassConf ? "text" : "password"}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        passwordConfirmation: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                showPassword={showPassConf}
                                showPasswordHandler={() => setShowPassConf(!showPassConf)}
                                isError={payload.passwordConfirmation.isError}
                                error={payload.passwordConfirmation.error}
                            />
                        </div>

                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Designation"
                                placeholder="Enter your designation"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        designation: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.designation.isError}
                                error={payload.designation.error}
                            />
                            <Input
                                label="Department"
                                placeholder="Enter your department"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        department: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.department.isError}
                                error={payload.department.error}
                            />
                        </div>

                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Address"
                                placeholder="Enter your address"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        address: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.address.isError}
                                error={payload.address.error}
                            />
                            <div className={`${pageClassName}__form__group__dropdown`}>
                                <label>Manager</label>
                                <Dropdown
                                    options={managerList?.result?.data.map((item: any) => ({
                                        value: item.id,
                                        label: item.middleName
                                            ? `${item.firstName} ${item.middleName} ${item.lastName}`
                                            : `${item.firstName} ${item.lastName}`,
                                    }))}
                                    isBordered
                                    isIcon
                                    selected={
                                        payload.managerId.value as { value: string; label: string }
                                    }
                                    placeholder="Select manager"
                                    onClickChange={(selected) =>
                                        setPayload({
                                            ...payload,
                                            managerId: {
                                                value: selected as { value: string; label: string },
                                                error: "",
                                                isError: false,
                                            },
                                        })
                                    }
                                    isError={payload.managerId.isError}
                                    error={payload.managerId.error}
                                />
                            </div>
                        </div>

                        <div className={`${pageClassName}__form__group`}>
                            <div className={`${pageClassName}__form__group__dropdown`}>
                                <label>Role</label>
                                <Dropdown
                                    options={
                                        roleData?.result?.data.filter(
                                            (item: RoleType) => item.displayTitle !== "Super Admin"
                                        ).map((item: RoleType) => {
                                            return {
                                                label: item.displayTitle,
                                                value: item.id,
                                            };
                                        }) || []
                                    }
                                    isBordered
                                    isIcon
                                    placeholder="Select role"
                                    onClickChange={(selected) =>
                                        setPayload({
                                            ...payload,
                                            role: {
                                                value: selected.value as string,
                                                error: "",
                                                isError: false,
                                            },
                                        })
                                    }
                                    isError={payload.role.isError}
                                    error={payload.role.error}
                                />
                            </div>
                            <Input
                                label="Phone"
                                placeholder="Enter phone number"
                                type="number"
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        contactNo: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.contactNo.isError}
                                error={payload.contactNo.error}
                            />
                        </div>
                        <Input
                            label="Remarks"
                            placeholder="Enter remarks"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    remarks: { value: e.target.value, error: "", isError: false },
                                })
                            }
                            isError={payload.remarks.isError}
                            error={payload.remarks.error}
                        />
                    </div>
                    <div className={`${pageClassName}__form__action`}>
                        <button type="submit" className={`${pageClassName}__form__submit`}>
                            Register
                        </button>
                    </div>
                </form>
            </Loader>
        </div>
    );
};

export default Registration;
