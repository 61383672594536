import React from "react";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { pascalToSnakeCase } from "../../../../../utils";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Input } from "../../../../atoms/input";
import { Table } from "../../../../atoms/table";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { Pagination } from "../../../../molecules/pagination";
import { useGitlabGroupListQuery } from "../../../../../store/saasTool/saasToolSlice";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { TableDataType } from "../../../../../types";

export const GitLabGroupList = () => {
    const pageClassName = "p-gitLabGroupList";

    const navigate = useNavigate();

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useGitlabGroupListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(query.sortBy),
        sort_direction: query.sortDirection,
        tool_name: "GitLab",
    });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "Name",
            dataIndex: "name",
            isSortable: true,
        },
        {
            title: "Total Storage",
            dataIndex: "totalStorageSize",
            isSortable: true,
        },
        {
            title: "Created",
            dataIndex: "createdAt",
            isSortable: true,
        },
    ];

    const toolSortHandler = (sortBy: string) => {
        if (sortBy === "userFirstName") {
            sortBy = "first_name";
        } else if (sortBy === "userLastName") {
            sortBy = "last_name";
        } else if (sortBy === "userEmail") {
            sortBy = "email";
        } else if (sortBy === "userStatus") {
            sortBy = "status";
        }

        sortHandler(sortBy);
    };

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string, e: any) => {
        const name = data?.result?.data.find(
          (item: TableDataType) => item.id === id
        ).name;
        navigate(ROUTE.GITLAB_GROUP_LIST + `/${id}`, { state: { name } });
      };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.GITLAB_GROUP_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__search`}>
                    <Input
                        placeholder="Search"
                        onChange={(e) => {
                            searchHandler(e);
                            handleFirstPage();
                        }}
                        value={search}
                    />
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={(sortBy: string) => toolSortHandler(sortBy)}
                        viewHandler={viewHandler}
                        minWidth="1000"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer

                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>

                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>
                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
