import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "../icon";

interface AccordionProps extends BaseComponentProps {
  heading: string | React.ReactNode;
  children: React.ReactNode;
}

export const Accordion = ({ children, heading, className }: AccordionProps) => {
  const componentClassName = "c-creativeAccordion";
  const { Container } = createComponentBase(componentClassName, "div");

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen, children]);

  const modifiers = {
    isOpen: isOpen,
  };
  return (
    <Container className={className} modifiers={modifiers}>
      <div className={`${componentClassName}__title`} onClick={handleClick}>
        <div className={`${componentClassName}__heading`}>
          <Icon icon="arrowRight" size="small"  className={`${componentClassName}__icon`} />
          {heading}
        </div>
        <button className={`${componentClassName}__toggle`} type="button">
          <span className={`${componentClassName}__line`}></span>
          <span className={`${componentClassName}__line`}></span>
        </button>
      </div>
      <div
        className={`${componentClassName}__contentWrap`}
        style={{ height: contentHeight }}
      >
        <div className={`${componentClassName}__content`} ref={contentRef}>
          {children}
        </div>
      </div>
    </Container>
  );
};
