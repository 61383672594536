import React from 'react'
import { createComponentBase, BaseComponentProps } from "../../../libs/components"
import { Icon } from '../icon'
import { Tooltip } from '../tooltip'

export interface ImageInputProps extends BaseComponentProps {
  className?: string
  url?: string
  fileChange: (file: File) => void
  isError?: boolean
  error?: string
}

export const ImageInput = ({
  url = 'https://www.shareicon.net/data/512x512/2016/07/26/802043_man_512x512.png',
  fileChange,
  className,
  isError,
  error
}: ImageInputProps) => {
  const componentClassName = 'a-imageInput'
  const modifiers = {
    'error': isError
  }
  const { Container } = createComponentBase(componentClassName, 'div')

  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      // setImage(URL.createObjectURL(e.target.files[0]))
      fileChange(e.target.files[0])
    }
  }

  return (
  <Tooltip message={error} isVisible={isError}>
    <Container className={className} modifiers={modifiers}>
      
      <div className={`${componentClassName}__image`}>
        <img src={url} alt='profile' />
      </div>
      <div className={`${componentClassName}__overlay`}>
        <Icon icon='camera' />
        <input
          type='file'
          className={`${componentClassName}__input`}
          onChange={fileChangeHandler}
        />
      </div>
      
    </Container>
    </Tooltip>
  )
}
