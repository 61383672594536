import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME, saasToolName } from "../../../../constants/constants";
import { usePaymentReportQuery } from "../../../../store/payment/paymentSlice";
import { ColumnChart } from "../../../molecules/columnChart";
import { Icon } from "../../../atoms/icon";
import { Dropdown } from "../../../atoms/dropdown";
import { Divider } from "../../../atoms/divider";

const numberFormat = (num: number, currency = ""): string => {
    if (currency) {
        const formattedNumber = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
        }).format(num);
        return formattedNumber;
    } else {
        const formattedNumber = new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
        }).format(num);
        return formattedNumber;
    }
};

const PaymentReport = () => {
    const pageClassName = "p-paymentReport";


    const filterRef = React.useRef<HTMLDivElement>(null);
    const [isShowFilter, setShowFilter] = React.useState(false);

    const [toolName, setToolName] = React.useState<{ label: string; value: string }>({
        label: "Select",
        value: "",
    });
    const [filteredToolName, setFilteredToolName] = React.useState(toolName);


    const { data, isLoading, isSuccess } = usePaymentReportQuery({ tool_name: filteredToolName.value });

    const Options: object = {
        title: "Payment Report",
        vAxis: { title: "Amount" },
        hAxis: { title: filteredToolName.value ? filteredToolName.label : "SaaS/Infra" },
        seriesType: "bars",
        backgroundColor: "#f5f5f5",
        legend: { position: "none" },
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const imageSrc = (name: string) => {
        switch (name) {
            case "AWS":
                return require(`../../../../assets/icons/saas/AWS.svg`).default;
            case "GCP":
                return require(`../../../../assets/icons/saas/GCP.svg`).default;
            case "Okta":
                return require(`../../../../assets/icons/saas/Okta.svg`).default;
            case "Slack":
                return require(`../../../../assets/icons/saas/Slack.svg`).default;
            case "Box":
                return require(`../../../../assets/icons/saas/Box.svg`).default;
            case "Confluence":
                return require(`../../../../assets/icons/saas/Confluence.svg`).default;
            case "Jira":
                return require(`../../../../assets/icons/saas/Jira.svg`).default;
            case "Atlassian":
                return require(`../../../../assets/icons/saas/Atlassian.svg`).default;
            case "AnyDesk":
                return require(`../../../../assets/icons/saas/AnyDesk.svg`).default;
            case "Youtrack":
                return require(`../../../../assets/icons/saas/YouTrack.svg`).default;
            case "GitLab":
                return require(`../../../../assets/icons/saas/GitLab.svg`).default;
            case "Miro":
                return require(`../../../../assets/icons/saas/Miro.svg`).default;
            case "SOPHOS MDR":
                return require(`../../../../assets/icons/saas/SOPHOS_MDR.svg`).default;
            case "SOPHOS MDR Server":
                return require(`../../../../assets/icons/saas/SOPHOS_MDR_Server.svg`).default;
            case "SOPHOS ZTNA":
                return require(`../../../../assets/icons/saas/SOPHOS_ZTNA.svg`).default;
            case "SOPHOS Cloud Optix":
                return require(`../../../../assets/icons/saas/SOPHOS_Cloud_Optix.svg`).default;
            case "Namecheap":
                return require(`../../../../assets/icons/saas/Namecheap.svg`).default;
            default:
                return require(`../../../../assets/icons/saas/default.svg`).default;
        }
    }

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {PAGE_NAME.PAYMENT_REPORT}
            </p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__report`}>
                    <div className={`${pageClassName}__report__card`}>
                        <p className={`${pageClassName}__report__card__label`}>Payment</p>
                        <div className={`${pageClassName}__report__card__total`}>
                            <p className={`${pageClassName}__report__card__label`}>Grand Total:</p>
                            <p className={`${pageClassName}__report__card__value`}>{numberFormat(data?.result?.total_payment, "JPY")}</p>
                        </div>
                    </div>
                    <div className={`${pageClassName}__report__item`}>
                        {data?.result?.expense_data?.map((item: any) => (
                            <div key={item.tool_name} className={`${pageClassName}__report__item__card`}>
                                <div className={`${pageClassName}__report__item__card__label`}>
                                    {isSuccess && <img src={imageSrc(item.tool_name)} alt={item.tool_name} height={32} />}
                                    <p>{item.tool_name}</p>
                                </div>

                                <div className={`${pageClassName}__report__item__card__value`}>
                                    <p>{numberFormat(item.expense_in_yen, 'JPY')}</p>
                                    {item.currency === 'USD' && <p className={`${pageClassName}__report__card__label`}>[ {numberFormat(item.total_expense, 'USD')} ]</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <div className={`${pageClassName}__filter`} ref={filterRef}>
                        <button
                            onClick={() => setShowFilter(!isShowFilter)}
                            className={`${pageClassName}__filter__button`}
                            title="Filter"
                        >
                            <Icon icon="filter" size="primary" />
                        </button>
                        {isShowFilter && (
                            <div className={`${pageClassName}__filter__container`}>
                                <div className={`${pageClassName}__filter__item`}>
                                    <label>SaaS/Infra</label>
                                    <Dropdown
                                        options={saasToolName}
                                        selected={toolName as { label: string; value: string }}
                                        onClickChange={(e) =>
                                            setToolName(e as { label: string; value: string })
                                        }
                                        isBordered
                                        isIcon
                                        iniDirection="top"
                                    />
                                </div>
                                <Divider />
                                <div className={`${pageClassName}__filter__btn`}>
                                    <button
                                        onClick={() => {
                                            setToolName({ label: "Select", value: "" });
                                            setFilteredToolName({ label: "Select", value: "" });
                                            setShowFilter(false);
                                        }}
                                    >
                                        Reset
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowFilter(false);
                                            setFilteredToolName(toolName);
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <ColumnChart
                        data={data?.result?.expense}
                        options={Options}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default PaymentReport;
