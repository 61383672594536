import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import { useSaasToolGroupUsersQuery, useSingleSaasToolGroupQuery } from "../../../../../store/saasTool/saasToolSlice";
import { PAGE_NAME } from "../../../../../constants/constants";
import { DataTable } from "../../../../organisms/dataTable";

const GitLabGroupDetails = () => {
  const pageClassName = "p-gitLabGroupDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useSingleSaasToolGroupQuery({ id });


  const memberTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "userFirstName",
      isSortable: true,
    },
    {
      title: "Last Name",
      dataIndex: "userLastName",
      isSortable: true,
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      isSortable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: true,
    }
  ];

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.GITLAB_GROUP_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Group Id
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? <Skeleton height="small" />: data?.result?.toolGroupId ?? "-"}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Group Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading? <Skeleton height="small" /> : data?.result?.name ?? "-" }
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Tool Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading?<Skeleton height="small" /> : data?.result?.toolName ?? "-"}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Number of members</p>
          {isLoading ? <Skeleton height="small" /> : data?.result?.numberOfMembers ?? 0}
        </div>
        
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.createdAt ? formattedDateTime(data?.result?.createdAt) : <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Updated
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.updatedAt ? formattedDateTime(data?.result?.updatedAt) : <Skeleton height="small" />}
          </p>
        </div>

        <DataTable
          title="Members"
          id={id as string}
          toolName="GitLab"
          tableHeader={memberTableHeader}
          useCallApiQuery={useSaasToolGroupUsersQuery}
        />
      </div>
    </div>
  );
};

export default GitLabGroupDetails;