import Dashboard from "../component/pages/dashboard";
import OktaAppList from "../component/pages/okta/apps/list";
import OktaGroupList from "../component/pages/okta/group/list";
import singleOktaUser from "../component/pages/okta/user/single";
import OktaUserList from "../component/pages/okta/user/list";
import SingleOktaApp from "../component/pages/okta/apps/single";
import SingleOktaGroup from "../component/pages/okta/group/single";
import Registration from "../component/pages/auth/registration";
import BoxUserList from "../component/pages/box/userList";
import LicenseList from "../component/pages/license/licenseSummary/list";
import { GitLabUserList } from "../component/pages/gitLab/userList";
import { GitLabGroupList } from "../component/pages/gitLab/group/list";
import { BoxUserLogHistory } from "../component/pages/box/logHistory";
import GroupStorageComparison from "../component/pages/gitLab/groupStorageComparison";
import GitlabProjectList from "../component/pages/gitLab/projectList";
import YoutrackUserList from "../component/pages/youtrack/user/list";
import YoutrackGroupList from "../component/pages/youtrack/groupList";
import YoutrackProjectList from "../component/pages/youtrack/project/list";
import YouTrackUserDetails from "../component/pages/youtrack/user/single";
import YouTrackProjectDetails from "../component/pages/youtrack/project/single";
import ApiKeyList from "../component/pages/apiKey/list";
import ConfluenceUserList from "../component/pages/confluence/user/list";
import ConfluenceGroupList from "../component/pages/confluence/group/list";
import ConfluenceGroupDetails from "../component/pages/confluence/group/single";
import ConfluenceUserDetails from "../component/pages/confluence/user/single";
import MiroUserList from "../component/pages/miro/user/list";
import MiroUserDetails from "../component/pages/miro/user/single";
import MiroTeamList from "../component/pages/miro/teams/list";
import MiroTeamDetails from "../component/pages/miro/teams/single";
import MiroBoardList from "../component/pages/miro/boards/list";
import MiroBoardDetails from "../component/pages/miro/boards/single";
import MiroProjectList from "../component/pages/miro/projects/list";
import MiroProjectDetails from "../component/pages/miro/projects/single";
import BroadcastCreate from "../component/pages/broadcast/create";
import BroadcastList from "../component/pages/broadcast/list";
import SingleBroadcast from "../component/pages/broadcast/single";
import OrganizationList from "../component/pages/organization/list";
import OrganizationCreate from "../component/pages/organization/create";
import OrganizationDetails from "../component/pages/organization/single";
import YoutrackSupportTicketsList from "../component/pages/youtrack/supportTickets/list";
import youtrackTicketDetails from "../component/pages/youtrack/supportTickets/single";
import { SupportTicketsReport } from "../component/pages/youtrack/supportTickets/report";
import GitlabInvitedUserList from "../component/pages/gitLab/invitedUser";
import BoxInvitedUserList from "../component/pages/box/invitedUser";
import AtlassianInvitedUserList from "../component/pages/atlassian/invitedUser";
import ConfluenceInvitedUserList from "../component/pages/confluence/invitedUser";
import MiroInvitedUserList from "../component/pages/miro/user/invited";
import YoutrackInvitedUserList from "../component/pages/youtrack/user/invited";
import OktaInvitedUserList from "../component/pages/okta/user/invited";
import PricingList from "../component/pages/pricing/list";
import OrganizationsPricingList from "../component/pages/pricing/organizationsCost";
import UserPricingList from "../component/pages/pricing/userCost";
import GcpUserList from "../component/pages/gcp/user/list";
import GCPInvitedUserList from "../component/pages/gcp/user/invitedUser";
import GCPUserDetails from "../component/pages/gcp/user/single";
import GCPGroupList from "../component/pages/gcp/group/list";
import GCPGroupDetails from "../component/pages/gcp/group/single";
import { PERMISSIONS, ROUTE } from "./constants";
import AWSUserList from "../component/pages/aws/user/list";
import AWSUserDetails from "../component/pages/aws/user/single";
import AWSInvitedUserList from "../component/pages/aws/user/invitedUser";
import AWSGroupList from "../component/pages/aws/group/list";
import AWSGroupDetails from "../component/pages/aws/group/single";
import SophosUserList from "../component/pages/sophos/user/list";
import SophosInvitedUserList from "../component/pages/sophos/user/invitedUser";
import SophosUserDetails from "../component/pages/sophos/user/single";
import SophosGroupList from "../component/pages/sophos/group/list";
import SophosGroupDetails from "../component/pages/sophos/group/single";
import InventoryManagement from "../component/pages/inventoryManagement";
import Activity from "../component/pages/reports/activity";
import AtlassianUserList from "../component/pages/atlassian/user/list";
import AtlassianUserDetails from "../component/pages/atlassian/user/single";
import GCPProjectBilling from "../component/pages/gcp/projectBilling";
import UserList from "../component/pages/user/list";
import UserDetails from "../component/pages/user/single";
import UpdateUser from "../component/pages/user/update";
import UserProfile from "../component/pages/user/profile";
import StaticReporting from "../component/pages/reports/reporting/static";
import DynamicReporting from "../component/pages/reports/reporting/dynamic";
import PageNotFound from "../component/pages/404";
import RoleList from "../component/pages/role/list";
import RoleDetails from "../component/pages/role/single";
import EditRole from "../component/pages/role/edit";
import AddRole from "../component/pages/role/add";
import LicenseHistoryList from "../component/pages/license/history/list";
import LicenseHistoryDetails from "../component/pages/license/history/single";
import LicenseSummaryDetails from "../component/pages/license/licenseSummary/single";
import PaymentList from "../component/pages/payment/list";
import PaymentDetails from "../component/pages/payment/single";
import PaymentCreate from "../component/pages/payment/create";
import PaymentEdit from "../component/pages/payment/edit";
import PaymentReport from "../component/pages/payment/report";
import AWSBilling from "../component/pages/aws/billings";
import OwnerList from "../component/pages/requestManagement/approver/list";
import OwnerCreate from "../component/pages/requestManagement/approver/create";
import OwnerUpdate from "../component/pages/requestManagement/approver/update";
import ConfluenceSpaceList from "../component/pages/confluence/space";
import MergeBaseUserList from "../component/pages/mergeBase";
import KlocworkUserList from "../component/pages/floatingLicense/klocwork";
import RenorexUserList from "../component/pages/floatingLicense/renorex";
import CreateRequest from "../component/pages/requestManagement/myRequest/create";
import RequestHistory from "../component/pages/requestManagement/myRequest/history";
import ApprovedRequestList from "../component/pages/approvalManagement/approved/list";
import AllRequestList from "../component/pages/approvalManagement/all/list";
import PendingRequestList from "../component/pages/approvalManagement/pending";
import RequestCreate from "../component/pages/requestManagement/myRequest/create";
import NotificationAndAlert from "../component/pages/notificationAndAlert";
import RequestDetails from "../component/pages/requestManagement/myRequest/single";
import ApprovedRequestDetails from "../component/pages/approvalManagement/approved/single";
import RequestsDetails from "../component/pages/approvalManagement/all/single";
import GitLabGroupDetails from "../component/pages/gitLab/group/single";

export const adminRoutes = [
  {
    path: "/",
    element: Dashboard,
    permission: PERMISSIONS.DASHBOARD_VIEW.title,
  },
  {
    path: ROUTE.LICENSE_MANAGEMENT,
    element: LicenseList,
    permission: PERMISSIONS.LICENSE_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.LICENSE_MANAGEMENT + "/:id",
    element: LicenseSummaryDetails,
    permission: PERMISSIONS.LICENSE_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.LICENSE_HISTORY_LIST,
    element: LicenseHistoryList,
    permission: PERMISSIONS.LICENSE_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.LICENSE_HISTORY_LIST + "/:id",
    element: LicenseHistoryDetails,
    permission: PERMISSIONS.LICENSE_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.OKTA_USER_LIST,
    element: OktaUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.OKTA_INVITED_USER_LIST,
    element: OktaInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.OKTA_APP_LIST,
    element: OktaAppList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.OKTA_GROUP_LIST,
    element: OktaGroupList,
    permission: PERMISSIONS.SAAS_GROUP_VIEW.title,
  },
  {
    path: ROUTE.OKTA_USER_LIST + "/:id",
    element: singleOktaUser,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.OKTA_APP_LIST + "/:id",
    element: SingleOktaApp,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.OKTA_GROUP_LIST + "/:id",
    element: SingleOktaGroup,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.USER_REGISTRATION,
    element: Registration,
    permission: PERMISSIONS.USER_REGISTRATION.title,
  },
  {
    path: ROUTE.BOX_USER_LIST,
    element: BoxUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.BOX_INVITED_USER_LIST,
    element: BoxInvitedUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.BOX_USER_LIST + "/:id",
    element: BoxUserLogHistory,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.GITLAB_USER_LIST,
    element: GitLabUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.GITLAB_GROUP_LIST,
    element: GitLabGroupList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.GITLAB_GROUP_LIST + "/:id",
    element: GitLabGroupDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.GITLAB_GROUP_STORAGE_COMPARISON,
    element: GroupStorageComparison,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.GITLAB_PROJECT_LIST,
    element: GitlabProjectList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.GITLAB_INVITED_USER_LIST,
    element: GitlabInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.YOUTRACK_USER_LIST,
    element: YoutrackUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.YOUTRACK_USER_LIST + "/:id",
    element: YouTrackUserDetails,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.YOUTRACK_INVITED_USER_LIST,
    element: YoutrackInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.YOUTRACK_GROUP_LIST,
    element: YoutrackGroupList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.YOUTRACK_PROJECT_LIST,
    element: YoutrackProjectList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.YOUTRACK_PROJECT_LIST + "/:id",
    element: YouTrackProjectDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.SUPPORT_TICKET_STATUS,
    element: SupportTicketsReport,
    permission: PERMISSIONS.SUPPORT_TICKETS_VIEW.title,
  },
  {
    path: ROUTE.SUPPORT_TICKETS_LIST,
    element: YoutrackSupportTicketsList,
    permission: PERMISSIONS.SUPPORT_TICKETS_VIEW.title,
  },
  {
    path: ROUTE.SUPPORT_TICKETS_LIST + "/:id",
    element: youtrackTicketDetails,
    permission: PERMISSIONS.SUPPORT_TICKETS_VIEW.title,
  },
  {
    path: ROUTE.API_KEY,
    element: ApiKeyList,
    permission: PERMISSIONS.API_KEY_VIEW.title,
  },
  {
    path: ROUTE.CONFLUENCE_USER_LIST,
    element: ConfluenceUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.CONFLUENCE_USER_LIST + "/:id",
    element: ConfluenceUserDetails,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.CONFLUENCE_INVITED_USER_LIST,
    element: ConfluenceInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.CONFLUENCE_GROUP_LIST,
    element: ConfluenceGroupList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.CONFLUENCE_GROUP_LIST + "/:id",
    element: ConfluenceGroupDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.CONFLUENCE_SPACE_LIST,
    element: ConfluenceSpaceList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.ATLASSIAN_USER_LIST,
    element: AtlassianUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.ATLASSIAN_USER_LIST + "/:id",
    element: AtlassianUserDetails,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.ATLASSIAN_INVITED_USER_LIST,
    element: AtlassianInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.MIRO_USER_LIST,
    element: MiroUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.MIRO_USER_LIST + "/:id",
    element: MiroUserDetails,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.MIRO_INVITED_USER_LIST,
    element: MiroInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.MIRO_TEAM_LIST,
    element: MiroTeamList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.MIRO_TEAM_LIST + "/:id",
    element: MiroTeamDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.MIRO_PROJECT_LIST,
    element: MiroProjectList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.MIRO_PROJECT_LIST + "/:id",
    element: MiroProjectDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.MIRO_BOARD_LIST,
    element: MiroBoardList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.MIRO_BOARD_LIST + "/:id",
    element: MiroBoardDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.BROADCAST_EMAIL,
    element: BroadcastCreate,
    permission: PERMISSIONS.BROADCAST_EMAIL_CREATE.title,
  },
  {
    path: ROUTE.BROADCAST_HISTORY,
    element: BroadcastList,
    permission: PERMISSIONS.BROADCAST_VIEW.title,
  },
  {
    path: ROUTE.BROADCAST_HISTORY + "/:id",
    element: SingleBroadcast,
    permission: PERMISSIONS.BROADCAST_VIEW.title,
  },
  {
    path: ROUTE.ACTIVITY_REPORT,
    element: Activity,
    permission: PERMISSIONS.ACTIVITY_REPORT_VIEW.title,
  },
  {
    path: ROUTE.STATIC_REPORTING,
    element: StaticReporting,
    permission: PERMISSIONS.STATIC_REPORT_VIEW.title,
  },
  {
    path: ROUTE.DYNAMIC_REPORTING,
    element: DynamicReporting,
    permission: PERMISSIONS.DYNAMIC_REPORT_VIEW.title,
  },
  {
    path: ROUTE.ORGANIZATION_LIST,
    element: OrganizationList,
    permission: PERMISSIONS.ORGANIZATION_VIEW.title,
  },
  {
    path: ROUTE.ORGANIZATION_CREATE,
    element: OrganizationCreate,
    permission: PERMISSIONS.ORGANIZATION_CREATE.title,
  },
  {
    path: ROUTE.ORGANIZATION_EDIT + "/:id",
    element: OrganizationCreate,
    permission: PERMISSIONS.ORGANIZATION_EDIT.title,
  },
  {
    path: ROUTE.ORGANIZATION_LIST + "/:id",
    element: OrganizationDetails,
    permission: PERMISSIONS.ORGANIZATION_VIEW.title,
  },
  {
    path: ROUTE.SAAS_TOOl_WISE_BUDGET,
    element: PricingList,
    permission: PERMISSIONS.SAAS_TOOL_WISE_BUDGET_VIEW.title,
  },
  {
    path: ROUTE.ORGANIZATIONS_WISE_BUDGET,
    element: OrganizationsPricingList,
    permission: PERMISSIONS.ORGANIZATION_WISE_BUDGET_VIEW.title,
  },
  {
    path: ROUTE.USER_WISE_BUDGET,
    element: UserPricingList,
    permission: PERMISSIONS.USER_WISE_BUDGET_VIEW.title,
  },
  {
    path: ROUTE.GCP_USER_LIST,
    element: GcpUserList,
    permission: PERMISSIONS.CLOUD_USER_VIEW.title,
  },
  {
    path: ROUTE.GCP_USER_LIST + "/:id",
    element: GCPUserDetails,
    permission: PERMISSIONS.CLOUD_USER_VIEW.title,
  },
  {
    path: ROUTE.GCP_INVITED_USER_LIST,
    element: GCPInvitedUserList,
    permission: PERMISSIONS.CLOUD_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.GCP_GROUP_LIST,
    element: GCPGroupList,
    permission: PERMISSIONS.CLOUD_GROUP_VIEW.title,
  },
  {
    path: ROUTE.GCP_GROUP_LIST + "/:id",
    element: GCPGroupDetails,
    permission: PERMISSIONS.CLOUD_GROUP_VIEW.title,
  },
  {
    path: ROUTE.AWS_USER_LIST,
    element: AWSUserList,
    permission: PERMISSIONS.CLOUD_USER_VIEW.title,
  },
  {
    path: ROUTE.AWS_USER_LIST + "/:id",
    element: AWSUserDetails,
    permission: PERMISSIONS.CLOUD_USER_VIEW.title,
  },
  {
    path: ROUTE.AWS_INVITED_USER_LIST,
    element: AWSInvitedUserList,
    permission: PERMISSIONS.CLOUD_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.AWS_GROUP_LIST,
    element: AWSGroupList,
    permission: PERMISSIONS.CLOUD_GROUP_VIEW.title,
  },
  {
    path: ROUTE.AWS_GROUP_LIST + "/:id",
    element: AWSGroupDetails,
    permission: PERMISSIONS.CLOUD_GROUP_VIEW.title,
  },
  {
    path: ROUTE.AWS_BILLING,
    element: AWSBilling,
    permission: PERMISSIONS.STATIC_REPORT_VIEW.title,
  },
  {
    path: ROUTE.SOPHOS_USER_LIST,
    element: SophosUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.SOPHOS_USER_LIST + "/:id",
    element: SophosUserDetails,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.SOPHOS_INVITED_USER_LIST,
    element: SophosInvitedUserList,
    permission: PERMISSIONS.SAAS_INVITED_USER_VIEW.title,
  },
  {
    path: ROUTE.SOPHOS_GROUP_LIST,
    element: SophosGroupList,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.SOPHOS_GROUP_LIST + "/:id",
    element: SophosGroupDetails,
    permission: PERMISSIONS.SAAS_PROJECT_VIEW.title,
  },
  {
    path: ROUTE.KLOCWORK,
    element: KlocworkUserList,
    permission: PERMISSIONS.FLOATING_LICENSE_VIEW.title,
  },
  {
    path: ROUTE.RENOREX,
    element: RenorexUserList,
    permission: PERMISSIONS.FLOATING_LICENSE_VIEW.title,
  },
  {
    path: ROUTE.INVENTORY_MANAGEMENT,
    element: InventoryManagement,
    permission: PERMISSIONS.INVENTORY_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.GCP_PROJECT_BILLING_INFO,
    element: GCPProjectBilling,
    permission: PERMISSIONS.STATIC_REPORT_VIEW.title,
  },
  {
    path: ROUTE.UM_TOOL_USER_LIST,
    element: UserList,
    permission: PERMISSIONS.USER_VIEW.title,
  },
  {
    path: ROUTE.UM_TOOL_USER_LIST + "/:id",
    element: UserDetails,
    permission: PERMISSIONS.USER_VIEW.title,
  },
  {
    path: ROUTE.UM_TOOL_USER_LIST + ROUTE.EDIT,
    element: UpdateUser,
    permission: PERMISSIONS.USER_EDIT.title,
  },
  {
    path: ROUTE.USER_PROFILE,
    element: UserProfile,
    permission: PERMISSIONS.USER_PROFILE.title,
  },
  {
    path: ROUTE.UM_TOOL_USER_ROLE_LIST,
    element: RoleList,
    permission: PERMISSIONS.ROLE_VIEW.title,
  },

  {
    path: ROUTE.UM_TOOL_USER_ROLE_LIST + ROUTE.ROLE_CREATE,
    element: AddRole,
    permission: PERMISSIONS.ROLE_CREATE.title,
  },
  {
    path: ROUTE.UM_TOOL_USER_ROLE_LIST + ROUTE.ROLE_EDIT,
    element: EditRole,
    permission: PERMISSIONS.ROLE_EDIT.title,
  },
  {
    path: ROUTE.UM_TOOL_USER_ROLE_LIST + "/:id",
    element: RoleDetails,
    permission: PERMISSIONS.ROLE_VIEW.title,
  },
  {
    path: ROUTE.PAYMENT_HISTORY,
    element: PaymentList,
    permission: PERMISSIONS.PAYMENT_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.PAYMENT_HISTORY + "/:id",
    element: PaymentDetails,
    permission: PERMISSIONS.PAYMENT_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.PAYMENT_HISTORY + ROUTE.PAYMENT_CREATE,
    element: PaymentCreate,
    permission: PERMISSIONS.PAYMENT_MANAGEMENT_CREATE.title,
  },
  {
    path: ROUTE.PAYMENT_HISTORY + ROUTE.PAYMENT_EDIT,
    element: PaymentEdit,
    permission: PERMISSIONS.PAYMENT_MANAGEMENT_EDIT.title,
  },
  {
    path: ROUTE.PAYMENT_REPORT,
    element: PaymentReport,
    permission: PERMISSIONS.PAYMENT_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.APPROVER_LIST,
    element: OwnerList,
    permission: PERMISSIONS.APPROVER_VIEW.title,
  },
  {
    path: ROUTE.APPROVER_LIST + ROUTE.ADD,
    element: OwnerCreate,
    permission: PERMISSIONS.APPROVER_CREATE.title,
  },
  {
    path: ROUTE.APPROVER_LIST + ROUTE.EDIT,
    element: OwnerUpdate,
    permission: PERMISSIONS.APPROVER_EDIT.title,
  },
  {
    path: ROUTE.REQUEST_HISTORY + ROUTE.CREATE_REQUEST,
    element: CreateRequest,
    permission: PERMISSIONS.REQUEST_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.REQUEST_HISTORY,
    element: RequestHistory,
    permission: PERMISSIONS.REQUEST_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.REQUEST_HISTORY + "/:id",
    element: RequestDetails,
    permission: PERMISSIONS.REQUEST_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.REQUEST_LIST + ROUTE.ADD,
    element: RequestCreate,
    permission: PERMISSIONS.REQUEST_MANAGEMENT_CREATE.title,
  },
  {
    path: ROUTE.MERGEBASE,
    element: MergeBaseUserList,
    permission: PERMISSIONS.SAAS_USER_VIEW.title,
  },
  {
    path: ROUTE.APPROVED_REQUEST_LIST,
    element: ApprovedRequestList,
    permission: PERMISSIONS.APPROVAL_MANAGEMENT_APPROVED_VIEW.title,
  },
  {
    path: ROUTE.APPROVED_REQUEST_LIST + "/:id",
    element: ApprovedRequestDetails,
    permission: PERMISSIONS.APPROVAL_MANAGEMENT_APPROVED_VIEW.title,
  },
  {
    path: ROUTE.ALL_REQUEST_LIST,
    element: AllRequestList,
    permission: PERMISSIONS.APPROVAL_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.ALL_REQUEST_LIST + "/:id",
    element: RequestsDetails,
    permission: PERMISSIONS.APPROVAL_MANAGEMENT_VIEW.title,
  },
  {
    path: ROUTE.PENDING_REQUEST_LIST,
    element: PendingRequestList,
    permission: PERMISSIONS.APPROVAL_MANAGEMENT_PENDING_VIEW.title,
  },
  {
    path: ROUTE.NOTIFICATION_ALERTS,
    element: NotificationAndAlert,
    permission: PERMISSIONS.NOTIFICATION_ALERTS_VIEW.title,
  },
  {
    path: "*",
    element: PageNotFound,
  },
];
