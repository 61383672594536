import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { useBroadcastListQuery } from "../../../../store/broadcast/broadcastSlice";
import { useNavigate } from "react-router-dom";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { hasPermission, pascalToSnakeCase } from "../../../../utils";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { PAGE_NAME, PERMISSIONS, ROUTE } from "../../../../constants/constants";
import { TableDataType } from "../../../../types";

const BroadcastList = () => {
    const pageClassName = "p-broadcastList";
    const navigate = useNavigate();
    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "Type",
            dataIndex: "type",
            isSortable: true,
        },
        {
            title: "Group Name",
            dataIndex: "groupName",
            isSortable: true,
        },
        {
            title: "Subject",
            dataIndex: "subject",
            isSortable: true,
        },

        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: true,
        }
    ];

    const viewHandler = (id: string) => {
        const name = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        ).subject;
        navigate(ROUTE.BROADCAST_HISTORY + `/${id}`, { state: { name } });
    };

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useBroadcastListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(query.sortBy),
        sort_direction: query.sortDirection,
    });


    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.BROADCAST_HISTORY}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                    {hasPermission(PERMISSIONS.BROADCAST_EMAIL_CREATE.title) && (
                        <button
                            className={`${pageClassName}__button`}
                            onClick={() =>
                                navigate(ROUTE.BROADCAST_EMAIL)
                            }
                        >
                            Create Broadcast Email
                        </button>
                    )}
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        minWidth="1000"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        viewHandler={viewHandler}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>
                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BroadcastList;
