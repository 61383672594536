import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { PAGE_NAME } from "../../../../../constants/constants";
import { UnderConstruction } from "../../../../organisms/underConstruction";

const DynamicReporting= () => {
    const pageClassName = "p-dynamicReporting";

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.DYNAMIC_REPORTING}</p>
            <div className={`${pageClassName}__container`}>
                <UnderConstruction />
            </div>
        </div>
    );
};

export default DynamicReporting;
