import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formattedDateTime } from "../../../../../utils";
import { Divider } from "../../../../atoms/divider";
import { useSingleOktaGroupQuery } from "../../../../../store/okta/groupSlice";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Table } from "../../../../atoms/table";
import { Skeleton } from "../../../../atoms/skeleton";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";

const SingleOktaGroup = () => {
  const pageClassName = "p-singleOktaGroup";
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useSingleOktaGroupQuery(id as string);

  const userTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      isSortable: false,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      isSortable: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      isSortable: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      isSortable: false,
    },
    {
      title: "Created",
      dataIndex: "created",
      isSortable: false,
    },
  ];

  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      isSortable: false,
    },
    {
      title: "Created",
      dataIndex: "created",
      isSortable: false,
    },
  ];

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.OKTA_GROUP_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__info`}>
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>Name</p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.name ?? <Skeleton height="small" />}
            </p>
          </div>
          {data?.result?.users?.length !== 0 && (
            <div className={`${pageClassName}__container__item`}>
              <p className={`${pageClassName}__container__item__title`}>
                Number of Users
              </p>
              <p className={`${pageClassName}__container__item__value`}>
                {data?.result?.users?.length ?? <Skeleton height="small" />}
              </p>
            </div>
          )}
          {data?.result?.apps?.length !== 0 && (
            <div className={`${pageClassName}__container__item`}>
              <p className={`${pageClassName}__container__item__title`}>
                Number of Apps
              </p>
              <p className={`${pageClassName}__container__item__value`}>
                {data?.result?.apps?.length ?? <Skeleton height="small" />}
              </p>
            </div>
          )}
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Created
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.created ? (
                formattedDateTime(data?.result?.created)
              ) : (
                <Skeleton height="small" />
              )}
            </p>
          </div>
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Last Updated
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.lastUpdated ? (
                formattedDateTime(data?.result?.lastUpdated)
              ) : (
                <Skeleton height="small" />
              )}
            </p>
          </div>
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>Logo</p>
            <img src={data?.result?.logo} alt={data?.result?.label} />
          </div>
        </div>
        {data?.result?.users?.length > 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__users`}>
              <p className={`${pageClassName}__users__title`}>Users</p>
              {/* <div className={`${pageClassName}__users__container`}>
                {data?.result?.users?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`${pageClassName}__users__container__item`}
                    onClick={() => {
                      navigate(`/user-data-list/${item.id}`);
                    }}
                  >
                    <div
                      className={`${pageClassName}__users__container__item__info`}
                    >
                      <p
                        className={`${pageClassName}__users__container__item__title`}
                      >
                        {item.firstName} {item.lastName}
                      </p>
                      <p
                        className={`${pageClassName}__users__container__item__title`}
                      >
                        {item.email}
                      </p>
                      <p
                        className={`${pageClassName}__users__container__item__value`}
                      >
                        <Status status={item.status} />
                      </p>
                    </div>
                  </div>
                ))}
              </div> */}
              <Table
                header={userTableHeader}
                data={data?.result?.users ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                viewHandler={(id: string) =>
                  navigate(ROUTE.OKTA_USER_LIST + `/${id}`)
                }
                startIndex={1}
                cursorPointer
              />
            </div>
          </>
        )}
        {data?.result?.apps?.length > 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Apps</p>
              {/* <div className={`${pageClassName}__apps__container`}>
                {data?.result?.apps?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`${pageClassName}__apps__container__item`}
                    onClick={() => {
                      navigate(`/app-data-list/${item.id}`);
                    }}
                  >
                    <img src={item.logo} alt={item.label} />
                    <div
                      className={`${pageClassName}__apps__container__item__info`}
                    >
                      <p
                        className={`${pageClassName}__apps__container__item__title`}
                      >
                        {item.label}
                      </p>
                      <p
                        className={`${pageClassName}__apps__container__item__value`}
                      >
                        <Status status={item.status} />
                      </p>
                    </div>
                  </div>
                ))}
              </div> */}
              <Table
                header={appsTableHeader}
                data={data?.result?.apps ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                viewHandler={(id) => navigate(ROUTE.OKTA_APP_LIST + `/${id}`)}
                startIndex={1}
                cursorPointer
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default SingleOktaGroup;
