import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["broadcastList", "broadcast", "broadcastUsers"],
});

export const broadcastSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * Creates a new broadcast.
         *
         * @param payload - The data for the new broadcast.
         * @returns A promise that resolves to the response data of the created broadcast.
         */
        createBroadcast: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.BROADCAST,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["broadcastList", "broadcast"],
        }),
        /**
         * Retrieves a list of broadcasts from the server.
         *
         * @param search - The search query to filter the broadcasts.
         * @param limit - The maximum number of broadcasts to retrieve.
         * @param current_page - The current page of the broadcast list.
         * @param sort_by - The field to sort the broadcasts by.
         * @param sort_direction - The direction to sort the broadcasts in.
         * @returns A promise that resolves to the response data containing the list of broadcasts.
         */
        broadcastList: builder.query<ResponseDataType, listDataType>({
            query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
                url: API_URL.BROADCAST + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
                    }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
                    }${search ? `&search=${search}` : ""}`,
                method: "GET",
            }),
            providesTags: ["broadcastList"],
        }),
        /**
         * Fetches a single broadcast by its ID.
         *
         * @param id - The ID of the broadcast to fetch.
         * @returns A promise that resolves to the response data of the fetched broadcast.
         */
        singleBroadcast: builder.query<ResponseDataType, number>({
            query: (id) => ({
                url: API_URL.BROADCAST + `/${id}`,
                method: "GET",
            }),
            providesTags: ["broadcast"],
        }),

        /**
         * Query function to fetch broadcast users.
         *
         * @param id - The ID of the broadcast.
         * @param search - The search query for filtering users.
         * @param limit - The maximum number of users to fetch.
         * @param current_page - The current page number for pagination.
         * @param sort_by - The field to sort the users by.
         * @param sort_direction - The direction to sort the users in.
         * @returns A promise that resolves to the response data.
         */
        broadcastUsers: builder.query<ResponseDataType, listDataType>({
            query: ({
                id,
                search,
                limit,
                current_page,
                sort_by,
                sort_direction,
            }) => ({
                url: API_URL.BROADCAST + `/${id}/users?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
                    }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
                    }${search ? `&search=${search}` : ""}`,
                method: "GET",
            }),
            providesTags: ["broadcastUsers"],
        }),
    }),
});

export const {
    useCreateBroadcastMutation,
    useBroadcastListQuery,
    useSingleBroadcastQuery,
    useBroadcastUsersQuery,
} = broadcastSlice;
