import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import {
    PAGE_NAME,
    ROUTE,
    SOMETHING_WENT_WRONG,
} from "../../../../../constants/constants";
import { Dropdown } from "../../../../atoms/dropdown";
import { Icon } from "../../../../atoms/icon";
import { Divider } from "../../../../atoms/divider";
import { AddUsersRequest } from "../../../../organisms/requestManagement/licenseManagement/addUsers";
import { Textarea } from "../../../../atoms/textarea";
import {
    useApproverListQuery,
    useCreateUserRequestMutation,
} from "../../../../../store/requestManagement/requestSlice";
import { ResponseType } from "../../../../../types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../../molecules/modal";
import { RemoveUsersRequest } from "../../../../organisms/requestManagement/licenseManagement/removeUsers";
import { ProjectAndTeamManagementRequest } from "../../../../organisms/requestManagement/project&TeamManagement";
import { Loader } from "../../../../atoms/loader";
import { PermissionManagementRequest } from "../../../../organisms/requestManagement/permissionManagement";

export const requestTypeOptions = [
    { label: "User & License Management", value: "User & License Management" },
    { label: "Project & Team Management", value: "Project & Team Management" },
    { label: "Permission Management", value: "Permission Management" },
    { label: "SaaS Tool Configuration", value: "SaaS Tool Configuration" },
];

export const subTypeOptionsUserLicense = [
    { label: "Add Users", value: "Add Users" },
    { label: "Remove Users", value: "Remove Users" },
];

export const subTypeOptionsProjectTeam = [
    { label: "Project Creation", value: "Project Creation" },
    { label: "Group Creation", value: "Group Creation" },
    { label: "Space Creation", value: "Space Creation" },
    { label: "Team Creation", value: "Team Creation" },
    { label: "Dashboard Creation", value: "Dashboard Creation" },

    { label: "Project Modification", value: "Project Modification" },
    { label: "Group Modification", value: "Group Modification" },
    { label: "Space Modification", value: "Space Modification" },
    { label: "Team Modification", value: "Team Modification" },
    { label: "Dashboard Modification", value: "Dashboard Modification" },

    { label: "Project Deletion", value: "Project Deletion" },
    { label: "Group Deletion", value: "Group Deletion" },
    { label: "Space Deletion", value: "Space Deletion" },
    { label: "Team Deletion", value: "Team Deletion" },
    { label: "Dashboard Deletion", value: "Dashboard Deletion" },
];

export const subTypeOptionsPermission = [
    { label: "Assign Permission", value: "Assign Permission" },
    { label: "Modify Permission", value: "Modify Permission" },
    { label: "Remove Permission", value: "Remove Permission" },
];

export const subTypeOptionsSaaS = [
    { label: "Configuration", value: "Configuration" },
];

const RequestCreate = () => {
    const pageClassName = "p-requestCreate";
    const navigate = useNavigate();

    const [payload, setPayload] = React.useState<any>({});
    const [subTypeOptions, setSubTypeOptions] = React.useState<any>([]);

    const [isOpenErrorModal, setIsOpenErrorModal] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState<any>([]);

    const { data: approverList } = useApproverListQuery({});
    const [createAPI, { isLoading: createLoading }] =
        useCreateUserRequestMutation();

    const requestTypeChangeHandler = (val: any) => {
        setPayload({
            ...payload,
            requestType: {
                value: val,
                isError: false,
                error: "",
            },
            subType: {
                value: "",
                isError: false,
                error: "",
            },
        });
        if (val.value === "User & License Management") {
            setSubTypeOptions(subTypeOptionsUserLicense);
        } else if (val.value === "Project & Team Management") {
            setSubTypeOptions(subTypeOptionsProjectTeam);
        } else if (val.value === "Permission Management") {
            setSubTypeOptions(subTypeOptionsPermission);
        } else if (val.value === "SaaS Tool Configuration") {
            setSubTypeOptions(subTypeOptionsSaaS);
            setPayload({
                ...payload,
                subType: {
                    value: subTypeOptionsSaaS[0],
                    isError: false,
                    error: "",
                },
            });
        }
    };

    const subTypeChangeHandler = (val: any) => {
        if (val.value === "Add Users") {
            setPayload({
                ...payload,
                subType: {
                    value: val,
                    isError: false,
                    error: "",
                },
                users: {
                    value: [
                        {
                            first_name: "",
                            last_name: "",
                            full_name: "",
                            email: "",
                            designation: "",
                            department: "",
                            address: "",
                            manager: "",
                            saas_tools: [
                                {
                                    name: {
                                        value: "Okta",
                                        label: "Okta",
                                    },
                                },
                                {
                                    name: {
                                        value: "UR&AM Tool",
                                        label: "UR&AM Tool",
                                    },
                                    role: {
                                        value: "User",
                                        label: "User",
                                    },
                                },
                            ],
                        },
                    ],
                    isError: false,
                    error: "",
                },
            });
        } else if (val.value === "Remove Users") {
            setPayload({
                ...payload,
                subType: {
                    value: val,
                    isError: false,
                    error: "",
                },
                users: {
                    value: [
                        {
                            email: "",
                            saas_tools: [],
                        },
                    ],
                    isError: false,
                    error: "",
                },
            });
        } else if (
            [
                "Project Creation",
                "Group Creation",
                "Space Creation",
                "Team Creation",
                "Dashboard Creation",
                "Project Deletion",
                "Group Deletion",
                "Space Deletion",
                "Team Deletion",
                "Dashboard Deletion",
            ].includes(val.value)
        ) {
            setPayload({
                ...payload,
                subType: {
                    value: val,
                    isError: false,
                    error: "",
                },
                saas_tools: {
                    value: [
                        {
                            name: "",
                            entity_name: "",
                            approver: [],
                        },
                    ],
                    isError: false,
                    error: "",
                },
            });
        } else if (
            [
                "Project Modification",
                "Group Modification",
                "Space Modification",
                "Team Modification",
                "Dashboard Modification",
            ].includes(val.value)
        ) {
            setPayload({
                ...payload,
                subType: {
                    value: val,
                    isError: false,
                    error: "",
                },
                saas_tools: {
                    value: [
                        {
                            name: "",
                            entity_name: "",
                            new_name: "",
                            approver: [],
                        },
                    ],
                    isError: false,
                    error: "",
                },
            });
        } else if( ["Assign Permission", "Modify Permission", "Remove Permission"].includes(val.value) ) {
            setPayload({
                ...payload,
                subType: {
                    value: val,
                    isError: false,
                    error: "",
                },
                user:{
                    value: "",
                    isError: false,
                    error: "",
                },
                saas_tools: {
                    value: [
                        {
                            name: "",
                            type: "",
                            entity_name: "",
                            role: "",
                            approver: [],
                        },
                    ],
                    isError: false,
                    error: "",
                },
            });
        }
        else {
            setPayload({
                ...payload,
                subType: {
                    value: val,
                    isError: false,
                    error: "",
                },
            });
        }
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append("type", payload.requestType.value.value);
        formData.append("sub_type", payload.subType.value.value);
        payload?.note?.value && formData.append("notes", payload?.note?.value);
        if (payload?.subType?.value?.value === "Add Users") {
            formData.append("requests", JSON.stringify(payload?.users?.value));
        } else if (payload?.subType?.value?.value === "Remove Users") {
            const users = payload?.users?.value.map((user: any) => {
                return {
                    email: user.email,
                    saas_tools: user.saas_tools,
                };
            });
            formData.append("requests", JSON.stringify(users));
        } else if (
            payload?.requestType?.value?.value === "Project & Team Management" &&
            [
                "Project Creation",
                "Group Creation",
                "Space Creation",
                "Team Creation",
                "Dashboard Creation",
                "Project Modification",
                "Group Modification",
                "Space Modification",
                "Team Modification",
                "Dashboard Modification",
                "Project Deletion",
                "Group Deletion",
                "Space Deletion",
                "Team Deletion",
                "Dashboard Deletion",
            ].includes(payload.subType?.value?.value)
        ) {
            const requests = payload?.saas_tools?.value.map((element: any) => {
                delete element?.entity_name?.approver;
                return element;
            });
            formData.append("requests", JSON.stringify(requests));
        }else if(([
            'Assign Permission',
            'Modify Permission',
            'Remove Permission'
        ]).includes(payload.subType?.value?.value)){
            const requests = {
                user: payload?.user?.value,
                saas_tools: payload?.saas_tools?.value
            }
            formData.append("requests", JSON.stringify(requests));
        }

        const response = (await createAPI(formData)) as ResponseType;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate(ROUTE.REQUEST_HISTORY);
        } else {
            if (response?.error?.data?.errors) {
                if (
                    Array.isArray(response?.error?.data?.errors?.requests) &&
                    response?.error?.data?.errors?.requests.length > 0
                ) {
                    setErrorMessages(response?.error?.data?.errors?.requests);
                    setIsOpenErrorModal(true);
                    return;
                }
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState: any) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                });
            } else {
                toast.error(response?.error?.data?.message);
            }
            if (response?.error?.data?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.REQUEST_CREATE}</p>
            <div className={`${pageClassName}__container`}>
                <Loader isLoading={createLoading}>
                    <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                        <div className={`${pageClassName}__form__items`}>
                            <div className={`${pageClassName}__form__item`}>
                                <label className={`${pageClassName}__form__item__label`}>
                                    Request Type
                                </label>
                                <Dropdown
                                    options={requestTypeOptions}
                                    selected={payload?.requestType?.value}
                                    placeholder="Please select request type"
                                    onClickChange={requestTypeChangeHandler}
                                    className={`${pageClassName}__form__item__dropdown`}
                                    isRequired
                                    isError={payload?.requestType?.isError}
                                    error={payload?.requestType?.error}
                                />
                            </div>
                            <div className={`${pageClassName}__form__item`}>
                                <label className={`${pageClassName}__form__item__label`}>
                                    Sub Type
                                </label>
                                <div className={`${pageClassName}__form__item__subType`}>
                                    <Dropdown
                                        className={`${pageClassName}__form__item__dropdown`}
                                        options={subTypeOptions}
                                        selected={payload?.subType?.value}
                                        placeholder="Please select request sub type"
                                        onClickChange={subTypeChangeHandler}
                                        isDisabled={
                                            payload?.subType?.value?.value === "Configuration"
                                                ? true
                                                : false
                                        }
                                        isRequired
                                    />

                                    <Icon icon="information" size="primary" />
                                </div>
                            </div>
                        </div>
                        <Divider />

                        {payload?.requestType?.value?.value ===
                            "User & License Management" &&
                            payload.subType?.value?.value === "Add Users" && (
                                <AddUsersRequest
                                    payload={payload}
                                    setPayload={setPayload}
                                    approverList={approverList}
                                />
                            )}

                        {payload?.requestType?.value?.value ===
                            "User & License Management" &&
                            payload.subType?.value?.value === "Remove Users" && (
                                <RemoveUsersRequest
                                    payload={payload}
                                    setPayload={setPayload}
                                    approverList={approverList}
                                />
                            )}

                        {payload?.requestType?.value?.value ===
                            "Project & Team Management" &&
                            [
                                "Project Creation",
                                "Group Creation",
                                "Space Creation",
                                "Team Creation",
                                "Dashboard Creation",
                                "Project Modification",
                                "Group Modification",
                                "Space Modification",
                                "Team Modification",
                                "Dashboard Modification",
                                "Project Deletion",
                                "Group Deletion",
                                "Space Deletion",
                                "Team Deletion",
                                "Dashboard Deletion",
                            ].includes(payload.subType?.value?.value) && (
                                <ProjectAndTeamManagementRequest
                                    payload={payload}
                                    setPayload={setPayload}
                                    approverList={approverList}
                                />
                            )}

                        {payload?.requestType?.value?.value ===
                            "Permission Management" && 
                            ["Assign Permission", "Modify Permission", "Remove Permission"].includes(payload.subType?.value?.value) && (
                                <PermissionManagementRequest
                                    payload={payload}
                                    setPayload={setPayload}
                                    approverList={approverList}
                                />
                            )}
                        <Textarea
                            label="Notes"
                            placeholder="Please provide notes"
                            value={payload?.note?.value}
                            isError={payload?.note?.isError}
                            error={payload?.note?.error}
                            onChange={(e: any) =>
                                setPayload({
                                    ...payload,
                                    note: {
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                        />

                        <div className={`${pageClassName}__form__action`}>
                            <button
                                type="submit"
                                className={`${pageClassName}__form__action__button`}
                                disabled={createLoading}
                            >
                                Save
                            </button>

                            <button
                                type="button"
                                className={`${pageClassName}__form__action__button ${pageClassName}__form__action--cancel`}
                                onClick={() => navigate(ROUTE.REQUEST_HISTORY)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </Loader>
            </div>

            <Modal
                isOpen={isOpenErrorModal}
                header="Error Message"
                width="small"
                headerColor="error"
                onClose={() => setIsOpenErrorModal(!isOpenErrorModal)}
                className={`${pageClassName}__modal`}
            >
                <div className={`${pageClassName}__modal__body`}>
                    <p className={`${pageClassName}__modal__body__message`}>
                        We found {errorMessages.length} error
                        {errorMessages.length > 1 ? "s" : ""} in your request. Please
                        correct them and try again.
                    </p>
                    {errorMessages.map((element: string, index: number) => {
                        return (
                            <div key={element} className={`${pageClassName}__modal__error`}>
                                <p className={`${pageClassName}__modal__error__serial`}>
                                    {index + 1}.
                                </p>
                                <p className={`${pageClassName}__modal__error__message`}>
                                    {element}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </div>
    );
};

export default RequestCreate;
