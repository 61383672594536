import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";
import Select, { components } from "react-select";
import { Tooltip } from "../tooltip";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = React.useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    columnGap: "8px",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} onChange={() => {}} />
      {children}
    </components.Option>
  );
};

const customStyles = (isError: boolean) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    border: isError ? "1px solid #FF0000" : "1px solid #00000040",
    overflow: "auto",
    borderRadius: "6px",
  }),

  indicatorSeparator: (state: any) => ({
    display: "none",
  }),

  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    // display: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ?  "#e1defb" : "white",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#D11A2A",
      color: "white",
    },
  }),
});

export interface MultipleDropdownProps extends BaseComponentProps {
  className?: string;
  value: { label: string; value: string }[];
  dropdownHandler: (selected: { label: string; value: string }[]) => void;
  options: { label: string; value: string }[];
  isError?: boolean;
  isRequired?: boolean;
  error?: string;
}
export const MultipleDropdown = ({
  className,
  value,
  dropdownHandler,
  options,
  isError = false,
  isRequired = false,
  error,
}: MultipleDropdownProps) => {
  const componentClassName = "a-multipleDropdown";
  const modifiers = {
    error: isError,
  };
  const { Container } = createComponentBase(componentClassName, "div");
  return (
    <Container className={className} modifiers={modifiers}>
      <Tooltip message={error} isVisible={isError}>
        <Select
          value={value}
          onChange={(selected) =>
            dropdownHandler(selected as { label: string; value: string }[])
          }
          options={options}
          hideSelectedOptions={false}
          isMulti
          styles={customStyles(isError)}
          className={"react-select"}
          classNamePrefix="react-select"
          required={isRequired}
          components={{ Option: InputOption }}
        />
      </Tooltip>
    </Container>
  );
};
