import React from "react";
import { useSingleOktaUserQuery } from "../../../../../store/okta/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { formattedDateTime } from "../../../../../utils";
import { Status } from "../../../../atoms/status";
import { Divider } from "../../../../atoms/divider";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Table } from "../../../../atoms/table";
import { Skeleton } from "../../../../atoms/skeleton";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";

const SingleOktaUser = () => {
  const pageClassName = "p-singleOktaUser";
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useSingleOktaUserQuery({ id });

  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      isSortable: false,
    },
    {
      title: "Created",
      dataIndex: "created",
      isSortable: false,
    },
  ];

  const groupsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: false,
    },
    {
      title: "Created",
      dataIndex: "created",
      isSortable: false,
    },
  ];

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.OKTA_USER_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            First Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.firstName ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.lastName ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Email</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.email ?? <Skeleton height="small" />}
          </p>
        </div>
        {data?.result?.secondEmail && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Second Email
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.secondEmail}
            </p>
          </div>
        )}
        {data?.result?.mobilePhone && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Mobile Phone
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.mobilePhone}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Status</p>
          {data?.result?.status ? (
            <Status status={data?.result?.status} />
          ) : (
            <Skeleton height="small" />
          )}
        </div>
        {data?.result?.apps?.length !== 0 && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Number of Apps
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.apps?.length ?? <Skeleton height="small" />}
            </p>
          </div>
        )}
        {data?.result?.groups?.length !== 0 && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Number of Groups
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {data?.result?.groups?.length ?? <Skeleton height="small" />}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.created ? (
              formattedDateTime(data?.result?.created)
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Activated
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.activated
              ? formattedDateTime(data?.result?.activated)
              : "-"}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Login
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.lastLogin
              ? formattedDateTime(data?.result?.lastLogin)
              : "-"}
          </p>
        </div>
        {data?.result?.passwordChanged && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Password Changed
            </p>
            <p className={`${pageClassName}__container__item__value`}>
              {formattedDateTime(data?.result?.passwordChanged)}
            </p>
          </div>
        )}
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Status Changed
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.statusChanged
              ? formattedDateTime(data?.result?.statusChanged)
              : "-"}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Last Updated
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.lastUpdated
              ? formattedDateTime(data?.result?.lastUpdated)
              : "-"}
          </p>
        </div>
        {data?.result?.apps?.length !== 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Apps</p>
              {/* <div className={`${pageClassName}__apps__container`}>
                {data?.result?.apps?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`${pageClassName}__apps__container__item`}
                    onClick={() => {
                      navigate(`/app-data-list/${item.id}`);
                    }}
                  >
                    <img src={item.logo} alt={item.label} />
                    <div
                      className={`${pageClassName}__apps__container__item__info`}
                    >
                      <p
                        className={`${pageClassName}__apps__container__item__title`}
                      >
                        {item.label}
                      </p>
                      <p
                        className={`${pageClassName}__apps__container__item__value`}
                      >
                        <Status status={item.status} />
                      </p>
                    </div>
                  </div>
                ))}
              </div> */}

              <Table
                header={appsTableHeader}
                data={data?.result?.apps ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                sortHandler={() => {}}
                startIndex={1}
                viewHandler={(id) => navigate(ROUTE.OKTA_APP_LIST + `/${id}`)}
                cursorPointer
              />
            </div>
          </>
        )}
        {data?.result?.groups?.length !== 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__groups`}>
              <p className={`${pageClassName}__groups__title`}>Groups</p>
              {/* <div className={`${pageClassName}__groups__container`}>
                {data?.result?.groups?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`${pageClassName}__groups__container__item`}
                    onClick={() => {
                      navigate(`/group-data-list/${item.id}`);
                    }}
                  >
                    <p
                      className={`${pageClassName}__groups__container__item__title`}
                    >
                      {item?.name}
                    </p>
                  </div>
                ))}
              </div> */}
              <Table
                header={groupsTableHeader}
                data={data?.result?.groups ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                sortHandler={() => {}}
                startIndex={1}
                viewHandler={(id) => navigate(ROUTE.OKTA_GROUP_LIST+`/${id}`)}
                cursorPointer
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default SingleOktaUser;
