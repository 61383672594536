import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { useSingleSaasToolGroupQuery } from "../../../../../store/saasTool/saasToolSlice";
import { PAGE_NAME } from "../../../../../constants/constants";

const ConfluenceGroupDetails = () => {
  const pageClassName = "p-confluenceGroupDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useSingleSaasToolGroupQuery({ id });

  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      isSortable: false,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      isSortable: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      isSortable: false,
    }
  ];


  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.CONFLUENCE_GROUP_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Group ID
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.toolGroupId ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Group Name
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.name ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Tool Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.toolName ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Created</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.createdAt ? formattedDateTime(data?.result?.createdAt) : <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Updated
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.updatedAt ? formattedDateTime(data?.result?.updatedAt) : <Skeleton height="small" />}
          </p>
        </div>

        {data?.result?.users?.length !== 0 && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Users</p>

              <Table
                header={appsTableHeader}
                data={data?.result?.users ?? []}
                loading={isLoading}
                firstLoad={isLoading}
                sortHandler={() => { }}
                startIndex={1}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfluenceGroupDetails;