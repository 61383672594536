import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";
import { Icon } from "../icon";
import { Divider } from "../divider";

export interface DialogProps extends BaseComponentProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  successFn: () => void;
  header: string;
  body: React.ReactNode;
  btn: string;
  icon: string;
  color?: "green" 
}

export const Dialog = ({
  className,
  isOpen,
  onClose,
  header,
  body,
  btn,
  successFn,
  icon,
  color,
}: DialogProps) => {
  const componentClassName = "a-Dialog";
  const modifiers = {
    [color + "Color"]: !!color,
  };
  const { Container } = createComponentBase(componentClassName, "div");

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isOpen &&
        event.target.className === `${componentClassName}__container`
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <Container className={className} modifiers={modifiers}>
      {isOpen && (
        <div className={`${componentClassName}__container`}>
          <div className={`${componentClassName}__content`}>
            <div className={`${componentClassName}__header`}>
              <p className={`${componentClassName}__header__title`}>{header}</p>
              <div
                className={`${componentClassName}__header__icon`}
                onClick={onClose}
              >
                <Icon icon="cross" />
              </div>
            </div>
            <Divider />
            <div className={`${componentClassName}__body`}>
              <div className={`${componentClassName}__body__icon`}>
                <Icon icon={icon} size="tertiary" />
              </div>
              {body}
            </div>
            <Divider />
            <div className={`${componentClassName}__footer`}>
              <button
                className={`${componentClassName}__footer__button ${componentClassName}__footer__cancel`}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className={`${componentClassName}__footer__button ${componentClassName}__footer__success`}
                onClick={successFn}
              >
                {btn}
              </button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};
