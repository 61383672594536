import React from "react";
import {
    useOrganizationDeleteMutation,
    useOrganizationListQuery,
} from "../../../../store/organization/organizationSlice";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { Dialog } from "../../../atoms/dialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PAGE_NAME, PERMISSIONS, ROUTE } from "../../../../constants/constants";
import { TableDataType } from "../../../../types";
import { hasPermission } from "../../../../utils";

const OrganizationList = () => {
    const pageClassName = "p-organizationList";

    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState<number>();

    const {
        search,
        query,
        pageChangeHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useOrganizationListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
    });

    const [deleteApi, { isLoading: deleteLoading }] =
        useOrganizationDeleteMutation();

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "Name",
            dataIndex: "name",
            isSortable: false,
        },
        {
            title: "State",
            dataIndex: "district",
            isSortable: false,
        },
        {
            title: "Country",
            dataIndex: "country",
            isSortable: false,
        },
        {
            title: "No of Users",
            dataIndex: "numberOfOrgMember",
        },
        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: false,
        },

        {
            title: "Okta",
            dataIndex: "oktaOrg",
            isSortable: false,
        },

        {
            title: "YouTrack",
            dataIndex: "youtrackOrg",
            isSortable: false,
        },
        {
            title: "Box",
            dataIndex: "boxOrg",
        },
        {
            title: "Confluence",
            dataIndex: "confluenceOrg",
            isSortable: false,
        },
        {
            title: "Atlassian",
            dataIndex: "atlassianOrg",
            isSortable: false,
        },
        {
            title: "Gitlab",
            dataIndex: "gitlabOrg",
            isSortable: false,
        },
        {
            title: "Miro",
            dataIndex: "miroOrg",
            isSortable: false,
        },
        {
            title: "Action",
            dataIndex: `action${hasPermission(PERMISSIONS.ORGANIZATION_DELETE.title) ? "Delete" : ""}${hasPermission(PERMISSIONS.ORGANIZATION_EDIT.title) ? "Edit" : ""}`,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const removeHandler = (id: string) => {
        setIsDialogOpen(!isDialogOpen);
        setDeleteId(parseInt(id));
    };

    const deleteHandler = async () => {
        setIsDialogOpen(!isDialogOpen);
        let response = (await deleteApi(deleteId as number)) as any;

        if (response?.data?.success) {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.error?.data?.message);
        }
    };

    const editHandler = (id: string) => {
        navigate(ROUTE.ORGANIZATION_EDIT + `/${id}`);
    };

    const viewHandler = (id: string, e: any) => {
        if (
            [
                "a-table__table__action__button",
                "a-icon a-icon--edit a-icon--secondarySize",
                "a-table__table__action__button a-table__table__action__edit",
                "a-icon a-icon--cross a-icon--secondarySize",
            ].includes(e.target.className)
        )
            return;
        const name = data?.result?.data.find(
            (item: TableDataType) => item.id === id
        ).name;
        navigate(ROUTE.ORGANIZATION_LIST + `/${id}`, { state: { name } });
    };

    const closeDialogHandler = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.ORGANIZATION_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                    {hasPermission(PERMISSIONS.ORGANIZATION_CREATE.title) && (
                    <button
                        className={`${pageClassName}__button`}
                        onClick={() => navigate(ROUTE.ORGANIZATION_CREATE)}
                    >
                        Create Organization
                    </button>
                    )}
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || deleteLoading}
                        firstLoad={isLoading}
                        minWidth="1600"
                        startIndex={data?.result?.from}
                        deleteHandler={removeHandler}
                        editHandler={editHandler}
                        viewHandler={viewHandler}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                header="Delete Organization"
                body={
                    <div className={`${pageClassName}__dialog`}>
                        <p className={`${pageClassName}__dialog__text`}>
                            Are you sure you want to delete this organization?
                        </p>
                        <p className={`${pageClassName}__dialog__text`}>
                            This action cannot be undone.
                        </p>
                    </div>
                }
                isOpen={isDialogOpen}
                onClose={closeDialogHandler}
                successFn={deleteHandler}
                btn="Delete"
                icon="delete"
            />
        </div>
    );
};

export default OrganizationList;
