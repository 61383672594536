/* eslint-disable react-hooks/rules-of-hooks */
import { useLayoutEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthStateType, PrivetRouteType, userStateType } from "../types";
import { useSelector } from "react-redux";

/**
 * Scrolls the document to the top when the location changes.
 */
export const Wrapper = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

/**
 * Formats a date string into a specific format.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
export const formattedDate = (dateString: string) => {
  if (dateString === "-") return dateString;
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day < 9 ? `0${day}` : day} ${month} ${year}`;
};
export const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
/**
 * Formats a date and time string into a specific format.
 * @param {string} dateString - The date and time string to format.
 * @returns {string} The formatted date and time string.
 */
export const formattedDateTime = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const localTime = `${month}, ${day < 10 ? `0${day}` : day} ${year} ${hours}:${minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;

  const UTCTime = `${monthShortNames[date.getUTCMonth()]
    }, ${date.getUTCDate()} ${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()} UTC`;

  return (
    <div>
      <p> {localTime} |</p>
      <p> {UTCTime}</p>
    </div>
  );
};

/**
 * Compares a date with the current date to check if it is not before 30 days.
 * @param {string} dateString - The date string to compare.
 * @returns {boolean} True if the date is not before 30 days, false otherwise.
 */
export const compareDate = (dateString: string) => {
  if (["-", "N/A", ""].includes(dateString)) return false;
  const date = new Date(dateString);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays > 30;
};

/**
 * Converts a PascalCase string to snake_case.
 * @param {string} str - The PascalCase string to convert.
 * @returns {string} The snake_case string.
 */
export const pascalToSnakeCase = (str: string) => {
  return str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};

/**
 * Handles errors by displaying toast messages.
 * @param {any} errors - The errors object.
 */
export const handleErrors = (errors: any) => {
  Object.keys(errors).forEach((key) => {
    const message = errors[key][0];
    toast.error(message);
  });
};

/**
 * Renders a private route component based on the user's authentication status.
 * @param {React.ReactNode} element - The component to render if the user is authenticated.
 * @returns {React.ReactNode} The rendered component.
 */
export const PrivetRoute = ({ element }: PrivetRouteType) => {
  const user = useSelector((state: userStateType) => state.user.user);

  return user ? element : <Navigate to="/logout" replace />;
};

/**
 * Renders a public route component based on the user's authentication status.
 * @param {React.ReactNode} element - The component to render if the user is not authenticated.
 * @returns {React.ReactNode} The rendered component.
 */
export const PublicRoute = ({ element }: PrivetRouteType) => {
  const user = useSelector((state: userStateType) => state.user.user);

  return !user ? element : <Navigate to="/" replace />;
};

/**
 * Formats a Miro role string by removing the prefix.
 * @param {string} role - The Miro role string.
 * @returns {string} The formatted role string.
 */
export const formatMiroRole = (role: string) => {
  const roleArr = role.split("_");
  roleArr.shift();
  return roleArr.join(" ");
};

/**
 * Generates a random color in hexadecimal format.
 * @returns {string} The random color.
 */
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 * Generates an array of random colors in hexadecimal format.
 * @param {number} length - The length of the color array. Default is 100.
 * @returns {string[]} The array of random colors.
 */
export const getRandomColorArray = (length = 100) => {
  const colorArray = [];
  const letters = "0123456789ABCDEF";

  for (let i = 0; i < length; i++) {
    let color = "#";
    for (let j = 0; j < 6; j++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    colorArray.push(color);
  }

  return colorArray;
};

/**
 * An array of month names.
 */
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * Gets the last 12 months in the format "Month Year".
 * @returns {string[]} The array of last 12 months.
 */
export const getLast12Months = () => {
  var today = new Date();
  var result = [];

  for (var i = 0; i < 12; i++) {
    var month = today.getMonth() - i;
    var year = today.getFullYear();

    if (month < 0) {
      month += 12;
      year -= 1;
    }

    var monthName = monthNames[month];
    var formattedDate = monthName + " " + year;
    result.push(formattedDate);
  }

  return result;
};

/**
 * Converts minutes to a formatted string in the format "X days X hours X minutes".
 * @param {number} minutes - The number of minutes.
 * @returns {string} The formatted time string.
 */
export const minutesToDayFormat = (minutes: number): string => {
  const minutesInDay = 8 * 60; // 8 hours in minutes
  const minutesInHour = 60;

  const days = Math.floor(minutes / minutesInDay);
  const remainingMinutesAfterDays = minutes % minutesInDay;
  const hours = Math.floor(remainingMinutesAfterDays / minutesInHour);
  const remainingMinutes = remainingMinutesAfterDays % minutesInHour;

  const formattedTime = [];
  if (days > 0) {
    formattedTime.push(`${days} day${days > 1 ? "s" : ""}`);
  }
  if (hours > 0) {
    formattedTime.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  }
  if (remainingMinutes > 0) {
    formattedTime.push(
      `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`
    );
  }

  return formattedTime.join(" ");
};

/**
 * Formats a number with decimal places and thousand separators.
 * @param {number} num - The number to format.
 * @returns {string} The formatted number string.
 */
export const numberFormat = (num: number, currency = ""): string => {
  if (currency) {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: currency === "JPY" ? 0 : 2,
    }).format(num);
    return formattedNumber;
  } else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(num);
    return formattedNumber;
  }
};

export const numberFormatWithDynamicDecimal = (num: number, digit = 2, currency = ""): string => {
  if (currency) {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: currency === "JPY" ? 0 : digit,
    }).format(num);
    return formattedNumber;
  } else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: digit,
    }).format(num);
    return formattedNumber;
  }
};

/**
 * Converts minutes to hours.
 * @param {number} minutes - The number of minutes.
 * @returns {number | false} The converted hours, or false if the conversion fails.
 */
export const minutesToHours = (minutes: number): false | number => {
  const hours = minutes / 60;
  return parseFloat(hours.toFixed(2));
};

/**
 * Checks if a date string is valid.
 * @param {string} date - The date string to check.
 * @returns {boolean} True if the date is valid, false otherwise.
 */
export const isValidDate = (date: string) => {
  const dateObject = new Date(date);
  return !isNaN(dateObject.getTime());
};

/**
 * Checks if a given date is expired based on the specified difference in months.
 * @param date - The date to check for expiration.
 * @param difference - The difference in months to compare against.
 * @returns True if the date is expired, false otherwise.
 */
export const isExpiredDate = (date: string, difference: number) => {
  if (difference === 0) return false;
  const givenDate = new Date(date);
  const currentDate = new Date();

  const givenMonth = givenDate.getMonth();
  const givenYear = givenDate.getFullYear();

  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const monthDiff =
    (currentYear - givenYear) * 12 + (currentMonth - givenMonth);

  return monthDiff > difference;
};

/**
 * Converts a number to the format "Month Year".
 * @param {number} input - The number to convert.
 * @returns {string} The converted string.
 * @throws {Error} If the month is invalid.
 */
export const convertToMonthYear = (input: number) => {
  const currentYear = new Date().getFullYear();
  const inputString = input.toString();
  const year = inputString.slice(0, 4);
  const month = parseInt(inputString.slice(4), 10);

  if (isNaN(month) || month < 1 || month > 12) {
    throw new Error("Invalid month");
  }

  const monthAbbreviation = new Date(
    `${month} 1 ${currentYear}`
  ).toLocaleString("en-us", { month: "short" });

  return `${monthAbbreviation} ${year}`;
};

/**
 * Gets the dimensions of an image file.
 * @param {File} imageFile - The image file.
 * @returns {Promise<HTMLImageElement>} A promise that resolves with the image element.
 */
export const getImageDimension = (imageFile: File) => {
  const src = URL.createObjectURL(imageFile);

  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

/**
 * Checks if the user has a specific permission.
 * @param {string} permission - The permission to check.
 * @returns {boolean} True if the user has the permission, false otherwise.
 */
export const hasPermission = (permission: string) => {
  const permissions = useSelector(
    (state: AuthStateType) => state.auth.permissions
  );
  const hasPermission = permissions?.find((item) => item.title === permission);

  return hasPermission ? true : false;
};

export const capitalizeFirstCharacter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const saasToolImageSrc = (name: string) => {
  switch (name) {
    case "UR&AM Tool":
      return require(`../assets/icons/saas/UR&AM.svg`).default;
    case "AWS":
      return require(`../assets/icons/saas/AWS.svg`).default;
    case "GCP":
      return require(`../assets/icons/saas/GCP.svg`).default;
    case "Okta":
      return require(`../assets/icons/saas/Okta.svg`).default;
    case "Slack":
      return require(`../assets/icons/saas/Slack.svg`).default;
    case "Box":
      return require(`../assets/icons/saas/Box.svg`).default;
    case "Confluence":
      return require(`../assets/icons/saas/Confluence.svg`).default;
    case "Jira":
      return require(`../assets/icons/saas/Jira.svg`).default;
    case "Atlassian":
      return require(`../assets/icons/saas/Atlassian.svg`).default;
    case "AnyDesk":
      return require(`../assets/icons/saas/AnyDesk.svg`).default;
    case "Youtrack":
      return require(`../assets/icons/saas/YouTrack.svg`).default;
    case "GitLab":
      return require(`../assets/icons/saas/GitLab.svg`).default;
    case "Miro":
      return require(`../assets/icons/saas/Miro.svg`).default;
    case "SOPHOS MDR":
      return require(`../assets/icons/saas/SOPHOS_MDR.svg`).default;
    case "SOPHOS_MDR":
      return require(`../assets/icons/saas/SOPHOS_MDR.svg`).default;
    case "SOPHOS MDR Server":
      return require(`../assets/icons/saas/SOPHOS_MDR_Server.svg`).default;
    case "SOPHOS ZTNA":
      return require(`../assets/icons/saas/SOPHOS_ZTNA.svg`).default;
    case "SOPHOS Cloud Optix":
      return require(`../assets/icons/saas/SOPHOS_Cloud_Optix.svg`).default;
    case "Namecheap":
      return require(`../assets/icons/saas/Namecheap.svg`).default;
    default:
      return require(`../assets/icons/saas/default.svg`).default;
  }
}
