import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "licenseList",
    "singleLicense",
    "pricingList",
    "singlePricing",
    "organizationsPricing",
    "licenseHistoryList",
    "licenseHistoryDetail",
    "licenseHistoryByLicenseId",
    "licenseHistoryReport",
  ],
});

export const licenseSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Fetches a list of licenses from the server.
     *
     * @param search - The search query to filter the licenses.
     * @param limit - The maximum number of licenses to retrieve.
     * @param current_page - The current page of the license list.
     * @param sort_by - The field to sort the licenses by.
     * @param sort_direction - The direction to sort the licenses in.
     * @returns A promise that resolves to the response data containing the list of licenses.
     */
    licenseList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url:
          API_URL.LICENSE +
          `?${limit ? `limit=${limit}` : ""}${
            current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${
            sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["licenseList"],
    }),
    /**
     * Creates a new license.
     * @param payload - The data for creating the license.
     * @returns A promise that resolves to the response data.
     */
    createLicense: builder.mutation<ResponseDataType, FormData>({
      query: (payload) => ({
        url: API_URL.LICENSE + API_URL.CREATE,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "licenseList",
        "singleLicense",
        "pricingList",
        "singlePricing",
        "organizationsPricing",
      ],
    }),

    /**
     * Retrieves a single license by its ID.
     * @param id - The ID of the license to retrieve.
     * @returns A promise that resolves to the response data.
     */
    singleLicense: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.LICENSE + `/${id}`,
        method: "GET",
      }),
      providesTags: ["singleLicense"],
    }),

    /**
     * Updates the license with the provided payload.
     * @param payload - The data to be sent in the request body.
     * @returns A Promise that resolves to the response data.
     * @throws If the request fails or returns an error.
     * @remarks This mutation invalidates the following cache tags: "licenseList", "singleLicense", "pricingList", "singlePricing", "organizationsPricing".
     */
    updateLicense: builder.mutation<ResponseDataType, FormData>({
      query: (payload) => ({
        url: API_URL.LICENSE + API_URL.UPDATE,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "licenseList",
        "singleLicense",
        "pricingList",
        "singlePricing",
        "organizationsPricing",
      ],
    }),

    /**
     * Retrieves the license history list from the server.
     *
     * @param search - The search query for filtering the license history list.
     * @param limit - The maximum number of items to retrieve per page.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the license history list by.
     * @param sort_direction - The direction to sort the license history list in.
     * @param year - The year to filter the license history list by.
     * @returns A Promise that resolves to the license history list response data.
     */
    licenseHistoryList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        year,
        month,
        tool_name,
      }) => ({
        url:
          API_URL.LICENSE +
          API_URL.LICENSE_HISTORY_LIST +
          `?${limit ? `limit=${limit}` : ""}${
            current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${
            sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${year ? `&year=${year}` : ""}${
            month ? `&month=${month}` : ""
          }${tool_name ? `&tool_name=${tool_name}` : ""}`,
        method: "GET",
      }),
      providesTags: ["licenseHistoryList"],
    }),

    /**
     * Fetches the license history detail for a specific ID.
     *
     * @param id - The ID of the license history.
     * @returns A Promise that resolves to the license history detail.
     */
    licenseHistoryDetail: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.LICENSE + API_URL.LICENSE_HISTORY_LIST + `/${id}`,
        method: "GET",
      }),
      providesTags: ["licenseHistoryDetail"],
    }),

    /**
     * Retrieves the license history by license ID.
     *
     * @param id - The ID of the license.
     * @param search - The search query.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param year - The year to filter the results by.
     * @param month - The month to filter the results by.
     * @returns A Promise that resolves to the license history data.
     */
    licenseHistoryByLicenseId: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        year,
        month,
      }) => ({
        url:
          API_URL.LICENSE +
          `/${id}` +
          API_URL.LICENSE_HISTORY_LIST +
          `?${limit ? `limit=${limit}` : ""}${
            current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${
            sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${year ? `&year=${year}` : ""}${
            month ? `&month=${month}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["licenseHistoryByLicenseId"],
    }),

    licenseHistoryReport: builder.query<ResponseDataType, listDataType>({
      query: ({ id, type, year }) => ({
        url:
          API_URL.LICENSE +
          API_URL.LICENSE_HISTORY_LIST +
          `/${id}` +
          API_URL.REPORT +
          `?${type ? `type=${type}` : ""}${year ? `&year=${year}` : ""}`,

        method: "GET",
      }),
      providesTags: ["licenseHistoryReport"],
    }),
  }),
});

export const {
  useLicenseListQuery,
  useCreateLicenseMutation,
  useSingleLicenseQuery,
  useUpdateLicenseMutation,
  useLicenseHistoryListQuery,
  useLicenseHistoryDetailQuery,
  useLicenseHistoryByLicenseIdQuery,
  useLicenseHistoryReportQuery,
} = licenseSlice;
