import { combineReducers } from 'redux';
import { apiSlice } from './api/apiSlice';
import user from './auth/user';
import sidebar from './sidebar/sidebar';
import auth from './auth/auth';

/**
 * Root reducer function that combines all the reducers.
 * @returns The combined reducer object.
 */
export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  user: user,
  auth: auth,
  sidebar: sidebar
});
