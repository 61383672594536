import React from "react";

export const useTableHooks = () => {
  const [query, setQuery] = React.useState({
    current: 1,
    limit: 20,
    search: "",
    sortBy: "",
    sortDirection: "desc",
  });
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setQuery({ ...query, current: 1, search: search });
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const pageChangeHandler = (page: number) => {
    setQuery({ ...query, current: page });
  };

  const sortHandler = (sortBy: string) => {
    if (query.sortBy === sortBy) {
      setQuery({
        ...query,
        sortDirection: query.sortDirection === "asc" ? "desc" : "asc",
        current: 1,
      });
    } else {
      setQuery({
        ...query,
        sortBy,
        sortDirection: "asc",
        current: 1,
      });
    }
  };

  const limitChangeHandler = (limit: number) => {
    setQuery({ ...query, limit: limit});
  };

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setQuery({ ...query, current: 1 });
  };

  // const onChangeHandler = (data: any) => {
  //   setPayload(data)
  // }

  return {
    search,
    query,
    setQuery,
    pageChangeHandler,
    sortHandler,
    limitChangeHandler,
    searchHandler,
    // onChangeHandler,
  };
};
