import React from "react";

import {
    createComponentBase,
    BaseComponentProps,
} from "../../../../libs/components";
import { Dropdown } from "../../../atoms/dropdown";
import { Input } from "../../../atoms/input";
import { useUserListQuery } from "../../../../store/user/userSlice";
import { DropdownOptionsType, userStateType } from "../../../../types";
import { Icon } from "../../../atoms/icon";
import { useOktaUserListQuery } from "../../../../store/okta/userSlice";
import { useSelector } from "react-redux";
import { useAssigningListOfUserQuery } from "../../../../store/requestManagement/requestSlice";
import { saasToolImageSrc } from "../../../../utils";
import { Loader } from "../../../atoms/loader";
import { Modal } from "../../../molecules/modal";
import { StringOptionsWithoutImporter } from "sass";

export interface PermissionManagementProps extends BaseComponentProps {
    payload: any;
    setPayload: (payload: any) => void;
    approverList: any;
}

const Type = (subType: string) => {
    switch (subType) {
        case "Project Creation":
            return "project";
        case "Group Creation":
            return "group";
        case "Space Creation":
            return "space";
        case "Team Creation":
            return "team";
        case "Dashboard Creation":
            return "dashboard";
        default:
            return "";
    }
};

export const PermissionManagementRequest = ({
    className,
    payload,
    setPayload,
    approverList,
}: PermissionManagementProps) => {
    const componentClassName = "o-permissionManagementRequest";
    const { Container } = createComponentBase(componentClassName, "div");

    const userData = useSelector((state: userStateType) => state.user.user);

    const { data: users, isLoading: usersLoading } = useOktaUserListQuery({});
    const { data: managerList, isLoading: managerLoading } = useUserListQuery({});
    const {
        data: assigningEntities,
        isLoading: assigningLoading,
        isFetching: assigningFetching,
    } = useAssigningListOfUserQuery(payload?.user?.value?.value, {
        skip: !payload?.user?.value?.value,
    });

    const [saasToolOptions, setSaaSToolOptions] = React.useState<any>([]);
    const [roleOptionList, setRoleOptionList] = React.useState<any>([]);
    const [entityOptions, setEntityOptions] = React.useState<any>([]);
    const [isOpenEntityModal, setIsOpenEntityModal] = React.useState(false);
    const [assigned, setAssigned] = React.useState<any>({
        saasTool: "",
        assignedList: [],
    });

    const userChangeHandler = async (val: { value: string; label: string }) => {
        if (val.value === payload.user.value.value) return;
        setPayload({
            ...payload,
            user: {
                value: val,
                isError: false,
                error: "",
            },
            saas_tools: {
                value: [
                    {
                        name: "",
                        type: "",
                        entity_name: "",
                        role: "",
                        approver: [],
                    },
                ],
                isError: false,
                error: "",
            },
        });
    };

    const nameChangeHandler = (
        val: { value: string; label: string },
        index: number
    ) => {
        const newPayload = { ...payload };
        newPayload.saas_tools.value[index].name = val;
        newPayload.saas_tools.value[index].type = manageType(val.value);
        newPayload.saas_tools.value[index].entity_name = "";
        newPayload.saas_tools.value[index].role = "";
        if (!["Box", "UR&AM Tool"].includes(val.value)) {
            newPayload.saas_tools.value[index].entity_name = "";
            newPayload.saas_tools.value[index].approver = [];
        } else {
            delete newPayload.saas_tools.value[index].entity_name;
            const data = approverList?.result?.data.find(
                (item: any) => item.saasTool === val.value
            );

            const approvers = data?.approver.map((item: any) => ({
                value: item.id,
                label: `${item.firstName} ${item.middleName ? item.middleName + " " : ""
                    }${item.lastName}`,
            }));
            newPayload.saas_tools.value[index].approver = approvers;

            if (
                ["Modify Permission", "Remove Permission"].includes(
                    newPayload.subType.value.value
                )
            ) {
                const role = assigningEntities?.result.saas_tools.find(
                    (item: any) => item.name === val.value
                )?.role;
                newPayload.saas_tools.value[index].role = {
                    value: role,
                    label: role,
                };
            }
        }

        setPayload(newPayload);
    };

    const roleChangeHandler = (val: any, index: number) => {
        const newSaaSTools = [...payload.saas_tools.value];
        newSaaSTools[index].role = val;
        setPayload({
            ...payload,
            saas_tools: {
                value: newSaaSTools,
                isError: false,
                error: "",
            },
        });
    };

    const entitySelectHandler = (val: any, index: number) => {
        const newPayload = [...payload.saas_tools.value];
        newPayload[index].entity_name = val;
        const data = approverList?.result?.data.find(
            (item: any) => item.name === val.label
        );

        const approvers = data?.approver.map((item: any) => ({
            value: item.id,
            label: `${item.firstName} ${item.middleName ? item.middleName + " " : ""
                }${item.lastName}`,
        }));
        newPayload[index].approver = approvers;
        if (
            ["Modify Permission", "Remove Permission"].includes(
                payload.subType.value.value
            )
        ) {
            const role = assigningEntities?.result.saas_tools
                .find((item: any) => item.name === newPayload[index].name.value)
                ?.assigned.find(
                    (item: any) => item.name === newPayload[index].entity_name.label
                )?.role;
            newPayload[index].role = {
                value: role,
                label: role,
            };
        }
        setPayload({
            ...payload,
            saas_tools: {
                value: newPayload,
                isError: false,
                error: "",
            },
        });
    };

    const removeSaaSToolHandler = (index: number) => {
        const newPayload = { ...payload };
        if (newPayload.saas_tools.value.length > 1) {
            newPayload.saas_tools.value.splice(index, 1);
            setPayload(newPayload);
        }
    };

    const addSaaSToolHandler = () => {
        const newPayload = { ...payload };

        newPayload.saas_tools.value.push({
            name: "",
            type: "",
            entity_name: "",
            role: "",
            approver: [],
        });

        setPayload(newPayload);
    };
    const saasToolFocusHandler = () => {
        if (payload.subType.value.value === "Assign Permission") {
            if (
                assigningEntities?.result.saas_tools.find(
                    (item: any) => item.name === "Box"
                ) !== undefined
            ) {
                setSaaSToolOptions([
                    {
                        label: "Confluence",
                        value: "Confluence",
                    },
                    {
                        label: "GitLab",
                        value: "GitLab",
                    },
                    {
                        label: "Miro",
                        value: "Miro",
                    },
                    {
                        label: "Youtrack",
                        value: "Youtrack",
                    },
                    {
                        label: "GCP",
                        value: "GCP",
                    },
                    {
                        label: "SOPHOS MDR Agent Installation",
                        value: "SOPHOS MDR Agent Installation",
                    },
                    {
                        label: "SOPHOS MDR Server Agent Installation",
                        value: "SOPHOS MDR Server Agent Installation",
                    },
                    {
                        label: "SOPHOS Cloud Optix",
                        value: "SOPHOS Cloud Optix",
                    },
                ]);
            } else {
                setSaaSToolOptions([
                    {
                        label: "Confluence",
                        value: "Confluence",
                    },
                    {
                        label: "GitLab",
                        value: "GitLab",
                    },
                    {
                        label: "Miro",
                        value: "Miro",
                    },
                    {
                        label: "Youtrack",
                        value: "Youtrack",
                    },
                    {
                        label: "Box",
                        value: "Box",
                    },
                    {
                        label: "GCP",
                        value: "GCP",
                    },
                    {
                        label: "SOPHOS MDR Agent Installation",
                        value: "SOPHOS MDR Agent Installation",
                    },
                    {
                        label: "SOPHOS MDR Server Agent Installation",
                        value: "SOPHOS MDR Server Agent Installation",
                    },
                    {
                        label: "SOPHOS Cloud Optix",
                        value: "SOPHOS Cloud Optix",
                    },
                ]);
            }
        } else {
            setSaaSToolOptions(
                assigningEntities?.result.saas_tools.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.name,
                    };
                })
            );
        }
    };

    const manageType = (toolName: string) => {
        if (toolName === "Confluence") return "Space";
        else if (toolName === "GitLab") return "Group";
        else if (toolName === "Miro") return "Team";
        else if (toolName === "Youtrack") return "Project";
        else if (["UR&AM Tool", "Box", "Okta"].includes(toolName)) return "License";
        else if (toolName === "GCP") return "Project";
        else if (toolName === "SOPHOS Cloud Optix") return "Project";
        else if (toolName === "SOPHOS MDR Agent Installation") return "OS";
        else if (toolName === "SOPHOS MDR Server Agent Installation") return "OS";
    };

    const entityFocusHandler = (
        index: number,
        saas_tool: string,
        type: string
    ) => {
        var options: DropdownOptionsType[] = [];
        if (payload.subType.value.value === "Assign Permission") {
            const filter = approverList?.result?.data?.filter(
                (item: any) => item.type === type && item.saasTool === saas_tool
            );
            const filterOptions = filter?.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));
            options = filterOptions.filter(
                (val: any) =>
                    assigningEntities?.result?.assigning.find(
                        (item: any) => item.name === val.label
                    ) === undefined
            );
        } else {
            options = assigningEntities?.result?.saas_tools
                .find((item: any) => item.name === saas_tool)
                ?.assigned.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
        }
        setEntityOptions(options);
    };
    const meHandler = () => {
        const newPayload = { ...payload };
        if (userData.email === payload.user.value.value) return;
        newPayload.user.value = {
            value: userData.email,
            label: userData?.middleName
                ? `${userData.firstName} ${userData.middleName} ${userData.lastName}`
                : `${userData.firstName} ${userData.lastName}`,
        };
        newPayload.saas_tools.value = [
            {
                name: "",
                type: "",
                entity_name: "",
                role: "",
                approver: [],
            },
        ];
        setPayload(newPayload);
    };

    const roleFocusHandler = (toolName: string) => {
        if (toolName === "UR&AM Tool") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Super Admin",
                        label: "Super Admin",
                    },
                    {
                        value: "Admin",
                        label: "Admin",
                    },
                    {
                        value: "Admin Viewer",
                        label: "Admin Viewer",
                    },
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "Confluence") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Viewer",
                        label: "Viewer",
                    },
                    {
                        value: "Editor",
                        label: "Editor",
                    },
                    {
                        value: "Space Admin",
                        label: "Space Admin",
                    },
                ],
            });
        } else if (toolName === "GitLab") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Owner",
                        label: "Owner",
                    },
                    {
                        value: "Developer",
                        label: "Developer",
                    },
                    {
                        value: "Reporter",
                        label: "Reporter",
                    },
                ],
            });
        } else if (toolName === "Miro") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "Youtrack") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Project Admin",
                        label: "Project Admin",
                    },
                    {
                        value: "Developer",
                        label: "Developer",
                    },
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "Box") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "GCP") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Owner",
                        label: "Owner",
                    },
                    {
                        value: "Editor",
                        label: "Editor",
                    },
                    {
                        value: "Viewer",
                        label: "Viewer",
                    },
                    {
                        value: "Browser",
                        label: "Browser",
                    },
                ],
            });
        } else if (toolName === "SOPHOS Cloud Optix") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Admin",
                        label: "Admin",
                    },
                    {
                        value: "Read Only",
                        label: "Read Only",
                    },
                    {
                        value: "HelpDesk",
                        label: "HelpDesk",
                    },
                ],
            });
        }
    };
    const modifiers = {
        [usersLoading || managerLoading || assigningLoading || assigningFetching
            ? "loading"
            : ""]: true,
    };

    return (
        <Container className={className} modifiers={modifiers}>
            {(usersLoading ||
                managerLoading ||
                assigningLoading ||
                assigningFetching) && (
                    <div className={`${componentClassName}__loader__content`}>
                        <div className={`${componentClassName}__loader__spinner`}>
                            {Array.from(Array(12).keys()).map((index) => (
                                <div key={index}></div>
                            ))}
                        </div>
                    </div>
                )}
            <div className={`${componentClassName}__form`}>
                <div className={`${componentClassName}__form__items`}>
                    <div className={`${componentClassName}__form__user`}>
                        <Dropdown
                            className={`${componentClassName}__form__user__dropdown`}
                            options={users?.result?.data.map((user: any) => ({
                                value: user.email,
                                label: user?.middleName
                                    ? `${user.firstName} ${user.middleName} ${user.lastName}`
                                    : `${user.firstName} ${user.lastName}`,
                            }))}
                            selected={payload?.user?.value}
                            onClickChange={(val) =>
                                userChangeHandler(val as { value: string; label: string })
                            }
                            isRequired
                        />
                        <button
                            className={`${componentClassName}__form__user__me`}
                            type="button"
                            onClick={meHandler}
                        >
                            Me
                        </button>
                    </div>
                </div>

                {/* assigned saas tools */}
                {payload?.user?.value?.value && (
                    <div className={`${componentClassName}__form__items`}>
                        <p className={`${componentClassName}__form__title`}>
                            Assigned SaaS Tools
                        </p>
                        <div className={`${componentClassName}__saasTools`}>
                            {assigningEntities?.result?.saas_tools.map(
                                (saas_tool: any, index: number) => (
                                    <div
                                        key={index}
                                        className={`${componentClassName}__saasTools__container`}
                                    >
                                        <button
                                            title={saas_tool.name}
                                            type="button"
                                            className={`${componentClassName}__saasTools__container__button`}
                                            onClick={() => {
                                                setIsOpenEntityModal(!isOpenEntityModal);
                                                setAssigned({
                                                    saasTool: saas_tool.name,
                                                    assignedList: saas_tool.assigned,
                                                });
                                            }}
                                        >
                                            <img
                                                src={saasToolImageSrc(saas_tool.name)}
                                                alt={saas_tool.name}
                                                height={32}
                                            />
                                        </button>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}

                <p className={`${componentClassName}__form__title`}>Assign New Item</p>
                {payload?.saas_tools?.value.map((saas_tool: any, index: number) => {
                    return (
                        <div key={index} className={`${componentClassName}__form__items`}>
                            <div className={`${componentClassName}__form__item`}>
                                <Dropdown
                                    className={`${componentClassName}__form__item__dropdown`}
                                    options={saasToolOptions}
                                    selected={
                                        saas_tool?.name?.value === "" ? null : saas_tool?.name
                                    }
                                    placeholder="Select saas tool"
                                    onClickChange={(val) =>
                                        nameChangeHandler(
                                            val as { value: string; label: string },
                                            index
                                        )
                                    }
                                    focusHandler={saasToolFocusHandler}
                                    isRequired
                                />
                                {saas_tool?.type !== "License" && (
                                    <Dropdown
                                        className={`${componentClassName}__form__item__dropdown`}
                                        options={entityOptions}
                                        selected={
                                            saas_tool?.entity_name?.value === ""
                                                ? null
                                                : saas_tool?.entity_name
                                        }
                                        placeholder={
                                            manageType(saas_tool?.name.value)
                                                ? `Select ${manageType(
                                                    saas_tool?.name.value
                                                )?.toLocaleLowerCase()} name`
                                                : "Select name"
                                        }
                                        onClickChange={(val) =>
                                            entitySelectHandler(
                                                val as { value: string; label: string },
                                                index
                                            )
                                        }
                                        focusHandler={() =>
                                            entityFocusHandler(
                                                index,
                                                saas_tool.name.value,
                                                saas_tool.type
                                            )
                                        }
                                        isRequired
                                    />
                                )}

                                <Dropdown
                                    className={`${componentClassName}__form__item__dropdown`}
                                    options={roleOptionList[saas_tool.name.value]}
                                    selected={
                                        saas_tool?.role?.value === "" ? null : saas_tool?.role
                                    }
                                    placeholder="Select role"
                                    onClickChange={(val) => roleChangeHandler(val, index)}
                                    focusHandler={() => roleFocusHandler(saas_tool.name.value)}
                                    isRequired
                                    isDisabled={
                                        ["Remove Permission"].includes(
                                            payload.subType.value.value
                                        )
                                    }
                                />

                                <Dropdown
                                    className={`${componentClassName}__form__item__dropdown`}
                                    options={
                                        managerList?.result?.data?.map((user: any) => ({
                                            label: user?.middleName
                                                ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
                                                : `${user?.firstName} ${user?.lastName}`,
                                            value: user.id,
                                        })) ?? []
                                    }
                                    onClickChange={() => { }}
                                    placeholder=""
                                    isIcon
                                    color="black"
                                    selected={saas_tool?.approver}
                                    isMulti
                                    isDisabled
                                />
                            </div>
                            {payload?.saas_tools?.value.length > 1 && (
                                <div className={`${componentClassName}__form__items__remove`}>
                                    <button
                                        onClick={() => removeSaaSToolHandler(index)}
                                        type="button"
                                        className={`${componentClassName}__form__items__remove__button`}
                                    >
                                        <Icon icon="delete" size="primary" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                })}

                {payload?.saas_tools?.value[payload?.saas_tools?.value.length - 1]?.name
                    ?.value &&
                    payload?.saas_tools?.value[payload?.saas_tools?.value.length - 1]
                        ?.role?.value &&
                    (payload?.saas_tools?.value[payload?.saas_tools?.value.length - 1]
                        ?.entity_name?.value ||
                        !payload?.saas_tools?.value[
                            payload?.saas_tools?.value.length - 1
                        ]?.hasOwnProperty("entity_name")) && (
                        <button
                            type="button"
                            onClick={() => addSaaSToolHandler()}
                            className={`${componentClassName}__form__add`}
                        >
                            Add SaaS Tool
                        </button>
                    )}
            </div>

            <Modal
                header={`Assigned Item of ${assigned.saasTool}`}
                isOpen={isOpenEntityModal}
                onClose={() => setIsOpenEntityModal(false)}
                width="large"
            >
                <div className={`${componentClassName}__modalContainer`}>
                    {assigned.assignedList.length > 0 ? (
                        assigned.assignedList.map((entity: any, index: number) => {
                            return (
                                <div key={index} className={`${componentClassName}__modal`}>
                                    <Dropdown
                                        className={`${componentClassName}__modal__dropdown`}
                                        options={[]}
                                        selected={{
                                            label: entity?.saasTool + " " + entity?.type,
                                            value: entity?.saasTool,
                                        }}
                                        onClickChange={() => { }}
                                        isDisabled
                                    />
                                    <Dropdown
                                        className={`${componentClassName}__modal__dropdown`}
                                        options={[]}
                                        selected={{
                                            label: entity?.name,
                                            value: entity?.name,
                                        }}
                                        onClickChange={() => { }}
                                        isDisabled
                                        title={entity?.name}
                                    />
                                    <Dropdown
                                        className={`${componentClassName}__modal__dropdown`}
                                        options={[]}
                                        selected={{
                                            label: entity?.role,
                                            value: entity?.role,
                                        }}
                                        onClickChange={() => { }}
                                        isDisabled
                                    />
                                    <Dropdown
                                        className={`${componentClassName}__modal__approvers`}
                                        options={[]}
                                        selected={entity?.approver.map((approver: any) => ({
                                            label: approver?.middleName
                                                ? `${approver?.firstName} ${approver?.middleName} ${approver?.lastName}`
                                                : `${approver?.firstName} ${approver?.lastName}`,
                                            value: approver?.id,
                                        }))}
                                        onClickChange={() => { }}
                                        isMulti
                                        isDisabled
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <div className={`${componentClassName}__modal__empty`}>
                            <p>There is no assigned item for {assigned.saasTool}</p>
                        </div>
                    )}
                </div>
            </Modal>
        </Container>
    );
};
