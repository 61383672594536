import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import {
  useLicenseHistoryByLicenseIdQuery,
  useLicenseHistoryReportQuery,
} from "../../../../../store/license/licenseSlice";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { monthNames, pascalToSnakeCase } from "../../../../../utils";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { TableDataType } from "../../../../../types";
import { Input } from "../../../../atoms/input";
import { Icon } from "../../../../atoms/icon";
import { Dropdown } from "../../../../atoms/dropdown";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { Pagination } from "../../../../molecules/pagination";
import { ComboChart } from "../../../../molecules/comboChart";

const sortFormattedData = (dataIndex: string) => {
  switch (dataIndex) {
    case "licenseHistoryName":
      return "licenseName";
    case "licenseHistorySubscriptionType":
      return "subscriptionType";
    case "licenseHistoryPricing":
      return "pricing";
    case "licenseHistoryTotal":
      return "total";
    case "licenseHistoryActive":
      return "active";
    case "licenseHistoryInvited":
      return "invited";
    case "licenseHistoryPredicted":
      return "predicted";
    case "licenseHistoryRemaining":
      return "remaining";
    default:
      return dataIndex;
  }
};

const dropdownOptions = (data: string[], type?: "month") => {
  if (type === "month") {
    return data.map((item, index) => {
      return { value: index + 1, label: item };
    });
  }
  return data.map((item) => {
    return { value: item, label: item };
  });
};

const LicenseSummaryDetails = () => {
  const pageClassName = "p-licenseSummaryDetails";
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { state } = useLocation();
  const name = state ? (state as { name: string }).name : undefined;

  const ref = React.useRef<HTMLDivElement>(null);
  const filterRef = React.useRef<HTMLDivElement>(null);
  const reportFilterRef = React.useRef<HTMLDivElement>(null);
  const reportRef = React.useRef<HTMLDivElement>(null);

  const [isShowFilter, setShowFilter] = React.useState(false);
  const [isShowReportFilter, setShowReportFilter] = React.useState(false);
  const [selectedMonth, setSelectedMonth] = React.useState<{
    label: string;
    value: string;
  }>({ label: "All", value: "All" });

  const [selectedYear, setSelectedYear] = React.useState<{
    label: string;
    value: string;
  }>({ label: "All", value: "All" });

  const [filter, setFilter] = React.useState<{
    month: string;
    year: string;
  }>({ month: "All", year: "All" });

  const {
    search,
    query,
    pageChangeHandler,
    sortHandler,
    limitChangeHandler,
    searchHandler,
  } = useTableHooks();

  const { data, isLoading } = useLicenseHistoryByLicenseIdQuery({
    id: parseInt(id as string),
    search: query.search,
    limit: query.limit,
    current_page: query.current,
    sort_by: pascalToSnakeCase(sortFormattedData(query.sortBy)),
    sort_direction: query.sortDirection,
    year: filter.year === "All" ? undefined : filter.year,
    month: filter.month === "All" ? undefined : filter.month,
  });

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Date",
      dataIndex: "createdAtTime",
      isSortable: true,
    },
    {
      title: "SaaS/Infra",
      dataIndex: "licenseHistoryName",
      isSortable: true,
    },
    {
      title: "Subscription Type",
      dataIndex: "licenseHistorySubscriptionType",
      isSortable: true,
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "licenseHistoryPricing",
    //   isSortable: true,
    // },
    {
      title: "Total",
      dataIndex: "licenseHistoryTotal",
      isSortable: true,
    },
    {
      title: "Active",
      dataIndex: "licenseHistoryActive",
      isSortable: true,
    },
    {
      title: "Invited",
      dataIndex: "licenseHistoryInvited",
      isSortable: true,
    },
    {
      title: "Predicted",
      dataIndex: "licenseHistoryPredicted",
      isSortable: true,
    },
    {
      title: "Remaining",
      dataIndex: "licenseHistoryRemaining",
      isSortable: true,
    },
  ];

  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: data?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  const viewHandler = (id: string) => {
    const name = data?.result?.data.find(
      (item: TableDataType) => item.id === id
    ).license.name;
    navigate(`${ROUTE.LICENSE_HISTORY_LIST}/${id}`, { state: { name } });
  };

  const filterHandler = () => {
    setFilter({
      month: selectedMonth.value,
      year: selectedYear.value,
    });
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setShowFilter(false);
      }

      if (
        reportFilterRef.current &&
        !reportFilterRef.current.contains(event.target as Node)
      ) {
        setShowReportFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const resetFilter = () => {
    setSelectedMonth({ label: "All", value: "All" });
    setSelectedYear({ label: "All", value: "All" });
    setFilter({
      month: "All",
      year: "All",
    });
    handleFirstPage();
  };

  const [reportFilterValue, setReportFilterValue] = React.useState<{
    year: string;
    type: string;
  }>({
    year: "all",
    type: "weekly",
  });

  const [reportFilter, setReportFilter] = React.useState<{
    year: { label: string | "All"; value: number | "all" };
    type: { label: "Weekly" | "Monthly"; value: "weekly" | "monthly" };
  }>({
    year: { label: "All", value: "all" },
    type: { label: "Weekly", value: "weekly" },
  });

  const reportResetFilter = () => {
    setReportFilter({
      year: { label: "All", value: "all" },
      type: { label: "Weekly", value: "weekly" },
    });
  };

  const reportFilterHandler = () => {
    setReportFilterValue({
      year: reportFilter.year.value as string,
      type: reportFilter.type.value,
    });
  };

  const { data: report, isLoading: reportLoading } =
    useLicenseHistoryReportQuery({
      id: parseInt(id as string),
      year: reportFilterValue.year === "all" ? undefined : reportFilterValue.year,
      type: reportFilterValue.type,
    });

  const options = {
    title: "License Summary",
    vAxis: { title: "Licenses" },
    hAxis: { title: reportFilterValue.type === "weekly" ? "Week" : "Month" },
    seriesType: "bars",
    backgroundColor: "transparent",
    series: {
      0: { color: "blue" },
      1: { type: "line", color: "#13d443", lineWidth: 5 },
    },
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>
        {name
          ? name + " " + PAGE_NAME.LICENSE_HISTORY
          : PAGE_NAME.LICENSE_HISTORY}
      </p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__report`}>
          <div className={`${pageClassName}__filter`} ref={reportFilterRef}>
            <button
              onClick={() => setShowReportFilter(!isShowReportFilter)}
              className={`${pageClassName}__filter__button`}
              title="Filter"
            >
              <Icon icon="filter" size="primary" />
            </button>
            {isShowReportFilter && (
              <div
                className={`${pageClassName}__filter__container`}
                ref={reportRef}
              >
                <div className={`${pageClassName}__filter__item`}>
                  <label>Type</label>
                  <Dropdown
                    placeholder="Type"
                    selected={reportFilter.type}
                    options={["Weekly", "Monthly"].map((item: any) => {
                      const value = item.replace(/\s/g, "_").toLowerCase();
                      return { label: item, value: value };
                    })}
                    onClickChange={(e) => {
                      setReportFilter({
                        ...reportFilter,
                        type: e as {
                          label: "Weekly" | "Monthly";
                          value: "weekly" | "monthly";
                        },
                      });
                    }}
                  />
                </div>
                <div className={`${pageClassName}__filter__item`}>
                  <label htmlFor="filter">Year</label>
                  <Dropdown
                    options={dropdownOptions(data?.result?.years ?? [])}
                    selected={reportFilter.year}
                    onClickChange={(e) =>
                      setReportFilter({
                        ...reportFilter,
                        year: e as {
                          label: string | "All";
                          value: number | "all";
                        },
                      })
                    }
                    isBordered
                    isIcon
                    iniDirection="top"
                  />
                </div>
                <Divider />
                <div className={`${pageClassName}__filter__btn`}>
                  <button onClick={reportResetFilter}>Reset</button>
                  <button
                    onClick={() => {
                      setShowReportFilter(false);
                      reportFilterHandler();
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <ComboChart
          data={report?.result?.data ?? []}
          options={options}
          isLoading={reportLoading}
          style={{ width: "100%", height: "300px" }}
        />
      </div>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__header`}>
          <div className={`${pageClassName}__search`}>
            <Input
              placeholder="Search"
              onChange={(e) => {
                searchHandler(e);
                handleFirstPage();
              }}
              value={search}
            />
          </div>

          <div className={`${pageClassName}__filter`} ref={filterRef}>
            <button
              onClick={() => setShowFilter(!isShowFilter)}
              className={`${pageClassName}__filter__button`}
              title="Filter"
            >
              <Icon icon="filter" size="primary" />
            </button>
            {isShowFilter && (
              <div className={`${pageClassName}__filter__container`} ref={ref}>
                <div className={`${pageClassName}__filter__item`}>
                  <label>Month</label>
                  <Dropdown
                    options={dropdownOptions(monthNames, "month")}
                    selected={selectedMonth}
                    onClickChange={(e) =>
                      setSelectedMonth(e as { label: string; value: string })
                    }
                    isBordered
                    isIcon
                    iniDirection="top"
                  />
                </div>
                <div className={`${pageClassName}__filter__item`}>
                  <label htmlFor="filter">Year</label>
                  <Dropdown
                    options={dropdownOptions(data?.result?.years ?? [])}
                    selected={selectedYear}
                    onClickChange={(e) =>
                      setSelectedYear(e as { label: string; value: string })
                    }
                    isBordered
                    isIcon
                    iniDirection="top"
                  />
                </div>
                <Divider />
                <div className={`${pageClassName}__filter__btn`}>
                  <button onClick={resetFilter}>Reset</button>
                  <button
                    onClick={() => {
                      setShowFilter(false);
                      filterHandler();
                      handleFirstPage();
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`${pageClassName}__table`}>
          <Table
            header={tableHeader}
            data={data?.result?.data ?? []}
            loading={isLoading}
            firstLoad={isLoading}
            sortHandler={sortHandler}
            viewHandler={viewHandler}
            minWidth="1440"
            startIndex={data?.result?.from}
            sortBy={query.sortBy}
            cursorPointer
          />
        </div>
        <Divider />
        <div className={`${pageClassName}__pagination`}>
          <div className={`${pageClassName}__pagination__limit`}>
            <p>Display</p>
            <div className={`${pageClassName}__pagination__dropdown`}>
              <Dropdown
                options={[
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                selected={{ label: query.limit, value: query.limit }}
                onClickChange={(e) => {
                  limitChangeHandler(e.value as number);
                  handleFirstPage();
                }}
                isBordered
                isIcon
                iniDirection="top"
              />
            </div>
          </div>
          <div className={`${pageClassName}__pagination__number`}>
            {data?.result?.total ? (
              <p>
                Show {data?.result?.from} -{" "}
                {data?.result?.from + query.limit - 1 < data?.result?.total
                  ? data?.result?.from + query.limit - 1
                  : data?.result?.total}{" "}
                of {data?.result?.total}
              </p>
            ) : (
              <p>Show 00 - 00 of 00</p>
            )}
          </div>
          <div className={`${pageClassName}__pagination__nav`}>
            {data?.result?.total > query.limit && (
              <Pagination
                totalData={totalData}
                page={query.current}
                handleFirstPage={handleFirstPage}
                handlePrevPage={handlePrevPage}
                handleLastPage={handleLastPage}
                handleNextPage={handleNextPage}
                renderPageNumbers={renderPageNumbers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseSummaryDetails;
