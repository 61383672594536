export const DEFAULT_PROFILE_PHOTO =
  "https://www.shareicon.net/data/512x512/2016/07/26/802043_man_512x512.png";
export const saasToolName = [
  {
    label: "Okta",
    value: "Okta",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "Jira",
    value: "Jira",
  },
  {
    label: "GitLab",
    value: "GitLab",
  },
  {
    label: "Confluence",
    value: "Confluence",
  },
  {
    label: "Atlassian",
    value: "Atlassian",
  },
  {
    label: "Youtrack",
    value: "Youtrack",
  },
  {
    label: "Slack",
    value: "Slack",
  },
  {
    label: "AnyDesk",
    value: "AnyDesk",
  },
  {
    label: "Miro",
    value: "Miro",
  },
  {
    label: "GCP",
    value: "GCP",
  },
  {
    label: "AWS",
    value: "AWS",
  },
  {
    label: "MergeBase",
    value: "MergeBase",
  },
  {
    label: "SOPHOS MDR",
    value: "SOPHOS_MDR",
  },
  {
    label: "SOPHOS MDR Server",
    value: "SOPHOS_MDR_Server",
  },
  {
    label: "SOPHOS ZTNA",
    value: "SOPHOS_ZTNA",
  },
  {
    label: "SOPHOS Cloud Optix",
    value: "SOPHOS_Cloud_Optix",
  },
];

export const saasToolNameForPayment = [
  {
    label: "Okta",
    value: "Okta",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "Jira",
    value: "Jira",
  },
  {
    label: "GitLab",
    value: "GitLab",
  },
  {
    label: "Confluence",
    value: "Confluence",
  },
  {
    label: "Atlassian",
    value: "Atlassian",
  },
  {
    label: "Youtrack",
    value: "Youtrack",
  },
  {
    label: "Slack",
    value: "Slack",
  },
  {
    label: "AnyDesk",
    value: "AnyDesk",
  },
  {
    label: "Miro",
    value: "Miro",
  },
  {
    label: "GCP",
    value: "GCP",
  },
  {
    label: "AWS",
    value: "AWS",
  },
  {
    label: "MergeBase",
    value: "MergeBase",
  },
  {
    label: "SOPHOS MDR",
    value: "SOPHOS MDR",
  },
  {
    label: "SOPHOS MDR Server",
    value: "SOPHOS MDR Server",
  },
  {
    label: "SOPHOS ZTNA",
    value: "SOPHOS ZTNA",
  },
  {
    label: "SOPHOS Cloud Optix",
    value: "SOPHOS Cloud Optix",
  },
  {
    label: "Namecheap",
    value: "Namecheap",
  }
];

export const CURRENCY = [
  {
    label: "JPY",
    value: "JPY",
  },
  {
    label: "USD",
    value: "USD",
  },
];

export const SubscriptionsName = [
  {
    label: "Free",
    value: "Free",
  },
  {
    label: "Premium",
    value: "Premium",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Cloud",
    value: "Cloud",
  },
  {
    label: "Cloud Optix",
    value: "Cloud Optix",
  },
  {
    label: "Enterprise",
    value: "Enterprise",
  },
  {
    label: "Enterprise Plus",
    value: "Enterprise Plus",
  },
  {
    label: "Enterprise Cloud",
    value: "Enterprise Cloud",
  },
  {
    label: "Enterprise Trial",
    value: "Enterprise Trial",
  },
  {
    label: "ZTNA",
    value: "ZTNA",
  },
  {
    label: "MDR",
    value: "MDR",
  },
  {
    label: "MDR Server",
    value: "MDR Server",
  },
  {
    label: "As Pay As You Go",
    value: "As Pay As You Go",
  },
  {
    label: "SSO, Adaptive MFA, Universal Directory, Lifecycle Management",
    value: "SSO, Adaptive MFA, Universal Directory, Lifecycle Management",
  },
  // {
  //   label: "Adaptive MFA",
  //   value: "Adaptive MFA",
  // },
  // {
  //   label: "Universal Directory",
  //   value: "Universal Directory",
  // },
  // {
  //   label: "Lifecycle Management",
  //   value: "Lifecycle Management",
  // },
];

export const PAYMENT_TYPE_ARRAY = ['New', 'Renewal', 'Additional', 'Monthly', 'On Demand'];

export const PAYMENT_TYPE_OPTIONS = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Renewal",
    value: "Renewal",
  },
  {
    label: "Additional",
    value: "Additional",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "On Demand",
    value: "On Demand",
  },
];

export const PricingStructure = [
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Yearly",
    value: "Yearly",
  },
];
//organizations pricing list tool name. Used in table cellData
export const SAAS_TOOLS = [
  "okta",
  "box",
  "gitlab",
  "confluence",
  "atlassian",
  "youtrack",
  "miro",
  "gcp",
  "shophos_mdr",
  "shophos_mdr_server",
  "shophos_ztna",
  "shophos_cloud_optix",
];

export const REQUEST_MANAGEMENT_SAAS_TOOLS = [
  {
    label: "Confluence",
    value: "Confluence",
  },
  {
    label: "GitLab",
    value: "GitLab",
  },
  // {
  //   label: "MergeBase",
  //   value: "MergeBase",
  // },
  {
    label: "Miro",
    value: "Miro",
  },
  {
    label: "Youtrack",
    value: "Youtrack",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "GCP",
    value: "GCP",
  },
  {
    label: "SOPHOS MDR Agent Installation",
    value: "SOPHOS MDR Agent Installation",
  },
  {
    label: "SOPHOS MDR Server Agent Installation",
    value: "SOPHOS MDR Server Agent Installation",
  },
  {
    label: "SOPHOS Cloud Optix",
    value: "SOPHOS Cloud Optix",
  },
];

export const REQUEST_MANAGEMENT_TYPE_OPTIONS = [
  { label: "Group", value: "Group" },
  { label: "Project", value: "Project" },
  { label: "Space", value: "Space" },
  { label: "Team", value: "Team" },
  { label: "Dashboard", value: "Dashboard" },
  { label: "License", value: "License" },
  { label: "OS", value: "OS" },
];

export const YOU_TRACK_URL = "https://onemitutoyodevops.youtrack.cloud/issue/";
export const SOMETHING_WENT_WRONG =
  "Something went wrong. Please try again later.";
export const NOTE_FOR_LICENSE_MANAGEMENT =
  "If you want to add new Saas Tool. Please add from License management and update from here.";

export const ROUTE = {
  LICENSE_MANAGEMENT: "/licensing-summary",
  LICENSE_HISTORY_LIST: "/licensing-summary/licensing-history",
  OKTA_USER_LIST: "/okta-user-list",
  OKTA_INVITED_USER_LIST: "/okta-invited-user-list",
  OKTA_APP_LIST: "/okta-app-list",
  OKTA_GROUP_LIST: "/okta-group-list",
  USER_REGISTRATION: "/ur&am-tool-user-registration",
  USER_LIST: "/user-list",
  USER_EDIT: "/user-edit",
  USER_PROFILE: "/user-profile",
  BOX_USER_LIST: "/box-user-list",
  BOX_INVITED_USER_LIST: "/box-invited-user-list",
  GITLAB_USER_LIST: "/gitlab-user-list",
  GITLAB_GROUP_LIST: "/gitlab-group-list",
  GITLAB_GROUP_STORAGE_COMPARISON: "/gitlab-group-storage-comparison",
  GITLAB_PROJECT_LIST: "/gitlab-project-list",
  GITLAB_INVITED_USER_LIST: "/gitlab-invited-user-list",
  YOUTRACK_USER_LIST: "/youtrack-user-list",
  YOUTRACK_INVITED_USER_LIST: "/youtrack-invited-user-list",
  YOUTRACK_GROUP_LIST: "/youtrack-group-list",
  YOUTRACK_PROJECT_LIST: "/youtrack-project-list",
  SUPPORT_TICKET_STATUS: "/support-tickets-status",
  SUPPORT_TICKETS_LIST: "/support-tickets-list",
  API_KEY: "/api-key-management",
  CONFLUENCE_USER_LIST: "/confluence-user-list",
  CONFLUENCE_INVITED_USER_LIST: "/confluence-invited-user-list",
  CONFLUENCE_GROUP_LIST: "/confluence-group-list",
  CONFLUENCE_SPACE_LIST: "/confluence-space-list",
  ATLASSIAN_USER_LIST: "/atlassian-user-list",
  ATLASSIAN_INVITED_USER_LIST: "/atlassian-invited-user-list",
  MIRO_USER_LIST: "/miro-user-list",
  MIRO_INVITED_USER_LIST: "/miro-invited-user-list",
  MIRO_TEAM_LIST: "/miro-team-list",
  MIRO_PROJECT_LIST: "/miro-project-list",
  MIRO_BOARD_LIST: "/miro-board-list",
  BROADCAST_EMAIL: "/create-broadcast-email",
  BROADCAST_HISTORY: "/email-broadcast-history",
  ACTIVITY_REPORT: "/user-activity-report",
  ORGANIZATION_LIST: "/organization-list",
  ORGANIZATION_CREATE: "/create-organization",
  ORGANIZATION_EDIT: "/organization-edit",
  SAAS_TOOl_WISE_BUDGET: "/saas-tool-wise-budget",
  ORGANIZATIONS_WISE_BUDGET: "/organizations-wise-budget",
  USER_WISE_BUDGET: "/user-wise-budget",
  GCP_USER_LIST: "/gcp-user-list",
  GCP_INVITED_USER_LIST: "/gcp-invited-user-list",
  GCP_GROUP_LIST: "/gcp-group-list",
  GCP_PROJECT_BILLING_INFO: "/gcp-project-billing-info",
  AWS_USER_LIST: "/aws-user-list",
  AWS_INVITED_USER_LIST: "/aws-invited-user-list",
  AWS_GROUP_LIST: "/aws-group-list",
  AWS_BILLING: "/aws-billing",
  SOPHOS_USER_LIST: "/sophos-user-list",
  SOPHOS_INVITED_USER_LIST: "/sophos-invited-user-list",
  SOPHOS_GROUP_LIST: "/sophos-group-list",
  KLOCWORK: "/klocwork",
  RENOREX: "/renorex",
  INVENTORY_MANAGEMENT: "/inventory-management",
  STATIC_REPORTING: "/static-report",
  DYNAMIC_REPORTING: "/dynamic-report",
  UM_TOOL_USER_ROLE_LIST: "/ur&am-tool-user-role-list",
  ROLE_CREATE: "/add-role",
  ROLE_EDIT: "/edit-role",
  OTP_VERIFICATION: "/otp-verification",
  SAML: "/saml",
  PRIVACY_POLICY: "/privacy-policy",
  LOGIN: "/login",
  LOGOUT: "/logout",
  PAYMENT_HISTORY: "/payment-history",
  PAYMENT_REPORT: "/payment-report",
  PAYMENT_CREATE: "/create-payment",
  PAYMENT_EDIT: "/edit-payment",
  APPROVER_LIST: "/approver-list",
  ADD: "/add",
  EDIT: "/edit",
  REQUEST_LIST: "/request-list",
  MERGEBASE: "/mergebase",
  REQUEST_HISTORY: "/request-history",
  CREATE_REQUEST: "/create-request",
  PENDING_REQUEST_LIST: "/pending-request-list",
  ALL_REQUEST_LIST: "/all-request-list",
  APPROVED_REQUEST_LIST: "/approved-request-list",
  UM_TOOL_USER_LIST: "/ur&am-tool-user-list",
  NOTIFICATION_ALERTS: "/notification-and-alerts",
};

export const PAGE_NAME = {
  DASHBOARD: "Dashboard",
  USER_REGISTRATION: "UR&AM Tool User Registration",
  USER_LIST: "User List",
  USER_DETAILS: "User Details",
  USER_UPDATE: "Update User",
  USER_PROFILE: "Profile",
  BOX_INVITED_USER_LIST: "Box Invited User List",
  BOX_USER_LIST: "Box User List",
  BOX_USER_INFO: "Box User's Info",
  BROADCAST_EMAIL: "Create Broadcast Email",
  BROADCAST_HISTORY: "Email Broadcast History",
  BROADCAST_INFO: "Broadcast Info",
  CONFLUENCE_SPACE_LIST: "Confluence Space List",
  CONFLUENCE_GROUP_LIST: "Confluence Group List",
  CONFLUENCE_GROUP_INFO: "Confluence Group Info",
  CONFLUENCE_INVITED_USER_LIST: "Confluence Invited User List",
  CONFLUENCE_USER_LIST: "Confluence User List",
  CONFLUENCE_USER_INFO: "Confluence User Info",
  ATLASSIAN_INVITED_USER_LIST: "Atlassian Invited User List",
  ATLASSIAN_USER_LIST: "Atlassian User List",
  ATLASSIAN_USER_INFO: "Atlassian User Info",
  GCP_GROUP_LIST: "GCP Group List",
  GCP_GROUP_INFO: "GCP Group Info",
  GCP_INVITED_USER_LIST: "GCP Invited User List",
  GCP_USER_LIST: "GCP User List",
  GCP_USER_INFO: "GCP User Info",
  GCP_PROJECT_BILLING_INFO: "GCP Project Billing Info",
  GITLAB_GROUP_LIST: "GitLab Group List",
  GITLAB_GROUP_INFO: "GitLab Group Info",
  GITLAB_GROUP_STORAGE_COMPARISON: "GitLab Group Storage Comparison",
  GITLAB_INVITED_USER_LIST: "GitLab Invited User List",
  GITLAB_PROJECT_LIST: "GitLab Project List",
  GITLAB_USER_LIST: "GitLab User List",
  LICENSE_MANAGEMENT: "Licensing Summary",
  LICENSE_SUMMARY_DETAILS: "Licensing Details",
  LICENSE_HISTORY_LIST: "Licensing History",
  LICENSE_HISTORY_DETAILS: "Licensing History Details",
  LICENSE_HISTORY: "Licensing History",
  MIRO_TEAM_LIST: "Miro Team List",
  MIRO_TEAM_INFO: "Miro Team Info",
  MIRO_PROJECT_LIST: "Miro Project List",
  MIRO_PROJECT_INFO: "Miro Project Info",
  MIRO_BOARD_INFO: "Miro Board Info",
  MIRO_BOARD_LIST: "Miro Board List",
  MIRO_INVITED_USER_LIST: "Miro Invited User List",
  MIRO_USER_LIST: "Miro User List",
  MIRO_USER_INFO: "Miro User Info",
  OKTA_APP_LIST: "Okta App List",
  OKTA_APP_INFO: "Okta App Info",
  OKTA_GROUP_LIST: "Okta Group List",
  OKTA_GROUP_INFO: "Okta Group Info",
  OKTA_INVITED_USER_LIST: "Okta Invited User List",
  OKTA_USER_LIST: "Okta User List",
  OKTA_USER_INFO: "Okta User Info",
  ORGANIZATION_LIST: "Organization List",
  ORGANIZATION: "Organization",
  CREATE: "Create",
  EDIT: "Edit",
  ORGANIZATION_INFO: "Organization Info",
  SAAS_TOOl_WISE_BUDGET: "SaaS Tool Wise Budget (Monthly)",
  ORGANIZATIONS_WISE_BUDGET: "Organizations Wise Budget (Monthly)",
  USER_WISE_BUDGET: "User Wise Budget (Monthly)",
  ACTIVITY_REPORT: "User Activity Report",
  YOUTRACK_GROUP_LIST: "YouTrack Group List",
  YOUTRACK_PROJECT_LIST: "YouTrack Project List",
  YOUTRACK_PROJECT_INFO: "YouTrack Project Info",
  SUPPORT_TICKETS_LIST: "Support Tickets List",
  SUPPORT_TICKET_STATUS: "Support Tickets Status",
  SUPPORT_TICKET_INFO: "Support Ticket Info",
  YOUTRACK_INVITED_USER_LIST: "Youtrack Invited User List",
  YOUTRACK_USER_LIST: "YouTrack User List",
  YOUTRACK_USER_INFO: "YouTrack User Info",
  AWS_USER_LIST: "AWS User List",
  AWS_USER_INFO: "AWS User Info",
  AWS_INVITED_USER_LIST: "AWS Invited User List",
  AWS_GROUP_LIST: "AWS Group List",
  AWS_GROUP_INFO: "AWS Group Info",
  AWS_BILLING: "AWS Billing Info",
  SOPHOS_USER_LIST: "Sophos User List",
  SOPHOS_USER_INFO: "Sophos User Info",
  SOPHOS_INVITED_USER_LIST: "Sophos Invited User List",
  SOPHOS_GROUP_LIST: "Sophos Group List",
  SOPHOS_GROUP_INFO: "Sophos Group Info",
  FLOATING_LICENSE: "Floating License",
  INVENTORY_MANAGEMENT: "Inventory Management",
  STATIC_REPORTING: "Static Report",
  DYNAMIC_REPORTING: "Dynamic Report",
  UM_TOOL_USER_ROLE_LIST: "UR&AM Tool User Role List",
  UM_TOOL_USER_ROLE_DETAILS: "UR&AM Tool User Role Details",
  ROLE_CREATE: "UR&AM Tool User Role Create",
  ROLE_EDIT: "UR&AM Tool User Role Edit",
  PAYMENT_HISTORY: "Payment History",
  PAYMENT_DETAILS: "Payment Details",
  PAYMENT_CREATE: "Create Payment",
  PAYMENT_EDIT: "Edit Payment",
  PAYMENT_REPORT: "Payment Report",
  APPROVER_LIST: "Approver List",
  APPROVER_ADD: "Add Approver",
  APPROVER_EDIT: "Edit Approver",
  REQUEST_LIST: "Request List",
  REQUEST_DETAILS: "Request Details",
  REQUEST_CREATE: "Create Request",
  UM_TOOL_USER_LIST: "UR&AM Tool User List",
  UM_TOOL_USER_DETAILS: "UR&AM Tool User Details",
  APPROVED_REQUEST_LIST: "Approved Request List",
  APPROVED_REQUEST_DETAILS: "Approved Request Details",
  PENDING_REQUEST_LIST: "Pending Request List",
  REQUEST_HISTORY: "Request History",
  ALL_REQUEST_LIST: "All Request List",
};

export const API_URL = {
  CREATE: "/create",
  UPDATE: "/update",
  USERS: "/users",
  API_KEY: "/api-key",
  ROLES: "/roles",
  PERMISSIONS: "/permissions",
  CATEGORY_WISE_PERMISSIONS: "/category-wise-permissions",
  LOGIN: "/login",
  SAML_LOGIN: "/saml/login",
  REGISTER: "/register",
  LOGOUT: "/logout",
  BROADCAST: "/broadcast",
  LICENSE: "/license",
  LICENSE_HISTORY_LIST: "/history-list",
  REPORT: "/report",
  OKTA_USERS: "/okta/users",
  OKTA_APPS: "/okta/apps",
  OKTA_GROUPS: "/okta/groups",
  ORGANIZATIONS: "/organizations",
  PRICING: "/pricing",
  REPORTS_ACTIVITY: "/reports/activity",
  REPORTS_ACTIVITY_DAYS: "/reports/users-last-activity-date",
  SAAS_TOOLS: "/saas-tools",
  SYNC_USER: "/sync-user",
  INVITED_USER_LIST: "/invited-user-list",
  USER_PROJECTS: "/user-projects",
  USER_TEAMS: "/user-teams",
  USER_BOARDS: "/user-boards",
  GROUPS: "/groups",
  USER_GROUPS: "/user-groups",
  GROUP_USERS: "/group-users",
  USER_SYNC_BY_CSV: "/user-sync-by-csv",
  GROUP_LIST: "/group-list",
  GITLAB: "/gitlab",
  STORAGE_COMPARISON: "/storage-comparison",
  PROJECTS: "/projects",
  PROJECT: "/project",
  PIPELINE_REPORTS: "/pipeline-reports",
  PROJECT_LIST: "/project-list",
  PROJECT_MEMBER: "/project-member",
  TEAMS: "/teams",
  TEAM_MEMBERS: "/team-members",
  BOARDS: "/boards",
  BOARD_MEMBERS: "/board-members",
  YOUTRACK_TICKETS: "/youtrack/tickets",
  YOUTRACK_TICKET_REPORTS: "/youtrack/ticket-reports",
  YOUTRACK_TICKET_SPENT_TIME_REPORTS: "/youtrack/ticket-spent-time-reports",
  YOUTRACK_SYNC_TICKETS: "/youtrack/sync-tickets",
  GCP_PROJECT_BILLING_INFO: "reports/project-billing",
  AWS_BILLING_INFO: "reports/aws-billing",
  AWS_BILLING_REPORT: "reports/aws-billing-report",
  MIRO_BOARD_REPORT_BY_PROJECT: "reports/miro-board-report-by-project",
  UPDATE_PROFILE: "/update-profile",
  OTP: "otp",
  RESEND_OTP: "resend-otp",
  PAYMENT: "/payment",
  APPROVER: "/approver",
  ME: "/me",
  DASHBOARD: "/dashboard",
  CARD_DATA: "/card-data",
  GROUP_DATA: "/group-data",
  REQUEST_MANAGEMENT: "/request-management",
  PENDING: "/pending",
  ENTITY_LIST: "/entity-list",
  MIRO: "/miro",
  BOARD_REPORTS: "/board-reports",
  TEAM: "/team",
  REVIEW: "/review",
  APPROVED: "/approved",
  CHANGE_STATUS: "/change-status",
  MY_REQUESTS: "/my-requests",
};

export const PERMISSIONS = {
  API_KEY_VIEW: {
    title: "api-key-view",
    displayTitle: "API Key View",
    category: "API Key",
  },
  API_KEY_CREATE: {
    title: "api-key-create",
    displayTitle: "API Key Create",
    category: "API Key",
  },
  API_KEY_DELETE: {
    title: "api-key-delete",
    displayTitle: "API Key Delete",
    category: "API Key",
  },
  API_KEY_EDIT: {
    title: "api-key-edit",
    displayTitle: "API Key Edit",
    category: "API Key",
  },
  DASHBOARD_VIEW: {
    title: "dashboard-view",
    displayTitle: "Dashboard View",
    category: "Dashboard",
  },
  BROADCAST_VIEW: {
    title: "broadcast-view",
    displayTitle: "Broadcast View",
    category: "Broadcast",
  },
  BROADCAST_EMAIL_CREATE: {
    title: "broadcast-email-create",
    displayTitle: "Broadcast Email Create",
    category: "Broadcast",
  },
  REPORTING_VIEW: {
    title: "reporting-view",
    displayTitle: "Reporting View",
    category: "Reporting",
  },
  STATIC_REPORT_VIEW: {
    title: "static-report-view",
    displayTitle: "Static Report View",
    category: "Reporting",
  },
  ACTIVITY_REPORT_VIEW: {
    title: "activity-report-view",
    displayTitle: "Activity Report View",
    category: "Reporting",
  },
  DYNAMIC_REPORT_VIEW: {
    title: "dynamic-report-view",
    displayTitle: "Dynamic Report View",
    category: "Reporting",
  },
  DYNAMIC_REPORT_CREATE: {
    title: "dynamic-report-create",
    displayTitle: "Dynamic Report Create",
    category: "Reporting",
  },
  DYNAMIC_REPORT_EDIT: {
    title: "dynamic-report-edit",
    displayTitle: "Dynamic Report Edit",
    category: "Reporting",
  },
  DYNAMIC_REPORT_DELETE: {
    title: "dynamic-report-delete",
    displayTitle: "Dynamic Report Delete",
    category: "Reporting",
  },
  LICENSE_MANAGEMENT_VIEW: {
    title: "license-management-view",
    displayTitle: "License Management View",
    category: "License Management",
  },
  LICENSE_HISTORY_VIEW: {
    title: "license-history-view",
    displayTitle: "License History View",
    category: "License Management",
  },
  LICENSE_HISTORY_SYNC_BY_CSV: {
    title: "license-history-sync-by-csv",
    displayTitle: "License History Sync By CSV",
    category: "License History",
  },
  UPDATE_LICENSE_INFO: {
    title: "update-license-info",
    displayTitle: "Update License Info",
    category: "License Management",
  },
  LICENSE_CREATE: {
    title: "license-create",
    displayTitle: "License Create",
    category: "License Management",
  },
  LICENSE_EDIT: {
    title: "license-edit",
    displayTitle: "License Edit",
    category: "License Management",
  },
  BUDGET_CALCULATION_VIEW: {
    title: "budget-calculation-view",
    displayTitle: "Budget Calculation View",
    category: "Budget Calculation",
  },
  SAAS_TOOL_WISE_BUDGET_VIEW: {
    title: "saas-tool-wise-budget-view",
    displayTitle: "Saas Tool Wise Budget View",
    category: "Budget Calculation",
  },
  ORGANIZATION_WISE_BUDGET_VIEW: {
    title: "organization-wise-budget-view",
    displayTitle: "Organization Wise Budget View",
    category: "Budget Calculation",
  },
  USER_WISE_BUDGET_VIEW: {
    title: "user-wise-budget-view",
    displayTitle: "User Wise Budget View",
    category: "Budget Calculation",
  },
  EDIT_BUDGET: {
    title: "edit-budget",
    displayTitle: "Edit Budget",
    category: "Budget Calculation",
  },
  SAAS_TOOL_VIEW: {
    title: "saas-tool-view",
    displayTitle: "Saas Tool View",
    category: "Saas Tool",
  },
  SAAS_GROUP_VIEW: {
    title: "saas-group-view",
    displayTitle: "Saas Group View",
    category: "Saas Tool",
  },
  SAAS_PROJECT_VIEW: {
    title: "saas-project-view",
    displayTitle: "Saas Project View",
    category: "Saas Tool",
  },
  SAAS_USER_VIEW: {
    title: "saas-user-view",
    displayTitle: "Saas User View",
    category: "Saas Tool",
  },
  SAAS_INVITED_USER_VIEW: {
    title: "saas-invited-user-view",
    displayTitle: "Saas Invited User View",
    category: "Saas Tool",
  },
  SYNC_SAAS_USER: {
    title: "sync-saas-user",
    displayTitle: "Sync Saas User",
    category: "Saas Tool",
  },
  CLOUD_INFRA_VIEW: {
    title: "cloud-infra-view",
    displayTitle: "Cloud Infra View",
    category: "Cloud Infra",
  },
  CLOUD_USER_VIEW: {
    title: "cloud-user-view",
    displayTitle: "Cloud User View",
    category: "Cloud Infra",
  },
  CLOUD_INVITED_USER_VIEW: {
    title: "cloud-invited-user-view",
    displayTitle: "Cloud Invited User View",
    category: "Cloud Infra",
  },
  CLOUD_GROUP_VIEW: {
    title: "cloud-group-view",
    displayTitle: "Cloud Group View",
    category: "Cloud Infra",
  },
  CLOUD_PROJECT_VIEW: {
    title: "cloud-project-view",
    displayTitle: "Cloud Project View",
    category: "Cloud Infra",
  },
  FLOATING_LICENSE_VIEW: {
    title: "floating-license-view",
    displayTitle: "Floating License View",
    category: "Floating License",
  },
  INVENTORY_MANAGEMENT_VIEW: {
    title: "inventory-management-view",
    displayTitle: "Inventory Management View",
    category: "Inventory",
  },
  SUPPORT_TICKETS_VIEW: {
    title: "support-tickets-view",
    displayTitle: "Support Tickets View",
    category: "Support Tickets",
  },
  SUPPORT_TICKETS_REPORT_VIEW: {
    title: "support-tickets-report-view",
    displayTitle: "Support Tickets Report View",
    category: "Support Tickets",
  },
  SYNC_SUPPORT_TICKETS: {
    title: "sync-support-tickets",
    displayTitle: "Sync Support Tickets",
    category: "Support Tickets",
  },
  ORGANIZATION_VIEW: {
    title: "organization-view",
    displayTitle: "Organization View",
    category: "Organization",
  },
  ORGANIZATION_CREATE: {
    title: "organization-create",
    displayTitle: "Organization Create",
    category: "Organization",
  },
  ORGANIZATION_EDIT: {
    title: "organization-edit",
    displayTitle: "Organization Edit",
    category: "Organization",
  },
  ORGANIZATION_DELETE: {
    title: "organization-delete",
    displayTitle: "Organization Delete",
    category: "Organization",
  },
  USER_VIEW: {
    title: "user-view",
    displayTitle: "User View",
    category: "User Management",
  },
  USER_ROLE_EDIT: {
    title: "user-role-edit",
    displayTitle: "User Role Edit",
    category: "User Management",
  },
  USER_REGISTRATION: {
    title: "user-registration",
    displayTitle: "User Registration",
    category: "User Management",
  },
  USER_EDIT: {
    title: "user-edit",
    displayTitle: "User Edit",
    category: "User Management",
  },
  USER_DELETE: {
    title: "user-delete",
    displayTitle: "User Delete",
    category: "User Management",
  },
  USER_RESTORE: {
    title: "user-restore",
    displayTitle: "User Restore",
    category: "User Management",
  },
  USER_PROFILE: {
    title: "user-profile",
    displayTitle: "User Profile",
    category: "User Management",
  },
  PERMISSION_VIEW: {
    title: "permission-view",
    displayTitle: "Permission View",
    category: "Permission Management",
  },
  PERMISSION_CREATE: {
    title: "permission-create",
    displayTitle: "Permission Create",
    category: "Permission Management",
  },
  PERMISSION_EDIT: {
    title: "permission-edit",
    displayTitle: "Permission Edit",
    category: "Permission Management",
  },
  PERMISSION_DELETE: {
    title: "permission-delete",
    displayTitle: "Permission Delete",
    category: "Permission Management",
  },
  ROLE_VIEW: {
    title: "role-view",
    displayTitle: "Role View",
    category: "Role Management",
  },
  ROLE_CREATE: {
    title: "role-create",
    displayTitle: "Role Create",
    category: "Role Management",
  },
  ROLE_EDIT: {
    title: "role-edit",
    displayTitle: "Role Edit",
    category: "Role Management",
  },
  ROLE_DELETE: {
    title: "role-delete",
    displayTitle: "Role Delete",
    category: "Role Management",
  },

  PAYMENT_MANAGEMENT_VIEW: {
    title: "payment-management-view",
    displayTitle: "Payment Management View",
    category: "Payment Management",
  },
  PAYMENT_MANAGEMENT_CREATE: {
    title: "payment-management-create",
    displayTitle: "Payment Management Create",
    category: "Payment Management",
  },
  PAYMENT_MANAGEMENT_EDIT: {
    title: "payment-management-edit",
    displayTitle: "Payment Management Edit",
    category: "Payment Management",
  },
  PAYMENT_MANAGEMENT_DELETE: {
    title: "payment-management-delete",
    displayTitle: "Payment Management Delete",
    category: "Payment Management",
  },
  APPROVER_VIEW: {
    title: "approver-view",
    displayTitle: "Approver View",
    category: "Request Management",
  },
  APPROVER_CREATE: {
    title: "approver-create",
    displayTitle: "Approver Create",
    category: "Request Management",
  },
  APPROVER_EDIT: {
    title: "approver-edit",
    displayTitle: "Approver Edit",
    category: "Request Management",
  },
  APPROVER_DELETE: {
    title: "approver-delete",
    displayTitle: "Approver Delete",
    category: "Request Management",
  },
  REQUEST_MANAGEMENT_VIEW: {
    title: "request-view",
    displayTitle: "Request View",
    category: "Request Management",
  },
  REQUEST_MANAGEMENT_CREATE: {
    title: "request-create",
    displayTitle: "Create Request",
    category: "Request Management",
  },
  REQUEST_MANAGEMENT_APPROVE: {
    title: "request-approve",
    displayTitle: "Request Approve",
    category: "Request Management",
  },
  SECURITY_TOOL_USER_VIEW: {
    title: "security-tool-user-view",
    displayTitle: "Security Tool User View",
    category: "Security Tool Management",
  },
  SECURITY_TOOL_INVITED_USER_VIEW: {
    title: "security-tool-invited-user-view",
    displayTitle: "Security Tool Invited User View",
    category: "Security Tool Management",
  },
  SECURITY_TOOL_GROUP_VIEW: {
    title: "security-tool-group-view",
    displayTitle: "Security Tool Group View",
    category: "Security Tool Management",
  },
  APPROVAL_MANAGEMENT_VIEW: {
    title: "approval-management-view",
    displayTitle: "Approval Management View",
    category: "Approval Management",
  },
  APPROVAL_MANAGEMENT_PENDING_VIEW: {
    title: "approval-management-pending-view",
    displayTitle: "Approval Management Pending View",
    category: "Approval Management",
  },
  APPROVAL_MANAGEMENT_APPROVED_VIEW: {
    title: "approval-management-approved-view",
    displayTitle: "Approval Management Approved View",
    category: "Approval Management",
  },
  NOTIFICATION_ALERTS_VIEW: {
    title: "notification-alerts-view",
    displayTitle: "Notification Alerts View",
    category: "Notification Alerts",
  },
  DASHBOARD_ADMIN_VIEW: {
    title: "dashboard-admin-view",
    displayTitle: "Dashboard Admin View",
    category: "Dashboard",
  },
};
