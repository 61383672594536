import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";

interface statusProps extends BaseComponentProps {
  status: string;
}

export const Status = ({ status, className }: statusProps) => {
  const componentClassName = "a-status";
  const modifiers = {
    [status === "DEPROVISIONED"
      ? "invitedStatus"
      : ["ACTIVE", "active"].includes(status)
        ? "activeStatus"
        : status === "PROVISIONED"
          ? "deactivatedStatus"
          : "InactiveStatus"]: !!status,
  };

  const { Container } = createComponentBase(componentClassName, "p");

  return (
    <Container modifiers={modifiers} className={className}>
      {status === "PROVISIONED"
        ? "Invited"
        : ["ACTIVE", "active"].includes(status)
          ? "Active"
          : status === "DEPROVISIONED"
            ? "Deactivated"
            : "Inactive"}
    </Container>
  );
};
