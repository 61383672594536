import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["oktaAppList"],
});

export const appSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves a list of Okta apps based on the provided query parameters.
     *
     * @param search - The search term to filter the apps.
     * @param limit - The maximum number of apps to retrieve.
     * @param current_page - The current page number for pagination.
     * @param sort_by - The field to sort the apps by.
     * @param sort_direction - The direction to sort the apps in.
     * @returns A Promise that resolves to the response data containing the list of Okta apps.
     */
    oktaAppList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url: API_URL.OKTA_APPS + `?${limit ? `limit=${limit}` : ""}${
          current_page ? `&current_page=${current_page}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ""}${
          sort_direction ? `&sort_direction=${sort_direction}` : ""
        }${search ? `&search=${search}` : ""}`,

        method: "GET",
      }),
      providesTags: ["oktaAppList"],
    }),
    /**
     * Retrieves a single Okta app by its ID.
     *
     * @param id - The ID of the Okta app to retrieve.
     * @returns A promise that resolves to the response data of the Okta app.
     */
    singleOktaApp: builder.query<ResponseDataType, string>({
      query: (id) => API_URL.OKTA_APPS + `/${id}`,
      providesTags: ["oktaAppList"],
    }),
  }),
});

export const { 
  useOktaAppListQuery,
  useSingleOktaAppQuery,
 } = appSlice;