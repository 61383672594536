import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { UnderConstruction } from "../../../organisms/underConstruction";

const RenorexUserList = () => {
    const pageClassName = "p-floatingLicense";

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>Renorex</p>
            <div className={`${pageClassName}__container`}>
                <UnderConstruction />
            </div>
        </div>
    );
};

export default RenorexUserList;
