import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";


/**
 * The base query used for making API requests.
 * @type {BaseQuery}
 */
const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:8000/api/v1",
  baseUrl: `${process.env.REACT_APP_API_URL ?? "http://localhost:8000"}/api/v1`,
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

/**
 * API Slice
 * 
 * This file contains the configuration for the API slice, which is responsible for making API requests.
 */
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401 && result?.meta?.request?.url !== `${process.env.REACT_APP_API_URL ?? "http://localhost:8000"}/api/v1/login`) {
      toast.error("Your session has expired. Please login again.");
      localStorage.clear();
      window.location.href = '/logout';
    }

    return result;
  },
  tagTypes: [],
  endpoints: (builder) => ({}),
});
