import React from "react";
import {
    createComponentBase,
    BaseComponentProps,
} from "../../../libs/components";
import image from "../../../assets/images/under-construction.png";

export interface ConstructionProps extends BaseComponentProps {
    className?: string;
}

export const UnderConstruction = ({
    className,
}: ConstructionProps) => {
    const componentClassName = "o-underConstruction";
    const { Container } = createComponentBase(componentClassName, "div");
    const modifiers = {};
    
    return (
        <Container className={className} modifiers={modifiers}>
            <div className={`${componentClassName}__container`}>
                <h1 className={`${componentClassName}__title`}>Under Construction</h1>
                <div className={`${componentClassName}__poster`}>
                    <img src={image} alt="under-construction" />
                </div>
                <p className={`${componentClassName}__text`}>
                    Currently, we are hard at work enhancing and optimizing our platform
                    to provide you with an even better experience.
                </p>
            </div>
        </Container>
    );
};
