import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime } from "../../../../../utils";
import {
  useSaasToolProjectMembersQuery,
  useSingleSaasToolProjectQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { DataTable } from "../../../../organisms/dataTable";
import { PAGE_NAME } from "../../../../../constants/constants";
import { useBoardListByProjectQuery } from "../../../../../store/miro/miroSlice";

const MiroProjectDetails = () => {
  const pageClassName = "p-miroProjectDetails";
  const { id } = useParams<{ id: string }>();
  const { data, isSuccess } = useSingleSaasToolProjectQuery({ id });

  const memberTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "memberFirstName",
      isSortable: true,
    },
    {
      title: "Last Name",
      dataIndex: "memberLastName",
      isSortable: true,
    },
    {
      title: "Email",
      dataIndex: "memberEmail",
      isSortable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: true,
    }
  ];


  const boardTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: true,
    },
    {
      title: "Number of Members",
      dataIndex: "numberOfMembers",
      isSortable: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      isSortable: true,
    },
  ];


  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.MIRO_PROJECT_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Saas Tool Project Id
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.toolProjectId ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.name ?? <Skeleton height="small" />}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Number of Members
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.numberOfMembers
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Number of Boards
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isSuccess ? (
              data?.result?.numberOfBoards
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>
        
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Updated At</p>
          <p className={`${pageClassName}__container__item__value`}>
            {data?.result?.updatedAt ? (
              formattedDateTime(data?.result?.updatedAt)
            ) : (
              <Skeleton height="small" />
            )}
          </p>
        </div>

        <DataTable
          title="Members"
          id={id as string}
          toolName="Miro"
          tableHeader={memberTableHeader}
          useCallApiQuery={useSaasToolProjectMembersQuery}
        />

        <DataTable
          title="Boards"
          id={id as string}
          toolName="Miro"
          tableHeader={boardTableHeader}
          useCallApiQuery={useBoardListByProjectQuery}
        />
      </div>
    </div>
  );
};

export default MiroProjectDetails;
