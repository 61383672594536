import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";
import { TableDataType, TableHeaderType } from "../../../types";
import { Icon } from "../icon";
import cellDataGenerator from "./cellData";
import SimpleBar from "simplebar-react";
import { MultipleSelect } from "../multipleSelect";
import { Loader } from "../loader";

export interface TableProps extends BaseComponentProps {
  className?: string;
  data: TableDataType[];
  header: TableHeaderType[];
  sortHandler?: (sortBy: string) => void;
  loading?: boolean;
  firstLoad?: boolean;
  startIndex?: number;
  sortBy?: string;
  icon?: string;
  cursorPointer?: boolean;
  minWidth?: "800" | "1000" | "1440" | "1600" | "2000" | "2400" | "3000"
  minHeight?: "330"
  viewHandler?: (slug: string, e: any) => void;
  editHandler?: (slug: string) => void;
  deleteHandler?: (id: string) => void;
  clickedIndex?: number;
  showMoreHandler?: (num: number) => void;
  showLessHandler?: () => void;
  dropdownOptions?: {
    label: string;
    value: string;
  }[];
  selectedType?: {
    label: string;
    value: string;
  }[];
  dropdownHandler?: (selected: { label: string; value: string }[]) => void;
  customField ?: string | number | boolean | React.ReactNode;
}

export const Table = ({
  data,
  header,
  startIndex,
  className,
  loading = false,
  firstLoad,
  sortBy,
  icon,
  minWidth,
  minHeight,
  cursorPointer,
  customField,
  dropdownOptions = [],
  selectedType = [],
  viewHandler = () => { },
  editHandler = () => { },
  deleteHandler = () => { },
  sortHandler = () => { },
  showMoreHandler = () => { },
  showLessHandler = () => { },
  dropdownHandler = () => { },
  clickedIndex,
}: TableProps) => {
  const componentClassName = "a-table";
  const [tableHeader, setTableHeader] =
    React.useState<TableHeaderType[]>(header);

  React.useEffect(() => {
    setTableHeader(header);
  }, [header]);

  const { Container } = createComponentBase(componentClassName, "div");


  const modifiers = {
    [minWidth + "minWidth"]: !!minWidth,
    [minHeight + "minHeight"]: !!minHeight,
    "cursorPointer": cursorPointer,
    'scrollable': data.length < 15
  };

  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }, [data]);



  return (
    <Container className={className} modifiers={modifiers}>
      <div className={`${componentClassName}__header`}>
        {tableHeader.map((column, index) => (
          <div
            key={index}
            className={`${componentClassName}__header__cell ${column.isSortable
              ? `${componentClassName}__header__cell--sortable`
              : ""
              } ${`${componentClassName}__header__cell--${column.dataIndex}`}`}
            onClick={() => column.isSortable && sortHandler(column.dataIndex)}
          >
            {column.title}
            {["state", "eventType","paymentToolName"].includes(column.dataIndex) && (
              <MultipleSelect
                defaultSelected={selectedType}
                defaultOptions={dropdownOptions}
                onChangeHandler={(selected: any) =>
                  dropdownHandler(selected)
                }
              >
                {selectedType.length > 0 ? (
                  <Icon icon="filter" mask size="small" />
                ) : (
                  <Icon icon="downFill" mask size="small" />
                )}
              </MultipleSelect>
            )}
            {column.isSortable && (
              <div className={`${componentClassName}__header__cell__sort`}>
                <Icon
                  icon={
                    sortBy === column.dataIndex
                      ? icon ?? "sort"
                      : ""
                  }
                  mask
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {firstLoad ? (
        <div
          className={`${componentClassName}__skeleton ${componentClassName}__body`}
        >
          {Array.from(Array(10).keys()).map((item) => (
            <div key={item} className={`${componentClassName}__body__row`}>
              {header.map((item) => (
                <div
                  key={item.dataIndex}
                  className={`${componentClassName}__body__row__cell ${`${componentClassName}__body__row__cell--${item.dataIndex}`} `}
                >
                  <div className={`${componentClassName}__skeleton__content`} />
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        data.length === 0 ? (
          <div className={`${componentClassName}__body`}>
            <div className={`${componentClassName}__body__empty`}>
              <p>No data available</p>
            </div>
          </div>
        ) : (
          <Loader isLoading={loading}>
            <div className={`${componentClassName}__body`}>
              <SimpleBar className={`${componentClassName}__body__scroll`}>
                <div
                  className={`${componentClassName}__body__content`}
                  ref={divRef}
                >
                  {data.map((row: any, index: number) => (
                    <div key={index} className={`${componentClassName}__body__row`}>
                      {header.map((item) => (
                        <div
                          key={item.dataIndex}
                          className={`${componentClassName}__body__row__cell ${`${componentClassName}__body__row__cell--${item.dataIndex}`}`}
                          onClick={(e) => viewHandler(row.id, e)}
                        >
                          {cellDataGenerator({
                            cell: item.dataIndex,
                            hasPermission: item?.permission?? true,
                            row,
                            index: startIndex ? startIndex + index : index,
                            editHandler,
                            deleteHandler,
                            showMoreHandler,
                            showLessHandler,
                            clickedIndex,
                            componentClassName,
                            customField
                          })}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </SimpleBar>
            </div>
          </Loader>
        )
      )}
    </Container>
  );
};
