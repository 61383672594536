// Switch.tsx
import React, { useState } from 'react';
import {
    BaseComponentProps,
    createComponentBase,
} from "../../../libs/components";

interface SwitchProps extends BaseComponentProps {
    onInputChange: (checked: boolean) => void;
    defaultChecked?: boolean;
    color?: "blue";
}
export const Switch = ({ onInputChange, defaultChecked, color, className }: SwitchProps) => {
    const componentClassName = "a-switch";
    const [checked, setChecked] = useState<boolean>(defaultChecked as boolean);

    React.useEffect(() => {
        setChecked(defaultChecked as boolean);
    }, [defaultChecked]);

    const handleToggle = () => {
        const newChecked = !checked;
        onInputChange(newChecked);
    };

    const modifiers = {
        [checked ? color ? color + "Checked" : "checked" : ""]: true,
    };
    const { Container } = createComponentBase(componentClassName, "label");

    return (
        <Container className={className} modifiers={modifiers} onClick={handleToggle}>
            <input type="checkbox" checked={checked} className={`${componentClassName}__input`} />
            <span className={`${componentClassName}__slider`} />
        </Container>
    );
};
