import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { toast } from "react-toastify";
import { ResponseType, payloadType } from "../../../../types";
import { useNavigate, useParams } from "react-router-dom";
import {
    useOrganizationCreateMutation,
    useOrganizationDetailsQuery,
    useOrganizationUpdateMutation,
} from "../../../../store/organization/organizationSlice";
import { Loader } from "../../../atoms/loader";
import { PAGE_NAME, ROUTE, SOMETHING_WENT_WRONG } from "../../../../constants/constants";

const OrganizationCreate = () => {
    const pageClassName = "p-organizationCreate";
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const initialPayload: payloadType = {
        name: {
            value: "",
            error: "",
            isError: false,
        },
        state: {
            value: "",
            error: "",
            isError: false,
        },
        country: {
            value: "",
            error: "",
            isError: false,
        },
        email_ext: {
            value: "",
            error: "",
            isError: false,
        },
    };

    const [payload, setPayload] = React.useState(initialPayload);

    const [createApi, { isLoading }] = useOrganizationCreateMutation();
    const [updateApi] = useOrganizationUpdateMutation();

    const {
        data,
        isSuccess,
        isLoading: detailsLoading,
    } = useOrganizationDetailsQuery(
        id as unknown as number,
        {
            skip: id ? false : true,
        }
    );

    React.useEffect(() => {
        if (!id) {
            setPayload(initialPayload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    React.useEffect(() => {
        if (id) {
            if (isSuccess) {
                setPayload({
                    name: {
                        value: data?.result?.name,
                        error: "",
                        isError: false,
                    },
                    state: {
                        value: data?.result?.state,
                        error: "",
                        isError: false,
                    },
                    country: {
                        value: data?.result?.country,
                        error: "",
                        isError: false,
                    },
                    email_ext: {
                        value: data?.result?.emailExt,
                        error: "",
                        isError: false,
                    },
                });
            }
        }
    }, [isSuccess, id, data]);

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("name", payload.name.value as string);
        // eslint-disable-next-line no-lone-blocks
        {
            payload.state.value &&
                formData.append("state", payload.state.value as string);
        }
        formData.append("country", payload.country.value as string);
        formData.append("email_ext", payload.email_ext.value as string);
        if (id) {
            formData.append("_method", "PUT");
        }

        let response: any = null;
        if (id) {
            response = (await updateApi({ id: id as unknown as number, formData })) as ResponseType;
        } else {
            response = (await createApi(formData)) as ResponseType;
        }

        if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate(ROUTE.ORGANIZATION_LIST);
        } else {
            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                    //   }
                });
            } else {
                toast.error(response?.error.data?.message);
            }
            if (response?.error?.error) {
                toast.error(SOMETHING_WENT_WRONG);
            }
        }
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>
                {id ? PAGE_NAME.EDIT : PAGE_NAME.CREATE} {PAGE_NAME.ORGANIZATION}
            </p>
            <div className={`${pageClassName}__container`}>
                <Loader isLoading={isLoading || detailsLoading}>
                    <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                        <Input
                            label="Name"
                            placeholder="Please enter name"
                            type="text"
                            isRequired
                            value={payload.name.value as string}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    name: {
                                        ...payload.name,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            error={payload.name.error}
                            isError={payload.name.isError}
                        />

                        <Input
                            label="State"
                            placeholder="Please enter state"
                            type="text"
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    state: {
                                        ...payload.state,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            value={payload.state.value as string}
                            error={payload.state.error}
                            isError={payload.state.isError}
                        />
                        <Input
                            label="Country"
                            placeholder="Please enter country"
                            type="text"
                            isRequired
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    country: {
                                        ...payload.country,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            value={payload.country.value as string}
                            error={payload.country.error}
                            isError={payload.country.isError}
                        />

                        <Input
                            label="Email Extension"
                            placeholder="Please enter email extension"
                            type="text"
                            isRequired
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    email_ext: {
                                        ...payload.email_ext,
                                        value: e.target.value,
                                        isError: false,
                                        error: "",
                                    },
                                })
                            }
                            value={payload.email_ext.value as string}
                            error={payload.email_ext.error}
                            isError={payload.email_ext.isError}
                        />

                        <div className={`${pageClassName}__action`}>
                            <button
                                className={`${pageClassName}__action__button`}
                                type="submit"
                                disabled={isLoading}
                            >
                                Save
                            </button>
                            <button
                                className={`${pageClassName}__action__button ${pageClassName}__action__cancel`}
                                type="button"
                                onClick={() => navigate(ROUTE.ORGANIZATION_LIST)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </Loader>
            </div>
        </div>
    );
};

export default OrganizationCreate;
