import React from "react";

import {
    createComponentBase,
    BaseComponentProps,
} from "../../../../../libs/components";
import { Divider } from "../../../../atoms/divider";
import { Input } from "../../../../atoms/input";
import { Dropdown } from "../../../../atoms/dropdown";
import { REQUEST_MANAGEMENT_SAAS_TOOLS } from "../../../../../constants/constants";
import { Icon } from "../../../../atoms/icon";
import { useUserListQuery } from "../../../../../store/user/userSlice";

export interface RemoveUsersProps extends BaseComponentProps {
    payload: any;
    setPayload: (payload: any) => void;
    approverList: any;
}

export const AddUsersRequest = ({
    className,
    payload,
    setPayload,
    approverList,
}: RemoveUsersProps) => {
    const componentClassName = "o-addUsersRequest";
    const { Container } = createComponentBase(componentClassName, "div");
    const modifiers = {};

    const [entityOptionList, setEntityOptionList] = React.useState<any>([]);
    const [typeOptionList, setTypeOptionList] = React.useState<any>([]);
    const [roleOptionList, setRoleOptionList] = React.useState<any>([]);

    const { data: managerList } = useUserListQuery({});

    const changeHandler = (key: string, index: number) => (e: any) => {
        const { value } = e.target;
        const newUsers = [...payload.users.value];
        newUsers[index][key] = value;

        if (key === "first_name" || key === "last_name") {
            newUsers[
                index
            ].full_name = `${newUsers[index].first_name} ${newUsers[index].last_name}`;
        }
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const dropdownChangeHandler = (key: string, index: number) => (val: any) => {
        const newUsers = [...payload.users.value];
        newUsers[index][key] = val;
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    }
    const addSaaSToolHandler = (index: number) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools.push({
            name: {
                value: "",
                label: "",
            },
            type: {
                value: "",
                label: "",
            },
            entity_name: {
                value: "",
                label: "",
            },
            role: {
                value: "",
                label: "",
            },
            approver: {
                value: "",
                label: "",
            },
        });
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const addUserHandler = () => {
        const newUsers = [...payload.users.value];
        newUsers.push({
            first_name: "",
            last_name: "",
            full_name: "",
            designation: "",
            department: "",
            address: "",
            manager: "",
            email: "",
            saas_tools: [
                {
                    name: {
                        value: "Okta",
                        label: "Okta",
                    },
                },
                {
                    name: {
                        value: "UR&AM Tool",
                        label: "UR&AM Tool",
                    },
                    role: {
                        value: "User",
                        label: "User",
                    },
                },
            ],
        });
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const removeSaaSToolHandler = (index: number, saas_tool_index: number) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools.splice(saas_tool_index, 1);
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const removeUserHandler = (index: number) => {
        const newUsers = [...payload.users.value];
        newUsers.splice(index, 1);
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const toolChangeHandler = (
        val: any,
        index: number,
        saas_tool_index: number
    ) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools[saas_tool_index].name = val;
        // newUsers[index].saas_tools[saas_tool_index].type = {
        //     value: "",
        //     label: "",
        // };
        // newUsers[index].saas_tools[saas_tool_index].entity_name = {
        //     value: "",
        //     label: "",
        // };

        if (val.value === "Box") {
            const data = approverList?.result?.data.find(
                (item: any) => item.saasTool === val.value
            );

            const approvers = data?.approver.map((item: any) => ({
                value: item.id,
                label: `${item.firstName} ${item.middleName ? item.middleName + " " : ""
                    }${item.lastName}`,
            }));

            newUsers[index].saas_tools[saas_tool_index] = {
                name: {
                    value: "Box",
                    label: "Box",
                },
                type: {
                    value: "License",
                    label: "License",
                },
                role: {
                    value: "User",
                    label: "User",
                },
                approver: approvers,
            };
        } else if (
            [
                "SOPHOS MDR Agent Installation",
                "SOPHOS MDR Server Agent Installation",
            ].includes(val.value)
        ) {
            newUsers[index].saas_tools[saas_tool_index] = {
                name: val,
                type: {
                    value: "",
                    label: "",
                },
                entity_name: {
                    value: "",
                    label: "",
                },
                approver: [],
            };
        } else {
            newUsers[index].saas_tools[saas_tool_index] = {
                name: val,
                type: {
                    value: "",
                    label: "",
                },
                entity_name: {
                    value: "",
                    label: "",
                },
                role: {
                    value: "",
                    label: "",
                },
                approver: [],
            };
        }
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const typeChangeHandler = (
        val: any,
        index: number,
        saas_tool_index: number
    ) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools[saas_tool_index].type = val;
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    const typeFocusHandler = (toolName: string) => {
        if (toolName === "Confluence") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "Space",
                        label: "Space",
                    },
                ],
            });
        } else if (toolName === "GitLab") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "Group",
                        label: "Group",
                    },
                ],
            });
        } else if (toolName === "Miro") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "Team",
                        label: "Team",
                    },
                ],
            });
        } else if (toolName === "Youtrack") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "Project",
                        label: "Project",
                    },
                ],
            });
        } else if (toolName === "Box") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "License",
                        label: "License",
                    },
                ],
            });
        } else if (toolName === "GCP") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "Project",
                        label: "Project",
                    },
                ],
            });
        } else if (toolName === "SOPHOS Cloud Optix") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "Project",
                        label: "Project",
                    },
                ],
            });
        } else if (toolName === "SOPHOS MDR Agent Installation") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "OS",
                        label: "OS",
                    },
                ],
            });
        } else if (toolName === "SOPHOS MDR Server Agent Installation") {
            setTypeOptionList({
                ...typeOptionList,
                [toolName]: [
                    {
                        value: "OS",
                        label: "OS",
                    },
                ],
            });
        }
    };

    const entityFocusHandler = (
        saas_tool_index: number,
        toolName: string,
        type: string
    ) => {
        const filter = approverList?.result?.data?.filter(
            (item: any) => item.type === type && item.saasTool === toolName
        );
        const filterOptions = filter?.map((item: any) => ({
            value: item.name,
            label: item.name,
        }));
        setEntityOptionList({
            ...entityOptionList,
            [saas_tool_index]: filterOptions,
        });
    };

    const roleFocusHandler = (toolName: string, type: string) => {
        if (toolName === "Confluence" && type === "Space") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Viewer",
                        label: "Viewer",
                    },
                    {
                        value: "Editor",
                        label: "Editor",
                    },
                    {
                        value: "Space Admin",
                        label: "Space Admin",
                    },
                ],
            });
        } else if (toolName === "GitLab" && type === "Group") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Owner",
                        label: "Owner",
                    },
                    {
                        value: "Developer",
                        label: "Developer",
                    },
                    {
                        value: "Reporter",
                        label: "Reporter",
                    },
                ],
            });
        } else if (toolName === "Miro" && type === "Team") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "Youtrack" && type === "Project") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Project Admin",
                        label: "Project Admin",
                    },
                    {
                        value: "Developer",
                        label: "Developer",
                    },
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "Box" && type === "License") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "User",
                        label: "User",
                    },
                ],
            });
        } else if (toolName === "GCP" && type === "Project") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Owner",
                        label: "Owner",
                    },
                    {
                        value: "Editor",
                        label: "Editor",
                    },
                    {
                        value: "Viewer",
                        label: "Viewer",
                    },
                    {
                        value: "Browser",
                        label: "Browser",
                    },
                ],
            });
        } else if (toolName === "SOPHOS Cloud Optix" && type === "Project") {
            setRoleOptionList({
                ...roleOptionList,
                [toolName]: [
                    {
                        value: "Admin",
                        label: "Admin",
                    },
                    {
                        value: "Read Only",
                        label: "Read Only",
                    },
                    {
                        value: "HelpDesk",
                        label: "HelpDesk",
                    },
                ],
            });
        }
    };

    const entityChangeHandler = (
        val: any,
        index: number,
        saas_tool_index: number
    ) => {
        const newUsers = [...payload.users.value];
        newUsers[index].saas_tools[saas_tool_index].entity_name = val;
        const data = approverList?.result?.data.find(
            (item: any) => item.name === val.value
        );

        const approvers = data?.approver.map((item: any) => ({
            value: item.id,
            label: `${item.firstName} ${item.middleName ? item.middleName + " " : ""
                }${item.lastName}`,
        }));
        newUsers[index].saas_tools[saas_tool_index].approver = approvers;
        setPayload({
            ...payload,
            users: {
                value: newUsers,
                isError: false,
                error: "",
            },
        });
    };

    return (
        <Container className={className} modifiers={modifiers}>
            <div className={`${componentClassName}__form__users`}>
                {payload?.subType?.value?.value === "Add Users" &&
                    payload?.users?.value.map((user: any, index: number) => (
                        <div className={`${componentClassName}__form`} key={index}>
                            <Divider />
                            <div className={`${componentClassName}__form__items`}>
                                <Input
                                    label="First Name"
                                    value={user?.first_name}
                                    placeholder="Enter first name"
                                    onChange={changeHandler("first_name", index)}
                                    isRequired
                                    isStar
                                />

                                <Input
                                    label="Last Name"
                                    value={user?.last_name}
                                    placeholder="Enter last name"
                                    onChange={changeHandler("last_name", index)}
                                    isRequired
                                    isStar
                                />
                            </div>
                            <div className={`${componentClassName}__form__items`}>
                                <Input
                                    label="Full Name"
                                    value={user?.full_name}
                                    placeholder="Enter full name"
                                    onChange={() => { }}
                                    readonly
                                />

                                <Input
                                    label="Email"
                                    value={user?.email}
                                    placeholder="Enter email"
                                    onChange={changeHandler("email", index)}
                                    type="email"
                                    isRequired
                                    isStar
                                />
                            </div>

                            <div className={`${componentClassName}__form__items`}>
                                <Input
                                    label="Designation"
                                    value={user?.designation}
                                    placeholder="Enter your designation"
                                    onChange={changeHandler("designation", index)}
                                    type="text"
                                />

                                <Input
                                    label="Department"
                                    value={user?.department}
                                    placeholder="Enter your department"
                                    onChange={changeHandler("department", index)}
                                    type="text"
                                    isRequired
                                    isStar
                                />
                            </div>

                            <div className={`${componentClassName}__form__items`}>
                                <Input
                                    label="Address"
                                    value={user?.address}
                                    placeholder="Enter your address"
                                    onChange={changeHandler("address", index)}
                                    type="text"
                                    isRequired
                                    isStar
                                />

                                <div className={`${componentClassName}__form__item`}>
                                    <label className={`${componentClassName}__form__item__label`}>
                                    Personal In Charge (PIC/Manager/Team Lead)
                                    </label>
                                    <Dropdown
                                        options={managerList?.result?.data.map((item: any) => ({
                                            value: item.email,
                                            label: item.middleName ? `${item.firstName} ${item.middleName} ${item.lastName}` : `${item.firstName} ${item.lastName}`,
                                        }))}
                                        selected={user?.manager}
                                        placeholder="Select PIC"
                                        onClickChange={dropdownChangeHandler('manager', index)}
                                        className={`${componentClassName}__form__item__dropdown`}
                                        isRequired
                                    />
                                </div>
                            </div>

                            <div className={`${componentClassName}__form__items__saasTools`}>
                                {payload?.subType?.value?.value === "Add Users" &&
                                    user?.saas_tools?.map(
                                        (saas_tool: any, saas_tool_index: number) => (
                                            <div
                                                className={`${componentClassName}__form__items__remove`}
                                                key={saas_tool_index}
                                            >
                                                <div
                                                    className={`${componentClassName}__form__items`}
                                                    key={saas_tool_index}
                                                >
                                                    <Dropdown
                                                        className={`${componentClassName}__form__item__dropdown`}
                                                        options={REQUEST_MANAGEMENT_SAAS_TOOLS}
                                                        selected={
                                                            saas_tool?.name?.value === ""
                                                                ? null
                                                                : saas_tool?.name
                                                        }
                                                        placeholder="Select saas tool"
                                                        onClickChange={(val) =>
                                                            toolChangeHandler(val, index, saas_tool_index)
                                                        }
                                                        isDisabled={
                                                            ["Okta", "UR&AM Tool"].includes(
                                                                saas_tool?.name?.value
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        isRequired
                                                    />

                                                    {saas_tool?.type && (
                                                        <Dropdown
                                                            className={`${componentClassName}__form__item__dropdown`}
                                                            options={
                                                                typeOptionList[
                                                                payload.users.value[index].saas_tools[
                                                                    saas_tool_index
                                                                ].name.value
                                                                ]
                                                            }
                                                            selected={
                                                                saas_tool?.type.value === ""
                                                                    ? null
                                                                    : saas_tool?.type
                                                            }
                                                            placeholder="Select type"
                                                            onClickChange={(val) =>
                                                                typeChangeHandler(val, index, saas_tool_index)
                                                            }
                                                            focusHandler={() =>
                                                                typeFocusHandler(
                                                                    payload.users.value[index].saas_tools[
                                                                        saas_tool_index
                                                                    ].name.value
                                                                )
                                                            }
                                                            isRequired
                                                        />
                                                    )}

                                                    {saas_tool?.entity_name && (
                                                        <Dropdown
                                                            className={`${componentClassName}__form__item__dropdown`}
                                                            options={entityOptionList[saas_tool_index]}
                                                            selected={
                                                                saas_tool?.entity_name.value === ""
                                                                    ? null
                                                                    : saas_tool?.entity_name
                                                            }
                                                            focusHandler={() =>
                                                                entityFocusHandler(
                                                                    saas_tool_index,
                                                                    payload.users.value[index].saas_tools[
                                                                        saas_tool_index
                                                                    ].name.value,
                                                                    payload.users.value[index].saas_tools[
                                                                        saas_tool_index
                                                                    ].type.value
                                                                )
                                                            }
                                                            placeholder={`Select ${(saas_tool?.type?.value).toLowerCase()}`}
                                                            onClickChange={(val) =>
                                                                entityChangeHandler(val, index, saas_tool_index)
                                                            }
                                                            isRequired
                                                        />
                                                    )}

                                                    {saas_tool?.role && (
                                                        <Dropdown
                                                            className={`${componentClassName}__form__item__dropdown`}
                                                            options={
                                                                roleOptionList[
                                                                payload.users.value[index].saas_tools[
                                                                    saas_tool_index
                                                                ].name.value
                                                                ]
                                                            }
                                                            selected={
                                                                saas_tool?.role.value === ""
                                                                    ? null
                                                                    : saas_tool?.role
                                                            }
                                                            placeholder="Select role"
                                                            onClickChange={(val) => {
                                                                const newUsers = [...payload.users.value];
                                                                newUsers[index].saas_tools[
                                                                    saas_tool_index
                                                                ].role = val;
                                                                setPayload({
                                                                    ...payload,
                                                                    users: {
                                                                        value: newUsers,
                                                                        isError: false,
                                                                        error: "",
                                                                    },
                                                                });
                                                            }}
                                                            isRequired
                                                            focusHandler={() =>
                                                                roleFocusHandler(
                                                                    payload.users.value[index].saas_tools[
                                                                        saas_tool_index
                                                                    ].name.value,
                                                                    payload.users.value[index].saas_tools[
                                                                        saas_tool_index
                                                                    ].type.value
                                                                )
                                                            }
                                                            isDisabled={
                                                                ["UR&AM Tool", "Box"].includes(
                                                                    saas_tool?.name?.value
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )}

                                                    {saas_tool?.approver && (
                                                        <Dropdown
                                                            className={`${componentClassName}__form__item__dropdown`}
                                                            options={[]}
                                                            selected={
                                                                saas_tool?.approver.length > 0
                                                                    ? saas_tool?.approver
                                                                    : null
                                                            }
                                                            isDisabled
                                                            isMulti
                                                            placeholder=""
                                                            onClickChange={(val) => {
                                                                const newUsers = [...payload.users.value];
                                                                newUsers[index].saas_tools[
                                                                    saas_tool_index
                                                                ].approver = val;
                                                                setPayload({
                                                                    ...payload,
                                                                    users: {
                                                                        value: newUsers,
                                                                        isError: false,
                                                                        error: "",
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                {["Okta", "UR&AM Tool"].includes(
                                                    saas_tool?.name?.value
                                                ) ? null : (
                                                    <button
                                                        onClick={() =>
                                                            removeSaaSToolHandler(index, saas_tool_index)
                                                        }
                                                        type="button"
                                                        className={`${componentClassName}__form__items__remove__button`}
                                                    >
                                                        <Icon icon="delete" size="primary" />
                                                    </button>
                                                )}
                                            </div>
                                        )
                                    )}
                                {user?.saas_tools[user?.saas_tools?.length - 1]?.name?.value &&
                                    (!user?.saas_tools[
                                        user?.saas_tools?.length - 1
                                    ].hasOwnProperty("type") ||
                                        user?.saas_tools[user?.saas_tools?.length - 1]?.type
                                            ?.value) &&
                                    (!user?.saas_tools[
                                        user?.saas_tools?.length - 1
                                    ].hasOwnProperty("entity_name") ||
                                        user?.saas_tools[user?.saas_tools?.length - 1]?.entity_name
                                            ?.value) &&
                                    (!user?.saas_tools[
                                        user?.saas_tools?.length - 1
                                    ].hasOwnProperty("role") ||
                                        user?.saas_tools[user?.saas_tools?.length - 1]?.role
                                            ?.value) && (
                                        <button
                                            type="button"
                                            onClick={() => addSaaSToolHandler(index)}
                                            className={`${componentClassName}__form__items__saasTools__add`}
                                        >
                                            Add SaaS Tool
                                        </button>
                                    )}
                            </div>
                            <div
                                className={`${componentClassName}__form__items__remove__action`}
                            >
                                {payload?.users?.value?.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => removeUserHandler(index)}
                                        className={`${componentClassName}__form__items__remove__button`}
                                    >
                                        Remove
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                {payload?.subType?.value?.value === "Add Users" &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.first_name &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.last_name &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.full_name &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.email &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.designation &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.department &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.address &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.manager &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools[
                        payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools
                            .length - 1
                    ]?.name?.value &&
                    payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools[
                        payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools
                            .length - 1
                    ]?.role?.value &&
                    (payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools[
                        payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools
                            .length - 1
                    ]?.type?.value ||
                        !payload?.users?.value[
                            payload?.users?.value.length - 1
                        ]?.saas_tools[
                            payload?.users?.value[payload?.users?.value.length - 1]
                                ?.saas_tools.length - 1
                        ].hasOwnProperty("type")) &&
                    (payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools[
                        payload?.users?.value[payload?.users?.value.length - 1]?.saas_tools
                            .length - 1
                    ]?.entity_name?.value ||
                        !payload?.users?.value[
                            payload?.users?.value.length - 1
                        ]?.saas_tools[
                            payload?.users?.value[payload?.users?.value.length - 1]
                                ?.saas_tools.length - 1
                        ].hasOwnProperty("entity_name")) && (
                        <button
                            type="button"
                            onClick={() => addUserHandler()}
                            className={`${componentClassName}__form__items__add`}
                        >
                            Add Another User
                        </button>
                    )}
            </div>
        </Container>
    );
};
