import React from "react";
import {
    useDeletePaymentMutation,
    usePaymentDetailsQuery,
} from "../../../../store/payment/paymentSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { PAGE_NAME, PERMISSIONS, ROUTE } from "../../../../constants/constants";
import { Skeleton } from "../../../atoms/skeleton";
import {
    formattedDate,
    formattedDateTime,
    hasPermission,
    numberFormat,
} from "../../../../utils";
import { Divider } from "../../../atoms/divider";
import { Icon } from "../../../atoms/icon";
import { Dialog } from "../../../atoms/dialog";
import { ResponseType } from "../../../../types";
import { toast } from "react-toastify";
import { Switch } from "../../../atoms/switch";

const PaymentDetails = () => {
    const pageClassName = "p-paymentDetails";
    const { id } = useParams();

    const navigate = useNavigate();
    const [deleteApi] = useDeletePaymentMutation();

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState<number>();

    const dialogHandler = () => {
        setIsDialogOpen(true);
        setDeleteId(Number(id));
    };

    const closeDialogHandler = () => {
        setIsDialogOpen(false);
    };

    const deleteHandler = async () => {
        closeDialogHandler();

        const response = (await deleteApi(deleteId as number)) as ResponseType;

        if (response?.data?.success) {
            toast.success(response.data.message);
            navigate(ROUTE.PAYMENT_HISTORY);
        } else {
            toast.error(response?.error?.data?.message);
        }
    };

    const { data, isLoading } = usePaymentDetailsQuery(parseInt(id as string));
    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.PAYMENT_DETAILS}</p>
            <div className={`${pageClassName}__container`}>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Payment ID
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.customId ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        SaaS/Infra Name
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.toolName ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Payment Type
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? <Skeleton height="small" /> : data?.result?.type}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Subscription Type
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.subscriptionType ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Start Date
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.startDate ? (
                            formattedDate(data?.result?.startDate)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        End Date
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.endDate ? (
                            formattedDate(data?.result?.endDate)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Payment Date
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.paymentDate ? (
                            formattedDate(data?.result?.paymentDate)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Payment Method
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.paymentMethod ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Number of Licenses
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : (
                            data?.result?.numberOfLicense ?? "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Included Tax</p>
                    <Switch
                        color="blue"
                        onInputChange={() => { }}
                        defaultChecked={data?.result?.excludeTax}
                    />
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Total Amount
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.totalAmount ? (
                            numberFormat(data?.result?.totalAmount, data?.result?.currency)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Total Amount In JPY
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.totalAmount ? (
                            numberFormat(data?.result?.totalAmountJPY, 'JPY')
                        ) : (
                            "-"
                        )}
                    </p>
                </div>
                {data?.result?.currency === "USD" && (
                    <div className={`${pageClassName}__container__item`}>
                        <p className={`${pageClassName}__container__item__title`}>
                            Conversion Rate
                        </p>
                        <p className={`${pageClassName}__container__item__value`}>
                            {isLoading ? (
                                <Skeleton height="small" />
                            ) : data?.result?.totalAmount ? (
                                numberFormat(data?.result?.conversionRate, 'JPY')
                            ) : (
                                "-"
                            )}
                        </p>
                    </div>
                )}

                {data?.result?.file?.filePath && (
                    <div className={`${pageClassName}__container__item`}>
                        <p className={`${pageClassName}__container__item__title`}>
                            Invoice File
                        </p>
                        <a
                            href={
                                data?.result?.file?.basePath +
                                "/" +
                                data?.result?.file?.filePath
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            {isLoading ? (
                                <Skeleton height="small" />
                            ) : (
                                data?.result?.file?.fileName ?? "-"
                            )}
                        </a>
                    </div>
                )}

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>Remarks</p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.remarks ? (
                            data?.result?.remarks
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Create Date
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.createdAt ? (
                            formattedDateTime(data?.result?.createdAt)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>

                <div className={`${pageClassName}__container__item`}>
                    <p className={`${pageClassName}__container__item__title`}>
                        Update Date
                    </p>
                    <p className={`${pageClassName}__container__item__value`}>
                        {isLoading ? (
                            <Skeleton height="small" />
                        ) : data?.result?.updatedAt ? (
                            formattedDateTime(data?.result?.updatedAt)
                        ) : (
                            "-"
                        )}
                    </p>
                </div>
                <Divider />
                <div className={`${pageClassName}__container__action`}>
                    {hasPermission(PERMISSIONS.PAYMENT_MANAGEMENT_CREATE.title) && (
                        <button
                            className={`${pageClassName}__container__action__button`}
                            onClick={() =>
                                navigate(ROUTE.PAYMENT_HISTORY + ROUTE.PAYMENT_CREATE, {
                                    state: {
                                        payment: data?.result,
                                    },
                                })
                            }
                            type="button"
                        >
                            <Icon icon="copy" size="medium" /> Copy
                        </button>
                    )}
                    {hasPermission(PERMISSIONS.PAYMENT_MANAGEMENT_EDIT.title) && (
                        <button
                            className={`${pageClassName}__container__action__button`}
                            onClick={() =>
                                navigate(ROUTE.PAYMENT_HISTORY + ROUTE.PAYMENT_EDIT, {
                                    state: {
                                        id: data?.result?.id,
                                    },
                                })
                            }
                            type="button"
                        >
                            <Icon icon="edit" size="medium" /> Edit
                        </button>
                    )}
                    {hasPermission(PERMISSIONS.PAYMENT_MANAGEMENT_DELETE.title) && (
                        <button
                            className={`${pageClassName}__container__action__button ${pageClassName}__container__action__button--delete`}
                            onClick={dialogHandler}
                            type="button"
                        >
                            <Icon icon="delete" size="medium" /> Delete
                        </button>
                    )}
                </div>
            </div>
            <Dialog
                header="Delete Payment"
                body="Are you sure you want to delete this payment?"
                isOpen={isDialogOpen}
                onClose={closeDialogHandler}
                successFn={deleteHandler}
                btn="Delete"
                icon="delete"
            />
        </div>
    );
};

export default PaymentDetails;
