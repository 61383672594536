import React from "react";
import { Icon } from "../icon";
import {
  compareDate,
  formatMiroRole,
  formattedDate,
  formattedDateTime,
  isValidDate,
  minutesToDayFormat,
  numberFormat,
} from "../../../utils";
import {
  DEFAULT_PROFILE_PHOTO,
  SAAS_TOOLS,
  YOU_TRACK_URL,
} from "../../../constants/constants";
import { BACKEND_URL } from "../../../constants/urls";
import { isExpiredDate } from "../../../utils";

type CellDataGeneratorProps = {
  cell: string;
  row: any;
  hasPermission?: boolean;
  index: number;
  showMoreHandler?: (num: number) => void;
  showLessHandler?: () => void;
  editHandler?: (slug: string) => void;
  deleteHandler?: (slug: string) => void;
  clickedIndex?: number;
  componentClassName: string;
  customField?: string | number | boolean | React.ReactNode;
};
const cellDataGenerator = ({
  cell,
  row,
  hasPermission,
  index,
  customField,
  editHandler = () => { },
  showMoreHandler = () => { },
  showLessHandler = () => { },
  deleteHandler = () => { },
  clickedIndex,
  componentClassName,
}: CellDataGeneratorProps) => {
  const uniqueUsers = row?.users?.filter(
    (user: any, index: number, self: any) =>
      index === self.findIndex((u: any) => u.id === user.id)
  );

  const uniqueProjects = row?.projects?.filter(
    (project: any, index: number, self: any) =>
      index === self.findIndex((p: any) => p.id === project.id)
  );

  switch (cell) {
    case "id":
      return index;
    case "userFirstName":
      return row.oktaUser?.firstName ?? row?.name;
    case "memberFirstName":
      return row?.member?.firstName;
    case "userLastName":
      return row.oktaUser?.lastName;
    case "memberLastName":
      return row?.member?.lastName;
    case "userEmail":
      return row.oktaUser?.email;
    case "memberEmail":
      return row?.member?.email;
    case "externalEmail":
      return row?.email;
    case "lastLogin":
      return row?.lastLogin ? formattedDate(row?.lastLogin) : "-";
    case "lastLoginTime":
      return row?.lastLogin ? formattedDateTime(row?.lastLogin) : "-";
    case "lastModified":
      return row?.lastModified ? formattedDate(row?.lastModified) : "-";
    case "lastActivity":
      return row?.lastActivity ? formattedDate(row?.lastActivity) : "-";
    case "lastPreview":
      return row?.lastPreview ? formattedDate(row?.lastPreview) : "-";
    case "paymentDate":
      return row?.paymentDate ? formattedDate(row?.paymentDate) : "-";
    case "paymentToolName":
      return row?.toolName;
    case "paymentPeriod":
      return row?.startDate && row?.endDate ? `${formattedDate(row?.startDate)} - ${formattedDate(row?.endDate)}` : "-";
    case "oktaOrg":
      return row?.okta;
    case "youtrackOrg":
      return row?.youtrack;
    case "boxOrg":
      return row?.box;
    case "confluenceOrg":
      return row?.confluence;
    case "atlassianOrg":
      return row?.atlassian;
    case "gitlabOrg":
      return row?.gitlab;
    case "miroOrg":
      return row?.miro;
    case "eventType":
      return (
        <p className={`${componentClassName}__eventType`}>{row?.eventType}</p>
      );
    case "userStatus":
      return row?.status === "active" ? (
        <p className={`${componentClassName}__status--active`}>Active</p>
      ) : (
        <p className={`${componentClassName}__status--inActive`}>Inactive</p>
      );
    case "umUserRole":
      return row.role[0]?.displayTitle;
    case "sophosRole":
      return row?.role ?? "User";
    case "userRole":
      return row?.role === "coadmin"
        ? "Co-Admin"
        : row?.role === "admin"
          ? "Admin"
          : "User";
    case "groupRole":
      return row?.role;
    case "groupId":
      return row?.group?.toolGroupId;
    case "isDeleted":
      return row?.deletedAt ? (
        <p className={`${componentClassName}__status--inActive`}>Suspended</p>
      ) : (
        <p className={`${componentClassName}__status--active`}>Active</p>
      );
    case "status":
      return row[cell] === "PROVISIONED" ? (
        <p className={`${componentClassName}__status--invited`}>Invited</p>
      ) : row[cell] === "ACTIVE" ? (
        <p className={`${componentClassName}__status--active`}>Active</p>
      ) : row[cell] === "DEPROVISIONED" ? (
        <p className={`${componentClassName}__status--inActive`}>Deactivate</p>
      ) : (
        <p className={`${componentClassName}__status--inActive`}>Inactive</p>
      );
    case "licenses":
      return row[cell] ? (
        <p className={`${componentClassName}__status--active`}>Paid</p>
      ) : (
        <p className={`${componentClassName}__status--invited`}>Free</p>
      );
    case "licenseHistoryName":
      return row?.license?.name;
    case "licenseHistorySubscriptionType":
      return row?.subscriptionType;
    case "licenseHistoryInvited":
      return row?.invited;
    case "licenseHistoryActive":
      return row?.active;
    case "licenseHistoryTotal":
      return row?.total;
    case "licenseHistoryPricing":
      return "$ " + row?.pricing;
    case "licenseHistoryPredicted":
      return row?.predicted;
    case "licenseHistoryRemaining":
      return row?.remaining;
    case "miroRole":
      return (
        <p className={`${componentClassName}__table__miroRole`}>
          {formatMiroRole(row?.role)}
        </p>
      );
    case "totalMembers":
      return uniqueUsers?.length;
    case "totalProjects":
      return uniqueProjects?.length;
    case "district":
      return row?.state ? row?.state : "-";
    case "price":
      return `${row?.price ? numberFormat(row.price, 'JPY') : 0}`;
    case "totalPrice":
      return `${(row?.total && row.price) ? numberFormat(row.total * row.price, "JPY") : 0}`;
    case "organizationsTotalPrice":
      return row?.total ? numberFormat(row.total,"JPY") : 0;
    case "gitlabGroupName":
      return row?.group?.name;
    case "totalStorageSize":
      return (parseInt(row[cell]) / 1024000).toFixed(2) + " MB";
    case "storageSize":
      return (parseInt(row[cell]) / 1024000).toFixed(2) + " MB";
    case "created":
      return formattedDate(row[cell]);
    case "createdTime":
      return formattedDate(row[cell]);
    case "createdAtTime":
      return formattedDateTime(row.createdAt);
    case "createdAt":
      return formattedDate(row[cell]);
    case "activated":
      return formattedDate(row[cell]);
    case "lastUpdated":
      return formattedDate(row[cell]);
    case "renewalDate":
      return row[cell] ? formattedDate(row[cell]) : "-";
    case "updatedAt":
      return row[cell] ? formattedDate(row[cell]) : "-";
    case "lastMembershipUpdated":
      return formattedDate(row[cell]);
    case "eventCreatedAt":
      return formattedDateTime(row.createdAt);
    case "updatedAtTime":
      return formattedDateTime(row.updatedAt);
    case "billingDate":
      return formattedDate(row[cell]);
    case "cost":
      return `¥ ${row?.cost ? numberFormat(row.cost) : 0}`;
    case "ticketName":
      return row?.name;
    case "oktaOrganization":
      return row?.okta;
    case "totalAmount":
      return `${row?.totalAmount ? numberFormat(row.totalAmount, "JPY") : 0}`;
    case "totalAmountPayment":
      return `${row?.totalAmount ? numberFormat(row?.totalAmount, row?.currency) + `${row?.excludeTax ? " (Tax included)" : ""}` : 0}`;
    case "conversionRate":
      return `${row?.conversionRate ? numberFormat(row.conversionRate, "JPY") : "N/A"
        }`;
    /******************Budget Calculation************************** */
    case "oktaCost":
      return row?.okta ? numberFormat(row.okta,"JPY") : 0;
    case "youtrackCost":
      return row?.youtrack ? numberFormat(row.youtrack,"JPY") : 0;
    case "boxCost":
      return row?.box ? numberFormat(row.box,"JPY") : 0;
    case "confluenceCost":
      return row?.confluence ? numberFormat(row.confluence,"JPY") : 0;
    case "atlassianCost":
      return row?.atlassian ? numberFormat(row.atlassian,"JPY") : 0;
    case "gitlabCost":
      return row?.gitlab ? numberFormat(row.gitlab,"JPY") : 0;
    case "miroCost":
      return row?.miro ? numberFormat(row.miro,"JPY") : 0;
    case "gcpCost":
      return row?.gcp ? numberFormat(row.gcp,"JPY") : 0;
    case "awsCost":
      return row?.aws ? numberFormat(row.aws,"JPY") : 0;
    /******************Budget Calculation************************** */
    /*****************Request Management****************************/
    case "requester":
      return row?.requester?.middleName
        ? row?.requester?.firstName +
        " " +
        row?.requester?.middleName +
        " " +
        row?.requester?.lastName
        : row?.requester?.firstName + " " + row?.requester?.lastName;
    case "requestStatus":
      return row?.status;
    case "numberOfRequests":
      return row?.requests?.length;
    case "requestId":
      return row?.id;
    /*****************Request Management****************************/
    /**
     * ProfilePhoto
     */
    case "profilePhoto":
      return (
        <img
          src={
            row?.profilePhotoPath
              ? BACKEND_URL + "/" + row?.profilePhotoPath
              : DEFAULT_PROFILE_PHOTO
          }
          alt="default"
          className={`${componentClassName}__table__profilePhoto`}
        />
      );
    case "oktaActivity":
      return (
        <p
          className={
            isExpiredDate(row?.okta?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.okta?.date
            ? isValidDate(row?.okta?.date)
              ? formattedDateTime(row?.okta?.date)
              : row?.okta?.date
            : "N/A"}
        </p>
      );
    case "youtrackActivity":
      return (
        <p
          className={
            isExpiredDate(row?.youtrack?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.youtrack?.date
            ? isValidDate(row?.youtrack?.date)
              ? formattedDateTime(row?.youtrack?.date)
              : row?.youtrack?.date
            : "N/A"}
        </p>
      );
    case "boxActivity":
      return (
        <p
          className={
            isExpiredDate(row?.box?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.box?.date
            ? isValidDate(row?.box?.date)
              ? formattedDateTime(row?.box?.date)
              : row?.box?.date
            : "N/A"}
        </p>
      );
    case "confluenceActivity":
      return (
        <p
          className={
            isExpiredDate(row?.confluence?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.confluence?.date
            ? isValidDate(row?.confluence?.date)
              ? formattedDateTime(row?.confluence?.date)
              : row?.confluence?.date
            : "N/A"}
        </p>
      );
    case "gitlabActivity":
      return (
        <p
          className={
            isExpiredDate(row?.gitlab?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.gitlab?.date
            ? isValidDate(row?.gitlab?.date)
              ? formattedDateTime(row?.gitlab?.date)
              : row?.gitlab?.date
            : "N/A"}
        </p>
      );
    case "miroActivity":
      return (
        <p
          className={
            isExpiredDate(row?.miro?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.miro?.date
            ? isValidDate(row?.miro?.date)
              ? formattedDateTime(row?.miro?.date)
              : row?.miro?.date
            : "N/A"}
        </p>
      );
    case "gcpActivity":
      return (
        <p
          className={
            isExpiredDate(row?.gcp?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.gcp?.date
            ? isValidDate(row?.gcp?.date)
              ? formattedDateTime(row?.gcp?.date)
              : row?.gcp?.date
            : "N/A"}
        </p>
      );
    case "awsActivity":
      return (
        <p
          className={
            isExpiredDate(row?.aws?.date, customField as number)
              ? `${componentClassName}__table__activityDateRed`
              : ""
          }
        >
          {row?.aws?.date
            ? isValidDate(row?.aws?.date)
              ? formattedDateTime(row?.aws?.date)
              : row?.aws?.date
            : "N/A"}
        </p>
      );
    case "saasToolsActivity":
      const tools = [] as string[];
      Object.keys(row).forEach((key: string, index: number) => {
        if (
          [
            "okta",
            "youtrack",
            "box",
            "confluence",
            "gitlab",
            "miro",
            "gcp",
            "aws",
            "atlassian",
          ].includes(key)
          // && isValidDate(row[key].date)
        ) {
          row[key].license
            ? tools.push(`${key}License`)
            : tools.push(`${key}Guest`);
        }
      });
      return (
        <div className={`${componentClassName}__table__saasToolLogo`}>
          {tools.map((tool: string, index: number) => (
            <>
              <img
                src={require(`./../../../assets/saasToolLogo/${tool}.png`)}
                alt={tool}
                key={index}
                width="24px"
                height="100%"
              />
              {!(tools.length - 1 === index) && <span>|</span>}
            </>
          ))}
        </div>
      );
    /********************************************************************/
    case "oktaActivityDate":
      return (
        <p
          className={
            compareDate(row?.okta)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.okta ? formattedDateTime(row?.okta) : "N/A"}
        </p>
      );
    case "youtrackActivityDate":
      return (
        <p
          className={
            compareDate(row?.youtrack)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.youtrack ? formattedDateTime(row?.youtrack) : "N/A"}
        </p>
      );
    case "boxActivityDate":
      return (
        <p
          className={
            compareDate(row?.box)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.box ? formattedDateTime(row?.box) : "N/A"}
        </p>
      );
    case "confluenceActivityDate":
      return (
        <p
          className={
            compareDate(row?.confluence)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.confluence ? formattedDateTime(row?.confluence) : "N/A"}
        </p>
      );
    case "gitlabActivityDate":
      return (
        <p
          className={
            compareDate(row?.gitlab)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.gitlab ? formattedDateTime(row?.gitlab) : "N/A"}
        </p>
      );
    case "miroActivityDate":
      return (
        <p
          className={
            compareDate(row?.miro)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.miro ? formattedDateTime(row?.miro) : "N/A"}
        </p>
      );
    case "gcpActivityDate":
      return (
        <p
          className={
            compareDate(row?.gcp)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.gcp ? formattedDateTime(row?.gcp) : "N/A"}
        </p>
      );
    case "awsActivityDate":
      return (
        <p
          className={
            compareDate(row?.aws)
              ? `${componentClassName}__table__activityDate`
              : ""
          }
        >
          {row?.aws ? formattedDateTime(row?.aws) : "N/A"}
        </p>
      );
    /********************************************************/
    case "assignee":
      return row?.assignee?.firstName && row?.assignee?.lastName ? (
        row?.assignee?.firstName + " " + row?.assignee?.lastName
      ) : (
        <p className={`${componentClassName}__table__unassigned`}>Unassigned</p>
      );
    case "spentTime":
      return row?.spentTime ? minutesToDayFormat(row?.spentTime) : "-";
    case "ticketProject":
      return row?.project?.name;
    case "resolvedAt":
      return row[cell] ? formattedDateTime(row[cell]) : "-";
    case "name":
      return (
        <p className={`${componentClassName}__table__name`} title={row[cell]}>
          {row[cell]}
        </p>
      );
    case "invited":
      return (
        <p
          className={`${componentClassName}__table__link`}
          onClick={() => deleteHandler(row?.name)}
        >
          {row[cell]}
        </p>
      );
    case "label":
      return (
        <p className={`${componentClassName}__table__name`}>{row[cell]}</p>
      );
    case "appLogo":
      return (
        <img
          src={row?.logo}
          alt="logo"
          className={`${componentClassName}__table__logo`}
        />
      );
    case "apiKey":
      return <p className={`${componentClassName}__table__key`}>{row[cell]}</p>;
    case "organizationId":
      return <p className={`${componentClassName}__table__key`}>{row[cell]}</p>;
    case "isRevoked":
      return row[cell] ? (
        <p className={`${componentClassName}__status--inActive`}>Revoked</p>
      ) : (
        <p className={`${componentClassName}__status--active`}>Active</p>
      );
    case "expiresAt":
      return formattedDate(row[cell]);
    case "updatedBy":
      return (
        row?.updatedBy?.firstName +
        " " +
        row?.updatedBy?.middleName +
        " " +
        row?.updatedBy?.lastName
      );
    case "issueId":
      return (
        <a
          href={`${YOU_TRACK_URL}${row?.issueId}`}
          target="_blank"
          rel="noreferrer"
          className={`${componentClassName}__table__hyperlink`}
        >
          {row?.issueId}
        </a>
      );
    case "parentIssueId":
      return row?.parentIssueId ? (
        <a
          href={`${YOU_TRACK_URL}${row?.parentIssueId}`}
          target="_blank"
          rel="noreferrer"
          className={`${componentClassName}__table__hyperlink`}
        >
          {row?.parentIssueId}
        </a>
      ) : (
        "-"
      );
    case "totalLicenses":
      return (
        <div className={`${componentClassName}__table__licensePurchased`}>
          <p className={`${componentClassName}__table__name`}>{row?.total}</p>
          {hasPermission && (
            <div className={`${componentClassName}__table__action`}>
              <button
                className={`${componentClassName}__table__action__button`}
                onClick={() => editHandler(row.id)}
                title="Edit"
              >
                <Icon icon="edit" />
              </button>
            </div>
          )}
        </div>
      );
    case "groupName":
      return (
        <p className={`${componentClassName}__table__miroRole`}>
          {row?.group?.name ?? "-"}
        </p>
      );
    case "type":
      return (
        <p className={`${componentClassName}__table__miroRole`}>{row?.type}</p>
      );
    case "invoiceFile":
      return (
        <a
          className="payment__invoiceFile"
          href={row?.file?.basePath + "/" + row?.file?.filePath}
          target="_blank"
          rel="noreferrer"
        >
          {row?.file?.fileName}
        </a>
      );
    case "toolNames":
      return (
        <div className={`${componentClassName}__table__toolName`}>
          {row?.toolName.map((tool: string, index: number) => {
            return (
              <p
                key={index}
                className={`${componentClassName}__table__toolName__item`}
              >
                {tool}{" "}
                <span
                  className={`${componentClassName}__table__toolName__separator`}
                >
                  {index !== row?.toolName.length - 1 ? " | " : ""}
                </span>
              </p>
            );
          })}
        </div>
      );

    case "toolBoardId":
      return row?.board?.toolBoardId;
    case "boardName":
      return row?.board?.name;
    case "toolTeamId":
      return row?.team?.toolTeamId;
    case "teamName":
      return row?.team?.name;
    case "miroProjectId":
      return row?.project?.toolProjectId;
    case "toolProjectId":
      return row?.toolProjectId;
    case "projectName":
      return row?.project?.name;
    case "approver":
      return (
        <div>
          {row?.approver.map((approver: any, index: number) =>
            approver?.middleName ? (
              <p className={`${componentClassName}__table__name`}>
                <span style={{ fontFamily: "monospace", fontSize: "16px" }}>
                  {index + 1}.
                </span>{" "}
                {approver?.firstName} {approver?.middleName}{" "}
                {approver?.lastName}
              </p>
            ) : (
              <p className={`${componentClassName}__table__name`}>
                <span style={{ fontFamily: "monospace", fontSize: "16px" }}>
                  {index + 1}.
                </span>{" "}
                {approver?.firstName} {approver?.lastName}
              </p>
            )
          )}
        </div>
      );
    case "projectUsers":
      return (
        <div className={`${componentClassName}__table__tag`}>
          <div className={`${componentClassName}__table__tag__container`}>
            {index === clickedIndex
              ? uniqueUsers?.map((user: any, index: number) => {
                return (
                  <p
                    key={index}
                    className={`${componentClassName}__table__tag__item`}
                  >
                    {user?.firstName} {user?.lastName}
                  </p>
                );
              })
              : uniqueUsers?.length > 2
                ? uniqueUsers.slice(0, 2).map((user: any, index: number) => {
                  return (
                    <p
                      key={index}
                      className={`${componentClassName}__table__tag__item`}
                    >
                      {user?.firstName} {user?.lastName}
                    </p>
                  );
                })
                : uniqueUsers?.map((user: any, index: number) => {
                  return (
                    <p
                      key={index}
                      className={`${componentClassName}__table__tag__item`}
                    >
                      {user?.firstName} {user?.lastName}
                    </p>
                  );
                })}
          </div>
          {uniqueUsers?.length > 2 && (
            <>
              {index === clickedIndex ? (
                <button
                  className={`${componentClassName}__table__tag__more`}
                  onClick={showLessHandler}
                >
                  Show less {"<<<"}
                </button>
              ) : (
                <button
                  className={`${componentClassName}__table__tag__more`}
                  onClick={() => showMoreHandler(index)}
                >
                  Show more {">>>"}
                </button>
              )}
            </>
          )}
        </div>
      );
    case "userProjects":
      return (
        <div className={`${componentClassName}__table__tag`}>
          <div className={`${componentClassName}__table__tag__container`}>
            {index === clickedIndex
              ? uniqueProjects?.map((project: any, index: number) => {
                return (
                  <p
                    key={index}
                    className={`${componentClassName}__table__tag__item`}
                  >
                    {project?.name}
                  </p>
                );
              })
              : uniqueProjects?.length > 2
                ? uniqueProjects
                  .slice(0, 2)
                  .map((project: any, index: number) => {
                    return (
                      <p
                        key={index}
                        className={`${componentClassName}__table__tag__item`}
                      >
                        {project?.name}
                      </p>
                    );
                  })
                : uniqueProjects?.map((project: any, index: number) => {
                  return (
                    <p
                      key={index}
                      className={`${componentClassName}__table__tag__item`}
                    >
                      {project?.name}
                    </p>
                  );
                })}
          </div>
          {uniqueProjects?.length > 2 && (
            <>
              {index === clickedIndex ? (
                <button
                  className={`${componentClassName}__table__tag__more`}
                  onClick={showLessHandler}
                >
                  Show less
                </button>
              ) : (
                <button
                  className={`${componentClassName}__table__tag__more`}
                  onClick={() => showMoreHandler(index)}
                >
                  Show more
                </button>
              )}
            </>
          )}
        </div>
      );
    case "actionDeleteEdit":
      return (
        <div className={`${componentClassName}__table__action`}>
          <button
            title="Edit"
            className={`${componentClassName}__table__action__button ${componentClassName}__table__action__edit`}
            onClick={() => editHandler(row.id)}
          >
            <Icon icon="edit" size="secondary" />
          </button>
          <button
            title="Delete"
            className={`${componentClassName}__table__action__button`}
            onClick={() => deleteHandler(row.id)}
          >
            <Icon icon="cross" size="secondary" />
          </button>
        </div>
      );
    case "actionDelete":
      return (
        <div className={`${componentClassName}__table__action`}>
          <button
            title="Delete"
            className={`${componentClassName}__table__action__button`}
            onClick={() => deleteHandler(row.id)}
          >
            <Icon icon="cross" size="secondary" />
          </button>
        </div>
      );
    case "actionEdit":
      return (
        <div className={`${componentClassName}__table__action`}>
          <button
            title="Edit"
            className={`${componentClassName}__table__action__button ${componentClassName}__table__action__edit`}
            onClick={() => editHandler(row.id)}
          >
            <Icon icon="edit" size="secondary" />
          </button>
        </div>
      );

    default:
      if (SAAS_TOOLS.includes(cell)) return numberFormat(row[cell] ?? 0);

      return row[cell] ?? "-";
  }
};
export default cellDataGenerator;
