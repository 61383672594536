import React from "react";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Dropdown } from "../../../atoms/dropdown";
import { ResponseType, RoleType, payloadType } from "../../../../types";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
    DEFAULT_PROFILE_PHOTO,
    PAGE_NAME,
    PERMISSIONS,
    ROUTE,
    SOMETHING_WENT_WRONG,
} from "../../../../constants/constants";
import {
    useRoleQuery,
    useSingleUserQuery,
    useUpdateUserMutation,
    useUserListQuery,
} from "../../../../store/user/userSlice";
import { Loader } from "../../../atoms/loader";
import { ImageInput } from "../../../atoms/imageInput";
import { BACKEND_URL } from "../../../../constants/urls";
import ImageCropper from "../../../atoms/imageCropper";
import { hasPermission } from "../../../../utils";

const UpdateUser = () => {
    const pageClassName = "p-updateUser";
    const navigate = useNavigate();

    const { state } = useLocation();

    const { data, isSuccess, isLoading } = useSingleUserQuery({
        id: state?.userId,
    });

    const [updateUserApi, { isLoading: updateLoading }] = useUpdateUserMutation();
    const { data: roleData } = useRoleQuery();
    const { data: managerList, isLoading: managerListLoading } = useUserListQuery(
        {}
    );

    const [image, setImage] = React.useState<any>(undefined);

    const initialState: payloadType = {
        firstName: {
            value: "",
            error: "",
            isError: false,
        },
        middleName: {
            value: "",
            error: "",
            isError: false,
        },
        lastName: {
            value: "",
            error: "",
            isError: false,
        },
        nameInJp: {
            value: "",
            error: "",
            isError: false,
        },
        email: {
            value: "",
            error: "",
            isError: false,
        },
        designation: {
            value: "",
            error: "",
            isError: false,
        },
        department: {
            value: "",
            error: "",
            isError: false,
        },
        address: {
            value: "",
            error: "",
            isError: false,
        },
        managerId: {
            value: "",
            error: "",
            isError: false,
        },
        image: {
            value: "",
            error: "",
            isError: false,
        },
        password: {
            value: "",
            error: "",
            isError: false,
        },
        passwordConfirmation: {
            value: "",
            error: "",
            isError: false,
        },
        role: {
            value: "",
            error: "",
            isError: false,
        },
        contactNo: {
            value: "",
            error: "",
            isError: false,
        },
        remarks: {
            value: "",
            error: "",
            isError: false,
        },
    };
    const [payload, setPayload] = React.useState<payloadType>(initialState);

    React.useEffect(() => {
        if (isSuccess) {
            setPayload((prevState) => ({
                ...prevState,
                firstName: {
                    value: data?.result?.firstName,
                    error: "",
                    isError: false,
                },
                middleName: {
                    value: data?.result?.middleName,
                    error: "",
                    isError: false,
                },
                lastName: {
                    value: data?.result?.lastName,
                    error: "",
                    isError: false,
                },
                nameInJp: {
                    value: data?.result?.nameInJp,
                    error: "",
                    isError: false,
                },
                email: {
                    value: data?.result?.email,
                    error: "",
                    isError: false,
                },
                designation: {
                    value: data?.result?.designation,
                    error: "",
                    isError: false,
                },
                department: {
                    value: data?.result?.department,
                    error: "",
                    isError: false,
                },
                address: {
                    value: data?.result?.address,
                    error: "",
                    isError: false,
                },
                managerId: {
                    value: {
                        label: data?.result?.manager?.middleName
                            ? `${data?.result?.manager?.firstName} ${data?.result?.manager?.middleName} ${data?.result?.manager?.lastName}`
                            : `${data?.result?.manager?.firstName} ${data?.result?.manager?.lastName}`,
                        value: data?.result?.manager?.id,
                    },
                    error: "",
                    isError: false,
                },
                image: {
                    value: data?.result?.profilePhotoPath,
                    error: "",
                    isError: false,
                },
                role: {
                    value: data?.result?.role[0]?.id,
                    error: "",
                    isError: false,
                },
                contactNo: {
                    value: data?.result?.contactNo,
                    error: "",
                    isError: false,
                },
                remarks: {
                    value: data?.result?.remarks,
                    error: "",
                    isError: false,
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, data]);

    const onCancelHandler = () => {
        setImage(undefined);
    };

    const croppedImageHandler = (img: File) => {
        setPayload({
            ...payload,
            image: { value: img, isError: false, error: "" },
        });

        onCancelHandler();
    };

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const toastId = toast.loading("Loading...");

        const formData = new FormData();
        formData.append("firstName", payload.firstName.value as string);
        formData.append("middleName", payload.middleName.value as string);
        formData.append("lastName", payload.lastName.value as string);
        payload.nameInJp.value &&
            formData.append("nameInJp", payload.nameInJp.value as string);
        formData.append("email", payload.email.value as string);
        if (payload.image.value) {
            if (typeof payload.image.value === "string") {
                formData.append("profilePhotoPath", payload.image.value as string);
            } else {
                formData.append("photo", payload.image.value as File);
            }
        }
        payload.designation.value &&
            formData.append("designation", payload.designation.value as string);
        payload.department.value &&
            formData.append("department", payload.department.value as string);
        payload.address.value &&
            formData.append("address", payload.address.value as string);
        (payload.managerId.value as { value: string; label: string }).value &&
            formData.append(
                "managerId",
                (payload.managerId.value as { value: string; label: string })
                    .value as string
            );
        formData.append("password", payload.password.value as string);
        formData.append(
            "passwordConfirmation",
            payload.passwordConfirmation.value as string
        );
        formData.append("role", payload.role.value as string);
        payload.contactNo.value &&
            formData.append("contactNo", payload.contactNo.value as string);
        payload.remarks.value &&
            formData.append("remarks", payload.remarks.value as string);

        const response = (await updateUserApi({
            formData,
            id: state?.userId as string,
        })) as ResponseType;
        if (response?.data?.success) {
            setPayload(initialState);

            toast.update(toastId, {
                render: response?.data?.message,
                type: toast.TYPE.SUCCESS,
                isLoading: isLoading,
                autoClose: 2000,
            });
            navigate(ROUTE.UM_TOOL_USER_LIST);
        } else {
            toast.update(toastId, {
                render: response?.error?.data?.message,
                type: toast.TYPE.ERROR,
                isLoading: isLoading,
                autoClose: 2000,
            });

            if (response?.error?.data?.errors) {
                Object.keys(response?.error?.data?.errors).forEach((key) => {
                    setPayload((prevState) => ({
                        ...prevState,
                        [key]: {
                            ...prevState[key],
                            error: response?.error?.data?.errors[key][0],
                            isError: true,
                        },
                    }));
                    //   }
                });
            } else {
                toast.update(toastId, {
                    render: response?.error?.message,
                    type: toast.TYPE.ERROR,
                    isLoading: isLoading,
                    autoClose: 2000,
                });
            }
            if (response?.error?.errors) {
                toast.update(toastId, {
                    render: SOMETHING_WENT_WRONG,
                    type: toast.TYPE.ERROR,
                    isLoading: isLoading,
                    autoClose: 2000,
                });
            }
        }
    };

    const cancelHandler = () => {
        setPayload((prevState) => ({
            ...prevState,
            firstName: {
                value: data?.result?.firstName,
                error: "",
                isError: false,
            },
            middleName: {
                value: data?.result?.middleName,
                error: "",
                isError: false,
            },
            lastName: {
                value: data?.result?.lastName,
                error: "",
                isError: false,
            },
            nameInJp: {
                value: data?.result?.nameInJp,
                error: "",
                isError: false,
            },
            email: {
                value: data?.result?.email,
                error: "",
                isError: false,
            },
            designation: {
                value: data?.result?.designation,
                error: "",
                isError: false,
            },
            department: {
                value: data?.result?.department,
                error: "",
                isError: false,
            },
            address: {
                value: data?.result?.address,
                error: "",
                isError: false,
            },
            managerId: {
                value: {
                    label: data?.result?.manager?.middleName
                        ? `${data?.result?.manager?.firstName} ${data?.result?.manager?.middleName} ${data?.result?.manager?.lastName}`
                        : `${data?.result?.manager?.firstName} ${data?.result?.manager?.lastName}`,
                    value: data?.result?.manager?.id,
                },
                error: "",
                isError: false,
            },
            image: {
                value: data?.result?.profilePhotoPath,
                error: "",
                isError: false,
            },
            contactNo: {
                value: data?.result?.contactNo,
                error: "",
                isError: false,
            },
            remarks: {
                value: data?.result?.remarks,
                error: "",
                isError: false,
            },
        }));
    };

    //remove Super Admin from roleData
    // React.useEffect(() => {
    //     if (roleData?.result?.data) {
    //         const filteredData = roleData?.result?.data.filter(
    //             (item: RoleType) => item.id !== "1"
    //         );
    //         roleData.result.data = filteredData;
    //     }
    // }, [roleData]);
    return (
        <div className={pageClassName}>
            <Breadcrumb />
            {image && (
                <ImageCropper
                    imageUrl={URL.createObjectURL(image)}
                    type={image.type}
                    onCancel={onCancelHandler}
                    croppedImageSetter={croppedImageHandler}
                    cropShape="rect"
                    width={200}
                    height={200}
                />
            )}
            <p className={`${pageClassName}__title`}>{PAGE_NAME.USER_UPDATE}</p>
            <Loader isLoading={isLoading || updateLoading || managerListLoading}>
                <form className={`${pageClassName}__form`} onSubmit={submitHandler}>
                    <div className={`${pageClassName}__form__image`}>
                        <ImageInput
                            fileChange={(file) => {
                                setImage(file);
                            }}
                            isError={payload.image.isError}
                            error={payload.image.error}
                            url={
                                payload?.image.value
                                    ? typeof payload?.image.value === "string"
                                        ? `${BACKEND_URL}/${payload?.image.value}`
                                        : URL.createObjectURL(payload?.image.value as File)
                                    : DEFAULT_PROFILE_PHOTO
                            }
                        />
                        <div className={`${pageClassName}__form__image__info`}>
                            <span>Role: </span>
                            <p>{data?.result?.role[0].displayTitle}</p>
                            <span>ORG: </span>
                            <p>{data?.result?.organization?.name}</p>
                            <span>ORG Address: </span>
                            <p>{data?.result?.organization?.country}</p>
                        </div>
                    </div>
                    <div className={`${pageClassName}__form__container`}>
                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="First Name"
                                placeholder="Enter your first name"
                                value={payload.firstName.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        firstName: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.firstName.isError}
                                error={payload.firstName.error}
                            />
                            <Input
                                label="Middle Name"
                                placeholder="Enter your middle name"
                                value={payload.middleName.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        middleName: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.middleName.isError}
                                error={payload.middleName.error}
                            />
                        </div>
                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Last Name"
                                placeholder="Enter your last name"
                                value={payload.lastName.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        lastName: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.lastName.isError}
                                error={payload.lastName.error}
                            />
                            <Input
                                label="Japanese Name"
                                placeholder="Enter your japanese name"
                                value={payload.nameInJp.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        nameInJp: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.nameInJp.isError}
                                error={payload.nameInJp.error}
                            />
                        </div>
                        <Input
                            label="Email"
                            placeholder="Enter your email"
                            type="email"
                            value={payload.email.value as string}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    email: { value: e.target.value, error: "", isError: false },
                                })
                            }
                            isError={payload.email.isError}
                            error={payload.email.error}
                            readonly
                        />

                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Designation"
                                placeholder="Enter your designation"
                                type="text"
                                value={payload.designation.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        designation: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.designation.isError}
                                error={payload.designation.error}
                            />
                            <Input
                                label="Department"
                                placeholder="Enter your department"
                                type="text"
                                value={payload.department.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        department: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.department.isError}
                                error={payload.department.error}
                            />
                        </div>

                        <div className={`${pageClassName}__form__group`}>
                            <Input
                                label="Address"
                                placeholder="Enter your address"
                                type="text"
                                value={payload.address.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        address: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.address.isError}
                                error={payload.address.error}
                            />

                            <div className={`${pageClassName}__form__group__dropdown`}>
                                <label>Personal In Charge (PIC/Manager/Team Lead)</label>
                                <Dropdown
                                    options={managerList?.result?.data.map((item: any) => ({
                                        value: item.id,
                                        label: item.middleName
                                            ? `${item.firstName} ${item.middleName} ${item.lastName}`
                                            : `${item.firstName} ${item.lastName}`,
                                    }))}
                                    isBordered
                                    isIcon
                                    selected={
                                        payload.managerId.value as { value: string; label: string }
                                    }
                                    placeholder="Select PIC"
                                    onClickChange={(selected) =>
                                        setPayload({
                                            ...payload,
                                            managerId: {
                                                value: selected as { value: string; label: string },
                                                error: "",
                                                isError: false,
                                            },
                                        })
                                    }
                                    isError={payload.managerId.isError}
                                    error={payload.managerId.error}
                                />
                            </div>
                        </div>

                        <div className={`${pageClassName}__form__group`}>
                            <div className={`${pageClassName}__form__group__dropdown`}>
                                <label>Role</label>
                                <Dropdown
                                    options={
                                        roleData?.result?.data
                                            // .filter(
                                            //     (item: RoleType) =>
                                            //         item.displayTitle !== "Super Admin"
                                            // )
                                            .map((item: RoleType) => {
                                                return {
                                                    label: item.displayTitle,
                                                    value: item.id,
                                                };
                                            }) || []
                                    }
                                    isBordered
                                    isIcon
                                    selected={{
                                        label: roleData?.result?.data.filter(
                                            (item: RoleType) => item.id === payload.role.value
                                        )[0]?.displayTitle,
                                        value: payload.role.value as string,
                                    }}
                                    isDisabled={!hasPermission(PERMISSIONS.USER_ROLE_EDIT.title)}
                                    placeholder="Select role"
                                    onClickChange={(selected) =>
                                        setPayload({
                                            ...payload,
                                            role: {
                                                value: selected.value as string,
                                                error: "",
                                                isError: false,
                                            },
                                        })
                                    }
                                    isError={payload.role.isError}
                                    error={payload.role.error}
                                />
                            </div>
                            <Input
                                label="Contact No"
                                placeholder="Enter your contact no"
                                type="number"
                                value={payload.contactNo.value as string}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        contactNo: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                isError={payload.contactNo.isError}
                                error={payload.contactNo.error}
                            />
                        </div>

                        <Input
                            label="Remarks"
                            placeholder="Enter remarks"
                            value={payload.remarks.value as string}
                            onChange={(e) =>
                                setPayload({
                                    ...payload,
                                    remarks: { value: e.target.value, error: "", isError: false },
                                })
                            }
                            isError={payload.remarks.isError}
                            error={payload.remarks.error}
                        />
                        <div className={`${pageClassName}__form__button`}>
                            <button
                                className={`${pageClassName}__form__button__submit`}
                                type="submit"
                            >
                                Update
                            </button>
                            <button
                                className={`${pageClassName}__form__button__cancel`}
                                type="button"
                                onClick={cancelHandler}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </form>
            </Loader>
        </div>
    );
};

export default UpdateUser;
