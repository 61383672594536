import { API_URL } from "../../constants/constants";
import { ResponseDataType, UpdateType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["Role", "roleList", "singleRole", "permissionList", "categoryWisePermissionList"],
});

export const roleSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * Fetches the list of roles from the server.
         * 
         * @param search - The search query to filter the roles.
         * @param sort_by - The field to sort the roles by.
         * @param sort_direction - The direction to sort the roles in.
         * 
         * @returns A promise that resolves to the response data containing the list of roles.
         */
        roleList: builder.query<ResponseDataType, listDataType>({
            query: ({ search, sort_by, sort_direction }) => ({
                url:
                    API_URL.ROLES +
                    `?${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
                    }${search ? `&search=${search}` : ""}`,
                method: "GET",
            }),
            providesTags: ["roleList"],
        }),
        /**
         * Fetches a single role from the server.
         *
         * @param id - The ID of the role to fetch.
         * @returns A promise that resolves to the response data.
         */
        singleRole: builder.query<ResponseDataType, string>({
            query: (id) => ({
                url: API_URL.ROLES + `/${id}`,
                method: "GET",
            }),
            providesTags: ["singleRole"],
        }),
        /**
         * Edits a role.
         *
         * @param id - The ID of the role to be edited.
         * @param formData - The updated data for the role.
         * @returns A promise that resolves to the response data.
         */
        editRole: builder.mutation<ResponseDataType, UpdateType>({
            query: ({ id, formData }) => ({
                url: API_URL.ROLES + `/${id}`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Role", "singleRole", "roleList", "categoryWisePermissionList"],
        }),
        /**
         * Adds a role by making a POST request to the API.
         * @param formData - The form data containing the role information.
         * @returns A promise that resolves to the response data.
         */
        addRole: builder.mutation<ResponseDataType, FormData>({
            query: (formData) => ({
                url: API_URL.ROLES,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Role", "roleList", "singleRole", "categoryWisePermissionList"],
        }),
        /**
         * Deletes a role by its ID.
         *
         * @param id - The ID of the role to delete.
         * @returns A Promise that resolves to the response data.
         */
        deleteRole: builder.mutation<ResponseDataType, number>({
            query: (id) => ({
                url: API_URL.ROLES + `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Role", "roleList", "singleRole", 'categoryWisePermissionList'],
        }),

        /**
         * Fetches the list of permissions from the server.
         * 
         * @param search - The search query to filter the permissions.
         * @param sort_by - The field to sort the permissions by.
         * @param sort_direction - The direction to sort the permissions in.
         * 
         * @returns A promise that resolves to the response data containing the list of permissions.
         */
        permissionList: builder.query<ResponseDataType, listDataType>({
            query: ({ search, sort_by, sort_direction }) => ({
                url:
                    API_URL.PERMISSIONS +
                    `?${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
                    }${search ? `&search=${search}` : ""}`,
                method: "GET",
            }),
            providesTags: ["permissionList"],
        }),

        /**
         * Fetches the category-wise permission list from the server.
         *
         * @returns A promise that resolves to the response data.
         */
        categoryWisePermissionList: builder.query<ResponseDataType, listDataType>({
            query: () => ({
                url: API_URL.CATEGORY_WISE_PERMISSIONS,
                method: "GET",
            }),
            providesTags: ["categoryWisePermissionList"],
        }),
    }),
});

export const {
    useRoleListQuery,
    useSingleRoleQuery,
    useEditRoleMutation,
    useAddRoleMutation,
    useDeleteRoleMutation,
    usePermissionListQuery,
    useCategoryWisePermissionListQuery
} = roleSlice;
