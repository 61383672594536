import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSamlLoginMutation } from "../../../../store/auth/authSlice";
import { useDispatch } from "react-redux";
import { ResponseType } from "../../../../types";
import { userActions } from "../../../../store/auth/user";
import { toast } from "react-toastify";
import { Loader } from "../../../atoms/loader";
import { SOMETHING_WENT_WRONG } from "../../../../constants/constants";
import { authActions } from "../../../../store/auth/auth";

const SamlWithOkta = () => {
  const { data } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginApi, { isLoading }] = useSamlLoginMutation();
  //base 64 decode
  const param = JSON.parse(atob(data || ""));

  useEffect(() => {
    const fetchData = async () => {
      if (param?.success) {

        const formData = new FormData();
        formData.append("remember_token", param?.rememberToken);
        formData.append("email", param?.user?.email);

        if (param?.user?.is_agree_terms) {
          const toastId = toast.loading("Loading...");
          const response = await loginApi(formData) as ResponseType;
          if (response?.data?.success) {
            dispatch(userActions.setUser(response.data.result.user));
            dispatch(authActions.setToken(response.data.result.token));
            dispatch(authActions.setPermissions(response.data.result.user.role[0].permissions));
            dispatch(authActions.setRole(response.data.result.user.role[0]));

            toast.update(toastId, {
              render: response?.data?.message,
              type: toast.TYPE.SUCCESS,
              isLoading: isLoading,
              autoClose: 2000,
            });

            navigate("/");

          } else {
            if (response?.error?.message) {
              toast.update(toastId, {
                render: response?.error?.message,
                type: toast.TYPE.ERROR,
                isLoading: isLoading,
                autoClose: 2000,
              });
              navigate("/login");
            } else {
              toast.update(toastId, {
                render: SOMETHING_WENT_WRONG,
                type: toast.TYPE.ERROR,
                isLoading: isLoading,
                autoClose: 2000,
              });
              navigate("/login");
            }
          }
        } else {
          navigate("/privacy-policy", {
            state: {
              user: {
                email: param?.user?.email,
                provider: param?.user?.provider,
                remember_token: param?.rememberToken,
              },
            },
          });
        }
      } else {
        toast.error(param?.message);
        navigate("/login")
      };
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.success]);
  return (
    <div>
      <Loader isLoading={isLoading} align="center">
        <div style={{ height: "100vh", width: "100vh" }}></div>
      </Loader>
    </div>
  );
};

export default SamlWithOkta;
