import React from "react";
import { Table } from "../../../../atoms/table";
import { Input } from "../../../../atoms/input";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { pascalToSnakeCase } from "../../../../../utils";
import { Pagination } from "../../../../molecules/pagination";
import { Divider } from "../../../../atoms/divider";
import { Dropdown } from "../../../../atoms/dropdown";
import { useOktaGroupListQuery } from "../../../../../store/okta/groupSlice";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import { TableDataType } from "../../../../../types";

const OktaGroupList = () => {
  const pageClassName = "p-oktaGroup";
  const navigate = useNavigate();
  const {
    search,
    query,
    pageChangeHandler,
    sortHandler,
    limitChangeHandler,
    searchHandler,
  } = useTableHooks();

  const { data, isLoading } = useOktaGroupListQuery({
    search: query.search,
    limit: query.limit,
    current_page: query.current,
    sort_by: pascalToSnakeCase(query.sortBy),
    sort_direction: query.sortDirection,
  });

  const tableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: true,
    },
    {
      title: "Membership Updated",
      dataIndex: "lastMembershipUpdated",
      isSortable: true,
    },
    {
      title: "Members",
      dataIndex: "totalUsers",
    },
    {
      title: "Created",
      dataIndex: "created",
      isSortable: true,
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      isSortable: true,
    },
  ];

  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: data?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  const viewHandler = (id: string) => {
    const name = data?.result?.data.find(
      (item: TableDataType) => item.id === id
    ).name;
    navigate(ROUTE.OKTA_GROUP_LIST + `/${id}`, { state: { name } });
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.OKTA_GROUP_LIST}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__search`}>
          <Input
            placeholder="Search"
            onChange={(e) => {
              searchHandler(e);
              handleFirstPage();
            }}
            value={search}
          />
        </div>
        <div className={`${pageClassName}__table`}>
          <Table
            header={tableHeader}
            data={data?.result?.data ?? []}
            loading={isLoading}
            firstLoad={isLoading}
            sortHandler={sortHandler}
            viewHandler={viewHandler}
            minWidth="1000"
            startIndex={data?.result?.from}
            sortBy={query.sortBy}
            cursorPointer
          />
        </div>
        <Divider />
        <div className={`${pageClassName}__pagination`}>
          <div className={`${pageClassName}__pagination__limit`}>
            <p>Display</p>
            <div className={`${pageClassName}__pagination__dropdown`}>
              <Dropdown
                options={[
                  { label: "20", value: 20 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                selected={{ label: query.limit, value: query.limit }}
                onClickChange={(e) => {
                  limitChangeHandler(e.value as number);
                  handleFirstPage();
                }}
                isBordered
                isIcon
                iniDirection="top"
              />
            </div>
          </div>
          <div className={`${pageClassName}__pagination__number`}>
            {data?.result?.total ? (
              <p>
                Show {data?.result?.from} -{" "}
                {data?.result?.from + query.limit - 1 < data?.result?.total
                  ? data?.result?.from + query.limit - 1
                  : data?.result?.total}{" "}
                of {data?.result?.total}
              </p>
            ) : (
              <p>Show 00 - 00 of 00</p>
            )}
          </div>
          <div className={`${pageClassName}__pagination__nav`}>
            {data?.result?.total > query.limit && (
              <Pagination
                totalData={totalData}
                page={query.current}
                handleFirstPage={handleFirstPage}
                handlePrevPage={handlePrevPage}
                handleLastPage={handleLastPage}
                handleNextPage={handleNextPage}
                renderPageNumbers={renderPageNumbers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OktaGroupList;
