import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import { apiSlice } from "./api/apiSlice";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/es/storage";

/**
 * Configuration object for persisting the Redux store.
 * @property {string} key - The key to use for storing the state in the storage.
 * @property {Storage} storage - The storage object to use for persisting the state.
 * @property {string[]} whitelist - The list of reducer keys to persist.
 * @property {number} version - The version of the persisted state.
 * @property {boolean} debug - Whether to enable debug mode for persisting the state.
 */
const persistConfig = {
  key: 'root', 
  storage: localStorage, 
  whitelist: ['user','auth'], 
  version: 1, 
  debug: false,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Configures and creates the Redux store.
 * 
 * @returns The configured Redux store.
 */
export default configureStore({
  reducer: persistedReducer,
  middleware:(getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }).concat(apiSlice.middleware),
});

