import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "approverList",
    "approverDetails",
    "namesBySaasTool",
    "requestList",
    "myRequestList",
    "requestDetails",
    "saasToolByUser",
    "pendingRequestList",
    "ApprovedRequestList",
    "assigningListOfUser",
  ],
});

export const requestSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    createApprover: builder.mutation<ResponseDataType, FormData>({
      query: (formData) => ({
        url: API_URL.APPROVER,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["approverList", "approverDetails"],
    }),

    approverList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        saasTool,
        type,
      }) => ({
        url:
          API_URL.APPROVER +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${saasTool ? `&saas_tool=${saasTool}` : ""
          }${type ? `&type=${type}` : ""}`,
        method: "GET",
      }),
      providesTags: ["approverList"],
    }),

    approverDetails: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.APPROVER + `/${id}`,
        method: "GET",
      }),
      providesTags: ["approverDetails"],
    }),

    updateApprover: builder.mutation<
      ResponseDataType,
      { id: string; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_URL.APPROVER + `/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["approverList", "approverDetails"],
    }),

    deleteApprover: builder.mutation<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.APPROVER + `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["approverList", "approverDetails"],
    }),

    namesBySaasTool: builder.query<
      ResponseDataType,
      { saasTool: string; type: string }
    >({
      query: ({ saasTool, type }) => ({
        url: API_URL.APPROVER + `/names/${saasTool}/${type}`,
        method: "GET",
      }),
      providesTags: ["namesBySaasTool"],
    }),

    requestList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        type,
        sub_type,
        status,
      }) => ({
        url:
          API_URL.REQUEST_MANAGEMENT +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${type ? `&type=${encodeURIComponent(type as string)}` : ""}${sub_type ? `&sub_type=${encodeURIComponent(sub_type as string)}` : ""
          }${status ? `&status=${encodeURIComponent(status as string)}` : ""}`,
        method: "GET",
      }),
      providesTags: ["requestList"],
    }),

    myRequestList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        type,
        sub_type,
        status,
      }) => ({
        url:
          API_URL.REQUEST_MANAGEMENT +
          API_URL.MY_REQUESTS +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${type ? `&type=${encodeURIComponent(type as string)}` : ""}${sub_type ? `&sub_type=${encodeURIComponent(sub_type as string)}` : ""
          }${status ? `&status=${encodeURIComponent(status as string)}` : ""}`,
        method: "GET",
      }),
      providesTags: ["myRequestList"],
    }),

    createUserRequest: builder.mutation<ResponseDataType, FormData>({
      query: (formData) => ({
        url: API_URL.REQUEST_MANAGEMENT,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        "requestList",
        "requestDetails",
        "pendingRequestList",
        "ApprovedRequestList",
        "myRequestList",
      ],
    }),

    singleRequest: builder.query<ResponseDataType, string>({
      query: (id) => ({
        url: API_URL.REQUEST_MANAGEMENT + `/${id}`,
        method: "GET",
      }),
      providesTags: ["requestDetails"],
    }),

    getSaaSToolByUser: builder.query<ResponseDataType, string>({
      query: (email) => ({
        url:
          API_URL.REQUEST_MANAGEMENT +
          API_URL.USERS +
          "/" +
          email +
          API_URL.SAAS_TOOLS,
        method: "GET",
      }),
      providesTags: ["saasToolByUser"],
    }),

    pendingRequestList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url:
          API_URL.REQUEST_MANAGEMENT +
          API_URL.PENDING +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["pendingRequestList"],
    }),

    ApprovedRequestList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url:
          API_URL.REQUEST_MANAGEMENT +
          API_URL.APPROVED +
          `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["ApprovedRequestList"],
    }),

    reviewRequest: builder.mutation<ResponseDataType, FormData>({
      query: (formData) => ({
        url: API_URL.REQUEST_MANAGEMENT + API_URL.REVIEW,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        "requestList",
        "requestDetails",
        "pendingRequestList",
        "ApprovedRequestList",
        "myRequestList",
        "assigningListOfUser"
      ],
    }),

    changeRequestStatus: builder.mutation<ResponseDataType, FormData>({
      query: (formData) => ({
        url: API_URL.REQUEST_MANAGEMENT + API_URL.CHANGE_STATUS,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        "requestList",
        "requestDetails",
        "pendingRequestList",
        "ApprovedRequestList",
        "myRequestList",
        "assigningListOfUser"
      ],
    }),
    assigningListOfUser: builder.query<ResponseDataType, string>({
      query: (email) => ({
        url: API_URL.REQUEST_MANAGEMENT + API_URL.USERS + "/" + email + "/assigning-entities",
        method: "GET",
      }),
      providesTags: ["assigningListOfUser"],
    }),
  }),
});

export const {
  useCreateApproverMutation,
  useApproverListQuery,
  useApproverDetailsQuery,
  useUpdateApproverMutation,
  useDeleteApproverMutation,
  useNamesBySaasToolQuery,
  useCreateUserRequestMutation,
  useRequestListQuery,
  useMyRequestListQuery,
  useSingleRequestQuery,
  useGetSaaSToolByUserQuery,
  usePendingRequestListQuery,
  useReviewRequestMutation,
  useApprovedRequestListQuery,
  useChangeRequestStatusMutation,
  useAssigningListOfUserQuery,
} = requestSlice;
