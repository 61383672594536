import React from 'react';
import { createComponentBase ,BaseComponentProps} from '../../../libs/components';

export interface DividerProps extends BaseComponentProps {
  className?: string;
  direction?: 'vertical';
}

export const Divider = ({ 
  direction,
  className,
  }: DividerProps) => {
  const componentClassName = 'a-divider';
  const modifiers = { [direction+'Direction']: !!direction };
  const { Container } = createComponentBase(componentClassName, 'div');
  return (
    <Container className={className} modifiers={modifiers}>
    </Container>
  );
};
