import React from "react";
import {
  createComponentBase,
  BaseComponentProps,
} from "../../../libs/components";
import { DropdownOptionsType } from "../../../types";
import { Tooltip } from "../tooltip";
import Select from "react-select";

export interface DropdownProps extends BaseComponentProps {
  className?: string;
  options: DropdownOptionsType[];
  selected?: DropdownOptionsType | DropdownOptionsType[];
  color?: "black";
  placeholder?: string;
  isBordered?: boolean;
  onClickChange: ({ value, label }: DropdownOptionsType) => void;
  height?: "primary";
  isIcon?: boolean;
  iniDirection?: "down" | "top";
  isError?: boolean;
  error?: string;
  optionsMaxHeight?: "small";
  isMulti?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  focusHandler?: () => void;
  title?: string;
}

const customStyles = (isError: boolean) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    border: isError ? "1px solid #FF0000" : "1px solid #00000040",
    overflow: "auto",
    borderRadius: "6px",
  }),

  indicatorSeparator: (state: any) => ({
    display: "none",
  }),

  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    // display: "none",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: isError ? "#FF0000" : "#00000080", // Adjust the color as needed
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#e1defb" : "white",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#D11A2A",
      color: "white",
    },
  }),
});

export const Dropdown = ({
  className = "react-select",
  options,
  selected,
  title,
  placeholder = "Select an option",
  isError = false,
  isMulti = false,
  isRequired = false,
  error,
  isDisabled = false,
  onClickChange,
  focusHandler = () => {},
}: DropdownProps) => {
  const componentClassName = "a-dropdown";

  const { Container } = createComponentBase(componentClassName, "div");

  const modifiers = {};
  return (
    <Container title={title} modifiers={modifiers} className={className}>
      <Tooltip message={error} isVisible={isError}>
        <Select
          value={selected}
          
          onChange={(selected) =>
            onClickChange(selected as { label: string; value: string })
          }
          onFocus={focusHandler}
          placeholder={placeholder}
          //read only select dropdown
          options={options}
          isMulti={isMulti}
          hideSelectedOptions={false}
          styles={customStyles(isError)}
          className={className}
          classNamePrefix="react-select"
          isDisabled = {isDisabled}
          required={isRequired}
        />
      </Tooltip>
    </Container>
  );
};
