import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["youtrackTicketList", "youtrackTicketDetail" ,"youtrackSupportTicketsReport","youtrackSupportTicketsSpendTimeReport"],
});

export const youtrackSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * fetch data from api
     * @returns
     * data: ResponseDataType | undefined;
     * @query search: string;
     * @query limit: number;
     * @query current_page: number;
     * @query sort_by: string;
     * @query sort_direction: string;
     * @query state: [string];
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     * refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ResponseDataType, unknown>>;
     */
    youtrackTicketList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction,state }) => ({
        url: API_URL.YOUTRACK_TICKETS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${search ? `&search=${search}` : ""}${sort_by ? `&sort_by=${sort_by}` : ""
          }${sort_direction ? `&sort_direction=${sort_direction}` : ""}${state ? `&state=${state}` : ""}`,
        method: "GET",
      }),
      providesTags: ["youtrackTicketList"],
    }),

    /**
     * fetch data from api
     * @returns
     * data: ResponseDataType | undefined;
     * @param id
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     * refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ResponseDataType, unknown>>;
     */

    youtrackTicketDetail: builder.query<ResponseDataType, number>({
      query: (id) => ({
        url: API_URL.YOUTRACK_TICKETS + `/${id}`,
        method: "GET",
      }),
      providesTags: ["youtrackTicketDetail"],
    }),

    /**
     * Youtrack Support Tickets Report
     * @returns
     * data: ResponseDataType | undefined;
     * @query type: string;
     * @query year: number;
     * @query month: number;
     * @query weeks: number;
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     */

    youtrackSupportTicketsReport: builder.query<ResponseDataType, listDataType>({
      query:({ type, year,month,weeks }) => ({
        url: API_URL.YOUTRACK_TICKET_REPORTS + `?type=${type}${year ? `&year=${year}` : ""}${month ? `&month=${month}` : ""}${weeks ? `&weeks=${weeks}` : ""}`,
        method: "GET",
      }),
      providesTags: ["youtrackSupportTicketsReport"],
    }),

    /**
     * Youtrack Support Tickets Spent Time Report
     * @returns
     * data: ResponseDataType | undefined;
     * @query type: string;
     * @query year: number;
     * @query month: number;
     * @query weeks: number;
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     */

    youtrackSupportTicketsSpentTimeReport: builder.query<ResponseDataType, listDataType>({
      query:({ type, year,month,weeks }) => ({
        url: API_URL.YOUTRACK_TICKET_SPENT_TIME_REPORTS + `?type=${type}${year ? `&year=${year}` : ""}${month ? `&month=${month}` : ""}${weeks ? `&weeks=${weeks}` : ""}`,
        method: "GET",
      }),
      providesTags: ["youtrackSupportTicketsSpendTimeReport"],
    }),

    /**
     * Sync Tickets
     * @returns
     * data: ResponseDataType | undefined;
     * isLoading: boolean;
     * isFetching: boolean;
     * isSuccess: boolean;
     * isError: boolean;
     * error: any;
     */

    syncTickets: builder.mutation<ResponseDataType, listDataType>({
      query: () => ({
        url: API_URL.YOUTRACK_SYNC_TICKETS,
        method: "GET",
      }),
      invalidatesTags: ["youtrackTicketList", "youtrackTicketDetail", "youtrackSupportTicketsReport", "youtrackSupportTicketsSpendTimeReport"],
    }),
  }),
});

export const {
  useYoutrackTicketListQuery,
  useYoutrackTicketDetailQuery,
  useYoutrackSupportTicketsReportQuery,
  useYoutrackSupportTicketsSpentTimeReportQuery,
  useSyncTicketsMutation,
} = youtrackSlice;
