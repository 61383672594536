import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    useOtpLoginMutation,
    useResendOTPMutation,
} from "../../../../store/auth/authSlice";
import { useDispatch } from "react-redux";
import { ResponseType, payloadType } from "../../../../types";
import { userActions } from "../../../../store/auth/user";
import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG } from "../../../../constants/constants";
import { Input } from "../../../atoms/input";
import logo from "../../../../assets/logo/logo.png";
import { Divider } from "../../../atoms/divider";
import { authActions } from "../../../../store/auth/auth";


const formattedDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
  
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
  
    return `${month}, ${day < 10 ? `0${day}` : day} ${year} ${hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
}

const OTPLogin = () => {
    const pageClassName = "p-otpLogin";
    const { data } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loginApi, { isLoading }] = useOtpLoginMutation();
    const [resendOtpApi] = useResendOTPMutation();
    const [remainingTime, setRemainingTime] = React.useState(0);
    //base 64 decode
    const param = JSON.parse(atob(data || ""));
    var expiryDate = localStorage.getItem("expiryDate");
    localStorage.setItem(
        "expiryDate",
        new Date(formattedDateTime(expiryDate as string)).getTime() >
            new Date(formattedDateTime(param?.expiryDate as string)).getTime()
            ? expiryDate
            : param?.expiryDate
    );

    const [payload, setPayload] = React.useState<payloadType>({
        otp: {
            value: "",
            error: "",
            isError: false,
        },
        expiryDate: {
            value: param?.expiryDate,
            error: "",
            isError: false,
        },
        remember_token: {
            value: param?.rememberToken,
            error: "",
            isError: false,
        },
    });

    React.useEffect(() => {
        setPayload((prevState) => ({
            ...prevState,
            expiryDate: {
                value: expiryDate as string,
                error: "",
                isError: false,
            },
        }));
    }, [expiryDate]);

    /**
     * Handles the form submission for OTP login.
     * @param e - The form event.
     */
    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        if (param?.success) {
            const formData = new FormData();
            formData.append("remember_token", payload.remember_token.value as string);
            formData.append("email", param?.user?.email);
            formData.append("otp", payload.otp.value as string);

            if (param?.user?.is_agree_terms) {
                const toastId = toast.loading("Loading...");
                const response = (await loginApi(formData)) as ResponseType;
                if (response?.data?.success) {
                    dispatch(userActions.setUser(response.data.result.user));
                    dispatch(authActions.setToken(response.data.result.token));
                    dispatch(authActions.setPermissions(response.data.result.user.role[0].permissions));
                    dispatch(authActions.setRole(response.data.result.user.role[0]));

                    toast.update(toastId, {
                        render: response?.data?.message,
                        type: toast.TYPE.SUCCESS,
                        isLoading: isLoading,
                        autoClose: 2000,
                    });

                    navigate("/");
                } else {
                    if (response?.error?.data?.errors) {
                        Object.keys(response?.error?.data?.errors).forEach((key) => {
                            setPayload((prevState) => ({
                                ...prevState,
                                [key]: {
                                    ...prevState[key],
                                    error: response?.error?.data?.errors[key][0],
                                    isError: true,
                                },
                            }));
                        });
                        toast.dismiss(toastId);
                    } else {
                        if (response?.error?.data?.message) {
                            toast.update(toastId, {
                                render: response?.error?.data?.message,
                                type: toast.TYPE.ERROR,
                                isLoading: isLoading,
                                autoClose: 2000,
                            });
                        } else {
                            toast.update(toastId, {
                                render: SOMETHING_WENT_WRONG,
                                type: toast.TYPE.ERROR,
                                isLoading: isLoading,
                                autoClose: 2000,
                            });
                        }
                    }
                }
            } else {
                navigate("/privacy-policy", {
                    state: {
                        user: {
                            email: param?.user?.email,
                            provider: param?.user?.provider,
                            remember_token: payload.remember_token.value as string,
                        },
                    },
                });
            }
        }
    };

    const resendOtpHandler = async () => {
        const formData = new FormData();
        formData.append("email", param?.user?.email);
        formData.append("remember_token", payload.remember_token.value as string);
        const response = (await resendOtpApi(formData)) as ResponseType;
        if (response?.data?.success) {
            toast.success(response?.data?.message);
            setPayload({
                ...payload,
                expiryDate: {
                    value: response?.data?.result?.expiryDate,
                    error: "",
                    isError: false,
                },
            });
            localStorage.setItem("expiryDate", response?.data?.result?.expiryDate);
        } else {
            toast.error(response?.data?.message);
        }
    };

    React.useEffect(() => {
        const formattedExpiryDate = new Date(
            formattedDateTime(payload.expiryDate.value as string)
        ).getTime();
        const currentDateTime = new Date().getTime();

        // Calculate the remaining seconds
        const differenceInSeconds = Math.floor(
            (formattedExpiryDate - currentDateTime) / 1000
        );

        // Update state with the remaining seconds
        setRemainingTime(differenceInSeconds);

        // Update the remaining seconds every second
        const interval = setInterval(() => {
            setRemainingTime((prevSeconds) => {
                if (prevSeconds && prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(interval);
                    return 0;
                }
            });
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [payload.expiryDate.value]);

    return (
        <div className={pageClassName}>
            <div className={`${pageClassName}__body`}>
                <div className={`${pageClassName}__body__container`}>
                    <div className={`${pageClassName}__body__logo`}>
                        <img src={logo} alt="logo" />
                    </div>
                    <form onSubmit={submitHandler} className={`${pageClassName}__form`}>
                        <h1 className={`${pageClassName}__form__heading`}>
                            Email Verification
                        </h1>
                        <p className={`${pageClassName}__form__title`}>
                            Verification code has been sent to your email address. Please
                            enter the code below to verify your email address.
                        </p>
                        <p className={`${pageClassName}__form__email`}>
                            {param?.user?.email}
                        </p>
                        <div className={`${pageClassName}__form__input`}>
                            <Input
                                type="number"
                                placeholder="Enter Verification Code"
                                value={payload.otp.value as number}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        otp: {
                                            value: e.target.value,
                                            error: "",
                                            isError: false,
                                        },
                                    })
                                }
                                error={payload.otp.error}
                                isError={payload.otp.isError}
                            />
                        </div>
                        <p className={`${pageClassName}__form__expiry`}>
                            Verification code will expire at{" "}
                            <span>
                                {formattedDateTime(payload?.expiryDate.value as string)}
                            </span>
                            . Remaining: <span>{remainingTime > 0 ? remainingTime : 0}</span> seconds
                        </p>
                        <Divider />
                        <div className={`${pageClassName}__form__action`}>
                            <button type="submit" className={`${pageClassName}__form__btn`}>
                                Verify
                            </button>
                            <button
                                type="button"
                                className={`${pageClassName}__form__btn ${pageClassName}__form__btn--resend`}
                                onClick={resendOtpHandler}
                                disabled={remainingTime > 150}
                            >
                                Resend Code
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OTPLogin;
