import React from "react";
import { useTableHooks } from "../../../atoms/table/useTableHooks";
import { usePaginationHook } from "../../../molecules/pagination/usePaginationHook";
import { Breadcrumb } from "../../../atoms/breadcrumb";
import { Input } from "../../../atoms/input";
import { Table } from "../../../atoms/table";
import { Divider } from "../../../atoms/divider";
import { Dropdown } from "../../../atoms/dropdown";
import { Pagination } from "../../../molecules/pagination";
import { useNavigate } from "react-router-dom";
import { useUserPricingListQuery } from "../../../../store/pricing/pricingSlice";
import { Icon } from "../../../atoms/icon";
import { numberFormat, numberFormatWithDynamicDecimal, pascalToSnakeCase } from "../../../../utils";
import { PAGE_NAME, ROUTE } from "../../../../constants/constants";

const UserPricingList = () => {
    const pageClassName = "p-userPricingList";

    const navigate = useNavigate();

    const {
        search,
        query,
        pageChangeHandler,
        limitChangeHandler,
        searchHandler,
        sortHandler,
    } = useTableHooks();

    const { data, isLoading, isFetching, isSuccess } =
        useUserPricingListQuery({
            search: query.search,
            limit: query.limit,
            current_page: query.current,
            sort_by: pascalToSnakeCase(query.sortBy),
            sort_direction: query.sortDirection,
        });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: false,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            isSortable: true,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            isSortable: true,
        },
        {
            title: "Email",
            dataIndex: "email",
            isSortable: true,
        },
        {
            title: "Total",
            dataIndex: "organizationsTotalPrice",
            isSortable: false,
        },
        {
            title: "Okta",
            dataIndex: "oktaCost",
            isSortable: false,
        },

        {
            title: "YouTrack",
            dataIndex: "youtrackCost",
            isSortable: false,
        },
        {
            title: "Box",
            dataIndex: "boxCost",
        },
        {
            title: "Confluence",
            dataIndex: "confluenceCost",
            isSortable: false,
        },
        {
            title: "Atlassian",
            dataIndex: "atlassianCost",
            isSortable: false,
        },
        {
            title: "Gitlab",
            dataIndex: "gitlabCost",
            isSortable: false,
        },
        {
            title: "Miro",
            dataIndex: "miroCost",
            isSortable: false,
        },
        {
            title: "GCP",
            dataIndex: "gcpCost",
            isSortable: false,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string) => {
        navigate(ROUTE.OKTA_USER_LIST + `/${id}`);
    };


    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <div className={`${pageClassName}__card`}>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Total Monthly Cost
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess
                                ? numberFormat(
                                    data?.result?.totalPrice,"JPY"
                                )
                                : 0}
                        </p>
                    </div>
                    <Icon icon="money" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>Total Yearly Cost</p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess
                                ? numberFormat(
                                    data?.result?.totalPrice * 12,"JPY"
                                )
                                : 0}
                        </p>
                    </div>
                    <Icon icon="moneyBundle" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Number of Users
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess ? data?.result?.total : 0}
                        </p>
                    </div>
                    <Icon icon="user" size="tertiary" />
                </div>
                <div className={`${pageClassName}__card__item`}>
                    <div className={`${pageClassName}__card__container`}>
                        <p className={`${pageClassName}__card__title`}>
                            Number of Licenses
                        </p>
                        <p className={`${pageClassName}__card__value`}>
                            {isSuccess
                                ? numberFormatWithDynamicDecimal(data?.result?.numberOfLicense,0)
                                : 0}
                        </p>
                    </div>
                    <Icon icon="license" size="tertiary" />
                </div>
            </div>

            <p className={`${pageClassName}__title`}>{PAGE_NAME.USER_WISE_BUDGET}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        minWidth="1600"
                        startIndex={data?.result?.from}
                        viewHandler={viewHandler}
                        sortBy={query.sortBy}
                        sortHandler={sortHandler}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserPricingList;
