import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTableHooks } from '../../../../atoms/table/useTableHooks';
import { useSaasToolListQuery } from '../../../../../store/saasTool/saasToolSlice';
import { pascalToSnakeCase } from '../../../../../utils';
import { usePaginationHook } from '../../../../molecules/pagination/usePaginationHook';
import { Breadcrumb } from '../../../../atoms/breadcrumb';
import { Input } from '../../../../atoms/input';
import { Table } from '../../../../atoms/table';
import { Divider } from '../../../../atoms/divider';
import { Dropdown } from '../../../../atoms/dropdown';
import { Pagination } from '../../../../molecules/pagination';
import { PAGE_NAME, ROUTE } from '../../../../../constants/constants';
import { TableDataType } from '../../../../../types';

const toolSortFormattedHandler = (sortBy: string) => {
    if (sortBy === "userFirstName") {
        sortBy = "firstName";
    } else if (sortBy === "userLastName") {
        sortBy = "lastName";
    } else if (sortBy === "userEmail") {
        sortBy = "email";
    }else if (sortBy === "lastLoginTime") {
        sortBy = "lastLogin";
    }

    return sortBy;
};

const GcpUserList = () => {
    const pageClassName = "p-gcpUserList";
    const navigate = useNavigate();

    const [index, setIndex] = React.useState(-1);

    const {
        search,
        query,
        pageChangeHandler,
        sortHandler,
        limitChangeHandler,
        searchHandler,
    } = useTableHooks();

    const { data, isLoading } = useSaasToolListQuery({
        search: query.search,
        limit: query.limit,
        current_page: query.current,
        sort_by: pascalToSnakeCase(toolSortFormattedHandler(query.sortBy)),
        sort_direction: query.sortDirection,
        tool_name: "GCP",
    });

    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "First Name",
            dataIndex: "userFirstName",
            isSortable: true,
        },
        {
            title: "Last Name",
            dataIndex: "userLastName",
            isSortable: true,
        },
        {
            title: "Email",
            dataIndex: "userEmail",
            isSortable: true,
        },
        {
            title: "Status",
            dataIndex: "userStatus",
            isSortable: false,
        },
        {
            title: "Last Login At",
            dataIndex: "lastLoginTime",
            isSortable: true,
        },
        {
            title: "Created At",
            dataIndex: "created",
            isSortable: true,
        },
    ];

    

    const formattedSortBy = (sortBy: string) => {
        switch (sortBy) {
            case "first_name":
                return "userFirstName";
            case "last_name":
                return "userLastName";
            default:
                return sortBy;
        }
    };

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string, e: any) => {
        const user = data?.result?.data?.find((item:TableDataType) => item.id === id);
        navigate(ROUTE.GCP_USER_LIST + `/${id}`,
        {
            state: {
              name: user?.oktaUser?.firstName + " " + user?.oktaUser?.lastName,
            },
          }
        );
    };

    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.GCP_USER_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__search`}>
                    <Input
                        placeholder="Search"
                        onChange={(e) => {
                            searchHandler(e);
                            handleFirstPage();
                        }}
                        value={search}
                    />
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        minWidth="1440"
                        startIndex={data?.result?.from}
                        showMoreHandler={(num) => setIndex(num)}
                        showLessHandler={() => setIndex(-1)}
                        clickedIndex={index}
                        sortBy={formattedSortBy(query.sortBy)}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GcpUserList;