import { API_URL } from "../../constants/constants";
import { ResponseDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ['UserList', 'singleUser', 'Role'],
});

/**
 * A slice of the Redux store for managing authentication.
 * @remarks
 * This slice includes endpoints for making API requests related to authentication.
 */
export const authSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        /**
         * Performs a login request.
         *
         * @param payload - The login data.
         * @returns A promise that resolves to the response data.
         */
        login: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.LOGIN,
                method: "POST",
                body: payload,
            }),
        }),
        /**
         * Performs a SAML login request.
         * @param payload - The data to be sent in the request body.
         * @returns A Promise that resolves to the response data.
         */
        samlLogin: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.SAML_LOGIN,
                method: "POST",
                body: payload,
            }),
        }),
        /**
         * Performs an OTP login request.
         *
         * @param payload - The data to be sent in the request body.
         * @returns A Promise that resolves to the response data.
         */
        otpLogin: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.LOGIN + "-" + API_URL.OTP,
                method: "POST",
                body: payload,
            }),
        }),
        /**
         * Resend the OTP (One-Time Password) to the server.
         * @param payload - The data to be sent in the request body.
         * @returns A Promise that resolves to the response data.
         */
        resendOTP: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.RESEND_OTP,
                method: "POST",
                body: payload,
            }),
        }),
        /**
         * Registers a user.
         *
         * @param payload - The user data to be registered.
         * @returns A promise that resolves to the response data.
         */
        register: builder.mutation<ResponseDataType, FormData>({
            query: (payload) => ({
                url: API_URL.REGISTER,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ['UserList', 'singleUser'],
        }),
        /**
         * Logs out the user.
         *
         * @returns A promise that resolves to the response data.
         */
        logout: builder.mutation<ResponseDataType, void>({
            query: () => ({
                url: API_URL.LOGOUT,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useSamlLoginMutation,
    useOtpLoginMutation,
    useResendOTPMutation,
    useRegisterMutation,
    useLogoutMutation,
} = authSlice;
