import React from "react";
import {
  BaseComponentProps,
  createComponentBase,
} from "../../../libs/components";


export interface PaginationProps extends BaseComponentProps {
  className?: string;
  page: number;
  totalData: number;
  handleFirstPage: () => void;
  handlePrevPage: (disabled: boolean) => void;
  handleLastPage: () => void;
  handleNextPage: (disabled: boolean) => void;
  renderPageNumbers: () => JSX.Element[];
}

export const Pagination = ({
  className,
  page,
  totalData,
  handleFirstPage,
  handlePrevPage,
  handleLastPage,
  handleNextPage,
  renderPageNumbers,
}: PaginationProps) => {
  const componentClassName = "m-pagination";
  const modifiers = {};
  const { Container } = createComponentBase(componentClassName, "div");


  return (
    <Container className={className} modifiers={modifiers}>
      <button
        onClick={handleFirstPage}
        className={`${componentClassName}__btn ${
          page === 1 && `${componentClassName}__btn--disabled`
        } `}
      >
        {"<<"}
      </button>
      <button
        onClick={() => handlePrevPage(page === 1)}
        className={`${componentClassName}__btn ${
          page === 1 && `${componentClassName}__btn--disabled`
        } `}
      >
        {"<"}
      </button>
      {renderPageNumbers()}
      <button
        onClick={() => handleNextPage(page === totalData)}
        className={`${componentClassName}__btn ${
          page === totalData && `${componentClassName}__btn--disabled`
        } `}
      >
        {">"}
      </button>
      <button
        onClick={handleLastPage}
        className={`${componentClassName}__btn ${
          page === totalData && `${componentClassName}__btn--disabled`
        } `}
      >
        {">>"}
      </button>
    </Container>
  );
};
