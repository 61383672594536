import React from "react";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Input } from "../../../../atoms/input";
import { pascalToSnakeCase } from "../../../../../utils";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";
import { Dropdown } from "../../../../atoms/dropdown";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import { Pagination } from "../../../../molecules/pagination";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { useNavigate } from "react-router-dom";
import { useApprovedRequestListQuery } from "../../../../../store/requestManagement/requestSlice";

const toolSortHandler = (sortBy: string) => {
    if (sortBy === "createdAtTime") {
        sortBy = "createdAt";
    } else if (sortBy === "requestStatus") {
        sortBy = "status";
    } else if (sortBy === "requestId") {
        sortBy = "id";
    }

    return sortBy;
};

const ApprovedRequestList = () => {
    const pageClassName = "p-approvedRequestList";

    const navigate = useNavigate();
    const {
        search,
        query,
        sortHandler,
        searchHandler,
        limitChangeHandler,
        pageChangeHandler,
    } = useTableHooks();

    const { data, isLoading, isFetching } = useApprovedRequestListQuery({
        search: query.search,
        sort_by: pascalToSnakeCase(toolSortHandler(query.sortBy)),
        sort_direction: query.sortDirection,
        limit: query.limit,
        current_page: query.current,
    });


    const tableHeader = [
        {
            title: "Serial",
            dataIndex: "id",
            isSortable: true,
        },
        {
            title: "ID",
            dataIndex: "requestId",
            isSortable: true,
        },
        {
            title: "Requester",
            dataIndex: "requester",
            isSortable: true,
        },
        {
            title: "Type",
            dataIndex: "type",
            isSortable: true,
        },
        {
            title: "Sub Type",
            dataIndex: "subType",
            isSortable: true,
        },
        {
            title: "Status",
            dataIndex: "requestStatus",
            isSortable: true,
        },
        {
            title: "Number of Request",
            dataIndex: "numberOfRequests",
            isSortable: true,
        },
        {
            title: "Created At",
            dataIndex: "createdAtTime",
            isSortable: true,
        },
    ];

    const {
        totalData,
        handleFirstPage,
        handlePrevPage,
        handleLastPage,
        handleNextPage,
        renderPageNumbers,
    } = usePaginationHook({
        totalNumberOfData: data?.result?.total,
        perPage: query.limit,
        setCurrentPage: pageChangeHandler,
        currentPage: query.current,
        className: "m-pagination",
    });

    const viewHandler = (id: string) => {
        navigate(ROUTE.APPROVED_REQUEST_LIST + "/" + id);
    };



    return (
        <div className={pageClassName}>
            <Breadcrumb />
            <p className={`${pageClassName}__title`}>{PAGE_NAME.APPROVED_REQUEST_LIST}</p>
            <div className={`${pageClassName}__container`}>
                <div className={`${pageClassName}__header`}>
                    <div className={`${pageClassName}__search`}>
                        <Input
                            placeholder="Search"
                            onChange={searchHandler}
                            value={search}
                        />
                    </div>
                </div>
                <div className={`${pageClassName}__table`}>
                    <Table
                        header={tableHeader}
                        data={data?.result?.data ?? []}
                        loading={isLoading || isFetching}
                        firstLoad={isLoading}
                        sortHandler={sortHandler}
                        viewHandler={viewHandler}
                        minWidth="800"
                        minHeight="330"
                        startIndex={data?.result?.from}
                        sortBy={query.sortBy}
                        cursorPointer
                    />
                </div>
                <Divider />
                <div className={`${pageClassName}__pagination`}>
                    <div className={`${pageClassName}__pagination__limit`}>
                        <p>Display</p>
                        <div className={`${pageClassName}__pagination__dropdown`}>
                            <Dropdown
                                options={[
                                    { label: "20", value: 20 },
                                    { label: "50", value: 50 },
                                    { label: "100", value: 100 },
                                ]}
                                selected={{ label: query.limit, value: query.limit }}
                                onClickChange={(e) => {
                                    limitChangeHandler(e.value as number);
                                    handleFirstPage();
                                }}
                                isBordered
                                isIcon
                                iniDirection="top"
                            />
                        </div>
                    </div>
                    <div className={`${pageClassName}__pagination__number`}>
                        {data?.result?.total ? (
                            <p>
                                Show {data?.result?.from} -{" "}
                                {data?.result?.from + query.limit - 1 < data?.result?.total
                                    ? data?.result?.from + query.limit - 1
                                    : data?.result?.total}{" "}
                                of {data?.result?.total}
                            </p>
                        ) : (
                            <p>Show 00 - 00 of 00</p>
                        )}
                    </div>

                    <div className={`${pageClassName}__pagination__nav`}>
                        {data?.result?.total > query.limit && (
                            <Pagination
                                totalData={totalData}
                                page={query.current}
                                handleFirstPage={handleFirstPage}
                                handlePrevPage={handlePrevPage}
                                handleLastPage={handleLastPage}
                                handleNextPage={handleNextPage}
                                renderPageNumbers={renderPageNumbers}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApprovedRequestList;
