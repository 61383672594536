import React from "react";
import { Loader } from "../../../atoms/loader";
import { useLogoutMutation } from "../../../../store/auth/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../../../store/auth/user";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authActions } from "../../../../store/auth/auth";
import { AuthStateType } from "../../../../types";

const Logout = () => {
  const [logoutApi, { isLoading }] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state: AuthStateType) => state.auth.token);


  React.useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const response = (await logoutApi()) as any;
        if (response?.data?.success) {

          dispatch(userActions.setUser(null));
          dispatch(authActions.setToken(""));
          if (response?.data?.result === 'okta')
            window.location.href = process.env.REACT_APP_OKTA_URL as string;
          else {
            toast.success(response?.data?.message);
            navigate("/login");
          }
        } else {
          toast.error(response?.data?.message);
          dispatch(userActions.setUser(null));
          dispatch(authActions.setToken(""));
          navigate("/login");
        }
      };
      fetchData();
    } else {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Loader isLoading={isLoading} align="center">
        <div style={{ height: "100vh", width: "100vh" }}></div>
      </Loader>
    </div>
  );
};

export default Logout;