import React from "react";
import {
    BaseComponentProps,
    createComponentBase,
} from "../../../libs/components";

import { Tooltip } from "../tooltip";

export interface FileUploadProps extends BaseComponentProps {
    onUpload: (files: File[]) => void;
    placeholder?: string;
    isError?: boolean;
    error?: string;
}

export const MultipleFileUpload = ({
    onUpload,
    placeholder,
    isError,
    error,
    className,
}: FileUploadProps) => {
    const componentClassName = "a-multipleFileUpload";
    const { Container } = createComponentBase(componentClassName, "div");

    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            setSelectedFiles(filesArray);
            onUpload(filesArray);
        }
    };

    const modifiers = {
        [`${!placeholder && "placeholder"}`]: !!placeholder,
        'isUploaded': selectedFiles.length > 0,
        'error': isError,
    };

    return (
        <Container className={className} modifiers={modifiers}>
            <Tooltip message={error} isVisible={isError}>
                <div className={`${componentClassName}__container`} tabIndex={1}>
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className={`${componentClassName}__input`}
                    />

                    <span className={`${componentClassName}__placeholder`}>{selectedFiles.length > 0 ? `${selectedFiles.length} files selected` : placeholder}</span>
                    <button className={`${componentClassName}__button`}>Browse File</button>
                </div>
            </Tooltip>
            <div className={`${componentClassName}__files`}>
                {selectedFiles.map((file, index) => (
                    <span key={index} className={`${componentClassName}__files__name`}>{file.name}</span>
                ))}
            </div>
        </Container>
    );
};
