import { createSlice } from "@reduxjs/toolkit";
const token = localStorage.getItem("token");
/**
 * Represents the initial state of the authentication module.
 */
const initialState = {
  token: token,
  role: null,
  permissions: [{ id: 17, title: "dashboard", displayTitle: "Dashboard" }],
};

/**
 * Represents the authentication slice of the Redux store.
 */
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
