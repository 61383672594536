import { API_URL } from "../../constants/constants";
import { ResponseDataType, listDataType } from "../../types";
import { apiSlice } from "../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "saasToolList",
    "singleSaasTool",
    "saasToolUserLogs",
    "gitlabGroupList",
    "gitlabGroupStorageComparison",
    "gitlabProjectsList",
    "saasToolGroupList",
    "userGroupList",
    "saasToolGroupUsers",
    "singleSaasToolGroup",
    "singleSaasToolProject",
    "licenseList",
    "saasToolTeamList",
    "saasToolSingleTeam",
    "saasToolTeamMembers",
    "saasToolBoardList",
    "singleSaasToolBoard",
    "boardsMemberList",
    "saasToolProjectMembers",
    "saasToolUserProjects",
    "saasToolUserTeams",
    "saasToolUserBoards",
    "gitlabPipelineReport",
    "saasToolInvitedUsers",
    "userPricingList",
    "organizationList",
    "organizationDetails",
    "organizationsPricing",
  ],
});

export const appSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Fetches a list of saas tools based on the provided parameters.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param tool_name - The name of the tool to filter the results by.
     * @returns A promise that resolves to the response data.
     */
    saasToolList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}
        ${tool_name ? `&tool_name=${tool_name}` : ""}`,

        method: "GET",
      }),
      providesTags: ["saasToolList"],
    }),

    /**
     * Mutation function to sync user data.
     * @returns A promise that resolves to the response data.
     */
    syncUser: builder.mutation<ResponseDataType, void>({
      query: () => ({
        url: API_URL.SAAS_TOOLS + API_URL.SYNC_USER,
        method: "POST",
        timeout: 600000,
      }),
      invalidatesTags: [
        "saasToolList",
        "singleSaasTool",
        "licenseList",
        "saasToolInvitedUsers",
        "saasToolUserProjects",
        "organizationList",
        "organizationDetails",
        "organizationsPricing",
      ],
    }),

    /**
     * Fetches a single SaasTool from the server.
     *
     * @param id - The ID of the SaasTool to fetch.
     * @returns A promise that resolves to the response data.
     */
    singleSaasTool: builder.query<ResponseDataType, listDataType>({
      query: ({ id }) => ({
        url: API_URL.SAAS_TOOLS + `/${id}`,
        method: "GET",
      }),
      providesTags: ["singleSaasTool"],
    }),

    /**
     * Fetches the list of invited users for the Saas Tool.
     *
     * @param search - The search query for filtering the invited users.
     * @param limit - The maximum number of invited users to retrieve.
     * @param current_page - The current page of invited users to retrieve.
     * @param sort_by - The field to sort the invited users by.
     * @param sort_direction - The direction to sort the invited users in.
     * @param tool_name - The name of the Saas Tool.
     * @returns A promise that resolves to the response data containing the list of invited users.
     */
    saasToolInvitedUsers: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.INVITED_USER_LIST + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${tool_name ? `&tool_name=${tool_name}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["saasToolInvitedUsers"],
    }),

    /**
     * Fetches user projects for a specific tool from the server.
     *
     * @param id - The ID of the user.
     * @param search - The search query for filtering projects.
     * @param limit - The maximum number of projects to fetch.
     * @param current_page - The current page number for pagination.
     * @param sort_by - The field to sort the projects by.
     * @param sort_direction - The direction to sort the projects in.
     * @param tool_name - The name of the tool.
     * @returns A promise that resolves to the response data.
     */
    saasToolUserProjects: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.USER_PROJECTS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${tool_name ? `&tool_name=${tool_name}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["saasToolUserProjects"],
    }),

    /**
     * Fetches user teams for a specific Saas tool.
     *
     * @param id - The ID of the Saas tool.
     * @param search - The search query for filtering user teams.
     * @param limit - The maximum number of user teams to fetch.
     * @param current_page - The current page of user teams to fetch.
     * @param sort_by - The field to sort the user teams by.
     * @param sort_direction - The direction to sort the user teams in.
     * @param tool_name - The name of the Saas tool.
     * @returns A promise that resolves to the response data containing the user teams.
     */
    saasToolUserTeams: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.USER_TEAMS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${tool_name ? `&tool_name=${tool_name}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["saasToolUserTeams"],
    }),

    /**
     * Fetches user boards for a specific tool from the server.
     *
     * @param id - The ID of the tool.
     * @param search - The search query to filter user boards.
     * @param limit - The maximum number of user boards to fetch.
     * @param current_page - The current page of user boards.
     * @param sort_by - The field to sort user boards by.
     * @param sort_direction - The direction to sort user boards in.
     * @param tool_name - The name of the tool.
     * @returns A promise that resolves to the response data containing user boards.
     */
    saasToolUserBoards: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.USER_BOARDS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${tool_name ? `&tool_name=${tool_name}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["saasToolUserBoards"],
    }),

    /**
     * Mutation function for uploading a file and syncing users by CSV.
     *
     * @param formData - The form data containing the file to upload.
     * @returns A Promise that resolves to the response data.
     */
    fileUpload: builder.mutation<ResponseDataType, FormData>({
      query: (formData) => ({
        url: API_URL.SAAS_TOOLS + API_URL.USER_SYNC_BY_CSV,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        "saasToolList",
        "singleSaasTool",
        "organizationList",
        "organizationDetails",
        "organizationsPricing",
        "userPricingList",
      ],
    }),

    /**
     * Query function to fetch user logs for a specific Saas tool.
     * @param id - The ID of the Saas tool.
     * @param tool_name - The name of the Saas tool.
     * @param limit - The maximum number of logs to fetch.
     * @param current_page - The current page of logs to fetch.
     * @param sort_by - The field to sort the logs by.
     * @param sort_direction - The direction to sort the logs in.
     * @param search - The search query to filter the logs.
     * @param start_date - The start date to filter the logs.
     * @param end_date - The end date to filter the logs.
     * @param event_type - The event types to filter the logs by.
     * @returns A promise that resolves to the response data.
     */
    saasToolUserLogs: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        tool_name,
        limit,
        current_page,
        sort_by,
        sort_direction,
        search,
        start_date,
        end_date,
        event_type,
      }) => ({
        url: API_URL.SAAS_TOOLS + `/${id}/${tool_name}/logs?${limit ? `limit=${limit}` : ""
          }${current_page ? `&current_page=${current_page}` : ""}${sort_by ? `&sort_by=${sort_by}` : ""
          }${sort_direction ? `&sort_direction=${sort_direction}` : ""}${search ? `&search=${search}` : ""
          }${start_date ? `&start_date=${start_date}` : ""}${end_date ? `&end_date=${end_date}` : ""
          }${event_type && JSON.parse(event_type).length > 0
            ? `&event_type=${event_type}`
            : ""
          }`,
        method: "GET",
      }),
      providesTags: ["saasToolUserLogs"],
    }),

    /**
     * Fetches the list of saas tool groups from the server.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param tool_name - The name of the tool.
     * @returns A promise that resolves to the response data.
     */
    saasToolGroupList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.GROUP_LIST + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${`&tool_name=${tool_name}`}`,
        method: "GET",
      }),
      providesTags: ["saasToolGroupList"],
    }),

    /**
     * Retrieves the details of a single Saas Tool group.
     *
     * @param id - The ID of the Saas Tool group.
     * @returns A Promise that resolves to the response data.
     */
    singleSaasToolGroup: builder.query<any, any>({
      query: ({ id }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.GROUP_LIST + `/${id}`,
        method: "GET",
      }),
      providesTags: ["singleSaasToolGroup"],
    }),

    /**
     * Fetches the user group list for a specific tool and ID.
     * 
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param tool_name - The name of the tool.
     * @param id - The ID of the user group.
     * 
     * @returns A promise that resolves to the response data.
     */
    saasToolUserGroupList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
        id
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.USER_GROUPS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""}${search ? `&search=${search}` : ""
          }${tool_name ? `&tool_name=${tool_name}` : ""}`,
        method: "GET",
      }),
      providesTags: ["userGroupList"],
    }),

    /**
     * Fetches the group users for a specific Saas Tool.
     *
     * @param search - The search query for filtering group users.
     * @param limit - The maximum number of group users to retrieve.
     * @param current_page - The current page of group users.
     * @param sort_by - The field to sort the group users by.
     * @param sort_direction - The direction to sort the group users in.
     * @param id - The ID of the Saas Tool.
     * @param tool_name - The name of the Saas Tool.
     * @returns A Promise that resolves to the response data containing the group users.
     */
    saasToolGroupUsers: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        id,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.GROUP_USERS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""}${search ? `&search=${search}` : ""
          }${tool_name ? `&tool_name=${tool_name}` : ""}`,
        method: "GET",
      }),
      providesTags: ["saasToolGroupUsers"],
    }),

    /**
     * Fetches the list of GitLab groups.
     *
     * @param search - The search query to filter the groups.
     * @param limit - The maximum number of groups to retrieve.
     * @param current_page - The current page of groups to retrieve.
     * @param sort_by - The field to sort the groups by.
     * @param sort_direction - The direction to sort the groups in.
     * @returns A promise that resolves to the response data containing the list of GitLab groups.
     */
    gitlabGroupList: builder.query<ResponseDataType, listDataType>({
      query: ({ search, limit, current_page, sort_by, sort_direction }) => ({
        url: API_URL.GITLAB + API_URL.GROUP_LIST + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["gitlabGroupList"],
    }),

    /**
     * Query function to fetch GitLab group storage comparison data.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param weeks - The number of weeks to consider for storage comparison.
     * @returns A promise that resolves to the response data.
     */
    gitlabGroupStorageComparison: builder.query<ResponseDataType, listDataType>(
      {
        query: ({
          search,
          limit,
          current_page,
          sort_by,
          sort_direction,
          weeks,
        }) => ({
          url: API_URL.GITLAB + API_URL.GROUPS + API_URL.STORAGE_COMPARISON + `?${limit ? `limit=${limit}` : ""
            }${current_page ? `&current_page=${current_page}` : ""}${sort_by ? `&sort_by=${sort_by}` : ""
            }${sort_direction ? `&sort_direction=${sort_direction}` : ""}${search ? `&search=${search}` : ""
            }${weeks ? `&weeks=${weeks}` : ""}`,
          method: "GET",
        }),
        providesTags: ["gitlabGroupStorageComparison"],
      }
    ),

    /**
     * Fetches a list of GitLab projects based on the provided parameters.
     *
     * @param search - The search query to filter the projects.
     * @param limit - The maximum number of projects to retrieve.
     * @param current_page - The current page number for pagination.
     * @param sort_by - The field to sort the projects by.
     * @param sort_direction - The direction to sort the projects in (asc or desc).
     * @param tool_name - The name of the tool associated with the projects.
     * @returns A promise that resolves to the response data containing the list of GitLab projects.
     */
    gitlabProjectsList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.GITLAB + API_URL.PROJECTS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${`&tool_name=${tool_name}`}`,
        method: "GET",
      }),
      providesTags: ["gitlabProjectsList"],
    }),

    /**
     * Fetches the GitLab storage changed percentage from last week.
     * @returns A promise that resolves to the response data.
     */

    gitlabStorageChangedPercentage: builder.query<ResponseDataType, void>({
      query: () => ({
        url: API_URL.GITLAB + API_URL.GROUPS + '/storage-percentage-difference',
        method: "GET",
      }),
    }),

    /**
     * Fetches the GitLab pipeline reports.
     *
     * @param search - The search query.
     * @param limit - The maximum number of results to return.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param year - The year to filter the results by.
     * @param month_year - The month and year to filter the results by.
     * @returns A promise that resolves to the response data.
     */
    gitlabPipelineReport: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        year,
        month_year,
      }) => ({
        url: API_URL.GITLAB + API_URL.PIPELINE_REPORTS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${year ? `&year=${year}` : ""}${search ? `&search=${search}` : ""}${month_year ? `&month_year=${month_year}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["gitlabPipelineReport"],
    }),

    /**
     * Fetches the list of saasTool projects from the server.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of projects to retrieve.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the projects by.
     * @param sort_direction - The direction to sort the projects in.
     * @param tool_name - The name of the tool.
     * @returns A promise that resolves to the response data.
     */
    saasToolProjectsList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.PROJECT_LIST + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${`&tool_name=${tool_name}`}`,
        method: "GET",
      }),
      providesTags: ["gitlabProjectsList"],
    }),

    /**
     * Fetches a single Saas Tool project from the server.
     *
     * @param id - The ID of the project to fetch.
     * @returns A promise that resolves to the response data.
     */
    singleSaasToolProject: builder.query<ResponseDataType, listDataType>({
      query: ({ id }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.PROJECT_LIST + `/${id}`,
        method: "GET",
      }),
      providesTags: ["singleSaasToolProject"],
    }),

    /**
     * Query function to fetch project members for a Saas Tool.
     *
     * @param id - The ID of the Saas Tool project.
     * @param search - The search query to filter project members.
     * @param limit - The maximum number of project members to fetch.
     * @param current_page - The current page of project members to fetch.
     * @param sort_by - The field to sort project members by.
     * @param sort_direction - The direction to sort project members in.
     * @returns A Promise that resolves to the response data containing the project members.
     */
    saasToolProjectMembers: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.PROJECT_MEMBER + `/${id}?${limit ? `limit=${limit}` : ""
          }${current_page ? `&current_page=${current_page}` : ""}${sort_by ? `&sort_by=${sort_by}` : ""
          }${sort_direction ? `&sort_direction=${sort_direction}` : ""}${search ? `&search=${search}` : ""
          }`,
        method: "GET",
      }),
      providesTags: ["saasToolProjectMembers"],
    }),

    /**
     * Fetches the list of teams associated with a Saas Tool.
     *
     * @param search - The search query for filtering teams.
     * @param limit - The maximum number of teams to retrieve.
     * @param current_page - The current page of teams to retrieve.
     * @param sort_by - The field to sort the teams by.
     * @param sort_direction - The direction to sort the teams in.
     * @param tool_name - The name of the Saas Tool.
     * @returns A promise that resolves to the response data containing the list of teams.
     */
    saasToolTeamList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.TEAMS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${`&tool_name=${tool_name}`}`,
        method: "GET",
      }),
      providesTags: ["saasToolTeamList"],
    }),

    /**
     * Fetches a single team from the saasTool API.
     *
     * @param id - The ID of the team to fetch.
     * @returns A promise that resolves to the response data.
     */
    saasToolSingleTeam: builder.query<ResponseDataType, listDataType>({
      query: ({ id }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.TEAMS + `/${id}`,
        method: "GET",
      }),
      providesTags: ["saasToolSingleTeam"],
    }),

    /**
     * Fetches the team members for a specific Saas Tool.
     *
     * @param id - The ID of the Saas Tool.
     * @param search - The search query for filtering team members.
     * @param limit - The maximum number of team members to retrieve.
     * @param current_page - The current page of team members.
     * @param sort_by - The field to sort the team members by.
     * @param sort_direction - The direction to sort the team members in.
     * @param tool_name - The name of the Saas Tool.
     * @returns A promise that resolves to the response data containing the team members.
     */
    saasToolTeamMembers: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.TEAM_MEMBERS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${`&tool_name=${tool_name}`}`,
        method: "GET",
      }),
      providesTags: ["saasToolTeamMembers"],
    }),

    /**
     * Fetches the list of saasTool boards from the server.
     *
     * @param search - The search query string.
     * @param limit - The maximum number of items to retrieve.
     * @param current_page - The current page number.
     * @param sort_by - The field to sort the results by.
     * @param sort_direction - The direction to sort the results in.
     * @param tool_name - The name of the tool.
     * @returns A promise that resolves to the response data.
     */
    saasToolBoardList: builder.query<ResponseDataType, listDataType>({
      query: ({
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
        tool_name,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.BOARDS + `?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}${`&tool_name=${tool_name}`}`,
        method: "GET",
      }),
      providesTags: ["saasToolBoardList"],
    }),

    /**
     * Fetches the data for a single Saas Tool board.
     *
     * @param id - The ID of the Saas Tool board to fetch.
     * @returns A promise that resolves to the response data.
     */
    singleSaasToolBoard: builder.query<ResponseDataType, listDataType>({
      query: ({ id }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.BOARDS + `/${id}`,
        method: "GET",
      }),
      providesTags: ["singleSaasToolBoard"],
    }),

    /**
     * Fetches the member list of a specific board in the SAAS tool.
     *
     * @param id - The ID of the board.
     * @param search - The search query to filter the member list.
     * @param limit - The maximum number of members to retrieve.
     * @param current_page - The current page of the member list.
     * @param sort_by - The field to sort the member list by.
     * @param sort_direction - The direction to sort the member list in.
     * @returns A promise that resolves to the response data containing the member list.
     */
    saasToolBoardsMemberList: builder.query<ResponseDataType, listDataType>({
      query: ({
        id,
        search,
        limit,
        current_page,
        sort_by,
        sort_direction,
      }) => ({
        url: API_URL.SAAS_TOOLS + API_URL.BOARD_MEMBERS + `/${id}?${limit ? `limit=${limit}` : ""}${current_page ? `&current_page=${current_page}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ""}${sort_direction ? `&sort_direction=${sort_direction}` : ""
          }${search ? `&search=${search}` : ""}`,
        method: "GET",
      }),
      providesTags: ["boardsMemberList"],
    }),
  }),
});

export const {
  useSaasToolListQuery,
  useSyncUserMutation,
  useSingleSaasToolQuery,
  useSaasToolInvitedUsersQuery,
  useSaasToolUserProjectsQuery,
  useSaasToolUserTeamsQuery,
  useSaasToolUserBoardsQuery,
  useSaasToolUserLogsQuery,
  useFileUploadMutation,
  useSaasToolGroupListQuery,
  useSaasToolUserGroupListQuery,
  useSaasToolGroupUsersQuery,
  useSingleSaasToolGroupQuery,
  useGitlabGroupListQuery,
  useGitlabGroupStorageComparisonQuery,
  useGitlabProjectsListQuery,
  useGitlabPipelineReportQuery,
  useGitlabStorageChangedPercentageQuery,
  useSaasToolProjectsListQuery,
  useSingleSaasToolProjectQuery,
  useSaasToolProjectMembersQuery,
  useSaasToolTeamListQuery,
  useSaasToolSingleTeamQuery,
  useSaasToolTeamMembersQuery,
  useSaasToolBoardListQuery,
  useSingleSaasToolBoardQuery,
  useSaasToolBoardsMemberListQuery,
} = appSlice;
