import React from "react";
import { createComponentBase, BaseComponentProps } from "../../libs/components";
import { Routes, Route } from "react-router-dom";
import { Wrapper } from "../../utils";
// import { Sidebar } from "../organisms/sidebar";
import { Header } from "../organisms/header";
import { adminRoutes } from "../../constants/adminRoures";
import { Icon } from "../atoms/icon";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../../store/sidebar/sidebar";
import { SidebarComponent } from "../organisms/sidebar";
import { AuthStateType, userStateType } from "../../types";
import { useSelector } from "react-redux";

export interface TemplateProps extends BaseComponentProps {
  className?: string;
}

export const Template = ({ className }: TemplateProps) => {
  const componentClassName = "t-Template";
  const { Container } = createComponentBase(componentClassName, "div");

  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const permissions = useSelector(
    (state: AuthStateType) => state.auth.permissions
  );
  const user = useSelector((state: userStateType) => state.user.user);
  const role = user?.role[0];

  const modifiers = {
    sidebarClose: isSidebarOpen,
  };
  const formattedRoutes = () => {
    if (role?.id === 1) return adminRoutes;
    return adminRoutes.filter((route) =>
      route.path === "*"
        ? route
        : permissions?.find(
            (permission) => permission.title === route.permission
          )
    );
  };
  return (
    <Container className={className} modifiers={modifiers}>
      <Wrapper />
      <div className={`${componentClassName}__container`}>
        <div className={`${componentClassName}__sidebar`}>
          <SidebarComponent />
          {/* <Sidebar toggle={isSidebarOpen} /> */}
          <button
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
              dispatch(sidebarActions.setSidebarToggle(!isSidebarOpen));
            }}
            className={`${componentClassName}__toggle`}
          >
            <Icon icon="arrowRight" size="small" />
          </button>
        </div>
        <div className={`${componentClassName}__header`}>
          <Header isFullWidth={isSidebarOpen} />
          <div className={`${componentClassName}__content`}>
            <Routes>
              {formattedRoutes().map(({ path, element: Component }, index) => (
                <Route key={index} path={path} element={<Component />} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </Container>
  );
};
