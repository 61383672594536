import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../../atoms/breadcrumb";
import { Skeleton } from "../../../../atoms/skeleton";
import { formattedDateTime, pascalToSnakeCase } from "../../../../../utils";
import { Divider } from "../../../../atoms/divider";
import { Table } from "../../../../atoms/table";
import {
  useSaasToolBoardsMemberListQuery,
  useSingleSaasToolBoardQuery,
} from "../../../../../store/saasTool/saasToolSlice";
import { useTableHooks } from "../../../../atoms/table/useTableHooks";
import { Input } from "../../../../atoms/input";
import { usePaginationHook } from "../../../../molecules/pagination/usePaginationHook";
import { Pagination } from "../../../../molecules/pagination";
import { Dropdown } from "../../../../atoms/dropdown";
import { PAGE_NAME, ROUTE } from "../../../../../constants/constants";

const MiroBoardDetails = () => {
  const pageClassName = "p-miroBoardDetails";
  const { id } = useParams<{ id: string }>();

  const { data, isSuccess, isLoading } = useSingleSaasToolBoardQuery({ id });

  const {
    search,
    query,
    pageChangeHandler,
    sortHandler,
    limitChangeHandler,
    searchHandler,
  } = useTableHooks();

  const {
    totalData,
    handleFirstPage,
    handlePrevPage,
    handleLastPage,
    handleNextPage,
    renderPageNumbers,
  } = usePaginationHook({
    totalNumberOfData: data?.result?.total,
    perPage: query.limit,
    setCurrentPage: pageChangeHandler,
    currentPage: query.current,
    className: "m-pagination",
  });

  const { data: membersData, isLoading: membersIsLoading } =
    useSaasToolBoardsMemberListQuery({
      id,
      search,
      current_page: query.current,
      limit: query.limit,
      sort_by: pascalToSnakeCase(query.sortBy),
      sort_direction: query.sortDirection,
    });
  const appsTableHeader = [
    {
      title: "Serial",
      dataIndex: "id",
      isSortable: false,
    },
    {
      title: "First Name",
      dataIndex: "memberFirstName",
      isSortable: true,
    },
    {
      title: "Last Name",
      dataIndex: "memberLastName",
      isSortable: true,
    },
    {
      title: "Email",
      dataIndex: "memberEmail",
      isSortable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      isSortable: true,
    },
  ];

  const sortFormatHandler = (sortBy: string) => {
    switch (sortBy) {
      case "memberFirstName":
        sortBy = "first_name";
        break;
      case "memberLastName":
        sortBy = "last_name";
        break;
      case "memberEmail":
        sortBy = "email";
        break;
      default:
        break;
    }

    sortHandler(sortBy);
  };

  return (
    <div className={pageClassName}>
      <Breadcrumb />
      <p className={`${pageClassName}__title`}>{PAGE_NAME.MIRO_BOARD_INFO}</p>
      <div className={`${pageClassName}__container`}>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Saas Tool Board Id
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? (
              <Skeleton height="small" />
            ) : (
              data?.result?.toolBoardId
            )}
          </p>
        </div>
        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>Name</p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? <Skeleton height="small" /> : data?.result?.name}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Team Name
          </p>
          <Link
            className={`${pageClassName}__container__item__value ${
              data?.result?.team?.id
                ? `${pageClassName}__container__item__value--link`
                : ""
            }`}
            to={
              isSuccess && data?.result?.team?.id
                ? ROUTE.MIRO_TEAM_LIST + "/" + data?.result?.team?.id
                : ""
            }
          >
            {isLoading ? (
              <Skeleton height="small" />
            ) : (
              data?.result?.team?.name ?? "-"
            )}
          </Link>
        </div>

        {data?.result?.project?.name && (
          <div className={`${pageClassName}__container__item`}>
            <p className={`${pageClassName}__container__item__title`}>
              Project Name
            </p>
            <Link className={`${pageClassName}__container__item__value ${pageClassName}__container__item__value--link`}
              to={ROUTE.MIRO_PROJECT_LIST + "/" + data?.result?.project?.id}
            >
              {isLoading ? (
                <Skeleton height="small" />
              ) : (
                data?.result?.project?.name
              )}
            </Link>
          </div>
        )}

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Number of Members
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? (
              <Skeleton height="small" />
            ) : (
              data?.result?.numberOfMembers ?? 0
            )}
          </p>
        </div>

        <div className={`${pageClassName}__container__item`}>
          <p className={`${pageClassName}__container__item__title`}>
            Created At
          </p>
          <p className={`${pageClassName}__container__item__value`}>
            {isLoading ? (
              <Skeleton height="small" />
            ) : data?.result?.createdAt ? (
              formattedDateTime(data?.result?.createdAt)
            ) : (
              "-"
            )}
          </p>
        </div>

        {!membersIsLoading && (
          <>
            <Divider />
            <div className={`${pageClassName}__apps`}>
              <p className={`${pageClassName}__apps__title`}>Members</p>
              <div className={`${pageClassName}__apps__container`}>
                <div className={`${pageClassName}__search`}>
                  <Input
                    placeholder="Search"
                    onChange={(e) => {
                      searchHandler(e);
                      handleFirstPage();
                    }}
                    value={search}
                  />
                </div>
                <Table
                  header={appsTableHeader}
                  data={membersData?.result?.data ?? []}
                  loading={membersIsLoading}
                  firstLoad={membersIsLoading}
                  sortHandler={sortFormatHandler}
                  startIndex={membersData?.result?.from}
                />
                <div className={`${pageClassName}__pagination`}>
                  <div className={`${pageClassName}__pagination__limit`}>
                    <p>Display</p>
                    <div className={`${pageClassName}__pagination__dropdown`}>
                      <Dropdown
                        options={[
                          { label: "20", value: 20 },
                          { label: "50", value: 50 },
                          { label: "100", value: 100 },
                        ]}
                        selected={{ label: query.limit, value: query.limit }}
                        onClickChange={(e) => {
                          limitChangeHandler(e.value as number);
                          handleFirstPage();
                        }}
                        isBordered
                        isIcon
                        iniDirection="top"
                      />
                    </div>
                  </div>
                  <div className={`${pageClassName}__pagination__number`}>
                    {membersData?.result?.total ? (
                      <p>
                        Show {membersData?.result?.from} -{" "}
                        {membersData?.result?.from + query.limit - 1 <
                        membersData?.result?.total
                          ? membersData?.result?.from + query.limit - 1
                          : membersData?.result?.total}{" "}
                        of {membersData?.result?.total}
                      </p>
                    ) : (
                      <p>Show 00 - 00 of 00</p>
                    )}
                  </div>

                  <div className={`${pageClassName}__pagination__nav`}>
                    {membersData?.result?.total > query.limit && (
                      <Pagination
                        totalData={totalData}
                        page={query.current}
                        handleFirstPage={handleFirstPage}
                        handlePrevPage={handlePrevPage}
                        handleLastPage={handleLastPage}
                        handleNextPage={handleNextPage}
                        renderPageNumbers={renderPageNumbers}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MiroBoardDetails;
